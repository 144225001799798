import 'react-native-gesture-handler';
import 'setimmediate';

import React, {useEffect} from 'react';
import {useFonts} from 'expo-font';
import {LogBox} from 'react-native';
import {Provider} from 'react-redux';
import {requestTrackingPermissionsAsync} from 'expo-tracking-transparency';
import {QueryClient, QueryClientProvider} from 'react-query';
import store from './src/modules/redux/store';
import './src/modules/helpers/prototypes';
import {actions as SubscriptionActions} from './src/modules/subscription';
import NavigationContainerWrapper from './NavigationContainerWrapper';
// @ts-ignore
import ErrorBoundary from './PackageWrappers/Components/ErrorBoundary';
import AppType from './AppType';

const queryClient = new QueryClient();

const App = () => {
    const routeNameRef = React.useRef();

    const [fontsLoaded] = useFonts(
        AppType.select({
            nezmo: {
                Medium: require('./src/assets/fonts/IBMPlexSansArabic-Medium.ttf'),
                Regular: require('./src/assets/fonts/IBMPlexSansArabic-Regular.ttf'),
                Light: require('./src/assets/fonts/IBMPlexSansArabic-Light.ttf'),
            },
            fadfadah: {
                Medium: require('./src/assets/fonts/ReadexPro-Medium.ttf'),
                Regular: require('./src/assets/fonts/ReadexPro-Regular.ttf'),
                Light: require('./src/assets/fonts/ReadexPro-Light.ttf'),
            },
        }),
    );

    const askForIOSPermissionTracking = () => {
        (async () => {
            await requestTrackingPermissionsAsync();
        })();
    };

    useEffect(() => {
        askForIOSPermissionTracking();

        SubscriptionActions.configure();

        // Dimensions.addEventListener('change', () => {
        //     if (Platform.isPad && Platform.OS === 'ios') {
        //         RNRestart.Restart();
        //     }
        // });
    }, []);

    // LogBox.ignoreLogs(['componentWillReceiveProps has been renamed']);
    LogBox.ignoreLogs([
        'componentWillReceiveProps has been renamed',
        'RCTBridge required dispatch_sync to load',
        'EventEmitter',
        'Require cycle',
        'Possible stableId collision',
        'No background message handler has been set. Set a handler via the "setBackgroundMessageHandler" method.',
        'Possible stableId collision',
        'No task registered for key ReactNativeFirebaseMessagingHeadlessTask',
        'Non-serializable values were found in the navigation state',
        'Bugsnag.start() was called more than once. Ignoring.',
    ]);

    if (!fontsLoaded) {
        return <></>;
    }

    if (__DEV__) {
        return (
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <NavigationContainerWrapper routeNameRef={routeNameRef} />
                </QueryClientProvider>
            </Provider>
        );
    }
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <NavigationContainerWrapper routeNameRef={routeNameRef} />
                </QueryClientProvider>
            </Provider>
        </ErrorBoundary>
    );
};

export default App;
