import React from 'react';
import AwesomeAlert from 'react-native-awesome-alerts';
import Constants from '../../assets/Constants';
import {Theme} from '../../assets/Colors';
import lang from '../../localization';

export interface CustomAlertProps {
    show?: boolean;
    title?: string;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    onConfirmPressed: () => void;
    onCancelPressed?: () => void;
    onDismiss: () => void;
    theme: Theme;
    confirmColor?: string;
}

const CustomAlert = ({
    show = true,
    title = undefined,
    message = lang().general_confirm_message,
    confirmText = lang().general_confirm,
    cancelText = lang().cancel,
    onConfirmPressed,
    onCancelPressed,
    onDismiss,
    theme,
    confirmColor = theme.PRIMARY,
}: CustomAlertProps) => {
    return (
        <AwesomeAlert
            show={show}
            showProgress={false}
            title={title}
            message={message}
            closeOnTouchOutside
            closeOnHardwareBackPress={false}
            showCancelButton={!!onCancelPressed}
            showConfirmButton
            cancelText={cancelText}
            confirmText={confirmText}
            confirmButtonColor={confirmColor}
            cancelButtonColor={theme.DISABLED}
            onConfirmPressed={onConfirmPressed}
            onCancelPressed={onCancelPressed}
            onDismiss={onDismiss}
            overlayStyle={{
                backgroundColor: theme.TRANS_GRAY,
                width: '100%',
                height: '100%',
            }}
            contentContainerStyle={{backgroundColor: theme.BACKGROUND}}
            titleStyle={{
                color: theme.TEXT_PRIMARY,
                fontFamily: Constants.fonts.Bold,
            }}
            messageStyle={{
                color: theme.TEXT_PRIMARY,
                fontFamily: Constants.fonts.Bold,
            }}
            confirmButtonTextStyle={{
                color: theme.TEXT_WHITE,
                fontFamily: Constants.fonts.Regular,
            }}
            cancelButtonTextStyle={{
                color: theme.TEXT_WHITE,
                fontFamily: Constants.fonts.Regular,
            }}
        />
    );
};

export default CustomAlert;
