export const LOAD_CONVERSATIONS = 'conversations/LOAD_CONVERSATIONS';
export const UNLOAD_CONVERSATIONS = 'conversations/UNLOAD_CONVERSATIONS';
export const MARK_CONVERSATION_AS_ACTIVE =
    'conversations/MARK_CONVERSATION_AS_ACTIVE';
export const FREE_ACTIVE_CONVERSATION =
    'conversations/FREE_ACTIVE_CONVERSATION';
export const UNSET_IS_NEW = 'conversations/UNSET_IS_NEW';
export const SET_IS_NEW = 'conversations/SET_IS_NEW';
export const NEW_CONVERSATION_RECEIVED =
    'conversations/NEW_CONVERSATION_RECEIVED';
export const REMOVE_CONVERSATIONS = 'conversations/REMOVE_CONVERSATIONS';
export const BLOCK_CONVERSATION = 'conversations/BLOCK_CONVERSATION';
export const UNBLOCK_CONVERSATION = 'conversations/UNBLOCK_CONVERSATION';
export const SET_CONVERSATION_IS_TYPING =
    'conversations/SET_CONVERSATION_IS_TYPING';
export const UNSET_CONVERSATION_IS_TYPING =
    'conversations/UNSET_CONVERSATION_IS_TYPING';
