import React, {useState, useEffect, useRef} from 'react';
import {Dimensions, Platform, useWindowDimensions, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import pSBC from 'shade-blend-color';
import {Theme} from '../../assets/Colors';
import Interests from '../../screens/Interests';
import {User} from '../../types/data/user';
import UserInfo from './UserInfo';
import UserInterests from './UserInterests';

const MainTabHeaderSection = ({
    user,
    changeProfilePicture,
    isProfilePictureUploading,
    openChangeUsernameMenu,
    theme,
    isSubscribed,
    setAlert,
}: {
    user: User;
    changeProfilePicture: (input: any) => void;
    isProfilePictureUploading: boolean;
    openChangeUsernameMenu: () => void;
    theme: Theme;
    isSubscribed: boolean;
    setAlert: any;
}) => {
    const inests = useSafeAreaInsets();
    const {width} = useWindowDimensions();

    return (
        <LinearGradient
            angle={180}
            colors={theme.GRADIENTS.PRIMARY}
            useAngle
            style={{
                backgroundColor: theme.SECONDARY,
                opacity: 1,
                position: 'relative',
                width,
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    marginTop: inests.top,
                    paddingTop: 35,
                    paddingBottom: 20,
                    width: '100%',
                    maxWidth: 700,
                }}
            >
                <UserInfo
                    user={user}
                    changeProfilePicture={changeProfilePicture}
                    isProfilePictureUploading={isProfilePictureUploading}
                    openChangeUsernameMenu={openChangeUsernameMenu}
                    theme={theme}
                    isSubscribed={isSubscribed}
                    setAlert={setAlert}
                />
                {user.interests && user.interests.length >= 1 && (
                    <UserInterests
                        interests={user.interests}
                        theme={theme}
                    />
                )}
            </View>
        </LinearGradient>
    );
};

export default MainTabHeaderSection;
