import {NavigationProp} from '@react-navigation/native';
import React from 'react';
import {I18nManager, Platform, View} from 'react-native';
import {useSelector} from 'react-redux';
import {Theme} from '../../../assets/Colors';
import ConversationIcon from '../../../assets/images/SVG/ConversationIcon';
import lang from '../../../localization';
import {useLocalaization} from '../../../modules/app/localization';
import {getDarkModeHomeButtonTextColor} from '../../../modules/app/theme';
import {ReducerState} from '../../../types/reducer/reducer';
import {RedDot} from '../../Shared';
import {isNew} from '../ConversationsTab';
import {
    MainTabSection,
    MainTabSectionTitle,
    MainSectionBody,
} from '../MainTabBody';

const ConversationsSection = ({
    theme,
    color,
    goToTab,
}: {
    theme: Theme;
    color: string;
    goToTab: ({index}: {index: number}) => void;
}) => {
    const conversations = useSelector(
        (state: ReducerState) => state.conversationsReducer.conversations,
    );

    const newConversations =
        Array.isArray(conversations) && conversations.length > 0
            ? conversations.filter(c => isNew(c))
            : [];

    const getNewConversationsNames = () => {
        if (newConversations.length === 0) {
            return undefined;
        }

        const conversation = newConversations[0];
        let name: string | undefined = '';
        if (conversation) {
            name = conversation.isInitiated
                ? conversation.name
                : conversation.anonymous_nickname;
        }

        if (newConversations.length === 1) {
            return lang().new_message.people_messaged_you(`${name}`);
        }

        if (newConversations.length > 1) {
            name += ` ${lang().new_message.and_others}`;
            return lang().new_message.people_messaged_you(`${name}`);
        }

        return undefined;
    };

    const getTitleText = () => {
        if (newConversations.length === 0) {
            return lang().my_conversations;
        }

        if (newConversations.length === 1) {
            return lang().new_message.one_message;
        }

        if (newConversations.length === 2) {
            return lang().new_message.two_messages;
        }

        if (newConversations.length <= 10) {
            return lang().new_message.to_ten_messages;
        }

        if (newConversations.length > 10) {
            return lang().new_message.to_infinite;
        }
    };

    const bodyText = getNewConversationsNames();
    const titleText = getTitleText();

    return (
        <MainTabSection
            onPress={() => {
                goToTab({
                    index:
                        I18nManager.isRTL && Platform.OS === 'android' ? 0 : 1,
                });
            }}
            mainColor={color}
            theme={theme}
            style={{minHeight: 90}}
        >
            <>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {titleText && (
                        <MainTabSectionTitle
                            style={{marginTop: -3}}
                            text={titleText}
                            color={color}
                            theme={theme}
                            heightLightedNumber={
                                newConversations.length > 1
                                    ? newConversations.length
                                    : undefined
                            }
                        />
                    )}
                    {newConversations.length >= 1 && (
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <RedDot style={{backgroundColor: theme.DANGER}} />
                        </View>
                    )}
                </View>
                {bodyText && (
                    <MainSectionBody
                        style={{marginTop: 2}}
                        text={bodyText}
                        color={color}
                        theme={theme}
                    />
                )}
            </>
            <ConversationIconWrapper color={color} />
        </MainTabSection>
    );
};

const ConversationIconWrapper = ({color}: {color: string}) => {
    const localization = useLocalaization();

    return (
        <View
            style={[
                {
                    position: 'absolute',
                    top: -30,
                    end: -30,
                    transform: [
                        {scaleX: localization?.direction === 'rtl' ? -1 : 1},
                        {rotate: '10deg'},
                    ],
                },
            ]}
        >
            <ConversationIcon
                width={100}
                height={100}
                fill={getDarkModeHomeButtonTextColor(color) as string}
            />
        </View>
    );
};

export default ConversationsSection;
