import moment from 'moment';
import {
    formatAPITime,
    formatCreatedAtDate,
    isOnlineFromLastSeen,
} from '../helpers';

export const conversationInitialState = {
    conversationData: undefined,
    messagesData: undefined,
    userData: undefined,
};

function flipMessageSender(messages) {
    return messages.map(message => {
        let {sender} = message;
        if (sender === 'anonymous') {
            sender = 'user';
        } else {
            sender = 'anonymous';
        }

        return {
            ...message,
            sender,
        };
    });
}

const isInitiated = conversation => {
    if (conversation) {
        return !!conversation.network_key;
    }
    return false;
};

export function conversationReducer(state, action) {
    const {payload, type} = action;
    switch (type) {
        case 'loadConversationAndUserAndMessages':
            // eslint-disable-next-line no-case-declarations
            const isOnline = () => {
                if (!isInitiated(payload.conversation)) {
                    return undefined;
                }
                if (payload.user.last_seen) {
                    return isOnlineFromLastSeen(payload.user.last_seen);
                }
                return false;
            };

            return {
                ...state,
                conversationData: payload.conversation,
                messagesData: isInitiated(payload.conversation)
                    ? flipMessageSender(payload.messages)
                    : payload.messages,
                userData: {
                    ...payload.user,
                    isOnline: isOnline(),
                },
            };

        case 'loadMoreMessages':
            if (!Array.isArray(state.messagesData)) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                messagesData: [
                    ...state.messagesData,
                    ...(isInitiated(state.conversationData)
                        ? flipMessageSender(payload.messages)
                        : payload.messages),
                ],
            };

        case 'setIsFullyLoaded':
            return {
                ...state,
                conversationData: {
                    ...state.conversationData,
                    is_fully_loaded: true,
                },
            };

        case 'setLoadingMoreMessages':
            return {
                ...state,
                conversationData: {
                    ...state.conversationData,
                    is_loading_more_messages: true,
                },
            };

        case 'unsetLoadingMoreMessages':
            return {
                ...state,
                conversationData: {
                    ...state.conversationData,
                    is_loading_more_messages: false,
                },
            };

        case 'receivedMessage':
            const {newMessage} = payload;
            if (!Array.isArray(state.messagesData)) {
                return {
                    ...state,
                };
            }

            return {
                ...state,
                userData: {
                    ...state.userData,
                    isOnline: isInitiated(state.conversationData)
                        ? true
                        : undefined,
                },
                messagesData: [
                    {
                        id: newMessage.id,
                        sender: 'anonymous',
                        text: newMessage.text,
                        time: formatAPITime(),
                        created_at: newMessage.created_at,
                    },
                    ...state.messagesData,
                ],
            };

        case 'startSendMessage':
            return {
                ...state,
                messagesData: [
                    {
                        id: payload.message.id,
                        sender: 'user',
                        text: payload.message.text,
                        time: formatAPITime(),
                        created_at: formatCreatedAtDate(new Date()),
                        isUploading: true,
                    },
                    ...state.messagesData,
                ],
            };

        case 'sendMessageSuccess':
            const clonedMessages = state.messagesData
                ? [...state.messagesData]
                : [];
            clonedMessages.forEach(message => {
                if (message.id === payload.message.tempID) {
                    message.id = payload.message.id;
                    message.created_at = payload.message.created_at;
                    message.isUploading = false;
                }
            });
            return {...state, messagesData: clonedMessages};

        case 'sendMessageError':
            const clonedMessages2 = state.messagesData
                ? [...state.messagesData]
                : [];
            clonedMessages2.forEach(message => {
                if (message.id === payload.message.tempID) {
                    message.hasFailed = true;
                    message.isUploading = false;
                }
            });
            return {...state, messagesData: clonedMessages2};

        case 'setUserIsTyping':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isOnline: isInitiated(state.conversationData)
                        ? true
                        : undefined,
                    isTyping: true,
                },
            };

        case 'unsetUserIsTyping':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isTyping: false,
                },
            };

        case 'setUserOnline':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isOnline: true,
                },
            };

        case 'setUserOffline':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    isOnline: false,
                    last_seen: '',
                },
            };

        case 'freeState':
            return {
                ...conversationInitialState,
            };

        default:
            throw new Error();
    }
}
