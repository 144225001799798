import React from 'react';
import {View, StyleSheet} from 'react-native';
import {ThemeColors} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {FadeInView} from '../Animations';
import {T} from '../Shared';

const styles = StyleSheet.create({
    errorSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginStart: 20,
    },
    errorText: {
        fontFamily: Constants.fonts.Bold,
    },
    errorIcon: {
        height: 12,
        marginTop: 1,
        resizeMode: 'contain',
    },
});

const ErrorSection = ({
    error,
    darkBackground = false,
}: {
    error?: string;
    darkBackground?: boolean;
}) => {
    if (error !== undefined) {
        return (
            <FadeInView style={styles.errorSection}>
                <T
                    style={{
                        ...styles.errorText,
                        ...{
                            color: darkBackground
                                ? ThemeColors.light.TEXT_WHITE
                                : ThemeColors.light.DANGER,
                        },
                    }}
                >
                    {error}
                </T>
                <View testID="errorSection" />
            </FadeInView>
        );
    }
    return <View />;
};

export default ErrorSection;
