import React, {useEffect, useState} from 'react';
import {
    ActivityIndicator,
    AppState,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import LinearGradient from 'react-native-linear-gradient';
import {connect} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {actions as conversationActions} from '../../modules/conversation';
import Constants from '../../assets/Constants';
import {Input, T} from '../Shared';
import store from '../../modules/redux/store';
import lang from '../../localization';
import {logEvent} from '../../modules/app/tracking';
import {SCREENS} from '../../screens/Screens';

const LinkExtractor = ({extractUserLinkFromURL, theme}) => {
    const [isError, setIsError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [linkText, setLinkText] = useState('');
    const navigation = useNavigation();

    const isValidDynamicLink = link => link.includes('chat.fadfadah.net/');
    const isValidUserLink = link => link.includes('fadfadah.net/user/');
    const isValidUserLink2 = link => link.includes('fadfadah.net/u/');
    const isValidLinkLength = link => link.length < 42 && link.length > 20;
    const extractLinkFromClipboard = () => {
        if (Platform.OS === 'android') {
            Clipboard.getString()
                .then(link => {
                    if (
                        link &&
                        (isValidDynamicLink(link) ||
                            isValidUserLink(link) ||
                            isValidUserLink2(link)) &&
                        isValidLinkLength(link)
                    ) {
                        logEvent('userCopiedMessage');
                        setLinkText(link);
                    } else {
                        // console.log(`${link} wrong content`);
                    }
                })
                .catch(e => {
                    // e
                });
        }
    };

    const onCheckURL = () => {
        setIsLoading(true);
        setIsError(undefined);
        extractUserLinkFromURL({link: linkText})
            .then(userLinkResponse => {
                setIsLoading(false);
                navigation.push(SCREENS.CREATE_CONVERSATION, {
                    url: userLinkResponse.user_link,
                });
            })
            .catch(error => {
                if (error.message === 'LinkNotFound') {
                    setIsError(lang().no_user_found_with_this_link);
                } else {
                    setIsError(lang().error_while_extracting_url);
                }
                setIsLoading(false);
            });
    };

    useEffect(() => {
        extractLinkFromClipboard();
        AppState.addEventListener('change', state => {
            if (state === 'active') {
                extractLinkFromClipboard();
            }
        });

        return () => {};
    }, []);

    return (
        <View
            style={{
                marginTop: 30,
                width: '92%',
                marginVertical: 5,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 10,
                paddingVertical: 20,
                backgroundColor: theme.TERTIARY,
                borderRadius: 5,
                maxWidth: 600,
                ...Constants.elevation.one,
            }}
        >
            <T
                style={{
                    fontFamily: Constants.fonts.SemiBold,
                    fontSize: 14,
                    color: theme.TEXT_MUTED,
                    marginBottom: 20,
                }}
            >
                {lang().copy_fadfadah_link_and_paste_it_here}
            </T>
            <View style={{width: '90%'}}>
                <Input
                    onFocus={() => {
                        setIsError(undefined);
                    }}
                    placeholder={lang().fadfadah_link}
                    selectionColor={theme.PRIMARY}
                    style={{
                        backgroundColor: theme.INPUT_BACKGROUND,
                        color: theme.TEXT,
                        borderRadius: 5,
                        paddingVertical: Platform.select({
                            ios: 10,
                            android: 8,
                            web: 10,
                        }),
                        paddingHorizontal: 10,
                        fontSize: 13,
                        marginBottom: 15,
                    }}
                    placeholderTextColor={theme.TEXT_MUTED}
                    value={linkText}
                    onChangeText={text => {
                        setIsError(undefined);
                        setLinkText(text);
                    }}
                />
            </View>
            <TouchableOpacity
                style={{
                    height: 40,
                    width: '70%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onPress={() => {
                    if (!isLoading) {
                        if (linkText === '') {
                            setIsError(lang().error_while_extracting_url);
                            return;
                        }
                        logEvent('createConversationFromLink');
                        onCheckURL();
                    }
                }}
            >
                <LinearGradient
                    colors={[theme.SUCCESS, theme.SUCCESS]}
                    style={{
                        width: '100%',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5,
                    }}
                >
                    {isLoading ? (
                        <ActivityIndicator
                            color="white"
                            style={{paddingHorizontal: 30}}
                        />
                    ) : (
                        <T
                            style={{
                                color: theme.TEXT_WHITE,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: 12,
                            }}
                        >
                            {lang().create_conversation}
                        </T>
                    )}
                </LinearGradient>
            </TouchableOpacity>
            {isError !== undefined && (
                <View style={{marginTop: 12}}>
                    <T
                        style={{
                            fontFamily: Constants.fonts.SemiBold,
                            color: Constants.colors.danger,
                            fontSize: 12,
                        }}
                    >
                        {isError}
                    </T>
                </View>
            )}
        </View>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    extractUserLinkFromURL: data =>
        conversationActions.extractUserLinkFromURL(data)(dispatch, getState),
});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LinkExtractor);
