import React from 'react';
import {TextInput, StyleSheet, TextInputProps} from 'react-native';
import Constants from '../../assets/Constants';
import lang from '../../localization';

const styles = StyleSheet.create({
    input: {
        fontFamily: Constants.fonts.Regular,
        writingDirection: lang().direction as 'rtl',
        textAlign: lang().align as 'left',
    },
});

const Input = (props: {children: JSX.Element} & TextInputProps) => {
    return (
        <TextInput
            {...props}
            style={{...styles.input, ...(props.style as any)}}
        >
            {props.children}
        </TextInput>
    );
};

export default Input;
