import {Alert, I18nManager} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import mobileAds from 'react-native-google-mobile-ads';
import {actions as authActions} from '../auth';
import {actions as conversationsActions} from '../conversations';
import {actions as messagesActions} from '../messages';
import {actions as subscriptionActions} from '../subscription';
import * as shareActions from './share';
import * as socket from './socket';
import * as t from './actionTypes';
import * as api from './api';
import lang from '../../localization';
import {domain, isWeb} from '../../../env';
import {successVibration} from './vibrations';
import {checkForceUpdate} from './forceUpdate';
import {navigate} from '../navigation';
import emitter from '../helpers/Emitter';
import {registerEvents} from '../events/actions';
import {SCREENS} from '../../screens/Screens';
import RNRestart from '../../../PackageWrappers/Modules/RNRestart';
import {logEvent, logShare} from './tracking';
import messaging from './react-native-firebase-notifications';

export function showSnackBarGlobal({
    textMessage,
    position = 'top',
    type = 'success',
    delay = 3000,
}) {
    emitter.emit('snackBar', {
        textMessage,
        position,
        type,
        delay,
    });
}

export function showSnackBar({
    textMessage,
    position = 'top',
    type = 'success',
    delay = 3000,
}) {
    return () => {
        showSnackBarGlobal({
            textMessage,
            position,
            type,
            delay,
        });
    };
}

export function shareLink({type}) {
    return async (dispatch, getState) => {
        successVibration();

        const {user} = getState().authReducer;
        logShare({
            content_type: `link - ${type}`,
            item_id: `${domain}/user/${user.user_link}`,
            method: `link - ${type}`,
        });

        shareActions.shareLink({type})(dispatch, getState);
    };
}

export function toggleLanguage() {
    try {
        if (I18nManager.isRTL) {
            AsyncStorage.multiSet([
                ['lang', 'en'],
                ['is_manually_restarted', 'true'],
            ]).then(() => {
                I18nManager.forceRTL(false);
                RNRestart.Restart();
            });
        } else {
            AsyncStorage.multiSet([
                ['lang', 'ar'],
                ['is_manually_restarted', 'true'],
            ]).then(() => {
                I18nManager.forceRTL(true);
                RNRestart.Restart();
            });
        }
    } catch (e) {
        AsyncStorage.setItem('is_manually_restarted', 'true').then(() => {
            RNRestart.Restart();
        });
    }
}

function startAdmob() {
    mobileAds()
        .initialize()
        .then(adapterStatuses => {
            // Initialization complete!
        });
}

export function startUp() {
    return async (dispatch, getState) => {
        const {user} = getState().authReducer;
        const {conversations} = getState().conversationsReducer;

        registerEvents()(dispatch, getState);

        if (isWeb) {
            await subscriptionActions.checkActiveSubscription()(
                dispatch,
                getState,
            );
        }

        if (user === undefined) {
            await authActions.loadUser()(dispatch, getState);
        }

        if (!isWeb) {
            startAdmob();
        }

        if (conversations === undefined) {
            await conversationsActions.loadConversations()(dispatch, getState);

            if (!isWeb) {
                await subscriptionActions.setUp()(dispatch, getState);
                await subscriptionActions.checkActiveSubscription()(
                    dispatch,
                    getState,
                );
            }

            if (
                !getState().appReducer.isListenerRegistered &&
                user !== undefined
            ) {
                dispatch({
                    type: t.REGISTER_MAIN_LISTENER,
                });
                socket.registerListeners(user)(dispatch, getState);
            }
        }
        checkForceUpdate()(dispatch, getState);
    };
}

export function logout() {
    return async (dispatch, getState) => {
        setMainLoading()(dispatch, getState);
        try {
            await authActions.detachNotificationKey(
                await messaging().getToken(),
            );
        } catch (e) {
            // who cares
        }
        try {
            authActions.unloadUser()(dispatch, getState);
            conversationsActions.unloadConversations()(dispatch, getState);
            freeReducers()(dispatch, getState);
            const notIncludedKeys = [
                'lang',
                'alreadyLaunched',
                'lastIdleNotificationID',
            ];
            await AsyncStorage.getAllKeys().then(keys => {
                keys.forEach(async key => {
                    if (!notIncludedKeys.includes(key)) {
                        await AsyncStorage.removeItem(key);
                    }
                });
            });
            socket.stopSocketListener();
            RNRestart.Restart();
            if (!isWeb) {
                await subscriptionActions.reset()(dispatch, getState);
            }
            unsetMainLoading()(dispatch, getState);
        } catch (e) {
            AsyncStorage.setItem('is_manually_restarted', 'true').then(
                async () => {
                    await subscriptionActions.reset()(dispatch, getState);
                    RNRestart.Restart();
                    unsetMainLoading()(dispatch, getState);
                },
            );
        }
    };
}

function freeReducers() {
    return (dispatch, getState) => {
        dispatch({
            type: t.FREE_REDUCER,
        });
    };
}

export function openConversation({conversation}) {
    return async (dispatch, getState) => {
        if (conversation) {
            conversationsActions.unsetNewConversation(conversation.id)(
                dispatch,
                getState,
            );

            navigate(SCREENS.CONVERSATION, {
                conversation,
            });
        }
    };
}

export function openConversationUsingID({conversationID, conversationLink}) {
    return async (dispatch, getState) => {
        const {conversations} = getState().conversationsReducer;
        if (conversations) {
            const conversation = conversations.filter(conversationObject => {
                const isSameID = conversationObject.id === conversationID;
                let isSameLink = true;
                if (conversationLink) {
                    isSameLink =
                        conversationObject.conversation_link ===
                        conversationLink;
                }
                return isSameID && isSameLink;
            })[0];

            openConversation({conversation})(dispatch, getState);
        }
    };
}

export function removeConversations({willBeRemovedConversations = []}) {
    return async (dispatch, getState) => {
        try {
            await conversationsActions.removeConversations({
                willBeRemovedConversations,
            })(dispatch, getState);
            showSnackBar({
                textMessage: lang().snackbar_success,
                position: 'top',
                type: 'success',
                delay: 1500,
            })(dispatch, getState);
        } catch (e) {
            showSnackBar({
                textMessage: lang().snackbar_error,
                position: 'top',
                type: 'danger',
                delay: 1500,
            })(dispatch, getState);
        }
    };
}

export function blockConversation({conversationID = undefined}) {
    return async (dispatch, getState) => {
        try {
            const isBlocked = await conversationsActions.blockConversation({
                conversationID,
            })(dispatch, getState);
            if (isBlocked) {
                showSnackBar({
                    textMessage: lang().block_success,
                    position: 'top',
                    type: 'success',
                    delay: 1500,
                })(dispatch, getState);
            } else {
                showSnackBar({
                    textMessage: lang().unblock_success,
                    position: 'top',
                    type: 'success',
                    delay: 1500,
                })(dispatch, getState);
            }
        } catch (e) {
            showSnackBar({
                textMessage: lang().snackbar_error,
                position: 'top',
                type: 'danger',
                delay: 1500,
            })(dispatch, getState);
        }
    };
}

export function reportConversation({conversationID = undefined}) {
    return async (dispatch, getState) => {
        try {
            await conversationsActions.reportConversation({conversationID})(
                dispatch,
                getState,
            );
            showSnackBar({
                textMessage: lang().report_user_success,
                position: 'top',
                type: 'success',
                delay: 2500,
            })(dispatch, getState);
        } catch (e) {
            showSnackBar({
                textMessage: lang().snackbar_error,
                position: 'top',
                type: 'danger',
                delay: 1500,
            })(dispatch, getState);
        }
    };
}

export function changeProfilePicture({image}) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: t.START_PROFILE_PICTURE_UPLOADING,
            });
            await authActions.changeProfilePicture({image})(dispatch, getState);
            dispatch({
                type: t.STOP_PROFILE_PICTURE_UPLOADING,
            });
            showSnackBar({
                textMessage: lang().snackbar_success,
                position: 'top',
                type: 'success',
                delay: 1500,
            })(dispatch, getState);
        } catch (e) {
            dispatch({
                type: t.STOP_PROFILE_PICTURE_UPLOADING,
            });
            showSnackBar({
                textMessage: lang().snackbar_error,
                position: 'top',
                type: 'danger',
                delay: 1500,
            })(dispatch, getState);
        }
    };
}

export function sendRatingToSlack({rating, review}) {
    return async (dispatch, getState) => {
        const {name, id, user_link} = getState().authReducer.user;
        api.sendRatingToSlack({name, userID: id, user_link, rating, review});
    };
}

export function resetPassword({email}) {
    return async (dispatch, getState) => {
        return api.resetPassword({email});
    };
}

function setMainLoading() {
    return dispatch => {
        dispatch({
            type: t.SET_MAIN_LOADING,
        });
    };
}

function unsetMainLoading() {
    return dispatch => {
        dispatch({
            type: t.UNSET_MAIN_LOADING,
        });
    };
}

export function userIsTyping({conversationID}) {
    return async (dispatch, getState) => {
        messagesActions.userIsTyping({conversationID})(dispatch, getState);
    };
}

export function toggleRandomConversations({setIsLoading = () => {}}) {
    return async (dispatch, getState) => {
        const hasSkippedLogin = getState()?.authReducer?.user?.hasSkippedLogin;

        if (hasSkippedLogin) {
            setIsLoading(false);
            navigate(SCREENS.LOGIN, {
                isCompleteSignup: true,
            });
            return;
        }

        try {
            const result = await authActions.toggleRandomConversations()(
                dispatch,
                getState,
            );
            setIsLoading(false);
            if (result.code === 12200) {
                if (isWeb) {
                    window.alert(
                        lang().you_are_blocked_from_random_conversations,
                    );
                }
                Alert.alert(
                    lang().you_are_blocked_from_random_conversations,
                    '',
                    [
                        {
                            text: lang().skip,
                            onPress: () => {},
                        },
                    ],
                    {cancelable: true},
                );
            }
        } catch (error) {
            setIsLoading(false);
            if (isWeb) {
                window.alert(lang().error_happened);
            }
            Alert.alert(
                lang().error_happened,
                '',
                [
                    {
                        text: lang().skip,
                        onPress: () => {},
                    },
                ],
                {cancelable: true},
            );
        }
    };
}

export function skipLoginScreen() {
    return async (dispatch, getState) => {
        logEvent('skipLoginScreen');
        authActions.skipLoginScreen()(dispatch, getState);
    };
}

export function openCompleteSignupScreen() {
    return (dispatch, getState) => {
        navigate(SCREENS.LOGIN, {
            isCompleteSignup: true,
        });
    };
}

export function deleteMyAccount() {
    return async (dispatch, getState) => {
        try {
            await authActions.deleteMyAccount()(dispatch, getState);
            await logout()(dispatch, getState);
            RNRestart.Restart();
        } catch (e) {
            // console.log(e);
        }
    };
}

export function openChangeUsernameMenu() {
    return (dispatch, getState) => {
        const {name} = getState().authReducer.user;
        navigate(SCREENS.INPUT_SCREEN, {
            title: lang().change_account_name,
            input_label: lang().write_your_new_name_to_continue,
            input_default_value: name,
            input_placeholder: lang().new_username,
            submit_button_text: lang().change,
            fieldValidator: field => {
                if (field.length > 20) {
                    return false;
                }
                if (field.length < 3) {
                    return false;
                }
                if (field === name) {
                    return false;
                }
                return true;
            },
            submitFunction: 'CHANGE_NAME',
        });
    };
}

export async function setNotificationSound(value) {
    return AsyncStorage.setItem('settings_notification_sound', `${value}`);
}
