import React from 'react';
import {View, TouchableOpacity, Image, ImageSourcePropType} from 'react-native';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {T} from '../Shared';

const SettingsButton = ({
    theme,
    onPress = () => {},
    color = '#6B61A9',
    title,
    icon = undefined,
    disabled = false,
    endComponent = <View />,
    testID = '',
    centered = false,
}: {
    theme: Theme;
    onPress?: (params?: any) => any;
    color?: string;
    title: string;
    icon?: ImageSourcePropType;
    disabled?: boolean;
    endComponent?: React.ReactNode;
    testID?: string;
    centered?: boolean;
}) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled}
            testID={testID}
            style={{
                height: 55,
                width: '90%',
                backgroundColor:
                    theme.MODE === 'light' ? theme.BACKGROUND : color,
                marginVertical: 9,
                borderRadius: 5,
                justifyContent: centered ? 'center' : 'space-between',
                paddingHorizontal: 20,
                alignItems: 'center',
                flexDirection: 'row',
                borderColor: color,
                borderWidth: 0.5,
            }}
        >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {icon && (
                    <Image
                        source={icon}
                        style={{
                            height: 20,
                            width: 20,
                            marginEnd: 10,
                            tintColor: theme.MODE === 'light' ? color : 'white',
                        }}
                        resizeMode="contain"
                    />
                )}
                <T
                    style={{
                        fontFamily: Constants.fonts.SemiBold,
                        color: theme.MODE === 'light' ? color : 'white',
                    }}
                >
                    {title}
                </T>
            </View>
            <View>{endComponent}</View>
        </TouchableOpacity>
    );
};

export default SettingsButton;
