import React, {useState} from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    ActivityIndicator,
} from 'react-native';
import {AxiosResponse} from 'axios';
import {T, Input} from '../Shared';
import {actions as appActions} from '../../modules/app';
import lang from '../../localization';
import Constants from '../../assets/Constants';
import {validateEmail} from '../../modules/helpers';
import {tinyClick} from '../../modules/app/vibrations';
import {ActionParameter} from '../../types/helpers/helpers';
import {Theme} from '../../assets/Colors';
import Modal from '../Shared/Modal';

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        borderRadius: 9,
        width: '70%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        paddingTop: 20,
    },
    forgetPasswordTitleSection: {
        width: '80%',
        marginBottom: 20,
    },
    forgetPasswordTitleText: {
        textAlign: 'center',
        fontFamily: Constants.fonts.Bold,
    },
    input: {
        width: '85%',
        minWidth: 200,
        height: 45,
        maxHeight: 50,
        fontSize: 15,
        borderWidth: 1,
        borderRadius: 7,
        padding: 10,
        marginBottom: 20,
    },
    resetPasswordButton: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    resetPasswordText: {fontFamily: Constants.fonts.Bold},
    divider: {
        width: '100%',
        borderWidth: 0.5,
    },
    errorSuccessSection: {
        paddingHorizontal: 20,
    },
    errorSuccessText: {
        marginBottom: 20,
        textAlign: 'center',
    },
    errorText: {
        color: Constants.colors.danger,
    },
    successText: {
        color: Constants.colors.success,
    },
    cancelButton: {
        paddingVertical: 15,
        paddingBottom: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cancelButtonText: {fontFamily: Constants.fonts.Bold},
});

const ForgetPasswordModal = ({
    show,
    setShow,
    resetPassword,
    theme,
}: {
    show: boolean;
    setShow: (value: boolean) => void;
    resetPassword: (
        data: ActionParameter<typeof appActions.resetPassword>,
    ) => Promise<AxiosResponse<any>>;
    theme: Theme;
}) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState<string | false>(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const clearErrorAndSuccessMessages = () => {
        setIsError(false);
        setIsSuccess(false);
    };

    const hideModal = () => {
        tinyClick();
        clearErrorAndSuccessMessages();
        setIsLoading(false);
        setEmail('');
        setShow(false);
    };

    return (
        <Modal
            closeModal={hideModal}
            isVisible={show}
            theme={theme}
        >
            <View style={{alignItems: 'center', paddingTop: 20}}>
                <View style={styles.forgetPasswordTitleSection}>
                    <T
                        style={{
                            ...styles.forgetPasswordTitleText,
                            color: theme.TEXT,
                            lineHeight: 20,
                        }}
                    >
                        {lang().reset_password_modal_title}
                    </T>
                </View>
                {/* @ts-ignore */}
                <Input
                    style={{
                        ...styles.input,
                        backgroundColor: theme.INPUT_BACKGROUND,
                        borderColor: theme.BACKGROUND,
                        color: theme.TEXT,
                    }}
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoComplete="email"
                    autoCapitalize="none"
                    clearButtonMode="while-editing"
                    autoCorrect={false}
                    placeholder={lang().email}
                    placeholderTextColor={theme.TEXT_MUTED}
                    value={email}
                    onChangeText={(text: string) => {
                        clearErrorAndSuccessMessages();
                        setEmail(text);
                    }}
                />
                <TouchableOpacity
                    style={{
                        ...styles.resetPasswordButton,
                        backgroundColor: theme.SUCCESS,
                        opacity: email === '' ? 0.5 : 1,
                    }}
                    disabled={email === ''}
                    onPress={() => {
                        clearErrorAndSuccessMessages();
                        if (!validateEmail(email)) {
                            setIsError(lang().email_not_valid as any);
                            return;
                        }
                        setIsLoading(true);
                        resetPassword({email})
                            .then(() => {
                                setIsSuccess(true);
                                setIsLoading(false);
                            })
                            .catch(() => {
                                setIsError(lang().error_happened as any);
                                setIsLoading(false);
                            });
                    }}
                >
                    {!isLoading && (
                        <T
                            style={{
                                ...styles.resetPasswordText,
                                color: theme.TEXT_WHITE,
                            }}
                        >
                            {lang().reset_password}
                        </T>
                    )}
                    {isLoading && (
                        <ActivityIndicator
                            size="small"
                            color={theme.TEXT_WHITE}
                        />
                    )}
                </TouchableOpacity>
                {isError && (
                    <View style={styles.errorSuccessSection}>
                        <T
                            style={{
                                ...styles.errorSuccessText,
                                ...styles.errorText,
                                color: theme.DANGER,
                                lineHeight: 23,
                            }}
                        >
                            {isError}
                        </T>
                    </View>
                )}
                {isSuccess && (
                    <View style={styles.errorSuccessSection}>
                        <T
                            style={{
                                ...styles.errorSuccessText,
                                ...styles.successText,
                                color: theme.SUCCESS,
                                lineHeight: 23,
                            }}
                        >
                            {lang().reset_password_success}
                        </T>
                    </View>
                )}
                <View
                    style={{...styles.divider, borderColor: theme.SECONDARY}}
                />
                <TouchableOpacity
                    style={styles.cancelButton}
                    onPress={hideModal}
                >
                    <T
                        style={{
                            ...styles.cancelButtonText,
                            color: theme.TEXT_MUTED,
                        }}
                    >
                        {lang().cancel}
                    </T>
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

export default ForgetPasswordModal;
