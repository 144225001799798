import React, {useEffect, useState} from 'react';
import {
    SafeAreaView,
    View,
    Image,
    TouchableOpacity,
    Platform,
    Text,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import {T} from '../components/Shared';
import Constants from '../assets/Constants';
import {normalize} from '../modules/helpers';
import lang from '../localization';
import {ReducerState} from '../types/reducer/reducer';
import {actions as appActions} from '../modules/app';
import {actions as authActions} from '../modules/auth';
import LoadingModal from '../components/Shared/LoadingModal';
import {AxiosResponse} from '../types/helpers/helpers';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {isWeb} from '../../env';
// @ts-ignore
import RNRestart from '../../PackageWrappers/Modules/RNRestart';
import AppType from '../../AppType';
import NezmoLogo from '../assets/svgs/NezmoLogo';

const logo = require('../assets/images/fadfadah-logo.png');

const CELL_COUNT = 4;

const EmailAuthentication = () => {
    const theme = useTheme();
    const navigation = useNavigation();
    const {email} = useSelector((state: ReducerState) => {
        return {
            email: state.authReducer.user?.email,
        };
    });
    const dispatch = useDispatch();
    const store = useStore();

    const SendAuthenticationCodeToUserMutation = useMutation<
        AxiosResponse,
        AxiosError,
        void,
        any
    >(() => {
        return authActions.sendEmailAuthenticationCodeToUser()();
    });

    const CheckEmailAuthenticationCodeMutation = useMutation<
        AxiosResponse,
        AxiosError,
        {code: string},
        any
    >(
        ({code}) => {
            return authActions.checkEmailAuthenticationCode({code})();
        },
        {
            onSuccess: (response: AxiosResponse) => {
                if (response.data.data?.isCorrect) {
                    RNRestart.Restart();
                }
            },
        },
    );

    const [value, setValue] = useState('');
    const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        value,
        setValue,
    });

    useEffect(() => {
        if (value.length === 1) {
            CheckEmailAuthenticationCodeMutation.reset();
        }
        if (value.length === CELL_COUNT) {
            const code = `${value}`;
            setValue('');
            CheckEmailAuthenticationCodeMutation.mutate({code});
            // console.log({code});
        }
    }, [value]);

    useEffect(
        () =>
            navigation.addListener('beforeRemove', e => {
                e.preventDefault();
            }),
        [navigation],
    );

    useEffect(() => {
        SendAuthenticationCodeToUserMutation.mutate();
    }, []);

    return (
        <SafeAreaView
            style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.BACKGROUND,
            }}
        >
            <StatusBarAware theme={theme} />
            {AppType.select({
                fadfadah: (
                    <Image
                        source={logo}
                        style={[
                            {
                                resizeMode: 'contain',
                                opacity: 0.9,
                                marginTop: 80,
                            },
                            {
                                tintColor:
                                    theme.MODE === 'dark' ? 'white' : undefined,
                                marginBottom: Platform.select({
                                    ios: 15,
                                    android: 5,
                                }),
                                height: Platform.select({
                                    ios: normalize(80),
                                    android: normalize(70),
                                    web: normalize(90),
                                }),
                                width: 200,
                            },
                        ]}
                        testID="logo"
                    />
                ),
                nezmo: (
                    <View
                        style={{
                            opacity: 0.9,
                            marginTop: 80,
                        }}
                    >
                        <NezmoLogo
                            width={80}
                            height={80}
                        />
                    </View>
                ),
            })}
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginVertical: 20,
                    width: '90%',
                }}
            >
                <T
                    style={{
                        color: theme.TEXT,
                        fontFamily: Constants.fonts.Bold,
                        fontSize: normalize(19),
                    }}
                >
                    {lang().email_authentication.email_authentication}
                </T>
                <T
                    style={{
                        marginTop: 20,
                        color: theme.TEXT_MUTED,
                        fontFamily: Constants.fonts.Bold,
                        textAlign: 'center',
                        lineHeight: normalize(20),
                    }}
                >
                    {lang().email_authentication.we_sent_code_via_email(email)}
                </T>
                {(CheckEmailAuthenticationCodeMutation.isError ||
                    CheckEmailAuthenticationCodeMutation?.data?.data?.code ===
                        11260) && (
                    <T
                        style={{
                            marginTop: 10,
                            color: theme.DANGER,
                            fontFamily: Constants.fonts.Bold,
                            textAlign: 'center',
                        }}
                    >
                        {lang().email_authentication.wrong_code}
                    </T>
                )}
            </View>
            <View
                style={{
                    width: '90%',
                }}
            >
                <CodeField
                    {...props}
                    ref={ref}
                    value={value}
                    onChangeText={setValue}
                    cellCount={CELL_COUNT}
                    rootStyle={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection:
                            lang().lang === 'ar' ? 'row-reverse' : 'row',
                    }}
                    keyboardType="number-pad"
                    textContentType="oneTimeCode"
                    renderCell={({index, symbol, isFocused}) => (
                        <View
                            key={index}
                            style={[
                                {
                                    borderRadius: 5,
                                    marginHorizontal: 5,
                                    paddingTop: 6,
                                    borderWidth: 1,
                                    borderColor:
                                        theme.MODE === 'light'
                                            ? theme.SECONDARY
                                            : theme.TERTIARY,
                                },
                                isFocused && {
                                    borderWidth: 2,
                                },
                            ]}
                        >
                            <Text
                                key={index}
                                style={[
                                    {
                                        width: 45,
                                        height: 45,
                                        paddingStart: isWeb ? undefined : 16,
                                        paddingLeft: isWeb ? 16 : undefined,
                                        fontSize: normalize(20),
                                        fontFamily: Constants.fonts.Bold,
                                        color: theme.TEXT_PRIMARY,
                                    },
                                    isFocused && {},
                                ]}
                                onLayout={getCellOnLayoutHandler(index)}
                            >
                                {symbol || (isFocused ? <Cursor /> : null)}
                            </Text>
                        </View>
                    )}
                />
                <View
                    style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 80,
                    }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            appActions
                                .logout()(dispatch, store.getState)
                                .then(() => {
                                    RNRestart.Restart();
                                })
                                .catch(() => {
                                    RNRestart.Restart();
                                });
                        }}
                    >
                        <T
                            style={{
                                color: theme.DANGER,
                                fontFamily: Constants.fonts.Bold,
                            }}
                        >
                            {lang().logout}
                        </T>
                    </TouchableOpacity>
                </View>
            </View>
            <LoadingModal
                show={
                    SendAuthenticationCodeToUserMutation.isLoading ||
                    CheckEmailAuthenticationCodeMutation.isLoading
                }
            />
        </SafeAreaView>
    );
};

export default EmailAuthentication;
