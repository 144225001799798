import React, {useEffect, useState} from 'react';
import {TouchableOpacity, Platform} from 'react-native';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import lang from '../../localization';
import {Interest} from '../../types/data/interest';
import {normalize} from '../../modules/helpers';
import {NUMBER_OF_ALLOWED_INTERESTS} from '../../screens/Interests';
import {Theme} from '../../assets/Colors';

const InterestButton = ({
    interest,
    toggleInterest,
    selectedInterests,
    theme,
}: {
    interest: Interest;
    toggleInterest: ({
        name,
        isLanguage,
    }: {
        name: string;
        isLanguage?: boolean;
    }) => void;
    selectedInterests: string[];
    theme: Theme;
}) => {
    const [isSelected, setIsSelected] = useState(
        selectedInterests.includes(interest.name),
    );
    const isMaximumReached =
        selectedInterests.length === NUMBER_OF_ALLOWED_INTERESTS;
    const canSelect = isSelected || (!isMaximumReached && !isSelected);

    const getButtonCollor = () => {
        if (isSelected) {
            return theme.PRIMARY;
        }
        if (theme.MODE === 'dark') {
            return theme.SECONDARY;
        }
        return theme.TERTIARY;
    };

    return (
        <TouchableOpacity
            disabled={isMaximumReached && !isSelected}
            onPress={() => {
                setTimeout(() => {
                    toggleInterest({
                        name: interest.name,
                        isLanguage: interest.type === 'language',
                    });
                }, 50);

                if (canSelect) {
                    setIsSelected(!isSelected);
                }
            }}
            style={{
                backgroundColor: getButtonCollor(),
                paddingHorizontal: 10,
                paddingVertical: 2,
                borderRadius: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 3,
                marginHorizontal: 4,
                minHeight: 25,
                ...Constants.elevation.one,
                opacity: isMaximumReached && !isSelected ? 0.25 : 1,
            }}
        >
            {interest.type !== 'language' && (
                <T
                    style={{
                        color: Constants.colors.main.dark,
                        fontFamily: Constants.fonts.SemiBold,
                        marginEnd: 4,
                        opacity: theme.MODE === 'dark' ? 0.7 : 1,
                        fontSize: normalize(
                            Platform.select({ios: 10, android: 10}) as number,
                        ),
                    }}
                >
                    {/* @ts-ignore */}
                    {String.fromCodePoint(`0x${interest.emoji}`)}
                </T>
            )}
            <T
                style={{
                    color: isSelected ? theme.TEXT_WHITE : theme.TEXT_MUTED,
                    fontFamily: Constants.fonts.SemiBold,
                    fontSize: normalize(
                        Platform.select({ios: 11, android: 11}) as number,
                    ),
                }}
            >
                {/* @ts-ignore */}
                {interest[lang().lang]}
            </T>
        </TouchableOpacity>
    );
};

export default InterestButton;
