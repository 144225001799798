import React from 'react';
import {View, TouchableOpacity, Linking, StyleSheet} from 'react-native';
import lang from '../../localization';
import Constants from '../../assets/Constants';
import Checkbox from '../../components/Shared/Checkbox';
import {T} from '../Shared';
import {Theme} from '../../assets/Colors';

const ApproveTermsCheckbox = ({
    theme,
    hasApproved,
    setHasApproved,
}: {
    theme: Theme;
    hasApproved: boolean;
    setHasApproved: (hasApproved: boolean) => void;
}) => {
    return (
        <>
            <Checkbox
                isChecked={hasApproved}
                setIsChecked={setHasApproved}
                component={
                    <View
                        style={{
                            marginVertical: 14,
                            alignItems: 'flex-start',
                        }}
                    >
                        <T
                            style={{
                                fontSize: 11,
                                color: theme.TEXT_MUTED,
                            }}
                        >
                            {lang().by_registering}
                        </T>
                        <View style={styles.terms_of_use_and_privacy_section}>
                            <TouchableOpacity
                                onPress={() => {
                                    Linking.openURL(
                                        Constants.links.termsOfUse,
                                    ).catch(() => {});
                                }}
                            >
                                <T
                                    style={{
                                        ...styles.link_text,
                                        color: theme.TEXT_MUTED,
                                    }}
                                >
                                    {lang().terms_of_use}
                                </T>
                            </TouchableOpacity>
                            <T
                                style={{
                                    ...styles.link_text,
                                    color: theme.TEXT_MUTED,
                                    marginHorizontal: 4,
                                }}
                            >{`${lang().and}`}</T>
                            <TouchableOpacity
                                onPress={() => {
                                    Linking.openURL(
                                        Constants.links.privacyPolicy,
                                    ).catch(() => {});
                                }}
                            >
                                <T
                                    style={{
                                        ...styles.link_text,
                                        color: theme.TEXT_MUTED,
                                    }}
                                >
                                    {lang().privacy_policy}
                                </T>
                            </TouchableOpacity>
                        </View>
                    </View>
                }
            />
        </>
    );
};

const styles = StyleSheet.create({
    terms_of_use_and_privacy_section: {flexDirection: 'row'},
    link_text: {
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
    },
    or_text: {
        fontSize: 10,
    },
});

export default ApproveTermsCheckbox;
