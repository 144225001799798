import React, {useEffect, useState} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    Image,
    TouchableOpacity,
    StatusBar,
    KeyboardAvoidingView,
    Platform,
    useWindowDimensions,
} from 'react-native';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import {Helmet} from 'react-helmet';
import pSBC from 'shade-blend-color';
import store from '../modules/redux/store';
import lang from '../localization';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import {Input, SocialLoginButtons} from '../components/Login';
import ErrorSection from '../components/Login/ErrorSections';
import {actions as authActions} from '../modules/auth';
import {actions as appActions} from '../modules/app';
import {SlideFromBottom} from '../components/Animations';
import {normalize} from '../modules/helpers';
import ForgetPasswordModal from '../components/Login/ForgetPasswordModal';
import {tinyClick} from '../modules/app/vibrations';
import LoadingModal from '../components/Shared/LoadingModal';
import {NavigationProp} from '../types/navigation/navigationProp';
import {ReduxDispatch, ReduxGetState} from '../types/redux/redux';
import {ReducerState} from '../types/reducer/reducer';
import {ActionParameter} from '../types/helpers/helpers';
import Purchases from '../modules/subscription/Purchases';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import NezmoLogo from '../assets/svgs/NezmoLogo';
import AppType from '../../AppType';
import {changeLanguage, useLocalaization} from '../modules/app/localization';
import {isWeb} from '../../env';
import {acceptedEmailDomains} from '../localization/localization';
import WebComponent from '../components/Shared/WebComponent';
import ApproveTermsCheckbox from '../components/Login/ApproveTermsCheckbox';

const logo = require('../assets/images/fadfadah-logo.png');

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
    keyboardAvoidingView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    headerSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        height: 50,
        marginBottom: -30,
        zIndex: 2,
        opacity: 0.9,
        marginHorizontal: 2,
        // backgroundColor: 'red',
    },
    changeLanguageSection: {
        flexDirection: 'column',
        justifyContent: 'center',
        // backgroundColor: 'blue',
        width: 73,
        height: 48,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: Constants.borderRadius.basePlus,
        borderColor: Constants.borderColor.med,
        // borderWidth: Constants.borderWidth.tiny,
    },
    changeLanguageText: {
        textAlign: 'center',
        fontFamily: Constants.fonts.Bold,
    },
    mainSection: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'blue',
    },
    loginCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '83%',
        paddingHorizontal: '5%',
        minWidth: 300,
        maxWidth: 500,
        paddingVertical: Platform.select({
            ios: 15,
            android: 10,
        }),
        marginBottom: 15,
        borderRadius: 7,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    footerSection: {
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'green',
    },
    footerText: {
        fontSize: 10,
    },
    logo: {
        resizeMode: 'contain',
        opacity: 0.9,
    },
    input: {
        minWidth: 260,
        height: normalize(35),
        maxHeight: 50,
        marginBottom: 2,
        fontSize: normalize(11),
        borderRadius: 4,
    },
    signinButtonsSection: {
        width: '100%',
        minWidth: 260,
        height: normalize(40),
        maxHeight: 50,
        flexDirection: 'row',
        marginTop: 10,
    },
    signinButton: {
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    signupButton: {
        backgroundColor: '#f2f2f2',
        flex: 4,
        margin: 1,
        borderRadius: 4,
        justifyContent: 'center',
    },
    skipSigningButton: {
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    signinButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(12),
        color: 'white',
        textAlign: 'center',
    },
    signupButtonText: {
        fontSize: normalize(12),
        textAlign: 'center',
    },
    orSection: {
        marginVertical: 4,
    },
    orText: {
        fontSize: normalize(12),
    },
    forgetPasswordSection: {
        marginTop: Platform.select({
            ios: 5,
            android: 3,
        }),
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 0.6,
    },
    forgetPasswordText: {
        fontSize: 13,
    },
    goBackButton: {
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    goBackButtonText: {
        fontSize: normalize(12),
        textAlign: 'center',
    },
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    login: (data: ActionParameter<typeof authActions.login>) =>
        authActions.login(data)(dispatch, getState),
    freeErrors: () => authActions.freeErrors()(dispatch),
    facebookAuth: (data: ActionParameter<typeof authActions.facebookAuth>) =>
        authActions.facebookAuth(data)(dispatch, getState),
    appleAuthentication: (
        data: ActionParameter<typeof authActions.appleAuthentication>,
    ) => authActions.appleAuthentication(data)(dispatch, getState),
    twitterAuth: (data: ActionParameter<typeof authActions.twitterAuth>) =>
        authActions.twitterAuth(data)(dispatch, getState),
    skipLoginScreen: () => authActions.skipLoginScreen()(),
    toggleLanguage: () => appActions.toggleLanguage(),
    resetPassword: (data: ActionParameter<typeof appActions.resetPassword>) =>
        appActions.resetPassword(data)(dispatch, getState),
    completeSignup: (
        data: ActionParameter<typeof authActions.completeSignup>,
    ) => authActions.completeSignup(data)(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    authReducer: state.authReducer,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
};

const LoginScreen = (
    props: NavigationProp<{isCompleteSignup: boolean}> & Props,
) => {
    const theme = useTheme();
    const isCompleteSignup = !!(
        props.route &&
        props.route.params &&
        props.route.params.isCompleteSignup === true
    );
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hasApprovedTerms, setHasApprovedTerms] = useState(false);
    const [hardLogin, setHardLogin] = useState(false);
    const [isForgetModalVisible, setIsForgetModalVisible] = useState(false);

    const localization = useLocalaization();
    const dispatch = useDispatch();
    const {width, height} = useWindowDimensions();

    useEffect(() => {
        if (Platform.OS !== 'web') {
            Purchases?.canMakePayments().then((canMakePayments: boolean) => {
                if (canMakePayments === false && Platform.OS === 'android') {
                    setHardLogin(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        props.navigation?.setOptions({
            title: `${lang().screenTitles.login}`,
        });
    }, []);

    const emailWebError = () => {
        if (isWeb && email !== '') {
            if (!email.includes('@') || !email.includes('.')) {
                return undefined;
            }

            const hasAValidEmail = acceptedEmailDomains.some(
                acceptedEmailDomain => {
                    if (email.includes(`@${acceptedEmailDomain}`)) {
                        return true;
                    }
                    return false;
                },
            );

            if (!hasAValidEmail) {
                return lang().email_authentication.web_email_not_valid;
            }
        }

        return undefined;
    };

    const isLoginDisabled = () => {
        if (isWeb) {
            return (
                email === '' || password === '' || emailWebError() !== undefined
            );
        }
        return false;
    };

    const didUserApproveTerms = () => {
        if (AppType.CURRENT_APP === 'NEZMO') {
            if (hasApprovedTerms) {
                return true;
            }
            return false;
        }
        return true;
    };

    const isCompleteSignupButtonEnabled =
        email !== '' && password !== '' && !isLoginDisabled();

    return (
        <View style={{flex: 1}}>
            <WebComponent>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{lang().screenTitles.login}</title>
                    <meta
                        name="Description"
                        content="فضفضة يتيح لاصدقائك بدأ محادثة معك بسرية تامة بدون ان تتعرف على هويتهم عن طريق مشاركة رابط حسابك"
                    />
                    <script type="application/json">
                        {`{
                            "@context": "http://schema.org/",
                            "@type": "orgnization",
                            "url": "https://app.fadfadah.net",
                            "name": "Fadfadah - فضفضة",
                            "image": "https://fadfadah.net/images/fadfadah-new-logo.png",
                            "description": "موقع شات فضفضة يتيح لاصدقائك بدأ محادثة معك بسرية تامة بدون ان تتعرف على هويتهم",
                        }`}
                    </script>
                </Helmet>
            </WebComponent>
            <SafeAreaView
                style={{
                    ...styles.container,
                    backgroundColor:
                        theme.MODE === 'light'
                            ? theme.BACKGROUND
                            : (pSBC(-0.4, theme.BACKGROUND) as string),
                }}
            >
                <StatusBar
                    backgroundColor={
                        theme.MODE === 'light'
                            ? theme.PRIMARY
                            : (pSBC(-0.4, theme.SECONDARY) as string)
                    }
                    barStyle="light-content"
                />
                {!isCompleteSignup && (
                    <View style={styles.headerSection}>
                        <ErrorSection
                            darkBackground
                            error={props.authReducer.loginError}
                        />
                        <TouchableOpacity
                            style={styles.changeLanguageSection}
                            onPress={() => {
                                changeLanguage({
                                    lang:
                                        localization?.language === 'ar'
                                            ? 'en'
                                            : 'ar',
                                })(dispatch);
                            }}
                            testID="changeLanguageButton"
                        >
                            <T
                                style={{
                                    ...styles.changeLanguageText,
                                    color: theme.TEXT_WHITE,
                                }}
                            >
                                {lang().other_language}
                            </T>
                        </TouchableOpacity>
                    </View>
                )}
                <LinearGradient
                    colors={
                        theme.MODE === 'dark'
                            ? [theme.SECONDARY, theme.BACKGROUND]
                            : theme.GRADIENTS.PRIMARY
                    }
                    style={[
                        {
                            width: width * 2,
                            height: width * 2,
                            bottom: height / 2,
                            right: -width / 2,
                            borderRadius: width * 2,
                            position: 'absolute',
                        },
                    ]}
                />
                <SlideFromBottom
                    disabled={isCompleteSignup}
                    style={{...styles.mainSection}}
                >
                    {isCompleteSignup && (
                        <View style={{paddingHorizontal: 30, marginBottom: 50}}>
                            <T
                                style={{
                                    color: Constants.colors.whiteMain,
                                    fontFamily: Constants.fonts.Bold,
                                    textAlign: 'center',
                                    fontSize: normalize(18),
                                }}
                            >
                                {lang().complete_your_info_to_use_this_feature}
                            </T>
                        </View>
                    )}
                    <View
                        style={[
                            styles.loginCard,
                            {
                                backgroundColor:
                                    theme.MODE === 'light'
                                        ? theme.TERTIARY
                                        : theme.SECONDARY,
                            },
                            isCompleteSignup
                                ? {marginBottom: 150, paddingBottom: 20}
                                : {},
                        ]}
                    >
                        <KeyboardAvoidingView
                            enabled={Platform.OS === 'ios'}
                            behavior="padding"
                            keyboardVerticalOffset={100}
                            style={styles.keyboardAvoidingView}
                        >
                            {!isCompleteSignup && (
                                <>
                                    {AppType.select({
                                        fadfadah: (
                                            <Image
                                                source={logo}
                                                style={[
                                                    styles.logo,
                                                    {
                                                        marginBottom:
                                                            Platform.select({
                                                                ios: 5,
                                                                android: 5,
                                                            }),
                                                        opacity:
                                                            theme.MODE ===
                                                            'dark'
                                                                ? 0.8
                                                                : 1,
                                                        width: 70,
                                                        tintColor:
                                                            theme.MODE ===
                                                            'dark'
                                                                ? theme.TEXT_WHITE
                                                                : undefined,
                                                        height: Platform.select(
                                                            {
                                                                ios: normalize(
                                                                    80,
                                                                ),
                                                                android:
                                                                    normalize(
                                                                        70,
                                                                    ),
                                                                web: normalize(
                                                                    80,
                                                                ),
                                                            },
                                                        ),
                                                    },
                                                ]}
                                                testID="logo"
                                            />
                                        ),
                                        nezmo: (
                                            <View>
                                                <NezmoLogo
                                                    width={80}
                                                    height={80}
                                                />
                                            </View>
                                        ),
                                    })}
                                </>
                            )}
                            <Input
                                style={{
                                    ...styles.input,
                                    backgroundColor:
                                        theme.MODE === 'light'
                                            ? theme.INPUT_BACKGROUND
                                            : theme.BACKGROUND,
                                    borderColor: theme.PRIMARY_DEVIDER,
                                    color: theme.TEXT,
                                }}
                                editable={!props.authReducer.loginLoading}
                                keyboardType="email-address"
                                textContentType="emailAddress"
                                autoComplete="email"
                                autoCapitalize="none"
                                autoCorrect={false}
                                placeholder={lang().email}
                                value={email}
                                onChangeText={(text: string) => {
                                    if (
                                        props.authReducer.loginError !==
                                        undefined
                                    ) {
                                        props.freeErrors();
                                    }
                                    setEmail(text);
                                }}
                                onFocus={() => {
                                    if (Platform.OS === 'android') {
                                        props.freeErrors();
                                    }
                                }}
                                testID="loginEmailInput"
                                error={emailWebError()}
                            />
                            <Input
                                style={{
                                    ...styles.input,
                                    backgroundColor:
                                        theme.MODE === 'light'
                                            ? theme.INPUT_BACKGROUND
                                            : theme.BACKGROUND,
                                    borderColor: theme.PRIMARY_DEVIDER,
                                    color: theme.TEXT,
                                }}
                                editable={!props.authReducer.loginLoading}
                                textContentType="password"
                                autoComplete="off"
                                autoCorrect={false}
                                secureTextEntry
                                placeholder={lang().password}
                                value={password}
                                onChangeText={(text: string) => {
                                    if (
                                        props.authReducer.loginError !==
                                        undefined
                                    ) {
                                        props.freeErrors();
                                    }
                                    setPassword(text);
                                }}
                                testID="loginPasswordInput"
                            />
                            {AppType.CURRENT_APP === 'NEZMO' && (
                                <ApproveTermsCheckbox
                                    theme={theme}
                                    hasApproved={hasApprovedTerms}
                                    setHasApproved={setHasApprovedTerms}
                                />
                            )}
                            {!isCompleteSignup && (
                                <>
                                    <View style={styles.signinButtonsSection}>
                                        <TouchableOpacity
                                            style={{
                                                ...styles.signinButton,
                                                backgroundColor:
                                                    didUserApproveTerms()
                                                        ? theme.PRIMARY
                                                        : theme.DISABLED,
                                            }}
                                            disabled={!didUserApproveTerms()}
                                            onPress={() => {
                                                props.login({email, password});
                                            }}
                                            testID="loginButton"
                                        >
                                            <T style={styles.signinButtonText}>
                                                {lang().sign}
                                            </T>
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            style={{
                                                ...styles.signupButton,
                                                backgroundColor:
                                                    theme.MODE === 'light'
                                                        ? theme.INPUT_BACKGROUND
                                                        : theme.BACKGROUND,
                                            }}
                                            onPress={() => {
                                                props.navigation.navigate(
                                                    SCREENS.SIGNUP,
                                                );
                                                tinyClick();
                                            }}
                                            testID="goToSignupButton"
                                        >
                                            <T
                                                style={{
                                                    ...styles.signupButtonText,
                                                    color: theme.TEXT,
                                                }}
                                            >
                                                {lang().create_account}
                                            </T>
                                        </TouchableOpacity>
                                    </View>
                                    {Platform.OS !== 'ios' && !hardLogin && (
                                        <View
                                            style={styles.signinButtonsSection}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    ...styles.skipSigningButton,
                                                    backgroundColor:
                                                        theme.MODE === 'dark'
                                                            ? (pSBC(
                                                                  -0.2,
                                                                  theme.SUCCESS,
                                                              ) as string)
                                                            : theme.SUCCESS,
                                                }}
                                                onPress={() => {
                                                    props.skipLoginScreen();
                                                    tinyClick();
                                                }}
                                                testID="goToSignupButton"
                                            >
                                                <T
                                                    style={
                                                        styles.signinButtonText
                                                    }
                                                >
                                                    {
                                                        lang()
                                                            .skip_creating_account
                                                    }
                                                </T>
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                </>
                            )}
                            {isCompleteSignup && (
                                <>
                                    <View style={styles.signinButtonsSection}>
                                        <TouchableOpacity
                                            style={[
                                                styles.skipSigningButton,
                                                {
                                                    backgroundColor:
                                                        theme.MODE === 'dark'
                                                            ? (pSBC(
                                                                  -0.2,
                                                                  theme.SUCCESS,
                                                              ) as string)
                                                            : theme.SUCCESS,
                                                },
                                                isCompleteSignupButtonEnabled
                                                    ? {}
                                                    : {opacity: 0.6},
                                            ]}
                                            disabled={
                                                !isCompleteSignupButtonEnabled
                                            }
                                            onPress={async () => {
                                                const response =
                                                    await props.completeSignup({
                                                        email,
                                                        password,
                                                    });
                                                if (response) {
                                                    props.navigation.goBack();
                                                }
                                                tinyClick();
                                            }}
                                            testID="goToSignupButton"
                                        >
                                            <T style={styles.signinButtonText}>
                                                {
                                                    lang()
                                                        .complete_your_info_to_use_this_feature_button
                                                }
                                            </T>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{height: 20}} />
                                    <View style={styles.signinButtonsSection}>
                                        <TouchableOpacity
                                            style={[
                                                styles.skipSigningButton,
                                                {backgroundColor: 'gray'},
                                            ]}
                                            onPress={() => {
                                                props.navigation.goBack();
                                                tinyClick();
                                            }}
                                            testID="goToSignupButton"
                                        >
                                            <T style={styles.signinButtonText}>
                                                {lang().cancel}
                                            </T>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </KeyboardAvoidingView>
                        {!isCompleteSignup &&
                            !hardLogin &&
                            AppType.CURRENT_APP !== 'NEZMO' && (
                                <SocialLoginButtons
                                    theme={theme}
                                    facebookAuth={props.facebookAuth}
                                    appleAuthentication={
                                        props.appleAuthentication
                                    }
                                    navigation={props.navigation}
                                    twitterAuth={props.twitterAuth}
                                />
                            )}
                    </View>
                    {/* <PrivacyTermsSection /> */}
                    {!isCompleteSignup && (
                        <View style={styles.forgetPasswordSection}>
                            <View>
                                <TouchableOpacity
                                    onPress={() => {
                                        setIsForgetModalVisible(true);
                                    }}
                                >
                                    <T
                                        style={{
                                            ...styles.forgetPasswordText,
                                            color: theme.TEXT,
                                        }}
                                    >
                                        {lang().forgot_password_button}
                                    </T>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                </SlideFromBottom>
                <LoadingModal show={props.authReducer.loginLoading} />
                <ForgetPasswordModal
                    theme={theme}
                    show={isForgetModalVisible}
                    setShow={setIsForgetModalVisible}
                    resetPassword={props.resetPassword}
                />
            </SafeAreaView>
        </View>
    );
};

export default connector(LoginScreen);
