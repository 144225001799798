/* eslint-disable no-useless-escape */
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import DeviceInfo from 'react-native-device-info';
import 'moment/locale/ar-sa';
import {Dimensions, I18nManager, PixelRatio, Platform} from 'react-native';
import {domain, isWeb} from '../../../env';
import {User} from '../../types/data/user';
import {Config} from '../../types/data/config';
import {Conversation} from '../../types/data/conversation';

export function isEmpty(string: string | null | undefined) {
    if (string === undefined || string === null || string === '') {
        return true;
    }
}

export function validateEmail(email: string) {
    if (email === 'e2e@test.test') {
        return true;
    }
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export async function getToken() {
    const userInStorage: string | null = await AsyncStorage.getItem('user');
    if (userInStorage !== null) {
        const user = JSON.parse(userInStorage);
        if (user && user.token) {
            return user.token;
        }
    }
    return undefined;
}

export function getDeviceID(): Promise<string | null> {
    if (isWeb) {
        return Promise.resolve(null);
    }
    const uniqueDeviceID = DeviceInfo.getUniqueId();
    if (uniqueDeviceID !== undefined && uniqueDeviceID !== null) {
        return uniqueDeviceID;
    }
    return Promise.resolve(null);
}

const replaceAt = (
    string: string,
    index: number,
    replacement: string,
): string => {
    return (
        string.substr(0, index) +
        replacement +
        string.substr(index + replacement.length)
    );
};

export const emojify = (text: string): string => {
    let result = text;
    while (result.search('&#x') !== -1) {
        const index = result.search('&#x');
        result = result.replace('&#x', '\\u{');
        result = replaceAt(result, index + 8, '}');
    }
    return result;
};

export const formatAPITime = (): string => {
    const currentTime = new Date();
    const startAPITime = String(currentTime.getHours()).padStart(2, '0');
    const endAPITime = String(currentTime.getMinutes()).padStart(2, '0');
    const apiTime = `${startAPITime}:${endAPITime}`;
    return apiTime;
};

export const formatFrontEndTime = (): string => {
    const date = new Date();
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${period}`;
    return strTime;
};

export const formatCreatedAtDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const generateRandomStrings = (): string => {
    return (
        Math.random().toString(36).substring(2, 7) +
        Math.random().toString(26).substring(2, 7)
    );
};

const {width: SCREEN_WIDTH} = Dimensions.get('window');
let scale = SCREEN_WIDTH / 320;
if (scale > 1.4) {
    scale = 1.4;
}
if (Platform.OS === 'ios') {
    scale -= 0.1;
}

export const normalize = (size: number) => {
    const newSize = size * scale;
    if (Platform.OS === 'ios') {
        return Math.round(PixelRatio.roundToNearestPixel(newSize));
    }
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
};

export const formatProfilePicturePath = ({
    profilePicture,
}: {
    profilePicture: string | undefined;
}): string => {
    if (profilePicture === undefined) {
        return '';
    }
    try {
        if (profilePicture.slice(0, 8) === '/images/') {
            return `${domain}${profilePicture}`;
        }
        return profilePicture;
    } catch (e) {
        return '';
    }
};

export const limitStringLength = (string: string, length: number): string => {
    if (string.length > length) {
        return `${string.substring(0, length - 3)}...`;
    }
    return string;
};

export const constructUserLink = (
    user: User,
    config: Config,
    short = true,
): string => {
    const {user_link} = user;
    return `${domain}/${Platform.select({
        ios: 'u/',
        android: 'u/',
        web: 'u/',
    })}${user_link}`.slice(short ? 8 : 0);
};

export const isOnlineFromLastSeen = (last_seen: Date): boolean => {
    if (!last_seen) {
        return false;
    }

    const lastSeenTimestamp = moment(last_seen).valueOf();
    const currentTimestamp = moment().subtract(30, 'seconds').valueOf();

    if (lastSeenTimestamp > currentTimestamp) {
        return true;
    }
    return false;
};

export const humanize = (timestamp: number): string => {
    const currentTimestamp = moment().subtract(30, 'days').valueOf();

    if (timestamp > currentTimestamp) {
        return moment(timestamp)
            .locale(I18nManager.isRTL ? 'ar-sa' : 'en-gb')
            .fromNow();
    }
    return '';
};

export const checkIfAsyncStorageWasUsedBefore = async ({
    key,
}: {
    key: string;
}) => {
    return AsyncStorage.getItem(key).then(value => {
        AsyncStorage.setItem(
            key,
            JSON.stringify({timestamp: new Date().getTime()}),
        );
        if (value === null) {
            return false;
        }
        return true;
    });
};

export const getConversationData = (conversation: Conversation) => {
    const {
        isInitiated,
        profile_picture_path,
        picture,
        name,
        anonymous_nickname,
    } = conversation;

    if (isInitiated) {
        return {
            picture: profile_picture_path,
            name,
        };
    }
    return {
        picture,
        name: anonymous_nickname,
    };
};

export const arabicNumbersToEnglishNumbers = (s: string) => {
    // @ts-ignore
    return s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
