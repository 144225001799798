import React from 'react';
import {
    View,
    TouchableOpacity,
    Platform,
    TouchableWithoutFeedback,
    ActivityIndicator,
    Image,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import {hasNotch} from 'react-native-device-info';
import LinearGradient from 'react-native-linear-gradient';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {useMutation} from 'react-query';
import {AxiosError} from 'axios';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {ReducerState} from '../../types/reducer/reducer';
import {T} from '../Shared';
import CurrentPoints from './CurrentPoints';
import {actions as pointsActions} from '../../modules/points';
import {AxiosResponse} from '../../types/helpers/helpers';
import {SCREENS} from '../../screens/Screens';
import {useTheme} from '../../modules/app/theme';
import Modal from '../Shared/Modal';
import {ReduxGetState} from '../../types/redux/redux';

const BuyWithPointsModal = () => {
    const {points, pointsModal} = useSelector((state: ReducerState) => {
        return {
            points: state.authReducer.user?.points,
            pointsModal: state.pointsReducer.pointsModal,
        };
    });
    const theme = useTheme();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const store = useStore();

    const {isVisible, type, amount} = pointsModal;

    const closeModal = () => {
        pointsActions.destroyPointsModal()(dispatch);
        BuyWithPointsMutation.reset();
    };

    const BuyWithPointsMutation = useMutation<
        AxiosResponse,
        AxiosError,
        any,
        any
    >(
        async () => {
            const response = await pointsActions.buyWithPoints({
                productName: 'unblock',
            })(dispatch, store.getState as ReduxGetState);
            return response;
        },
        {
            onSuccess: ({data}) => {
                if (data.success) {
                    setTimeout(() => {
                        navigation.goBack();
                        // @ts-ignore
                        navigation.navigate(SCREENS.RANDOM_CONVERSATION);
                        closeModal();
                    }, 1500);
                }
            },
        },
    );

    const userHasAmount = () => {
        if (points) {
            if (points >= amount) {
                return true;
            }
        }
        return false;
    };

    const buttonText = () => {
        if (!userHasAmount()) {
            return lang().points_system.buy_points;
        }
        if (type === 'unblock') {
            return `${lang().points_system.use_points_button(amount)} ${
                lang().points_system.points_reason.unblock
            }`;
        }
        return lang().points_system.use_points_button(amount);
    };

    return (
        <Modal
            closeModal={() => {
                closeModal();
            }}
            theme={theme}
            isVisible={isVisible}
        >
            <>
                <Animatable.View
                    animation="fadeInUp"
                    easing="ease-in"
                    duration={300}
                    delay={0}
                    iterationCount={1}
                    style={{
                        alignItems: 'stretch',
                        width: '100%',
                        height: hasNotch() && Platform.OS === 'ios' ? 280 : 240,
                        marginTop: Platform.OS === 'ios' ? 64 : 24,
                        borderRadius: 10,
                    }}
                >
                    <TouchableWithoutFeedback onPress={() => {}}>
                        <>
                            {BuyWithPointsMutation.isError && (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingHorizontal: 20,
                                        height: '100%',
                                        paddingBottom:
                                            hasNotch() && Platform.OS === 'ios'
                                                ? 40
                                                : 20,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Image
                                        source={require('../../assets/images/error.png')}
                                        style={{
                                            height: 100,
                                            resizeMode: 'contain',
                                            tintColor: Constants.colors.danger,
                                        }}
                                    />
                                    <T
                                        style={{
                                            fontFamily: Constants.fonts.Bold,
                                            fontSize: normalize(13),
                                            color: Constants.colors.danger,
                                        }}
                                    >
                                        {lang().error_happened}
                                    </T>
                                </View>
                            )}
                            {BuyWithPointsMutation.isSuccess && (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingHorizontal: 20,
                                        height: '100%',
                                        paddingBottom:
                                            hasNotch() && Platform.OS === 'ios'
                                                ? 40
                                                : 20,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Image
                                        source={require('../../assets/images/checkIcon.png')}
                                        style={{
                                            height: 100,
                                            resizeMode: 'contain',
                                            tintColor: Constants.colors.success,
                                        }}
                                    />
                                    <T
                                        style={{
                                            fontFamily: Constants.fonts.Bold,
                                            fontSize: normalize(13),
                                            color: Constants.colors.success,
                                        }}
                                    >
                                        {lang().points_system.added_x_points_success(
                                            amount,
                                        )}
                                    </T>
                                </View>
                            )}
                            {BuyWithPointsMutation.isLoading && (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingTop: 60,
                                        paddingHorizontal: 20,
                                        height: '100%',
                                        paddingBottom:
                                            hasNotch() && Platform.OS === 'ios'
                                                ? 40
                                                : 20,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <ActivityIndicator
                                        color={theme.TEXT_PRIMARY}
                                        size="large"
                                    />
                                </View>
                            )}
                            {BuyWithPointsMutation.isIdle && (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingTop: 20,
                                        paddingHorizontal: 20,
                                        height: '100%',
                                        paddingBottom:
                                            hasNotch() && Platform.OS === 'ios'
                                                ? 40
                                                : 20,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CurrentPoints
                                            theme={theme}
                                            points={points}
                                        />
                                    </View>
                                    {!userHasAmount() && (
                                        <T
                                            style={{
                                                fontFamily:
                                                    Constants.fonts.Bold,
                                                color: Constants.colors.danger,
                                                fontSize: normalize(11),
                                                marginBottom: -10,
                                            }}
                                        >
                                            {
                                                lang().points_system
                                                    .not_enough_points
                                            }{' '}
                                            {
                                                lang().points_system
                                                    .points_reason.unblock
                                            }
                                        </T>
                                    )}
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (userHasAmount()) {
                                                BuyWithPointsMutation.mutate(
                                                    'unblock',
                                                );
                                            } else {
                                                navigation.navigate(
                                                    // @ts-ignore
                                                    SCREENS.BUY_POINTS,
                                                    {
                                                        source: 'unblock',
                                                    },
                                                );
                                                pointsActions.destroyPointsModal()(
                                                    dispatch,
                                                );
                                            }
                                        }}
                                        style={{
                                            marginHorizontal: 10,
                                            width: '100%',
                                            marginTop: 15,
                                            borderRadius: 6,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'stretch',
                                        }}
                                    >
                                        <LinearGradient
                                            colors={theme.GRADIENTS.PRIMARY}
                                            style={{
                                                paddingVertical: 10,
                                                width: '100%',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                borderRadius: 6,
                                            }}
                                        >
                                            <View
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginVertical: 6,
                                                }}
                                            >
                                                <T
                                                    style={{
                                                        color: 'white',
                                                        fontFamily:
                                                            Constants.fonts
                                                                .Bold,
                                                        fontSize: 16,
                                                        lineHeight: 28,
                                                    }}
                                                >
                                                    {buttonText()}
                                                </T>
                                            </View>
                                        </LinearGradient>
                                    </TouchableOpacity>
                                </View>
                            )}
                        </>
                    </TouchableWithoutFeedback>
                </Animatable.View>
            </>
        </Modal>
    );
};

export default BuyWithPointsModal;
