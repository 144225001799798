import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
// import mobileAds, {MaxAdContentRating} from 'react-native-google-mobile-ads';
import {connect} from 'react-redux';
import {actions as authActions} from '../modules/auth';
import {actions as remoteConfigActions} from '../modules/remoteConfig';
import store from '../modules/redux/store';
import LoginScreen from './LoginScreen';
import MainScreen from './MainScreen';
import Constants from '../assets/Constants';

const styles = StyleSheet.create({
    container: {
        backgroundColor: Constants.colors.mainBackground,
        height: '100%',
    },
});

function startAdMob() {
    if (Platform.OS === 'web') {
        // mobileAds()
        // .setRequestConfiguration({
        //   // Update all future requests suitable for parental guidance
        //   maxAdContentRating: MaxAdContentRating.G,
        // })
        // .then(() => {
        //   // Request config successfully set!
        // });
    }
}

const WelcomeScreen = props => {
    const {user} = props.authReducer;
    startAdMob();

    if (user === undefined) {
        props.loadRemoteConfig();
        props.loadUser();
        return <View style={styles.container} />;
    }
    if (user === null) {
        return <LoginScreen navigation={props.navigation} />;
    }
    return <MainScreen navigation={props.navigation} />;
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    loadUser: data => authActions.loadUser(data)(dispatch, getState),
    loadRemoteConfig: data =>
        remoteConfigActions.loadRemoteConfig(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreen);
