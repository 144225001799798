import React, {useEffect, useState} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    Image,
    TouchableOpacity,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
    TouchableWithoutFeedback,
    Keyboard,
    useWindowDimensions,
} from 'react-native';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import {useNavigation} from '@react-navigation/native';
import store from '../modules/redux/store';
import lang from '../localization';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import {Input} from '../components/Login';
import {actions as authActions} from '../modules/auth';
import {normalize} from '../modules/helpers';
import {tinyClick} from '../modules/app/vibrations';
import LoadingModal from '../components/Shared/LoadingModal';
import {hasBadWords} from '../modules/helpers/checkBadWords';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {isWeb} from '../../env';
import CustomAlert from '../components/Shared/CustomAlert';

const Images = {
    SkipLoginIllustration: require('../assets/images/SkipLoginIllustration.png'),
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    keyboardAvoidingView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    input: {
        width: '80%',
        minWidth: 260,
        height: normalize(35),
        maxHeight: 50,
        marginBottom: 2,
        fontSize: normalize(11),
        textAlign: 'center',
        borderRadius: 20,
        ...Constants.elevation.one,
    },
    signinButtonsSection: {
        width: '85%',
        minWidth: 260,
        height: normalize(40),
        maxHeight: 50,
        flexDirection: 'row',
        marginTop: 10,
    },
    signupButton: {
        backgroundColor: '#f2f2f2',
        flex: 4,
        margin: 1,
        borderRadius: 4,
        justifyContent: 'center',
    },
    skipSigningButton: {
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    goBackButton: {
        backgroundColor: '#f2f2f2',
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    signinButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(13),
        color: 'white',
        textAlign: 'center',
    },
    goBackButtonText: {
        fontSize: normalize(12),
        color: Constants.colors.secondary,
        textAlign: 'center',
    },
});

const InputScreen = props => {
    const {
        title,
        input_label,
        input_default_value = '',
        input_placeholder,
        fieldValidator,
        submitFunction,
        screenImage,
        submit_button_text,
    } = props.route.params;
    const theme = useTheme();
    const [field, setField] = useState(input_default_value);
    const {width} = useWindowDimensions();
    const navigation = useNavigation();

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${title}`,
        });
    }, []);

    const PHONE_HAS_A_NOTCH =
        Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

    const onSubmit = () => {
        Keyboard.dismiss();
        tinyClick();
        switch (submitFunction) {
            case 'SKIP_LOGIN':
                if (hasBadWords(field)) {
                    setAlert({
                        show: true,
                        message: lang().warn_name_contains_bad_word,
                        confirmText: lang().confirm_bad_name,
                        confirmColor: theme.DANGER,
                        cancelText: lang().cancel_bad_name,
                        theme,
                        onConfirmPressed: () => {
                            props.skipLogin({username: field});
                            setAlert(null);
                        },
                        onCancelPressed: () => {
                            setAlert(null);
                        },
                        onDismiss: () => {
                            setAlert(null);
                        },
                    });
                } else {
                    props.skipLogin({username: field});
                }
                break;
            case 'CHANGE_NAME':
                if (hasBadWords(field)) {
                    setAlert({
                        show: true,
                        message: lang().warn_name_contains_bad_word,
                        confirmText: lang().confirm_bad_name,
                        confirmColor: theme.DANGER,
                        cancelText: lang().cancel_bad_name,
                        theme,
                        onConfirmPressed: () => {
                            props.changeUsername({newUsername: field});
                            setAlert(null);
                        },
                        onCancelPressed: () => {
                            setAlert(null);
                        },
                        onDismiss: () => {
                            setAlert(null);
                        },
                    });
                } else {
                    props.changeUsername({newUsername: field});
                }
                break;
            default:
                break;
        }
    };

    return (
        <SafeAreaView
            style={{...styles.container, backgroundColor: theme.BACKGROUND}}
        >
            <StatusBarAware theme={theme} />
            <KeyboardAvoidingView
                behavior={Platform.select({
                    android: 'height',
                    web: 'height',
                    ios: 'padding',
                })}
                keyboardVerticalOffset={Platform.select({ios: 0, android: 0})}
                style={styles.keyboardAvoidingView}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.PRIMARY}
                    style={{
                        position: 'absolute',
                        width: width * 4,
                        height: width * 4,
                        top: '50%',
                        right: -width * 1.5,
                        borderRadius: width * 4,
                    }}
                />

                <View
                    style={{
                        zIndex: 2,
                        backgroundColor: 'rgba(256, 256, 256, 0)',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        paddingTop: 100,
                    }}
                >
                    <TouchableWithoutFeedback
                        onPress={() => {
                            if (!isWeb) {
                                Keyboard.dismiss();
                            }
                        }}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                marginTop: -70,
                                marginBottom: 70,
                                height: '50%',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                source={Images[screenImage]}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    resizeMode: 'contain',
                                    backgroundColor: theme.BACKGROUND,
                                }}
                            />
                        </View>
                    </TouchableWithoutFeedback>

                    <TouchableWithoutFeedback
                        onPress={() => {
                            if (!isWeb) {
                                Keyboard.dismiss();
                            }
                        }}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                height: '50%',
                                width: '100%',
                                maxWidth: 600,
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingBottom:
                                    PHONE_HAS_A_NOTCH && !Platform.isPad
                                        ? 5
                                        : 20,
                            }}
                        >
                            {title && (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <T
                                        style={{
                                            color: theme.TEXT_WHITE,
                                            fontFamily: Constants.fonts.Bold,
                                            fontSize: normalize(20),
                                        }}
                                    >
                                        {title}
                                    </T>
                                </View>
                            )}

                            <View
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingHorizontal: 15,
                                }}
                            >
                                {input_label && (
                                    <T
                                        style={{
                                            color: theme.TEXT_WHITE,
                                            fontFamily: Constants.fonts.Bold,
                                            fontSize: normalize(12),
                                        }}
                                    >
                                        {input_label}
                                    </T>
                                )}
                                {input_placeholder && fieldValidator && (
                                    <Input
                                        style={{
                                            ...styles.input,
                                            backgroundColor:
                                                theme.INPUT_BACKGROUND,
                                            color: theme.TEXT,
                                            borderWidth: 0.5,
                                        }}
                                        placeholder={input_placeholder}
                                        autoCompleteType="username"
                                        clearButtonMode="while-editing"
                                        autoCapitalize="none"
                                        autoCorrect={false}
                                        value={field}
                                        onSubmitEditing={() => {
                                            onSubmit();
                                        }}
                                        onChangeText={text => {
                                            setField(text);
                                        }}
                                    />
                                )}
                            </View>

                            <View
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <View style={styles.signinButtonsSection}>
                                    <TouchableOpacity
                                        style={[
                                            styles.skipSigningButton,
                                            fieldValidator(field)
                                                ? {}
                                                : {opacity: 0.7},
                                            {
                                                backgroundColor: theme.SUCCESS,
                                            },
                                        ]}
                                        disabled={!fieldValidator(field)}
                                        onPress={() => {
                                            onSubmit();
                                        }}
                                        testID="goToSignupButton"
                                    >
                                        <T
                                            style={{
                                                ...styles.signinButtonText,
                                                color: theme.TEXT_WHITE,
                                            }}
                                        >
                                            {submit_button_text}
                                        </T>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.signinButtonsSection}>
                                    <TouchableOpacity
                                        style={{
                                            ...styles.goBackButton,
                                            backgroundColor: theme.TERTIARY,
                                        }}
                                        onPress={() => {
                                            props.navigation.goBack();
                                            tinyClick();
                                        }}
                                        testID="goToSignupButton"
                                    >
                                        <T
                                            style={{
                                                ...styles.goBackButtonText,
                                                color: theme.TEXT_MUTED,
                                            }}
                                        >
                                            {lang().cancel}
                                        </T>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </KeyboardAvoidingView>
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
            <LoadingModal show={props.authReducer.loginLoading} />
        </SafeAreaView>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    skipLogin: data => authActions.skipLogin(data)(dispatch, getState),
    changeUsername: data =>
        authActions.changeUsername(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    authReducer: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputScreen);
