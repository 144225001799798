import {SnackBarEventParams} from '../../types/events/SnackBarEventParams';
import {ReduxDispatch} from '../../types/redux/redux';
import emitter from '../helpers/Emitter';
import {errorVibration, successVibration} from '../app/vibrations';
import {HIDES_SNACKBAR, SHOW_SNACKBAR} from '../app/actionTypes';

export function registerEvents() {
    return (dispatch: ReduxDispatch) => {
        if (!emitter.eventNames().includes('snackBar')) {
            emitter.addListener(
                'snackBar',
                ({textMessage, delay, position, type}: SnackBarEventParams) => {
                    if (type === 'success') {
                        successVibration();
                    } else if (type === 'danger') {
                        errorVibration();
                    }
                    dispatch({
                        type: SHOW_SNACKBAR,
                        data: {textMessage, position, type},
                    });
                    setTimeout(() => {
                        dispatch({
                            type: HIDES_SNACKBAR,
                        });
                    }, delay);
                },
            );
        }
    };
}
