import React, {useEffect, useState} from 'react';
import {
    InterstitialAd,
    TestIds,
    AdEventType,
} from 'react-native-google-mobile-ads';
import {Platform} from 'react-native';
import {logEvent} from '../../modules/app/tracking';

const adUnitId: string = __DEV__
    ? TestIds?.INTERSTITIAL
    : (Platform.select({
          ios: 'ca-app-pub-4746201201889248/1670877532',
          android: 'ca-app-pub-4746201201889248/5918727238',
      }) as string);
const interstitial = InterstitialAd?.createForAdRequest(adUnitId, {
    requestNonPersonalizedAdsOnly: false,
});

const InterstitialAdController = ({
    numberOfSearches,
    isSubscribed,
}: {
    numberOfSearches: number;
    isSubscribed: boolean;
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    // ..... 15 .... 30 .... 45 .... 60

    // console.log({ isLoaded })
    if (
        !isSubscribed &&
        isLoaded &&
        numberOfSearches > 1 &&
        numberOfSearches % 15 === 0 &&
        Platform.OS !== 'web'
    ) {
        try {
            interstitial.show();
            logEvent('showInterstitialAd');
        } catch (e) {
            // maybe it is not loaded yet
        }
    }

    useEffect(() => {
        if (Platform.OS !== 'web') {
            const unsubscribeLoaded = interstitial.addAdEventListener(
                AdEventType.LOADED,
                () => {
                    setIsLoaded(true);
                },
            );

            const unsubscribeClosed = interstitial.addAdEventListener(
                AdEventType.CLOSED,
                () => {
                    interstitial.load();
                },
            );

            interstitial.load();

            return () => {
                unsubscribeLoaded();
                unsubscribeClosed();
            };
        }
    }, []);

    return <></>;
};

export default InterstitialAdController;
