import React, {useEffect} from 'react';
import {View} from 'react-native';
import {useDispatch} from 'react-redux';
import {isWeb} from '../../../env';
import {Theme} from '../../assets/Colors';
import {setSubscriptionActive} from '../../modules/subscription/actions';
import {
    useCreateCheckoutSession,
    useGetSubscriptionActions,
    useGetSubscriptionStatus,
} from '../../modules/subscription/api';
import {PackageType} from '../../types/data/subscriptionOptions';
import LoadingOfferings from './LoadingOfferings';
import LoadOfferingsError from './LoadOfferingsError';
import OfferingSection from './OfferingSection';

const WebSubscriptions = ({
    theme,
    setIsLoading,
}: {
    theme: Theme;
    setIsLoading: (v: boolean) => void;
}) => {
    const dispatch = useDispatch();
    const subscriptionOptions = useGetSubscriptionActions();
    const createCheckoutSession = useCreateCheckoutSession();
    const getSubscriptionStatus = useGetSubscriptionStatus();

    const onPress = ({duration}: {duration: PackageType}) => {
        setIsLoading(true);
        createCheckoutSession.mutate(
            {
                duration,
            },
            {
                onSuccess(data) {
                    const {url} = data.data.data;
                    window.location.href = url;
                },
                onError(error) {
                    console.log({error});
                },
                onSettled() {
                    setIsLoading(false);
                },
            },
        );
    };

    useEffect(() => {
        if (isWeb) {
            if (window.location.href?.includes('subscription/success')) {
                setIsLoading(true);
                getSubscriptionStatus.mutate(undefined, {
                    onSuccess(data) {
                        const {is_subscribed, start_date, end_date} =
                            data.data.data;
                        if (is_subscribed && start_date && end_date) {
                            setSubscriptionActive({
                                start_date,
                                end_date,
                            })(dispatch);
                        }
                    },
                    onError(error) {
                        console.log({error});
                    },
                    onSettled() {
                        setIsLoading(false);
                    },
                });
            }
        }
    }, []);

    if (subscriptionOptions.isLoading) {
        return <LoadingOfferings />;
    }

    if (subscriptionOptions.isError) {
        return <LoadOfferingsError />;
    }

    if (subscriptionOptions.isSuccess) {
        const offerings = Object.values(subscriptionOptions.data);

        return (
            <View
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                {offerings.map((offering, index) => {
                    const {price} = offering;
                    const packageType = offering.package_type;
                    const previousOfferingPrice =
                        offerings[index - 1]?.price || undefined;
                    const getDiscountPercentage = () => {
                        if (
                            !previousOfferingPrice ||
                            previousOfferingPrice > price
                        ) {
                            return undefined;
                        }
                        if (packageType === 'MONTHLY') {
                            return parseInt(
                                (((price / 30) * 100) /
                                    (previousOfferingPrice / 7)) as any,
                                10,
                            );
                        }
                        if (packageType === 'THREE_MONTH') {
                            return parseInt(
                                (((price / 90) * 100) /
                                    (previousOfferingPrice / 30)) as any,
                                10,
                            );
                        }
                    };

                    return (
                        <OfferingSection
                            key={offering.package_type}
                            theme={theme}
                            priceString={offering.price_string.en}
                            packageType={offering.package_type}
                            onPress={() => {
                                onPress({duration: packageType});
                            }}
                            discountPercentage={getDiscountPercentage()}
                        />
                    );
                })}
            </View>
        );
    }

    return <></>;
};

export default WebSubscriptions;
