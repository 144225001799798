import {AxiosError} from 'axios';
import {useMutation, useQuery} from 'react-query';
import {
    PackageType,
    SubscriptionOption,
} from '../../types/data/subscriptionOptions';
import {AxiosResponseWrapper} from '../../types/helpers/helpers';
import Backend from '../axios/axios';

export const useGetSubscriptionActions = () => {
    return useQuery<SubscriptionOption[], AxiosError>(
        'get_subscription_options',
        () => Backend.post('getSubscriptionOptions'),
        {
            retry: 2,
            retryDelay: 1000,
            enabled: true,
            cacheTime: 1000,
            // @ts-ignore
            select: data => data?.data?.data,
        },
    );
};

export const useCreateCheckoutSession = () => {
    return useMutation<
        AxiosResponseWrapper<{url: string}>,
        AxiosError,
        {duration: PackageType},
        unknown
    >(({duration}: any) => {
        const url = window.location.origin;

        return Backend.post('createCheckoutSession', {
            success_url: `${url}/subscription/success`,
            cancel_url: `${url}/subscription/cancel`,
            duration,
        });
    });
};

export const useGetSubscriptionStatus = () => {
    return useMutation<
        AxiosResponseWrapper<{
            is_subscribed: boolean;
            start_date?: number;
            end_date?: number;
        }>,
        AxiosError,
        undefined,
        unknown
    >(() => {
        return Backend.post('getSubscriptionStatus', {
            force_check: true,
        });
    });
};

export const useGetPortalSessionURL = () => {
    return useMutation<
        AxiosResponseWrapper<{url: string}>,
        AxiosError,
        undefined,
        unknown
    >(() => {
        const url = window.location.origin;

        return Backend.post('getCustomerPortalURL', {
            return_url: `${url}/subscription/main`,
        });
    });
};
