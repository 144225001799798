import Backend from '../axios/axios';

export async function getConversations() {
    return Backend.post('/getConversations', {
        include_initiated_conversations: true,
    });
}

export async function removeConversations({delete_conversation = []}) {
    return Backend.post('/deleteConversations', {
        delete_conversation,
    });
}

export async function blockConversation({conversation_id = undefined}) {
    return Backend.post('/blockAnonymous', {
        conversation_id,
    });
}

export async function reportConversation({conversation_id = undefined}) {
    return Backend.post('/reportAnonymous', {
        conversation_id,
    });
}

export async function checkUpdates() {
    return Backend.post('/checkUpdates', {
        include_initiated_conversations: true,
    });
}

export async function markConversationAsSeen({
    conversation_id,
}: {
    conversation_id: number;
}) {
    return Backend.post('/markConversationAsSeen', {
        conversation_id,
    });
}
