import {NUMBER_OF_WANTED_MESSAGES} from '../../../env';
import {formatAPITime} from '../helpers';
import Backend from '../axios/axios';

export async function getInitialMessages(conversation_id: string) {
    return Backend.post('/getInitialMessages', {
        conversation_id,
        number_of_wanted_messages: NUMBER_OF_WANTED_MESSAGES,
    });
}

export async function getMoreMessages({
    conversation_id,
    last_message_id,
}: {
    conversation_id: string;
    last_message_id: string;
}) {
    return Backend.post('/getMoreMessages', {
        conversation_id,
        last_message_id,
        number_of_wanted_messages: NUMBER_OF_WANTED_MESSAGES,
    });
}

export async function sendMessage({
    text,
    conversationID,
}: {
    text: string;
    conversationID: string;
}) {
    return Backend.post('/sendMessage', {
        message: text,
        conversation_id: conversationID,
        time: formatAPITime(),
    });
}
