import {NavigationProp} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {I18nManager, View} from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';
import {isWeb} from '../../../../env';
import {Theme} from '../../../assets/Colors';
import RefreshIcon from '../../../assets/images/SVG/RefreshIcon';
import lang from '../../../localization';
import {useLocalaization} from '../../../modules/app/localization';
import {getDarkModeHomeButtonTextColor} from '../../../modules/app/theme';
import {SCREENS} from '../../../screens/Screens';
import {
    MainTabSection,
    MainTabSectionTitle,
    MainSectionBody,
} from '../MainTabBody';

const RandomConversationSection = ({
    theme,
    color,
    navigation,
}: {
    theme: Theme;
    color: string;
    navigation: NavigationProp<any>;
}) => {
    return (
        <MainTabSection
            onPress={() => {
                navigation.navigate(SCREENS.RANDOM_CONVERSATION);
            }}
            mainColor={color}
            theme={theme}
            style={{
                minHeight: 90,
                opacity: theme.MODE === 'light' ? 0.75 : 1,
            }}
        >
            <MainTabSectionTitle
                text={lang().create_random_conversation_title}
                color={color}
                theme={theme}
            />
            <MainSectionBody
                style={{marginTop: 2}}
                text={lang().create_random_conversation_description}
                color={color}
                theme={theme}
            />
            <RefreshIconWrapper color={color} />
        </MainTabSection>
    );
};

const RefreshIconWrapper = ({color}: {color: string}) => {
    const localization = useLocalaization();
    const rotateSV = useSharedValue(I18nManager.isRTL ? 120 : 70);
    const opacitySV = useSharedValue(0);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {rotate: `${rotateSV.value}deg`},
                {scaleX: localization?.direction === 'rtl' ? 1 : -1},
            ],
            opacity: opacitySV.value,
        };
    }, []);

    useEffect(() => {
        if (!isWeb) {
            rotateSV.value = withRepeat(
                withDelay(
                    3000,
                    withTiming(
                        rotateSV.value +
                            (localization?.direction === 'rtl' ? -180 : 180),
                        {
                            duration: 1500,
                        },
                    ),
                ),
                -1,
                false,
            );
            opacitySV.value = withTiming(1, {duration: 400});
        }
    }, []);

    if (isWeb) {
        return (
            <View
                style={{
                    position: 'absolute',
                    top: -30,
                    end: -30,
                    transform: [
                        {
                            rotate:
                                localization?.direction === 'rtl'
                                    ? `100deg`
                                    : `0deg`,
                        },
                    ],
                }}
            >
                <RefreshIcon
                    width={100}
                    height={100}
                    fill={getDarkModeHomeButtonTextColor(color) as string}
                />
            </View>
        );
    }

    return (
        <Animated.View
            style={[
                {
                    position: 'absolute',
                    top: -30,
                    end: -30,
                },
                animatedStyle,
            ]}
        >
            <RefreshIcon
                width={100}
                height={100}
                fill={getDarkModeHomeButtonTextColor(color) as string}
            />
        </Animated.View>
    );
};

export default RandomConversationSection;
