import pSBC from 'shade-blend-color';
import AppType from '../../AppType';

export const Colors = {
    GREEN_BLUISH: '#54A09D',
    GOLD_CROWN: '#FFC107',
    WHITE: 'white',
};

export type Theme = {
    MODE: 'dark' | 'light';
    BACKGROUND: string;
    TEXT: string;
    TEXT_MUTED: string;
    TEXT_PRIMARY: string;
    TEXT_WHITE: string;
    PRIMARY: string;
    SECONDARY: string;
    TERTIARY: string;
    DISABLED: string;
    DANGER: string;
    SUCCESS: string;
    PRIMARY_DEVIDER: string;
    RED: string;
    GOLD: string;
    ORANGE: string;
    BABY_BLUE: string;
    PEACH: string;
    INPUT_BACKGROUND: string;
    GRADIENTS: {
        BOTTOM_FADER: string[];
        PRIMARY: string[];
        FACEBOOK: string[];
        TWITTER: string[];
    };
    TRANS_GRAY: string;
};

export const PRIMARY_COLOR = AppType.select({
    nezmo: '#5B91B4',
    fadfadah: '#8E8ABE',
});

export const ThemeColors: {dark: Theme; light: Theme} = {
    dark: {
        MODE: 'dark',
        BACKGROUND: pSBC(-0.85, PRIMARY_COLOR) as string,
        TEXT: 'white',
        TEXT_MUTED: '#D4D7DD',
        TEXT_PRIMARY: 'white',
        TEXT_WHITE: 'white',
        PRIMARY: pSBC(-0.5, PRIMARY_COLOR) as string,
        SECONDARY: pSBC(-0.75, PRIMARY_COLOR) as string,
        TERTIARY: pSBC(-0.6, PRIMARY_COLOR) as string,
        DISABLED: '#8C8C8C',
        DANGER: '#bc6363',
        SUCCESS: '#83C18C',
        PRIMARY_DEVIDER: '#1F1D2A',
        RED: '#9A3B57',
        GOLD: '#E2CF71',
        ORANGE: '#FFC107',
        BABY_BLUE: '#5E73C4',
        PEACH: '#E7BC94',
        INPUT_BACKGROUND: pSBC(-0.85, PRIMARY_COLOR) as string,
        GRADIENTS: {
            BOTTOM_FADER: [
                'rgba(18, 24, 29, 0)',
                pSBC(-0.7, PRIMARY_COLOR) as string,
            ],
            PRIMARY: [
                pSBC(-0.75, PRIMARY_COLOR) as string,
                pSBC(-0.8, PRIMARY_COLOR) as string,
            ],
            FACEBOOK: ['#4b74a3', '#466b94', '#406085'],
            TWITTER: ['#1583C3', '#146AA5'],
        },
        TRANS_GRAY: 'rgba(30, 30, 30, 0.8)',
    },
    light: {
        MODE: 'light',
        BACKGROUND: 'white',
        TEXT: '#656565',
        TEXT_MUTED: '#7B7D81',
        TEXT_PRIMARY: PRIMARY_COLOR,
        TEXT_WHITE: 'white',
        PRIMARY: PRIMARY_COLOR,
        SECONDARY: '#DADADA',
        TERTIARY: 'white',
        DISABLED: '#A2A2A2',
        DANGER: '#C86363',
        SUCCESS: '#83C18C',
        PRIMARY_DEVIDER: '#E7EFEF',
        RED: '#9A3B57',
        GOLD: '#DEC95F',
        ORANGE: '#FFC107',
        BABY_BLUE: '#9BB5F9',
        PEACH: '#F4D2BB',
        INPUT_BACKGROUND: '#F9F9F9',
        GRADIENTS: {
            BOTTOM_FADER: [
                'rgba(255, 255, 255, 0)',
                pSBC(0.2, PRIMARY_COLOR) as string,
            ],
            PRIMARY: [
                pSBC(0.3, PRIMARY_COLOR) as string,
                pSBC(0, PRIMARY_COLOR) as string,
            ],
            FACEBOOK: ['#4b74a3', '#466b94', '#406085'],
            TWITTER: ['#21a9f7', '#219ae0'],
        },
        TRANS_GRAY: 'rgba(100, 100, 100, 0.3)',
    },
};
