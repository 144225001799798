import React, {useEffect, useState} from 'react';
import {
    View,
    Modal as ReactModal,
    TouchableOpacity,
    TouchableWithoutFeedback,
    Platform,
    Dimensions,
    KeyboardAvoidingView,
} from 'react-native';
import Animated, {
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
    withTiming,
} from 'react-native-reanimated';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';

const ANIMATION_DURATION = 300;
const SCREEN_HEIGHT = Dimensions.get('screen').height;

export type ModalProps = {
    isVisible: boolean;
    closeModal: () => void;
    theme: Theme;
};

const Modal = ({
    isVisible,
    closeModal,
    theme,
    children,
}: {
    children?: JSX.Element;
} & ModalProps) => {
    const backgroundOpacity = useSharedValue(0);
    const modalTranslateY = useSharedValue(0);
    const modalOpacity = useSharedValue(0);
    const [localIsVisible, setLocalIsVisible] = useState(isVisible);

    const backgroundAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: backgroundOpacity.value,
        };
    }, []);

    const modalAnimatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{translateY: modalTranslateY.value}],
            opacity: modalOpacity.value,
        };
    }, []);

    useEffect(() => {
        if (isVisible === true) {
            setLocalIsVisible(true);
            backgroundOpacity.value = 0;
            backgroundOpacity.value = withTiming(1, {
                duration: ANIMATION_DURATION,
            });

            modalOpacity.value = 0;
            modalOpacity.value = withTiming(1, {duration: ANIMATION_DURATION});

            modalTranslateY.value = SCREEN_HEIGHT / 2;
            modalTranslateY.value = withSpring(0, {mass: 0.7});
        }
        if (isVisible === false) {
            onModalClose();
        }
    }, [isVisible]);

    const onModalClose = () => {
        modalOpacity.value = withTiming(0, {duration: ANIMATION_DURATION});
        modalTranslateY.value = withSpring(SCREEN_HEIGHT / 2);
        backgroundOpacity.value = withTiming(
            0,
            {duration: ANIMATION_DURATION},
            finished => {
                if (finished) {
                    runOnJS(closeModal)();
                    runOnJS(setLocalIsVisible)(false);
                }
            },
        );
    };

    if (!localIsVisible) {
        return <></>;
    }

    return (
        <ReactModal
            transparent
            animationType="none"
            visible={localIsVisible}
            style={{
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                height: '100%',
            }}
        >
            <Animated.View
                style={[
                    {
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                        backgroundColor:
                            theme.MODE === 'light'
                                ? 'rgba(255, 255, 255, 0.7)'
                                : 'rgba(0, 0, 0, 0.5)',
                    },
                    backgroundAnimatedStyle,
                ]}
            />
            <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => {
                    onModalClose();
                }}
                style={{
                    zIndex: 10,
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'padding' : undefined}
                    style={{
                        width: '100%',
                        maxWidth: 600,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Animated.View
                        style={[
                            {
                                alignItems: 'stretch',
                                backgroundColor: theme.BACKGROUND,
                                width: '90%',
                                marginTop: Platform.OS === 'ios' ? 64 : 24,
                                borderRadius: 12,
                                bottom: 30,
                                ...Constants.elevation.three,
                            },
                            modalAnimatedStyle,
                        ]}
                    >
                        <TouchableWithoutFeedback onPress={() => {}}>
                            {children || <></>}
                        </TouchableWithoutFeedback>
                    </Animated.View>
                </KeyboardAvoidingView>
            </TouchableOpacity>
        </ReactModal>
    );
};

export default Modal;
