import React from 'react';
import {StatusBar} from 'react-native';
import {Theme} from '../../assets/Colors';

const StatusBarAware = ({theme}: {theme: Theme}) => {
    return (
        <StatusBar
            backgroundColor={theme.BACKGROUND}
            barStyle={theme.MODE === 'light' ? 'dark-content' : 'light-content'}
        />
    );
};

export default StatusBarAware;
