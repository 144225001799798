import React, {useEffect, useRef} from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {isWeb} from '../../../env';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {useLocalaization} from '../../modules/app/localization';
import {tinyClick} from '../../modules/app/vibrations';
import {normalize} from '../../modules/helpers';
import Modal from '../Shared/Modal';

const icons = {
    partyIllustrator: require('../../assets/images/partyIllustration.png'),
    warningIcon: require('../../assets/images/warningIcon.png'),
    handShakeIcon: require('../../assets/images/handShakeIcon.png'),
    giftIcon: require('../../assets/images/giftIcon.png'),
};

const styles = StyleSheet.create({
    centeredView: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
    },
    modalView: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 9,
        zIndex: 20,
    },
});

const RandomConversationsWelcomeModal = ({show, setShow, theme}) => {
    const hideModal = () => {
        tinyClick();
        setShow(false);
    };

    const confettiRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (confettiRef.current) {
                confettiRef.current.startConfetti();
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Modal
            isVisible={show}
            theme={theme}
            closeModal={hideModal}
        >
            <View
                disabled
                style={styles.modalView}
            >
                {!isWeb && (
                    <View
                        style={{
                            height: 300,
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Image
                            source={icons.partyIllustrator}
                            style={{
                                width: 200,
                                height: 200,
                                resizeMode: 'contain',
                            }}
                        />
                    </View>
                )}
                <View style={{marginTop: 20}} />
                <View
                    style={{
                        flexDirection: 'column',
                        maxWidth: '100%',
                    }}
                >
                    <Point
                        iconName="giftIcon"
                        text={lang().random_welcome_modal.point_1}
                        color={theme.SUCCESS}
                    />
                    <Point
                        iconName="warningIcon"
                        text={lang().random_welcome_modal.point_2}
                        color={theme.DANGER}
                    />
                    <Point
                        iconName="handShakeIcon"
                        text={lang().random_welcome_modal.point_3}
                        color={theme.BABY_BLUE}
                    />
                </View>

                <TouchableOpacity
                    onPress={() => {
                        hideModal();
                    }}
                    style={{
                        backgroundColor: theme.SUCCESS,
                        width: '90%',
                        paddingVertical: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: 30,
                    }}
                >
                    <Text
                        style={{
                            color: 'white',
                            fontFamily: Constants.fonts.Bold,
                            fontSize: normalize(13),
                        }}
                    >
                        {lang().continue}
                    </Text>
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

const Point = ({iconName, text, color}) => {
    return (
        <View
            style={{
                flexDirection: 'row',
                maxWidth: '100%',
                marginBottom: 15,
                marginHorizontal: 10,
            }}
        >
            <View
                style={{
                    width: '17%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Image
                    source={icons[iconName]}
                    style={{
                        height: 30,
                        width: 30,
                        resizeMode: 'contain',
                        tintColor: color,
                    }}
                />
            </View>
            <View style={{width: '83%', paddingEnd: 15}}>
                <Text
                    style={{
                        fontFamily: Constants.fonts.SemiBold,
                        textAlign: 'left',
                        color,
                        fontSize: normalize(11),
                    }}
                >
                    {text}
                </Text>
            </View>
        </View>
    );
};

export default RandomConversationsWelcomeModal;
