export function hasBadWords(text = ''): boolean {
    const predefinedBadWords = getListOfBadWords();

    const badText = predefinedBadWords.find(predefinedBadWord => {
        if (text.toLowerCase() === predefinedBadWord.toLowerCase()) {
            return true;
        }
        return text
            .toLowerCase()
            .includes(`${predefinedBadWord.toLowerCase()} `);
    });

    if (badText) {
        return true;
    }

    return false;
}

export function getListOfBadWords(): string[] {
    return [
        'كسمك',
        'الحس',
        'زبي',
        'زب',
        'طيز',
        'طيزك',
        'طيزي',
        'نيك',
        'sex',
        'fuck',
        'daddy',
        'زب',
        'زبب',
        'ززب',
        'زززب',
        'زززززب',
        'كس',
        'كسس',
        'ككس',
        'ككسس',
        'زبي',
        'زببي',
        'ززبي',
        'زبييي',
        'زززبي',
        'زوبي',
        'زوببي',
        'زبري',
        'زببري',
        'زبرري',
        'قواد',
        'قوواد',
        'قوااد',
        'قواااد',
        'قوااااد',
        'قووااد',
        'سالب',
        'ساالب',
        'سسالب',
        'سااالب',
        'سالبب',
        'ساللب',
        'ساااالب',
        'شرموطة',
        'شرموطه',
        'شررموطة',
        'شرمووطة',
        'شرموووطة',
        'ششرموطة',
        'قضيب',
        'قضييب',
        'قضيبي',
        'ذكري',
        'مشتهي',
        'مشتهيي',
        'مشتهييي',
        'مششتهيي',
        'مشتهييي',
        'مشتهية',
        'موجب',
        'مووجب',
        'موووجب',
        'عاهرة',
        'عاهررة',
        'عااهرة',
        'عاااهرة',
        'عااااهرة',
        'كلبة',
        'كللبة',
        'خنيث',
        'خنييث',
        'خنيييث',
        'مخنث',
        'مخننث',
        'شاذ',
        'شااذ',
        'ششاذ',
        'شاذذ',
        'منيوك',
        'منيووك',
        'ممنيووك',
        'منيوووك',
        'منيووووك',
        'قحبتك',
        'قحبة',
        'قحببة',
        'قححبة',
        'سحاق',
        'سحااق',
        'سسحاق',
        'منيوك',
        'منيووك',
        'منييوة',
        'مننيوك',
        'كس',
        'كسس',
        'كسي',
        'كسسي',
        'ككسي',
        'مخصي',
        'نجس',
        'صديقلال',
        'مهبل',
        'لوطي',
        'لووطي',
        'لوووطي',
        'للوطي',
        'لواط',
        'لوااط',
        'لواااط',
        'زامل',
        'طيز',
        'طييز',
        'ططيز',
        'طيزي',
        'موخرة',
        'مؤخرة',
        'ممحون',
        'ممحون',
        'ممحوون',
        'ممححوونن',
        'ممحونة',
        'نيك',
        'نييك',
        'استي',
        'اسستي',
        'شهوة',
        'شههوة',
        'تجليخ',
        'تجلييخ',
        'تجليييخ',
        'تجليخخ',
        'safel',
        'Safel',
        'سافل',
        'السافل',
        'جسد',
        'نيجربيه',
        '+18',
        '+١٨',
        'جنس',
        'شرموط',
        'عنتيل',
        'Neswangy',
        'نسونجي',
        'فحل',
        'فحلك',
        'fa7el',
        'f7l',
        'fa7elk',
        'تحبي الجنس',
        'الجنس',
        'مشتهيه',
        'احم',
        'س.س',
        'بنوتي',
        'ديوث',
        'دياثة',
        'ذياثة',
        'دياثه',
        'ذياثه',
        'محرج',
        'ابادل',
        'مبادل',
        'مبادلة',
        'مببادل',
        'ممحونه',
        'محنه',
        'محنة',
        'مبادله',
        'مباددلة',
        'مححنة',
        'محننه',
        'مخنة',
        'مخننه',
        'هريحك',
        'هريححك',
        'كىىى',
        'كىىىك',
        'MMشتهيmM',
        'مشتهي',
        'مشتهيه',
        'مشتهية',
        'شهوه',
        'شهوة',
        'مشتههي',
        'مشتهيي',
        'ممشتهي',
        'الفحل',
        'فححححل',
        'ف77ل',
        'فhل',
        'فحل',
        'فححل',
        'فحلل',
        'فحححل',
        'f7l',
        'fhl',
        'اختي',
        'امي',
        'سالب',
        'موجب',
        'سالb',
        'سسالبب',
        'ليز',
        'ليزبيان',
        'les',
        'مولع',
        'مولعع',
        'ميقا',
        'مييقا',
        'ميجا',
        'مقاطع',
        'مقاطعع',
        'ممقاطع',
        'مققاطع',
        'مقاطعع',
        'ميgا',
        'ساخن',
        'ساخنة',
        'سخنة',
        'سخنه',
        'كلب',
        'كللب',
        'كلبة',
        'كلبه',
        'كلببه',
        'كلببة',
        'مطيع',
        'مطيعة',
        'مطيععه',
        'مطيعه',
        'مطيعهة',
        'تنتاك',
        'منيوك',
        'نياك',
        'نايك',
        'نيكك',
        'انيكك',
        'هنيكك',
        'مفشوخ',
        'مفشوخة',
        'افشخك',
        'هفشخك',
        'مشتهييه',
        'ك.سها',
        'هايج',
        'هايجة',
        'هايجج',
        'هايججج',
        'ههايج',
        'هاييج',
    ];
}
