import React from 'react';
import {Image, TouchableOpacity, StyleSheet} from 'react-native';
import * as Linking from 'expo-linking';
import {Theme} from '../../assets/Colors';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';

const whatsappIcon = require('../../assets/images/whatsappIcon.png');

const styles = StyleSheet.create({
    whatsappContactSection: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: normalize(8),
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 20,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.3,
        shadowRadius: 3.84,
        elevation: 4,
    },
    whatsappMessageText: {
        fontSize: normalize(10),
        color: 'white',
    },
});

const WhatsAppButton = ({
    showSnackBar,
    whatsAppNumber,
    theme,
}: {
    showSnackBar?: (props: {
        textMessage: string;
        position?: string;
        type?: string;
        delay?: number;
    }) => void;
    whatsAppNumber: string;
    theme: Theme;
}) => {
    if (whatsAppNumber) {
        return (
            <TouchableOpacity
                style={{
                    ...styles.whatsappContactSection,
                    backgroundColor:
                        theme.MODE === 'light' ? theme.SUCCESS : theme.TERTIARY,
                    shadowColor:
                        theme.MODE === 'light'
                            ? theme.SUCCESS
                            : theme.SECONDARY,
                }}
                onPress={() => {
                    Linking.openURL(
                        `whatsapp://send?phone=${whatsAppNumber}`,
                    ).catch(() => {
                        if (showSnackBar) {
                            showSnackBar({
                                textMessage: lang().you_dont_have_whatsapp,
                                position: 'top',
                                type: 'danger',
                                delay: 1500,
                            });
                        }
                    });
                }}
            >
                <T
                    style={{
                        ...styles.whatsappMessageText,
                        color: theme.TEXT_WHITE,
                    }}
                >
                    {lang().contact_us_with_whatsapp}
                </T>
                <Image
                    source={whatsappIcon}
                    style={{
                        height: 18,
                        width: 18,
                        marginStart: 10,
                    }}
                />
            </TouchableOpacity>
        );
    }
    return <></>;
};

export default WhatsAppButton;
