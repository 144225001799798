import React, {ReactNode} from 'react';
import {isWeb} from '../../../env';

const WebComponent = ({children}: {children: ReactNode}) => {
    if (isWeb) {
        return <>{children}</>;
    }
    return null;
};

export default WebComponent;
