import {Alert, Platform} from 'react-native';
import * as Linking from 'expo-linking';
// eslint-disable-next-line import/extensions
import {version as currentVersion} from '../../../package.json';

/**
 * Helper function that compare 2 Simvor versions
 * simvor example "0.13.2"
 *
 * @param { string } v1 first simvor version
 * @param { string } v2 second simvor verstion
 * @returns { boolean }
 */
function isVersionLessThan(v1, v2) {
    // check if all versions are valid
    if (
        typeof v1 === 'string' &&
        v1.split('.').length === 3 &&
        typeof v2 === 'string' &&
        v2.split('.').length === 3
    ) {
        try {
            const v1Arr = v1.split('.');
            const v2Arr = v2.split('.');
            for (let i = 0; i < v1Arr.length; i += 1) {
                if (v1Arr[i] !== v2Arr[i]) {
                    if (parseInt(v1Arr[i], 10) > parseInt(v2Arr[i], 10)) {
                        return false;
                    }
                    if (parseInt(v1Arr[i], 10) < parseInt(v2Arr[i], 10)) {
                        return true;
                    }
                }
            }
        } catch (error) {
            return false;
        }
    }
    return false;
}

function updateAlert() {
    // alert("needs updates");
    const APP_STORE_LINK =
        'https://apps.apple.com/us/app/fadfadah-فضفضة/id1515093716';
    const PLAY_STORE_LINK =
        'https://play.google.com/store/apps/details?id=com.fadfadahnative';

    Alert.alert(
        'يوجد تحديث جديد',
        'حمل التحديث من متجر التطبيقات',
        [
            {
                text: 'تحديث',
                onPress: () => {
                    updateAlert();
                    if (Platform.OS === 'ios') {
                        Linking.openURL(APP_STORE_LINK).catch(() => {});
                    } else {
                        Linking.openURL(PLAY_STORE_LINK).catch(() => {});
                    }
                },
            },
        ],
        {cancelable: false},
    );
}

export function checkForceUpdate() {
    return async (dispatch, getState) => {
        if (getState().authReducer.config) {
            const {minimum_required_version} = getState().authReducer.config;
            if (minimum_required_version) {
                if (
                    isVersionLessThan(currentVersion, minimum_required_version)
                ) {
                    updateAlert();
                }
            }
        }
    };
}
