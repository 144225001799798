import AppType from '../../AppType';
import Constants from '../assets/Constants';
import ScreenTitles from './screenTitles';

export const acceptedEmailDomains = [
    'gmail',
    'yahoo',
    'hotmail',
    'outlook',
    'aol',
    'icloud',
    'mail',
    'msn',
    'live',
    'ymail',
    'gmx',
    'zoho',
    'yandex',
    'protonmail',
];

const lang = {
    ar: {
        screenTitles: {...ScreenTitles.ar},
        lang: 'ar',
        direction: 'rtl',
        align: 'right',
        username: 'اسم المستخدم',
        email: 'البريد الالكتروني',
        password: 'كلمة المرور',
        password_confirm: 'تأكيد كلمة المرور',
        or: 'او',
        is_loading: 'جاري التحميل',
        sign: 'تسجيل',
        skip_creating_account: 'دخول بدون حساب',
        confirm: 'تأكيد',
        skip: 'تخطي',
        continue: 'متابعة',
        fromHere: 'من هنا',
        create_account: 'إنشاء حساب',
        or_share: 'أو شاركه باستخدام',
        copy_link: 'نسخ الرابط',
        or_auth: 'أو قم بالتسجيل عن طريق',
        or_sign_up: 'أو قم بإنشاء حساب عن طريق',
        facebook: 'فيسبوك',
        twitter: 'تويتر',
        other_language: 'English',
        current_language: 'عربي',
        copy: 'نسخ',
        create_account_title: 'قم بإنشاء حسابك',
        already_have_account: 'لديك حساب؟',
        fields_are_required: 'الرجاء تعبئة جميع الحقول',
        error_happened: 'حدث خطأ، تأكد من اتصالك',
        error_1001: 'معلومات خاطئة، حاول مرة أخرى',
        error_1011: 'هناك حساب آخر مسجل بنفس البريد الالكتروني',
        data_not_valid:
            'المعلومات التي أدخلتها غير صالحة. رجاءً، حاول مرة أخرى',
        name_less_than_two_chars: 'يجب أن يحتوي الاسم على أكثر من حرفين ',
        name_more_than_twenty_chars: 'يجب أن يحتوي الاسم على أقل من عشرين حرف',
        email_not_valid: 'تأكد من كتابة البريد الالكتروني بشكل صحيح',
        password_less_than_six_chars:
            'كلمة المرور يجب أن تحتوي على ٦ عناصر على الأقل',
        password_confirmation_error:
            'تأكيد كلمة المرور غير متطابق مع كلمة المرور',
        facebook_auth_cancel: 'لقد قمت بإلغاء العملية',
        facebook_auth_error: 'حدث خطأ أثناء التسجيل عن طريق فيسبوك',
        apple_auth_error: 'حدث خطأ أثناء التسجيل عن طريق ابل',
        twitter_auth_error: 'حدث خطأ أثناء التسجيل عن طريق تويتر',
        twitter_auth_error_desc:
            'سوف يتم اضافة هذه الميزة قريباً، يمكنك استخدام الموقع لتتمكن من التسجيل عن طريق تويتر',
        ok: 'حسناً',
        profile: 'الملف الشخصي',
        settings: 'الإعدادات',
        conversations_you_made_as_anonymous: 'المحادثات التي أجريتها كمجهول',
        create_random_conversation: AppType.select({
            fadfadah: 'محادثة عشوائية',
            nezmo: 'إنشاء محادثة',
        }),
        share_your_link: 'شارك رابط حسابك',
        share_your_link_desc: 'ليتمكن اصدقائك من محادثتك من دون ان تعرف هويتهم',
        share: 'مشاركة',
        for_help: 'للمساعدة',
        by_registering: `بتسجيلك حساب في ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })} فإنك توافق على `,
        terms_of_use: AppType.select({
            nezmo: 'اتفاقية العميل',
            fadfadah: 'شروط الاستخدام',
        }),
        privacy_policy: 'سياسة الخصوصية',
        and: 'و',
        options: 'الخيارات',
        general_confirm_message: 'هل أنت متأكد من اتمام العملية؟',
        general_confirm: 'تأكيد',
        remove_conversation: 'حذف المحادثة',
        remove_conversation_confirm: 'تأكيد حذف المحادثة',
        block_user: 'حظر المستخدم',
        unblock_user: 'إلغاء حظر المستخدم',
        block_user_confirm: 'تأكيد العملية',
        block_success: 'تم حظر المستخدم',
        unblock_success: 'تم إلغاء الحظر عن المستخدم',
        report_user: 'إبلاغ الإدارة عن مستخدم مسيء',
        report_user_confirm: 'تأكيد الإبلاغ عن المستخدم',
        report_user_success:
            'تم الإبلاغ، سنقوم باتخاذ الإجراء المناسب في أقرب فرصة',
        remove_conversations: 'حذف المحادثات',
        remove: 'حذف',
        remove_conversations_alert_title: 'تأكيد حذف المحادثات',
        remove_conversations_alert_message:
            'محادثة تم تحديدها، هل أنت متأكد من أنك تريد حذفها؟',
        confirm_removal: 'تأكيد الحذف',
        cancel: 'رجوع',
        snackbar_success: 'تمت العملية بنجاح',
        snackbar_error: 'حدث خطأ أثناء تنفيذ العملية',
        confirm_logout: 'هل أنت متأكد من أنك تريد تسجيل الخروج؟',
        logout: 'تسجيل خروج',
        no_conversations: 'لا توجد محادثات',
        fetching_conversations: 'جاري تحميل المحادثات',
        no_messages_in_conversation: 'لم يقم الطرف الآخر بإرسال رسالة',
        conversation_start: 'بداية المحادثة',
        sendMessagePlaceholder: 'اكتب رسالتك هنا',
        notificationWarningTitle: 'الإشعارات غير مفعلة',
        notificationWarningDescription: `لتفعيل الاشعارات: اذهب الى "الاعدادات" > "${AppType.select(
            {fadfadah: 'فضفضة', nezmo: 'نيزمو'},
        )}" > "الاشعارات" ثم تأكد من تشغيل "السماح بالإشعارات"`,
        upload_image: 'إرفاق صورة',
        take_image: 'التقط صورة',
        choose_from_library: 'اختر من مكتبة الصور',
        share_via: 'مشاركة عن طريق',
        share_message_1: ' تحدث مع',
        share_message_2: '، من غير أن يتعرف على هويتك',
        snapchat: 'سنابشات',
        instagram: 'انستجرام',
        send: 'إرسال',
        disconnected: 'غير متصل بالانترنت',
        mandatoryContinueButtonLabel: 'متابعة',
        mandatoryUpdateMessage: `يوجد تحديث جديد ل${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}، فضلاً سنقوم بتنزيله`,
        optionalIgnoreButtonLabel: 'تجاهل',
        optionalInstallButtonLabel: 'تحميل',
        optionalUpdateMessage: `يوجد تحديث جديد ل${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}، هل تود تنزيله؟`,
        updateAlertTitle: 'تحديث جديد متوفر',
        rateUsSectionText: 'ادعمنا بتقييمك للتطبيق',
        you_have_message_from: 'لديك محادثة من',
        you_have_random_message_from: `${AppType.select({
            fadfadah: 'لديك محادثة عشوائية من',
            nezmo: 'لديك محادثة من',
        })}`,
        do_you_want_to_view_it: 'هل تود عرضها؟',
        accept: 'موافقة',
        reject_and_delete: 'رفض',
        male: 'ذكر',
        female: 'أنثى',
        rating_modal_title: 'كيف كانت تجربتك للتطبيق',
        rating_modal_rate_button: 'قيَم',
        rating_modal_send_button: 'ارسل تقييمك',
        rating_modal_comment_placeholder: `قدم ملاحظاتك إلى فريق عمل ${AppType.select(
            {fadfadah: 'فضفضة', nezmo: 'نيزمو'},
        )}`,
        rating_modal_thankyou: 'شكراً لتقييمك',
        rating_modal_we_promise_to_provide_better_service:
            'نعدك بالاستمرار في العمل على تقديم تجربة أفضل',
        rating_modal_empty_comment_error: '',
        rating_modal_star_labels: ['سيء جداً', 'سيء', 'مقبول', 'جيد', 'ممتاز'],
        contact_us_at_facebook: 'تواصل معنا عن طريق صفحتنا في فيس بوك',
        or_contact_us_by_email: `أو عن طريق البريد الالكتروني ${Constants.links.supportEmail}`,
        forgot_password_button: 'هل نسيت كلمة المرور؟',
        reset_password_modal_title:
            'قم بإدخال بريدك الإلكتروني لاسترجاع كلمة المرور الخاصة بحسابك',
        reset_password: 'استرجاع كلمة المرور',
        reset_password_success:
            'تم إرسال رسالة استرجاع كلمة المرور إلى بريدك الالكتروني، قد تستغرق الرسالة عدة دقائق لتصلك',
        new_message: {
            one_message: 'رسالة جديدة',
            two_messages: 'رسالتان جديدتان',
            to_ten_messages: 'رسائل جديد',
            to_infinite: 'رسالة جديدة',
            people_messaged_you: (people: string) =>
                `قام ${people} بإرسال رسالة جديدة`,
            and_others: 'واخرون',
        },
        create_new_conversation: 'إنشاء محادثة جديدة',
        avoid_using_your_real_name: 'تجنب إدخال اسمك الحقيقي',
        your_nickname: 'اسمك المستعار',
        start_conversation: 'ابدأ المحادثة',
        not_logged_in_trying_to_create_conversation:
            'يجب عليك القيام بتسجيل الدخول إلى حسابك لتتمكن من إنشاء محادثة جديدة',
        get_user_data_error: 'حدث خطأ عند محاولة جلب معلومات المستخدم',
        user_has_blocked_you: 'قام المستخدم بحظرك',
        conversation_is_deleted: 'تم حذف المحادثة',
        conversation_is_blocked: 'تم حظر المحادثة',
        is_typing: 'يكتب الان',
        subscription_screen_title: `اشتراك ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}`,
        features: [
            {
                id: 0,
                title: 'محادثات عشوائية',
                description: 'تمتع بعدد غير محدود من المحادثات العشوائية',
                image: 'infiniteConversationsImage',
                color: '#9DC39D',
            },
            {
                id: 1,
                title: 'بحث حسب الدولة',
                description: 'ابحث عن المستخدمين حسب الدولة التي تريدها',
                image: 'noAdsImage',
                color: '#CE6A6A',
            },
            {
                id: 2,
                title: 'إزالة الإعلانات',
                description: 'احصل على تجربة فريدة بدون مقاطعة',
                image: 'noAdsImage',
                color: '#CE6A6A',
            },
            {
                id: 3,
                title: 'دعم سريع و مباشر',
                description: `دعم مباشر من فريق ${AppType.select({
                    fadfadah: 'فضفضة',
                    nezmo: 'نيزمو',
                })} لحل أي مشكلة تواجهك`,
                image: 'instantSupportImage',
                color: '#7382C1',
            },
            {
                id: 4,
                title: 'كن مميزاً',
                description: 'احصل على مظهر مميز خاص بالمشتركين',
                image: 'profilePicture',
                color: Constants.colors.goldCrown,
            },
        ],
        choose_subscription_period: 'اختر مدة الإشتراك',
        duration_text: {
            THREE_DAY: '3 أيام',
            MONTHLY: 'شهر',
            WEEKLY: 'إسبوع',
            THREE_MONTH: '3 أشهر',
            YEARLY: 'سنوي',
        },
        could_not_load_offerings: 'لم نتمكن من تحميل الإشتراكات',
        subscribe: 'اشتراك',
        free_then: (priceDuration: any) => {
            return `${priceDuration} مجاناً ثم`;
        },
        discount: (price: any) => `خصم ${price}%`,
        subscription_disclaimers: [
            'سيتم تجديد الاشتراك بشكل تلقائي، يمكنك إيقاف التجديد التلقائي قبل 24 ساعة من تاريخ إنتهاء اشتراكك الحالي.',
            'سيتم تجديد اشتراكك تلقائياً خلال 24 ساعة قبل إنتهاء الفترة الحالية.',
            'يمكنك التحكم باشتراكك أو إلغاء التجديد التلقائي من خلال إعدادات حسابك في المتجر.',
        ],
        restore_subscription: 'استعادة الإشتراك',
        restore_subscription_success: 'تم استعادة اشتراكك بنجاح',
        restore_subscription_fail: 'لم نتمكن من العثور على اشتراكك',
        restore_subscription_error: 'لم نتمكن من استعادة اشتراكك',
        subscribe_to_remove_ads: `اشترك في ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })} لتجربة جميع المميزات`,
        you_are_a_subscriber: `أنت مشترك في ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}`,
        your_subscription_will_end_at: 'اشتراكك سينتهي بتاريخ',
        thanks_for_subscribing: `شكراً لاشتراكك في ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}`,
        subscription_settings: 'إعدادات الاشتراك',
        fadfadah_link: `رابط ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })} الخاص بالمستخدم`,
        create_conversation: 'إنشاء محادثة',
        copy_fadfadah_link_and_paste_it_here: `انسخ رابط ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })} الخاص بالمستخدم والصقه هنا`,
        no_user_found_with_this_link: 'الرابط خاطئ، تأكد من صحة الرابط',
        error_while_extracting_url: 'خطأ أثناء محاولة فحص الرابط',
        contact_us_with_whatsapp: 'للمساعدة، تواصل عن طريق واتس اب',
        you_dont_have_whatsapp: 'لم نتمكن من فتح واتس اب',
        enable_random_conversations: 'السماح بتلقي المحادثات العشوائية',
        change_search_country: 'تغيير دولة البحث',
        error_no_users_with_selected_country:
            'لا يوجد مستخدمين في الدولة التي قمت بتحديدها',
        conversations_left_1: 'لديك',
        conversations_left_2: 'محادثات عشوائية مجانية',
        conversations_left_2_b: 'محادثات مجانية لهذا اليوم',
        start_random_conversation: 'إنشاء محادثة',
        watch_ad_to_continue: 'شاهد الإعلان للمتابعة',
        report_random_user: 'الإبلاغ',
        find_another_random_user: 'بحث عن شخص آخر',
        get_unlimited_random_conversations:
            'احصل على عدد غير محدود من المحادثات العشوائية',
        you_have_been_blocked_from_using_random_conversations:
            'تم حظر حسابك مؤقتاً من استخدام المحادثات العشوائية',
        block_reason: 'سبب الحظر',
        time_left_for_block_to_expire: 'المدة المتبقية لإنتهاء الحظر',
        reason_text: (reason: string | null | undefined) => {
            switch (reason) {
                case 'Report-Without-Reason':
                    return 'قمت بالإبلاغ عن مستخدم غير مسيء';
                case 'Bad-Messages':
                    return 'قمت بإرسال رسائل جنسية أو مسيئة';
                case 'Bad-Image':
                    return 'صورة الحساب جنسية أو مخالفة';
                case 'Bad-Name':
                    return 'اسم الحساب جنسي أو مخالف';
                default:
                    return 'تم الابلاغ عن حسابك من قبل العديد من المستخدمين';
            }
        },
        contact_us_if_you_think_block_is_wrong:
            'تواصل مع إدارة التطبيق إذا كنت تظن أن الحظر تم عن طريق الخطأ',
        block_contact: {
            facebook: 'عن طريق فيسبوك',
            instagram: 'عن طريق انستجرام',
        },
        no_free_random_conversations:
            'انتظر إلى الغد لتحصل على محادثات مجانية أخرى',
        watch_ad_to_get_more_conversations:
            'شاهد الإعلان للحصول على المزيد من المحادثات',
        no_online_random_users: 'لم نتمكن من العثور على مستخدمين',
        report_random_user_modal_title: (name: string) =>
            `هل أنت متأكد من أنك تريد إبلاغ الإدارة عن إساءة من قبل (${name}) ؟`,
        report_random_user_modal_warning:
            'قد تتعرض للحظر في حالة إبلاغك عن مستخدم غير مسيء',
        report_random_user_modal_confirm: 'تأكيد الابلاغ',
        report_random_user_modal_cancel: 'إلغاء البلاغ',
        report_random_user_modal_chose_reason: 'ما هو سبب البلاغ؟',
        report_random_user_modal_bad_image:
            'هذا المستخدم يضع صورة جنسية أو مسيئة',
        report_random_user_modal_bad_name: 'هذا المستخدم يحمل اسم جنسي او مسيء',
        you_have_unlimited_number_of_random_conversations:
            'لديك عدد غير محدود من المحادثات العشوائية',
        reject_and_stop_random_conversations:
            'رفض وإيقاف استلام المحادثات العشوائية',
        you_are_blocked_from_random_conversations:
            'تم حظرك من استخدام المحادثات العشوائية',
        random_welcome_modal: {
            point_1:
                'لقد حصلت على محادثات عشوائية مجانية، و عند انتهائها ستحصل على المزيد من المحادثات المجانية بعد 24 ساعة',
            point_2:
                'في حالة قيامك بعمل مخل بالآداب العامة سيتم حظرك من التطبيق إلى الأبد',
            point_3: 'ساعدنا في تحسين تجربتك، عن طريق الإبلاغ عن المخالفين',
        },
        welcome_to_fadfadah: `مرحباً بك في ${AppType.select({
            fadfadah: 'فضفضة',
            nezmo: 'نيزمو',
        })}`,
        write_your_name_to_continue: 'رجاءً قم بكتابة اسمك للمتابعة',
        complete_your_info_to_use_this_feature:
            ' قم بإدخال باقي معلومات حسابك لاستخدام هذه الميزة',
        complete_your_info_to_use_this_feature_button: 'ادخال بيانات الحساب',
        complete_your_info_to_show_conversation:
            'لديك محادثة جديدة\n قم بادخال باقي معلومات حسابك لعرضها',
        tabs: {
            home: 'الرئيسية',
            conversations: 'محادثاتي',
            random_conversation: 'محادثة عشوائية',
        },
        conversation_tab_header_buttons: {
            all: 'الكل',
            via_link: 'عن طريق رابط',
            random: 'عشوائية',
        },
        conversations_list: {
            random_conversation_tag: 'محادثة عشوائية',
            from_link_tag: 'عن طريق رابط',
            new_conversation_tag: 'رسالة جديدة',
            load_more: 'عرض المزيد',
            blocked: 'محظور',
        },
        no_conversations_tips: {
            create_conversations_to_show_them_here:
                'قم بإنشاء أو استقبال محادثة جديدة لنقوم بعرضها هنا',
            how_can_i_send_or_get_conversations:
                'كيف أقوم بإرسال واستقبال محادثات جديدة؟',
            tip1: '1) يمكنك إنشاء محادثة عشوائية عن طريق الضغط على الزر في الشريط أسفل الشاشة',
            tip2: '2) يمكنك مشاركة رابط حسابك عن طريق زر المشاركة في الصفحة الرئيسية لتقوم باستقبال رسائل من أصدقائك من دون أن تتعرف على هويتهم',
            tip3: '3) يمكنك أيضا استقبال محادثات عشوائية من باقي المستخدمين في حال كنت متصلا بالتطبيق',
        },
        create_conversation_floating_button: {
            create_random_conversation: AppType.select({
                fadfadah: 'إنشاء محادثة عشوائية',
                nezmo: 'إنشاء محادثة',
            }),
            create_conversation_using_link:
                'أنشئ محادثة باستخدام رابط المستخدم',
        },
        delete_account: {
            delete_account_settings_button: 'حذف حسابي',
            are_you_sure_you_want_to_delete_account:
                'هل أنت متأكد من أنك تريد حذف حسابك؟',
            your_account_will_be_removed_forever:
                'سنقوم بحذف حسابك إلى الأبد، ولن يكون هناك طريقة لإسترجاعه',
        },
        notification_sound: 'صوت التنبيهات',
        change_account_name: 'تعديل الاسم',
        write_your_new_name_to_continue: 'رجاء قم بكتابة اسمك الجديد',
        change: 'تغيير',
        new_username: 'اسم المستخدم الجديد',
        instagram_share_dialog: {
            title: ' كيف أقوم بنشر الرابط عبر انستجرام؟',
            first: 'أولاً',
            copy_your_link: 'قم بنسخ الرابط الخاص بك',
            copy: 'نسخ',
            link_is_copied: 'تم النسخ',
            second: 'ثانيًا',
            edit_bio:
                'قم بفتح تطبيق انستجرام والذهاب الى الملف الشخصي، ثم اضغط زر تعديل الملف الشخصي وقم بلصق الرابط في قسم البايو (Bio)',
        },
        warn_message_contains_bad_word:
            'يبدو أن هذه الرسالة قد تحتوي على ألفاظ مسيئة، وقد تعرضك إلى الحظر، هل أنت متأكد من أنك تود ارسالها؟',
        confirm_sending_bad_word: 'تأكيد الارسال',
        cancel_sending_bad_word: 'تراجع عن الارسال',
        warn_name_contains_bad_word:
            'يبدو أن الاسم الذي اخترته قد يكون مسيئًا، وهذا قد يعرضك للحظر، هل أنت متأكد من أنك تود استخدامه؟',
        confirm_bad_name: 'تأكيد استخدام الاسم',
        cancel_bad_name: 'التراجع عن استخدام الاسم',
        warn_for_first_profile_picture_upload:
            ' صورتك الشخصية تعبر عن ذاتك وأخلاقك، فتأكد من اختيار صورة لائقة \n\n يمنع وضع الصور الجنسية أو أي جسد عاري وغيرها من الصور غير اللائقة \n\n سيتم حظرك من استخدام التطبيق في حالة قيامك باستخدام صورة غير لائقة',
        warn_for_first_profile_picture_upload_confirm: 'موافق',
        customize_your_look: 'تخصيص مظهرك',
        select_frame_color: 'لون الاسم والإطار',
        select_frame_shape: 'مظهر الإطار',
        maintenance_mode: {
            title: 'تحت الصيانة',
            description:
                'عذراً، يعمل فريقنا على بعض الاصلاحات، سيعود التطبيق للعمل فور انتهائها',
        },
        interests: {
            add_interests: 'إضافة اهتمامات',
            edit_interests: 'تعديل الاهتمامات',
            limit: (num: number) => `متبقي ${num}`,
            limit_reached: (num: number) =>
                `لا يمكنك اختيار أكثر من ${num} اهتمامات`,
            types: {
                language: 'اللغات التي تتحدثها',
                general: 'الاهتمامات العامة',
                sport: 'الرياضة',
                tech: 'التقنية',
                lifestyle: 'أسلوب الحياة',
                music: 'موسيقى',
                places: 'أماكن',
                watching: 'المشاهدة',
                city: 'مدن',
            },
        },
        points_system: {
            buy_points: `شراء نقاط ${AppType.select({
                fadfadah: 'فضفضة',
                nezmo: 'نيزمو',
            })}`,
            your_current_points: 'نقاطك الحالية',
            point: 'نقطة',
            points: 'نقاط',
            get_more_points: 'احصل على المزيد من النقاط',
            buy: 'شراء',
            points_added: (points: number) => `تم إضافة ${points} نقاط بنجاح`,
            you_also_can: 'يمكنك أيضاَ',
            use_points: {
                a: 'استخدام',
                b: '10 نقاط',
                c: 'لتسوية المخالفة وفتح الحظر',
            },
            use_points_button: (points: number) => `إستخدام ${points} نقاط`,
            why_points: `شرائك نقاط ${AppType.select({
                fadfadah: 'فضفضة',
                nezmo: 'نيزمو',
            })} يدعم المطور للاستمرار في تحسين تجربة التطبيق`,
            not_enough_points: 'لا توجد نقاط كافية',
            points_reason: {
                unblock: 'لالغاء الحظر',
            },
            added_x_points_success: (points: number) =>
                `تم إضافة ${points} نقاط بنجاح`,
        },
        email_authentication: {
            web_email_not_valid: `البريد الالكتروني غير مدعوم، الرجاء اختيار تحديد احد هذه المزودين للبريد الالكتروني : ${acceptedEmailDomains.join(
                ', ',
            )}`,
            email_authentication: 'التحقق من البريد الالكتروني',
            we_sent_code_via_email: (email: string | undefined) =>
                `قمنا بارسال رمز التحقق إلى بريدك الإلكتروني (${email || ''})`,
            wrong_code: 'رمز التحقق الذي ادخلته غير صحيح',
        },
        change_theme_mode: {
            to_dark: 'تغيير الى المظهر الداكن',
            to_light: 'تغيير الى المظهر الفاتح',
        },
        change_langauge_button: 'Change langauge to English',
        subscribed: 'مشترك',
        create_random_conversation_title: AppType.select({
            fadfadah: 'أنشئ محادثة عشوائية',
            nezmo: 'أنشئ محادثة',
        }),
        create_random_conversation_description: 'تواصل، وشارك اهتماماتك',
        my_conversations: 'محادثاتي',
        share_the_link: 'شارك الرابط',
    },
    en: {
        screenTitles: {...ScreenTitles.en},
        lang: 'en',
        direction: 'ltr',
        align: 'left',
        username: 'Username',
        email: 'Email',
        password: 'Password',
        password_confirm: 'Confirm password',
        fromHere: 'From Here',
        confirm: 'Confirm',
        skip: 'Skip',
        continue: 'Continue',
        or: 'OR',
        is_loading: 'Loading',
        sign: 'Sign In',
        skip_creating_account: 'Skip',
        create_account: 'Create',
        or_share: 'or share it using',
        copy_link: 'Copy link',
        or_auth: 'Or Sign In Using',
        or_sign_up: 'OR Create Account Using',
        facebook: 'Facebook',
        twitter: 'Twitter',
        other_language: 'عربي',
        current_language: 'English',
        copy: 'Copy',
        create_account_title: 'Create your account',
        already_have_account: 'Have account?',
        fields_are_required: 'Please fill all the fields',
        error_happened: 'Error, make sure you are connected',
        error_1001: 'Wrong credentials, Please try again',
        error_1011: 'There is another account with the same email',
        data_not_valid:
            'The data you submitted are not valid, Please try again',
        name_less_than_two_chars: 'Name must be at least three characters',
        name_more_than_twenty_chars: 'Name must be less than twenty characters',
        email_not_valid: 'Make sure you have submitted a valid email',
        password_less_than_six_chars:
            'Password should contain at least 6 characters',
        password_confirmation_error:
            'Password confirmation does not match the password',
        facebook_auth_cancel: 'You Canceled Logging Using Facebook',
        facebook_auth_error: 'Error while logging using facebook',
        apple_auth_error: 'Error while logging using Apple',
        twitter_auth_error: 'Error while logging using Twitter',
        twitter_auth_error_desc:
            'This feature is coming soon to the application you can visit fadfadah.net website to use it',
        ok: 'Ok',
        profile: 'Profile',
        settings: 'Settings',
        conversations_you_made_as_anonymous: 'Conversations as anonymous',
        create_random_conversation: `${AppType.select({
            fadfadah: 'Random Conversation',
            nezmo: 'Create Conversation',
        })}`,
        share_your_link: 'Share Your Link',
        share_your_link_desc: 'with friends without knowing who they are',
        share: 'Share',
        for_help: 'Need Help?',
        by_registering: `By registering, You agree to ${AppType.select({
            fadfadah: 'Fadfadah',
            nezmo: 'Nezmo',
        })}'s`,
        terms_of_use: `${AppType.select({
            fadfadah: 'Terms of Use',
            nezmo: 'User License Agreement',
        })}`,
        privacy_policy: 'Privacy Policy',
        and: ' & ',
        options: 'Options',
        general_confirm_message: 'Are you sure you want to proceed?',
        general_confirm: 'Confirm',
        remove_conversation: 'Remove Conversation',
        remove_conversation_confirm: 'Confirm Remove',
        remove_conversations: 'Remove Conversations',
        remove: 'Remove',
        remove_conversations_alert_title: 'Confirm Remove Conversations',
        remove_conversations_alert_message:
            'Conversation is selected, are you sure you want to remove them?',
        confirm_removal: 'Confirm',
        block_user: 'Block User',
        unblock_user: 'Unblock User',
        block_user_confirm: 'Confirm Action',
        block_success: 'User is blocked',
        unblock_success: 'User is unblocked',
        report_user: 'Report User',
        report_user_confirm: 'Confirm Reporting User',
        report_user_success:
            'User reported, we will take action as soon as possible',
        cancel: 'Cancel',
        snackbar_success: 'Successful Action',
        snackbar_error: 'Error happened',
        confirm_logout: 'Are you sure you want to logout?',
        logout: 'Logout',
        no_conversations: 'No conversations yet',
        fetching_conversations: 'Loading conversations',
        no_messages_in_conversation: 'User did not send any messages',
        conversation_start: 'Conversation Started',
        sendMessagePlaceholder: 'Type here',
        notificationWarningTitle: 'Notifications are disabled',
        notificationWarningDescription:
            'to enable it go to "Settings" > "Notifications" > "Fadfadah" > Make sure "Allow Notifications" is enabled',
        upload_image: 'Attach Image',
        take_image: 'From Camera',
        choose_from_library: 'From your library',
        share_via: 'Share Via',
        share_message_1: ' Chat with',
        share_message_2: `, And he/she will not know who you are #${AppType.select(
            {
                fadfadah: 'فضفضة',
                nezmo: 'نيزمو',
            },
        )} #${AppType.select({fadfadah: 'fadfadah', nezmo: 'nezmo'})}`,
        snapchat: 'Snapchat',
        instagram: 'Instagram',
        send: 'Send',
        disconnected: 'Not connected to internet',
        mandatoryContinueButtonLabel: 'Continue',
        mandatoryUpdateMessage:
            'An update is available that must be installed.',
        optionalIgnoreButtonLabel: 'Ignore',
        optionalInstallButtonLabel: 'Install',
        optionalUpdateMessage:
            'An update is available. Would you like to install it?',
        updateAlertTitle: 'Update available',
        rateUsSectionText: 'Give us your openion',
        you_have_message_from: 'You have a conversation from',
        you_have_random_message_from: `${AppType.select({
            fadfadah: 'You have a random conversation from',
            nezmo: 'You have a message from',
        })}`,
        do_you_want_to_view_it: 'do you want to view it?',
        accept: 'Accept',
        reject_and_delete: 'Reject',
        male: 'Male',
        female: 'Female',
        rating_modal_title: 'Rate your experience with Fadfadah',
        rating_modal_rate_button: 'Rate',
        rating_modal_send_button: 'Send your rating',
        rating_modal_comment_placeholder: 'Provide your notes to our team',
        rating_modal_thankyou: 'Thank you',
        rating_modal_we_promise_to_provide_better_service:
            'we promise to keep pushing for delivering the best possible experience.',
        rating_modal_empty_comment_error: 'Please provide a note',
        rating_modal_star_labels: ['Terrible', 'Bad', 'Okay', 'Good', 'Great'],
        contact_us_at_facebook: 'Contact us using our facebook page',
        or_contact_us_by_email: `or using our email ${Constants.links.supportEmail}`,
        forgot_password_button: 'Forgot password?',
        reset_password_modal_title:
            "Fill your email to reset your account's password",
        reset_password: 'Reset Password',
        reset_password_success:
            'A reset email has been sent to you, it might take few minutes to be delivered to you',
        new_message: {
            one_message: 'New message',
            two_messages: 'New messages',
            to_ten_messages: 'New messages',
            to_infinite: 'New messages',
            people_messaged_you: (people: string) => `Received from ${people}`,
            and_others: 'and others',
        },
        create_new_conversation: 'Create new conversation',
        avoid_using_your_real_name: 'Avoid using your real name',
        your_nickname: 'Your nickname',
        start_conversation: 'Start conversation',
        not_logged_in_trying_to_create_conversation:
            'You should login to your account in order to create a conversation',
        get_user_data_error: 'Could not get user data',
        user_has_blocked_you: 'User has blocked you',
        conversation_is_deleted: 'Conversation has been deleted by the user',
        conversation_is_blocked: 'You have been blocked by the user',
        is_typing: 'Is Typing',
        subscription_screen_title: 'Fadfadah Subscription',
        features: [
            {
                id: 0,
                title: 'Random Conversations',
                description: 'Enjoy unlimited number of random conversations',
                image: 'infiniteConversationsImage',
                color: '#9DC39D',
            },
            {
                id: 1,
                title: 'Search by Country',
                description:
                    'Select your preferred country to find random users',
                image: 'infiniteConversationsImage',
                color: '#9DC39D',
            },
            {
                id: 2,
                title: 'Remove Ads',
                description: 'Remove all ads from displaying',
                image: 'noAdsImage',
                color: '#CE6A6A',
            },
            {
                id: 3,
                title: 'Direct Support',
                description: 'Support from fadfadah team to solve any issue',
                image: 'instantSupportImage',
                color: '#7382C1',
            },
            {
                id: 4,
                title: 'Be Special',
                description: 'Unlock all customization options',
                image: 'profilePicture',
                color: Constants.colors.goldCrown,
            },
        ],
        choose_subscription_period: 'Choose duration',
        duration_text: {
            THREE_DAY: '3 Days',
            MONTHLY: 'Month',
            WEEKLY: 'Week',
            THREE_MONTH: '3 Months',
            YEARLY: 'Year',
        },
        could_not_load_offerings: 'We could not load subscriptions',
        subscribe: 'Enroll',
        free_then: (priceDuration: any) => {
            return `Free ${priceDuration} then`;
        },
        discount: (price: any) => `${price}% OFF`,
        subscription_disclaimers: [
            'Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period.',
            'Subscription will be renewed automatically within 24 hours prior to the end of the current subscription.',
            'Subscription can be managed by the user, and auto-renewal may be turned off by going to the settings after purchase.',
        ],
        restore_subscription: 'Restore subscription',
        restore_subscription_success: 'Subscription is restored',
        restore_subscription_fail: 'Subscription can not be found',
        restore_subscription_error: 'Could not restore subscription',
        subscribe_to_remove_ads: 'Subscribe to try all features',
        you_are_a_subscriber: 'You are subscribed to Fadfadah',
        your_subscription_will_end_at: 'Your subscription will end at',
        thanks_for_subscribing: 'Thanks for Subscribing',
        fadfadah_link: 'User link',
        create_conversation: 'Create',
        copy_fadfadah_link_and_paste_it_here:
            'Copy user link and paste it here',
        no_user_found_with_this_link: 'Wrong url, make sure the link is valid',
        error_while_extracting_url: 'Error while checking user link',
        contact_us_with_whatsapp: 'Contact us via whatsapp',
        you_dont_have_whatsapp: 'We could not open whatsapp',
        enable_random_conversations: AppType.select({
            fadfadah: 'Allow receiving random conversations',
            nezmo: 'Allow receiving conversations',
        }),
        change_search_country: 'Change Search Country',
        error_no_users_with_selected_country:
            'There are no users in your selected country',
        conversations_left_1: 'You have',
        conversations_left_2: 'random conversations left',
        conversations_left_2_b: 'free conversations for today',
        start_random_conversation: 'Start',
        watch_ad_to_continue: 'Watch Ad to get more',
        report_random_user: 'Report',
        find_another_random_user: 'Find another person',
        get_unlimited_random_conversations:
            'Get unlimited random conversations',
        you_have_been_blocked_from_using_random_conversations:
            'You have been banned from using random conversations',
        block_reason: 'Block Reason',
        time_left_for_block_to_expire: 'Time left for block to expire',
        reason_text: (reason: string | null | undefined) => {
            switch (reason) {
                case 'Report-Without-Reason':
                    return 'You reported a user without a reason';
                case 'Bad-Messages':
                    return 'Sending a offensive/sexual messages';
                case 'Bad-Image':
                    return 'Your account picture is sexual or not appropriate';
                case 'Bad-Name':
                    return 'Your account name is sexual or not appropriate';
                default:
                    return 'Your account received many reports';
            }
        },
        contact_us_if_you_think_block_is_wrong:
            'Contact us if you think we blocked you by mistake',
        block_contact: {
            facebook: 'Via Facebook',
            instagram: 'Via Instagram',
        },
        no_free_random_conversations:
            'Wait until tomorrow to get more free conversations',
        watch_ad_to_get_more_conversations:
            'Watch ad to get more free conversations',
        no_online_random_users: 'No online users',
        report_random_user_modal_title: (name: string) =>
            `Are you sure you want to report (${name})?`,
        report_random_user_modal_warning:
            'You might get banned for unjustifiable reporting',
        report_random_user_modal_confirm: 'Confirm report',
        report_random_user_modal_cancel: 'Cancel report',
        report_random_user_modal_chose_reason:
            'What is the reason of the report?',
        report_random_user_modal_bad_image:
            'This is user is using a sexual/unsuitable image',
        report_random_user_modal_bad_name:
            'This is user is using a sexual/unsuitable name',
        you_have_unlimited_number_of_random_conversations:
            'You have unlimited random conversations',
        reject_and_stop_random_conversations: AppType.select({
            fadfadah: 'Reject + Stop receiving random conversations',
            nezmo: 'Reject + Stop receiving conversations',
        }),
        you_are_blocked_from_random_conversations:
            'You have been banned from random conversations',
        random_welcome_modal: {
            point_1:
                'You have received free conversations, if you consume them you will receive more free ones after 24 hours',
            point_2:
                'In case of a violation, you will be banned from the application for ever',
            point_3: 'Help us improve your experience by reporting violators',
        },
        welcome_to_fadfadah: `Welcome to ${AppType.select({
            fadfadah: 'Fadfadah',
            nezmo: 'Nezmo',
        })}`,
        write_your_name_to_continue: 'Please, write your name to continue',
        complete_your_info_to_use_this_feature:
            'Complete your account information to use this feature',
        complete_your_info_to_use_this_feature_button:
            'Complete profile information',
        complete_your_info_to_show_conversation:
            'You have new conversation\n Please complete your account information to view it',
        tabs: {
            home: 'Home',
            conversations: 'Conversations',
            random_conversation: 'Random Chat',
        },
        conversation_tab_header_buttons: {
            all: 'All',
            via_link: 'From link',
            random: 'Random',
        },
        conversations_list: {
            random_conversation_tag: 'Random conversation',
            from_link_tag: 'From link',
            new_conversation_tag: 'New Message',
            load_more: 'More',
            blocked: ' Blocked ',
        },
        no_conversations_tips: {
            create_conversations_to_show_them_here:
                'You have no converesations to display at the moment.',
            how_can_i_send_or_get_conversations:
                'How can I send or receive new conversations?',
            tip1: '1) You can create a random conversation by pressing the random conversations button down the screen.',
            tip2: '2) You can share your link with firends to receive messages from them while they can keep their secret identity',
            tip3: '3) You also can receive messages from random users if you stay online in the app',
        },
        create_conversation_floating_button: {
            create_random_conversation: AppType.select({
                fadfadah: 'Create random conversation',
                nezmo: 'Create conversation',
            }),
            create_conversation_using_link: 'Create a conversation using link',
        },
        delete_account: {
            delete_account_settings_button: 'Delete my account',
            are_you_sure_you_want_to_delete_account:
                'Are you sure you want to delete your account?',
            your_account_will_be_removed_forever:
                'Your account will be deleted for ever, and there will be no way to bring it back',
        },
        notification_sound: 'Notification sound',
        change_account_name: 'Change name',
        write_your_new_name_to_continue: 'Write your new name',
        change: 'Change',
        new_username: 'your new name',
        instagram_share_dialog: {
            title: 'How can I share my link on instagram?',
            first: 'First',
            copy_your_link: 'Copy your link',
            copy: 'Copy',
            link_is_copied: 'Link is copied',
            second: 'Second',
            edit_bio:
                'Open instagram app and go to your profile then click on "Edit Profile" then paste the link to your Bio',
        },
        warn_message_contains_bad_word:
            'Your message might contain a violation that might result in the ban of your account, are you sure you want to send it?',
        confirm_sending_bad_word: 'Confirm sending message',
        cancel_sending_bad_word: 'Cancel sending',
        warn_name_contains_bad_word:
            'The name you chosed might contain a violation that might result in the ban of your account, are you sure you want to use it?',
        confirm_bad_name: 'Conifrm using this name',
        cancel_bad_name: 'Cancel using this name',
        warn_for_first_profile_picture_upload:
            ' Your profile picture represents your personality, so be sure to chose wisely \n\n Pictures that contain sexual content, semi-naked bodies, or any inappropriate graphic, will result in the ban of your account',
        warn_for_first_profile_picture_upload_confirm: 'Got it',
        customize_your_look: 'Customize your appearence',
        select_frame_color: 'Select name and frame color',
        select_frame_shape: 'Select frame shape',
        maintenance_mode: {
            title: 'Under Maintenance',
            description:
                'Sorry, our team is currently working on some fixes, the app will return as soon as they are done.',
        },
        interests: {
            add_interests: 'Add interests',
            edit_interests: 'Edit interests',
            limit: (num: number) => `${num} remaining`,
            limit_reached: (num: number) =>
                `You can't select more than ${num} interests`,
            types: {
                language: 'Languages you speak',
                general: 'General Interests',
                sport: 'Sport',
                tech: 'Technology',
                lifestyle: 'Life Style',
                music: 'Music',
                places: 'Places',
                watching: 'Watching',
                city: 'Cities',
            },
        },
        points_system: {
            buy_points: 'Buy fadfadah points',
            your_current_points: 'Your points',
            point: 'Point',
            points: 'Points',
            get_more_points: 'Get more points',
            buy: 'Buy',
            points_added: (points: number) =>
                `${points} points are added successfully.`,
            you_also_can: 'You also can',
            use_points: {
                a: 'Use',
                b: '10 points',
                c: 'to unblock the account',
            },
            use_points_button: (points: number) => `Use ${points} points`,
            why_points:
                'By purchasing points, you will support the developers to make the app better',
            not_enough_points: 'There are not enoguht Points',
            points_reason: {
                unblock: 'to unblock the account',
            },
            added_x_points_success: (points: number) =>
                `Added ${points} points successfully`,
        },
        email_authentication: {
            web_email_not_valid: `البريد الالكتروني غير مدعوم، الرجاء اختيار تحديد احد هذه المزودين للبريد الالكتروني : ${acceptedEmailDomains.join(
                ',',
            )}`,
            email_authentication: 'Email Verification',
            we_sent_code_via_email: (email: string | undefined) =>
                `We sent the verification code to your email (${email || ''})`,
            wrong_code:
                'The code you provided is not correct, please try again',
        },
        change_theme_mode: {
            to_dark: 'Switch to dark mode',
            to_light: 'Switch to light mode',
        },
        change_langauge_button: 'تغيير إلى اللغة العربية',
        subscribed: 'Subscribed',
        create_random_conversation_title: AppType.select({
            fadfadah: 'Create Random Conversation',
            nezmo: 'Create New Conversation',
        }),
        create_random_conversation_description:
            'Communicate, and share your interests',
        my_conversations: 'Conversations',
        share_the_link: 'Share Link',
    },
};

export default lang;
