import AsyncStorage from '@react-native-async-storage/async-storage';
import {LoginManager, AccessToken} from 'react-native-fbsdk-next';
import Purchases from 'react-native-purchases';
import {Alert, Platform} from 'react-native';
import * as AppleAuthentication from 'expo-apple-authentication';
import {AxiosResponse} from '../../types/helpers/helpers';
import * as t from './actionTypes';
import * as api from './api';
import lang from '../../localization';
import {getToken, isEmpty, validateEmail} from '../helpers';
import {mediumClick, successVibration, errorVibration} from '../app/vibrations';
import {navigate} from '../navigation';
import {ReduxGetState, ReduxDispatch} from '../../types/redux/redux';
import {Config} from '../../types/data/config';
import {User} from '../../types/data/user';
import {SCREENS} from '../../screens/Screens';
import {isWeb} from '../../../env';
import {logEvent, logLogin, logSignup, setUser} from '../app/tracking';
// @ts-ignore
import bugsnag from './bugsnag';
import AppType from '../../../AppType';

export function login(data: {email: string; password: string}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const {email, password} = data;
        mediumClick();
        if (isEmpty(email) || isEmpty(password)) {
            return setLoginError(lang().fields_are_required)(dispatch);
        }
        dispatch({
            type: t.SET_LOGIN_LOADING,
        });

        try {
            const response: AxiosResponse = await api.login(email, password);

            if (response.data.success) {
                successVibration();
                const user = response.data.data;
                logUserToAnalytics(user);
                if (Platform.OS !== 'web') {
                    logLogin({method: 'email'});
                }
                await AsyncStorage.setItem('user', JSON.stringify(user));
                loadUser()(dispatch, getState);
            } else {
                errorVibration();
                // @ts-ignore
                return setLoginError(lang()[`error_${response.data.code}`])(
                    dispatch,
                );
            }
        } catch (e) {
            console.log({e});
            unsetLoginLoading()(dispatch);
            return setLoginError(lang().error_happened)(dispatch);
        }

        unsetLoginLoading()(dispatch);
    };
}

export function signup(data: {
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
    redirectToMainScreen: () => void;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const {
            email,
            password,
            password_confirmation,
            name,
            redirectToMainScreen,
        } = data;
        mediumClick();
        if (
            isEmpty(email) ||
            isEmpty(password) ||
            isEmpty(password_confirmation) ||
            isEmpty(name)
        ) {
            return setSingUpError(lang().fields_are_required)(dispatch);
        }
        if (name.length < 3) {
            return setSingUpError(lang().name_less_than_two_chars)(dispatch);
        }
        if (name.length > 20) {
            return setSingUpError(lang().name_more_than_twenty_chars)(dispatch);
        }
        if (!validateEmail(email)) {
            return setSingUpError(lang().email_not_valid)(dispatch);
        }
        if (password.length < 6) {
            return setSingUpError(lang().password_less_than_six_chars)(
                dispatch,
            );
        }
        if (password !== password_confirmation) {
            return setSingUpError(lang().password_confirmation_error)(dispatch);
        }

        dispatch({
            type: t.SET_SIGNUP_LOADING,
        });

        try {
            const response: AxiosResponse = await api.signup(
                name,
                email,
                password,
                password_confirmation,
                lang().lang,
                false,
            );
            if (response.data.success) {
                await postSignup(response)(dispatch, getState);
                logSignup({method: 'sign_up'});
                redirectToMainScreen();
            } else {
                errorVibration();
                // @ts-ignore
                return setSingUpError(lang()[`error_${response.data.code}`])(
                    dispatch,
                );
            }
        } catch (e) {
            unsetSignupLoading()(dispatch);
            return setSingUpError(lang().error_happened)(dispatch);
        }
        unsetSignupLoading()(dispatch);
    };
}

export function facebookAuth(data: {
    redirectToMainScreen: () => void;
    token?: string;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const {redirectToMainScreen} = data;

        try {
            // try three times
            let facebook_token;
            if (data.token !== undefined) {
                facebook_token = data.token;
            } else {
                facebook_token = await getFacebookToken()(dispatch);
            }
            if (!facebook_token) {
                return;
            }

            freeErrors()(dispatch);
            dispatch({
                type: t.SET_SIGNUP_LOADING,
            });
            dispatch({
                type: t.SET_LOGIN_LOADING,
            });

            const response: AxiosResponse = await api.facebookAuth(
                facebook_token,
            );

            if (response.data.success) {
                successVibration();
                const user = response.data.data;
                logUserToAnalytics(user);
                logLogin({method: 'facebook'});
                await AsyncStorage.setItem('user', JSON.stringify(user));
                loadUser()(dispatch, getState);
                if (redirectToMainScreen) {
                    redirectToMainScreen();
                }
            }
        } catch (e) {
            errorVibration();
            setLoginError(lang().facebook_auth_error)(dispatch);
            setSingUpError(lang().facebook_auth_error)(dispatch);
            unsetSignupLoading()(dispatch);
            unsetLoginLoading()(dispatch);
        }
        unsetLoginLoading()(dispatch);
        unsetSignupLoading()(dispatch);
    };
}

function getFacebookToken() {
    return async (dispatch: ReduxDispatch) => {
        try {
            const result = await LoginManager.logInWithPermissions([
                'public_profile',
                'email',
            ]);
            if (result.isCancelled) {
                setLoginError(lang().facebook_auth_cancel)(dispatch);
                setSingUpError(lang().facebook_auth_cancel)(dispatch);
            } else {
                const data = await AccessToken.getCurrentAccessToken();
                if (data) {
                    const {accessToken} = data;
                    return accessToken;
                }
                throw new Error();
            }
        } catch (e) {
            setLoginError(lang().facebook_auth_error)(dispatch);
            setSingUpError(lang().facebook_auth_error)(dispatch);
        }
        return undefined;
    };
}

export function appleAuthentication({
    redirectToMainScreen,
}: {
    redirectToMainScreen: () => void;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            const {identityToken, user, fullName} =
                await AppleAuthentication.signInAsync({
                    requestedScopes: [
                        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                    ],
                });

            if (identityToken && fullName) {
                let resultFullName = 'APPLE USER';

                if (
                    fullName?.givenName !== null ||
                    fullName?.familyName !== null
                ) {
                    resultFullName = `${
                        fullName?.givenName !== null ? fullName?.givenName : ''
                    } ${
                        fullName?.familyName !== null
                            ? fullName?.familyName
                            : ''
                    }`.trim();
                }
                freeErrors()(dispatch);
                dispatch({
                    type: t.SET_SIGNUP_LOADING,
                });
                dispatch({
                    type: t.SET_LOGIN_LOADING,
                });
                const response: AxiosResponse = await api.appleAuth({
                    apple_token: identityToken,
                    user_id: `${user}APP${AppType.CURRENT_APP}`,
                    name: resultFullName,
                });
                if (response.data.success) {
                    successVibration();
                    const userInfo = response.data.data;
                    logUserToAnalytics(userInfo);
                    logSignup({method: 'apple'});
                    await AsyncStorage.setItem(
                        'user',
                        JSON.stringify(userInfo),
                    );
                    loadUser()(dispatch, getState);
                    if (redirectToMainScreen) {
                        redirectToMainScreen();
                    }
                }
            }
        } catch (e) {
            errorVibration();
            setLoginError(lang().apple_auth_error)(dispatch);
            setSingUpError(lang().apple_auth_error)(dispatch);
            unsetSignupLoading()(dispatch);
            unsetLoginLoading()(dispatch);
        }
        unsetLoginLoading()(dispatch);
        unsetSignupLoading()(dispatch);

        // // use credentialState response to ensure the user is authenticated
        // if (credentialState === AppleAuthCredentialState.AUTHORIZED) {
        //   // user is authenticated
        // }
    };
}

export function twitterAuth({
    token,
    redirectToMainScreen,
}: {
    token: string;
    redirectToMainScreen: () => void;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        console.log({token});
        if (token !== undefined) {
            freeErrors()(dispatch);
            dispatch({
                type: t.SET_SIGNUP_LOADING,
            });
            dispatch({
                type: t.SET_LOGIN_LOADING,
            });
            try {
                const response: AxiosResponse =
                    await api.checkUserAndConfigTwitter({
                        token,
                    });
                if (response.data.success) {
                    const user = response.data.data;
                    successVibration();
                    logLogin({method: 'twitter'});
                    user.token = token;
                    await AsyncStorage.setItem('user', JSON.stringify(user));
                    loadUser()(dispatch, getState);
                    unsetSignupLoading()(dispatch);
                    unsetLoginLoading()(dispatch);
                    if (redirectToMainScreen) {
                        redirectToMainScreen();
                    }
                } else {
                    setLoginError(lang().twitter_auth_error)(dispatch);
                    setSingUpError(lang().twitter_auth_error)(dispatch);
                    unsetSignupLoading()(dispatch);
                    unsetLoginLoading()(dispatch);
                }
            } catch (e) {
                errorVibration();
                setLoginError(lang().twitter_auth_error)(dispatch);
                setSingUpError(lang().twitter_auth_error)(dispatch);
                unsetSignupLoading()(dispatch);
                unsetLoginLoading()(dispatch);
            }
            unsetLoginLoading()(dispatch);
            unsetSignupLoading()(dispatch);
        }
    };
}

export function unloadUser() {
    return async (dispatch: ReduxDispatch) => {
        await AsyncStorage.removeItem('user');
        dispatch({
            type: t.UNLOAD_USER,
        });
    };
}

function configDevelopmentFilter(config: Config) {
    if (!config) {
        return undefined;
    }
    if (__DEV__) {
        return {...config, is_ios_review: false};
    }
    return {...config};
}

export function loadUser() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const userInStorage = await AsyncStorage.getItem('user');
        if (userInStorage) {
            const user = JSON.parse(userInStorage);
            let token;
            let config;
            if (user !== null && user !== undefined) {
                bugsnag.setUser(`${user.id}`, user.name, user.email);
                token = user.token;
                config = configDevelopmentFilter(user.config);
                delete user.config;
                logUserToAnalytics(user);
                dispatch({
                    type: t.LOAD_USER,
                    data: {user, token, config},
                });

                updateUser()(dispatch, getState);

                return user;
            }
        } else {
            dispatch({
                type: t.UNLOAD_USER,
            });
        }
    };
}

export function updateUser() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            const token = await getToken();
            const {isSubscribed} = getState().subscriptionReducer;
            const response: AxiosResponse = await api.checkUserAndConfig({
                isSubscribed,
            });
            const updatedUser = response.data.data;
            updatedUser.token = token;

            checkPermissions({user: updatedUser})();

            if (updatedUser.config.is_maintenance_mode) {
                navigate(SCREENS.MAINTENANCE_MODE, {});
            }

            await AsyncStorage.setItem('user', JSON.stringify(updatedUser));
            const config = configDevelopmentFilter(updatedUser.config);
            dispatch({
                type: t.LOAD_USER,
                data: {user: updatedUser, token, config},
            });
        } catch (error) {
            //
        }
    };
}

function checkPermissions({user}: {user: User}) {
    return () => {
        if (isWeb) {
            if (
                !!user &&
                user?.has_approved_terms === 0 &&
                user?.email !== '' &&
                user?.email !== null &&
                user?.email !== undefined
            ) {
                navigate(SCREENS.EMAIL_AUTHENTICATION, {});
            }
        }

        if (Platform.OS === 'android') {
            Purchases?.canMakePayments().then((canMakePayments: boolean) => {
                if (
                    user !== null &&
                    user !== undefined &&
                    user.token !== undefined &&
                    canMakePayments === false
                ) {
                    if (user.has_approved_terms === 0) {
                        navigate(SCREENS.EMAIL_AUTHENTICATION, {});
                    }
                }
            });
        }
    };
}

export function freeErrors() {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.FREE_ERRORS,
        });
    };
}

export async function attachNotificationKey(notification_key: string) {
    await api.attachNotificationKey(notification_key).catch(() => {});
}

export async function detachNotificationKey(notification_key: string) {
    await api.detachNotificationKey(notification_key).catch(() => {});
}

function unsetSignupLoading() {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.UNSET_SIGNUP_LOADING,
        });
    };
}

function unsetLoginLoading() {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.UNSET_LOGIN_LOADING,
        });
    };
}

function setLoginError(error: string) {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.SET_LOGIN_ERROR,
            data: {
                loginError: error,
            },
        });
    };
}

function setSingUpError(error: string) {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.SET_SIGNUP_ERROR,
            data: {
                signupError: error,
            },
        });
    };
}

function logUserToAnalytics(user: User) {
    if (user !== null) {
        setUser({user});
    }
}

export function changeProfilePicture({image}: {image: string}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const response: AxiosResponse = await api.changeProfilePicture({image});
        if (response.data.success) {
            dispatch({
                type: t.CHANGE_PROFILE_PICTURE,
                data: {
                    image: response.data.data.path,
                },
            });
            const {user, token} = getState().authReducer;
            if (user && token) {
                user.token = token;
                await AsyncStorage.setItem('user', JSON.stringify(user));
                loadUser()(dispatch, getState);
            }
        } else {
            throw new Error('Error');
        }
    };
}

export function toggleRandomConversations() {
    return async (dispatch: ReduxDispatch) => {
        return api
            .toggleRandomConversation()
            .then(({data}: AxiosResponse) => {
                if (data.code === 1) {
                    if (data.data.can_receive) {
                        dispatch({
                            type: t.ALLOW_RANDOM_CONVERSATIONS,
                        });
                        return {
                            code: data.code,
                            can_receive: data.data.can_receive,
                        };
                    }
                    dispatch({
                        type: t.DISALLOW_RANDOM_CONVERSATIONS,
                    });
                    return {
                        code: data.code,
                        can_receive: data.data.can_receive,
                    };
                }
                return {
                    code: data.code,
                };
            })
            .catch(() => {
                throw new Error('error');
            });
    };
}

export function skipLoginScreen() {
    return async () => {
        navigate(SCREENS.INPUT_SCREEN, {
            title: lang().welcome_to_fadfadah,
            input_label: lang().write_your_name_to_continue,
            input_placeholder: lang().username,
            submit_button_text: lang().sign,
            screenImage: 'SkipLoginIllustration',
            fieldValidator: (field: string) => {
                if (field.length > 20) {
                    return false;
                }
                if (field.length < 4) {
                    return false;
                }
                return true;
            },
            submitFunction: 'SKIP_LOGIN',
        });
    };
}

export function skipLogin({username}: {username: string}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        dispatch({
            type: t.SET_LOGIN_LOADING,
        });

        try {
            const response: AxiosResponse = await api.signup(
                username,
                undefined,
                undefined,
                undefined,
                lang().lang,
                true,
            );
            if (response.data.success) {
                await postSignup(response)(dispatch, getState);
                logSignup({method: 'skippedLogin', skipLogin: true});
                navigate(SCREENS.WELCOME, {});
                dispatch({
                    type: t.UNSET_LOGIN_LOADING,
                });
            } else {
                dispatch({
                    type: t.UNSET_LOGIN_LOADING,
                });
                errorVibration();

                setTimeout(() => {
                    if (isWeb) {
                        // @ts-ignore
                        window.alert(lang()[`error_${response.data.code}`]);
                    } else {
                        // @ts-ignore
                        Alert.alert(lang()[`error_${response.data.code}`]);
                    }
                }, 200);
            }
        } catch (e) {
            dispatch({
                type: t.UNSET_LOGIN_LOADING,
            });
            setTimeout(() => {
                if (isWeb) {
                    window.alert(lang().error_happened);
                } else {
                    Alert.alert(lang().error_happened);
                }
            }, 200);
        }
    };
}

export function completeSignup({
    email,
    password,
}: {
    email: string;
    password: string;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        dispatch({
            type: t.SET_LOGIN_LOADING,
        });

        try {
            const response: AxiosResponse = await api.completeSignup({
                email,
                password,
            });
            if (response.data.success) {
                response.data.data.token = await getToken();
                await postSignup(response)(dispatch, getState);
                logEvent('completeSignUp');
                dispatch({
                    type: t.UNSET_LOGIN_LOADING,
                });
                return true;
            }
            dispatch({
                type: t.UNSET_LOGIN_LOADING,
            });
            errorVibration();
            setTimeout(() => {
                // @ts-ignore
                if (lang()[`error_${response.data.code}`] === undefined) {
                    if (isWeb) {
                        window.alert(lang().data_not_valid);
                    } else {
                        Alert.alert(lang().data_not_valid);
                    }
                } else if (isWeb) {
                    // @ts-ignore
                    window.alert(lang()[`error_${response.data.code}`]);
                } else {
                    // @ts-ignore
                    Alert.alert(lang()[`error_${response.data.code}`]);
                }
            }, 200);
            return false;
        } catch (e) {
            dispatch({
                type: t.UNSET_LOGIN_LOADING,
            });
            setTimeout(() => {
                if (isWeb) {
                    window.alert(lang().error_happened);
                } else {
                    Alert.alert(lang().error_happened);
                }
            }, 200);
            return false;
        }
    };
}

function postSignup(response: AxiosResponse) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        successVibration();
        const user = response.data.data;
        logUserToAnalytics(user);
        await AsyncStorage.setItem('user', JSON.stringify(user));
        await loadUser()(dispatch, getState);
    };
}

export function deleteMyAccount() {
    return async (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.SET_LOGIN_LOADING,
        });
        await api.deleteMyAccount();
        dispatch({
            type: t.UNSET_LOGIN_LOADING,
        });
    };
}

export function changeUsername({newUsername}: {newUsername: string}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        dispatch({
            type: t.SET_LOGIN_LOADING,
        });

        try {
            const response: AxiosResponse = await api.changeUsername({
                newUsername,
            });
            if (response.data.success) {
                logEvent('changedUsername');
                navigate(SCREENS.WELCOME, {});
                dispatch({
                    type: t.UNSET_LOGIN_LOADING,
                });
                updateUser()(dispatch, getState);
            } else {
                dispatch({
                    type: t.UNSET_LOGIN_LOADING,
                });
                errorVibration();

                setTimeout(() => {
                    if (isWeb) {
                        window.alert(lang().error_happened);
                    } else {
                        Alert.alert(lang().error_happened);
                    }
                }, 200);
            }
        } catch (e) {
            console.log({e});
            dispatch({
                type: t.UNSET_LOGIN_LOADING,
            });
            setTimeout(() => {
                if (isWeb) {
                    window.alert(lang().error_happened);
                } else {
                    Alert.alert(lang().error_happened);
                }
            }, 200);
        }
    };
}

export function changeCustomizationOptions({
    frameColor,
    frameShape,
}: {
    frameColor: string;
    frameShape: string | undefined;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            const response: AxiosResponse =
                await api.changeCustomizationOptions({
                    frame_color: frameColor,
                    frame_shape: frameShape,
                });
            if (response.data.success) {
                logEvent('changedCustomizationOptions');
                await updateUser()(dispatch, getState);
                navigate(SCREENS.WELCOME, {});
            } else {
                errorVibration();
                navigate(SCREENS.WELCOME, {});
                setTimeout(() => {
                    if (isWeb) {
                        window.alert(lang().error_happened);
                    } else {
                        Alert.alert(lang().error_happened);
                    }
                }, 200);
            }
        } catch (e) {
            navigate(SCREENS.WELCOME, {});
            setTimeout(() => {
                if (isWeb) {
                    window.alert(lang().error_happened);
                } else {
                    Alert.alert(lang().error_happened);
                }
            }, 200);
        }
    };
}

export function sendEmailAuthenticationCodeToUser() {
    return () => {
        return api.sendEmailAuthenticationCodeToUser();
    };
}

export function checkEmailAuthenticationCode({code}: {code: string}) {
    return () => {
        return api.checkEmailAuthenticationCode({code});
    };
}
