import React from 'react';
import {Modal, StyleSheet, View, ActivityIndicator} from 'react-native';
import {useTheme} from '../../modules/app/theme';

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 9,
        width: 200,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        paddingVertical: 50,
    },
});

const LoadingModal = ({show}) => {
    const theme = useTheme();

    return (
        <Modal
            animationType="fade"
            transparent
            visible={show}
        >
            <View
                style={{
                    ...styles.centeredView,
                    backgroundColor: 'rgba(255, 255, 255, 0.55)',
                    opacity: 0.7,
                }}
            >
                <ActivityIndicator
                    size="large"
                    color={theme.PRIMARY}
                />
            </View>
        </Modal>
    );
};

export default LoadingModal;
