import {ShareDialog} from 'react-native-fbsdk-next';
import Share from 'react-native-share';
import Clipboard from '@react-native-clipboard/clipboard';
import {constructUserLink} from '../helpers';
import lang from '../../localization';
import {showSnackBar} from './actions';
import {isWeb} from '../../../env';

function openShareWindow(url, title, w, h) {
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    return window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
    );
}

export function shareLink({type}) {
    return async (dispatch, getState) => {
        const {user, config} = getState().authReducer;

        const shareOptions = {
            title: lang().share_via,
            message: `${lang().share_message_1} ${user.name} ${
                lang().share_message_2
            }`,
            text: `${lang().share_message_1} ${user.name} ${
                lang().share_message_2
            }`,
            url: `https://${constructUserLink(user, config)}`,
        };

        switch (type) {
            case 'facebookPost':
                if (isWeb) {
                    openShareWindow(
                        `http://www.facebook.com/dialog/feed?app_id=286275538778968&link=${shareOptions.url}&message=${shareOptions.message}&display=popup&caption=An%20example%20caption&p[description]=fadfadah`,
                        'facebook-share',
                        600,
                        400,
                    );
                } else {
                    facebookPostShare({shareOptions})(dispatch, getState);
                }
                break;
            case 'twitter':
                if (isWeb) {
                    console.log(shareOptions.message);
                    openShareWindow(
                        `https://twitter.com/intent/tweet?text=${shareOptions.message}&url=${shareOptions.url}&hashtags=fadfadah,فضفضة`,
                        'twitter-share',
                        600,
                        400,
                    );
                } else {
                    twitterShare({shareOptions})(dispatch, getState);
                }
                break;
            case 'clipboard':
                if (isWeb) {
                    copyLinkToClipboard({shareOptions})(dispatch, getState);
                } else {
                    copyLinkToClipboard({shareOptions})(dispatch, getState);
                }
                break;
            case 'general':
            default:
                if (isWeb) {
                    copyLinkToClipboard({shareOptions})(dispatch, getState);
                } else {
                    await Share.open({
                        message: shareOptions.url,
                    })
                        .then(() => {})
                        .catch(() => {});
                }
        }
    };
}

function copyLinkToClipboard({shareOptions}) {
    return async (dispatch, getState) => {
        Clipboard.setString(shareOptions.url);
        showSnackBar({textMessage: 'تم نسخ الرابط'})(dispatch, getState);
    };
}

function twitterShare({shareOptions}) {
    return (dispatch, getState) => {
        Share.shareSingle({
            ...shareOptions,
            social: Share.Social.TWITTER,
        })
            .then(() => {})
            .catch(() => {});
    };
}

function facebookPostShare({shareOptions}) {
    return (dispatch, getState) => {
        const {user, config} = getState().authReducer;

        const facebookShareOptions = {
            contentType: 'link',
            contentUrl: constructUserLink(user, config, false),
            contentDescription: shareOptions.message,
        };

        ShareDialog.canShow(facebookShareOptions)
            .then(function (canShow) {
                if (canShow) {
                    return ShareDialog.show(facebookShareOptions);
                }
            })
            .then(function (result) {
                if (!result.isCancelled) {
                    // check if he shared
                }
            })
            .catch(e => {
                // e
            });
    };
}
