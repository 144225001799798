import {Platform} from 'react-native';
import {url} from '../../../env';
import ApiConnector from './ApiConnector';
import AppType from '../../../AppType';

const Backend = ApiConnector.getInstance('main', {
    baseURL: url,
    headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'App-Name': AppType.select({
            nezmo: 'nezmo',
            fadfadah: 'fadfadah',
        }),
        'App-Platform': Platform.OS,
    },
});

export default Backend;
