import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagWeb from '@bugsnag/js';
import Constants from '../../assets/Constants';

BugsnagWeb.start({
    apiKey: '465d8a1dac83e64108f7875f7ec95155',
    plugins: [new BugsnagPluginReact()],
    redactedKeys: ['password', 'token'],
    releaseStage: Constants.isProduction ? 'production' : 'development',
    appVersion: Constants.version,
});

export default BugsnagWeb;
