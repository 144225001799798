export type App = 'FADFADAH' | 'NEZMO';
export const APPS: App[] = ['FADFADAH', 'NEZMO'];

const CURRENT_APP: (typeof APPS)[number] = 'FADFADAH';

type SelectParam = {
    nezmo?: any;
    fadfadah?: any;
};

const select = (param: SelectParam) => {
    // @ts-ignore
    if (CURRENT_APP === 'FADFADAH' && param.fadfadah) {
        return param.fadfadah;
    }
    // @ts-ignore
    if (CURRENT_APP === 'NEZMO' && param.nezmo) {
        return param.nezmo;
    }
    return undefined;
};

export default {
    select,
    CURRENT_APP: CURRENT_APP as (typeof APPS)[number],
};
