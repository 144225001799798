import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18nManager, Platform, NativeModules} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {ReducerState} from '../../types/reducer/reducer';
import {ReduxDispatch} from '../../types/redux/redux';
import * as t from './actionTypes';
// @ts-ignore
import RNRestart from '../../../PackageWrappers/Modules/RNRestart';
import {isWeb} from '../../../env';

export const LANGS: {
    [lang: string]: {
        direction: 'ltr' | 'rtl';
        language: string;
    };
} = {
    ar: {
        language: 'ar',
        direction: 'rtl',
    },
    en: {
        language: 'en',
        direction: 'ltr',
    },
};

export function useLocalaization() {
    return useSelector((state: ReducerState) => {
        return state.appReducer.localization;
    });
}

function changeReducerLocalization({
    language,
    direction,
}: {
    language: string;
    direction: 'rtl' | 'ltr';
}) {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.CHANGE_LOCALIZATION,
            data: {language, direction},
        });
    };
}

const switchToRTL = () => {
    if (!isWeb && !I18nManager.isRTL) {
        I18nManager.forceRTL(true);
        I18nManager.allowRTL(true);
        AsyncStorage.setItem('is_manually_restarted', 'true').then(() => {
            RNRestart.Restart();
        });
    }
};

const switchToLTR = () => {
    if (!isWeb && I18nManager.isRTL) {
        I18nManager.forceRTL(false);
        I18nManager.allowRTL(false);
        AsyncStorage.setItem('is_manually_restarted', 'true').then(() => {
            RNRestart.Restart();
        });
    }
};

export function changeLanguage({lang}: {lang: 'ar' | 'en'}) {
    return (dispatch: ReduxDispatch) => {
        changeReducerLocalization(LANGS[lang])(dispatch);
        AsyncStorage.setItem('lang', lang);
        // @ts-ignore
        global.language = lang;
        if (lang === 'ar') {
            switchToRTL();
        } else if (lang === 'en') {
            switchToLTR();
        }
    };
}

const getDeviceDefaultLanguage = () => {
    let deviceLanguage;

    if (Platform.OS === 'ios') {
        deviceLanguage =
            NativeModules.SettingsManager.settings.AppleLocale ||
            NativeModules.SettingsManager.settings.AppleLanguages[0] ||
            NativeModules.SettingsManager.settings.AppleLanguages;
    }
    if (Platform.OS === 'android') {
        deviceLanguage = NativeModules.I18nManager.localeIdentifier;
    }
    if (!deviceLanguage || typeof deviceLanguage !== 'string') {
        deviceLanguage = 'ar';
    }

    if (deviceLanguage.search('ar') !== -1) {
        return 'ar';
    }
    return 'en';
};

export function useSetUpLocalization() {
    const dispatch = useDispatch();

    useEffect(() => {
        AsyncStorage.getItem('alreadyLaunched').then(value => {
            if (value !== 'true') {
                AsyncStorage.setItem('alreadyLaunched', 'true');
                AsyncStorage.setItem('lang', getDeviceDefaultLanguage()).then(
                    () => {
                        if (
                            getDeviceDefaultLanguage() === 'ar' ||
                            getDeviceDefaultLanguage() === 'en'
                        ) {
                            changeLanguage({lang: getDeviceDefaultLanguage()})(
                                dispatch,
                            );
                        } else {
                            changeLanguage({lang: 'ar'})(dispatch);
                        }
                    },
                );
            } else {
                AsyncStorage.getItem('lang').then(
                    (savedLanguage: 'ar' | 'en' | any) => {
                        if (savedLanguage === 'ar' || savedLanguage === 'en') {
                            changeLanguage({lang: savedLanguage})(dispatch);
                        } else {
                            changeLanguage({lang: 'ar'})(dispatch);
                        }
                    },
                );
            }
        });
    }, []);
}
