import React, {useEffect} from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    StyleSheet,
    I18nManager,
    useWindowDimensions,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {useDispatch, useStore} from 'react-redux';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withSequence,
    withTiming,
} from 'react-native-reanimated';
import Constants from '../../assets/Constants';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';
import language from '../../localization';
import {shareLink} from '../../modules/app/share';

const TwitterIcon = require('../../assets/images/twitter.png');
const FacebookIcon = require('../../assets/images/facebook.png');
const CopyIcon = require('../../assets/images/copyIcon.png');
const ShareIcon = require('../../assets/images/shareIcon.png');

const styles = StyleSheet.create({
    socialShareButton: {
        overflow: 'hidden',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: normalize(40),
        height: normalize(26),
        borderRadius: 20,
        opacity: 0.8,
        marginEnd: 8,
    },
    shareSocialMediaSection: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
    },
    socialShareIcon: {
        height: normalize(13),
        width: normalize(13),
        resizeMode: 'contain',
        tintColor: 'white',
        marginHorizontal: 5,
    },
});
const SocialShareButtons = ({link, theme, color}) => {
    const CUSTOM_COLOR = color;
    const dispatch = useDispatch();
    const store = useStore();

    return (
        <View style={[styles.shareSocialMediaSection, {alignItems: 'center'}]}>
            <ShareLinkButton
                link={link}
                shareLink={() => {
                    shareLink({type: 'general'})(dispatch, store.getState);
                }}
                customColor={CUSTOM_COLOR}
            />
            <View
                style={{
                    width: 1,
                    borderRadius: 10,
                    height: normalize(19),
                    backgroundColor: CUSTOM_COLOR,
                    marginHorizontal: 10,
                }}
            />

            <TouchableOpacity
                accessibilityHint="Share on Facebook"
                accessibilityLabel="Share on Facebook"
                onPress={() => {
                    shareLink({type: 'facebookPost'})(dispatch, store.getState);
                }}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.FACEBOOK}
                    style={styles.socialShareButton}
                >
                    <Image
                        source={FacebookIcon}
                        style={styles.socialShareIcon}
                    />
                </LinearGradient>
            </TouchableOpacity>

            <TouchableOpacity
                accessibilityHint="Share on Twitter"
                accessibilityLabel="Share on Twitter"
                onPress={() => {
                    shareLink({type: 'twitter'})(dispatch, store.getState);
                }}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.TWITTER}
                    style={styles.socialShareButton}
                >
                    <Image
                        source={TwitterIcon}
                        style={styles.socialShareIcon}
                    />
                </LinearGradient>
            </TouchableOpacity>
            <TouchableOpacity
                accessibilityHint="Copy link to clipboard"
                accessibilityLabel="Copy"
                onPress={() => {
                    shareLink({type: 'clipboard'})(dispatch, store.getState);
                }}
            >
                <LinearGradient
                    colors={[CUSTOM_COLOR, CUSTOM_COLOR]}
                    style={[styles.socialShareButton, {marginEnd: 0}]}
                >
                    <Image
                        source={CopyIcon}
                        style={styles.socialShareIcon}
                    />
                </LinearGradient>
            </TouchableOpacity>
        </View>
    );
};

const ShareLinkButton = ({link, shareLink, customColor}) => {
    const {width} = useWindowDimensions();
    const shareOpacity = useSharedValue(1);
    const linkOpacity = useSharedValue(0);

    const ANIMATION_SPEED = 1000;
    const ANIMATION_DELAY = 3000;
    const TIME_TO_SHOW_LINK = 1500;
    const TIME_TO_SHOW_SHARE = 4000;

    const shareAnimtedStyle = useAnimatedStyle(() => {
        return {
            opacity: shareOpacity.value,
        };
    }, []);
    const linkAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: linkOpacity.value,
        };
    }, []);

    useEffect(() => {
        shareOpacity.value = withDelay(
            ANIMATION_DELAY,
            withRepeat(
                withSequence(
                    withTiming(0, {duration: ANIMATION_SPEED}),
                    withTiming(0, {duration: TIME_TO_SHOW_LINK}),
                    withTiming(1, {duration: ANIMATION_SPEED}),
                    withTiming(1, {duration: TIME_TO_SHOW_SHARE}),
                ),
                -1,
                true,
            ),
        );
        linkOpacity.value = withDelay(
            ANIMATION_DELAY,
            withRepeat(
                withSequence(
                    withTiming(1, {duration: ANIMATION_SPEED}),
                    withTiming(1, {duration: TIME_TO_SHOW_LINK}),
                    withTiming(0, {duration: ANIMATION_SPEED}),
                    withTiming(0, {duration: TIME_TO_SHOW_SHARE}),
                ),
                -1,
                true,
            ),
        );
    }, []);

    const imageComponent = () => (
        <Image
            source={ShareIcon}
            style={[
                styles.socialShareIcon,
                {
                    marginStart: 0,
                    marginEnd: 10,
                    transform: [{scaleX: I18nManager.isRTL ? 1 : -1}],
                },
            ]}
        />
    );
    const linkComponent = () => (
        <T
            style={{
                color: 'white',
                fontSize: normalize(7.5),
                fontFamily: Constants.fonts.SemiBold,
            }}
        >
            {link}
        </T>
    );

    return (
        <TouchableOpacity
            style={{flex: 1}}
            onPress={shareLink}
        >
            <LinearGradient
                colors={[customColor, customColor]}
                style={[styles.socialShareButton, {width: '100%'}]}
            >
                {width > 700 ? (
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {imageComponent()}
                        {linkComponent()}
                    </View>
                ) : (
                    <>
                        <Animated.View
                            style={[
                                {
                                    position: 'absolute',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                shareAnimtedStyle,
                            ]}
                        >
                            <Image
                                source={ShareIcon}
                                style={[
                                    styles.socialShareIcon,
                                    {
                                        marginStart: 0,
                                        marginEnd: 10,
                                        transform: [
                                            {
                                                scaleX: I18nManager.isRTL
                                                    ? 1
                                                    : -1,
                                            },
                                        ],
                                    },
                                ]}
                            />
                            <T
                                style={{
                                    color: 'white',
                                    fontSize: normalize(10),
                                    fontFamily: Constants.fonts.SemiBold,
                                }}
                            >
                                {language.share_the_link}
                            </T>
                        </Animated.View>
                        <Animated.View
                            style={[linkAnimatedStyle, {position: 'absolute'}]}
                        >
                            <T
                                style={{
                                    color: 'white',
                                    fontSize: normalize(7.5),
                                    fontFamily: Constants.fonts.SemiBold,
                                }}
                            >
                                {link}
                            </T>
                        </Animated.View>
                    </>
                )}
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default SocialShareButtons;
