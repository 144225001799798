import React from 'react';
import {StyleSheet, View, TouchableOpacity, Platform} from 'react-native';
import * as Animatable from 'react-native-animatable';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {connect} from 'react-redux';
import {T} from '../Shared';
import lang from '../../localization';
import {actions as appActions} from '../../modules/app';
import Constants from '../../assets/Constants';
import store from '../../modules/redux/store';

const styles = StyleSheet.create({
    scrollViewContent: {flexGrow: 1},
    acceptConversationSection: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    acceptConversationTitle: {
        fontSize: 17,
        opacity: 0.8,
        textAlign: 'center',
        marginBottom: 30,
    },
    acceptConversationButtonsSection: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30,
    },
    acceptConversationButton: {
        backgroundColor: '#83C18C',
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginHorizontal: 10,
        borderRadius: 10,
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
        ...Constants.elevation.one,
    },
    acceptConversationButtonText: {
        fontFamily: Constants.fonts.Bold,
        color: 'white',
        fontSize: 14,
    },
    rejectConversationButton: {
        backgroundColor: '#F0F0F0',
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginHorizontal: 10,
        borderRadius: 10,
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rejectConversationButtonText: {fontSize: 14},
    stopRandomConversationsButton: {
        backgroundColor: '#F0F0F0',
        paddingVertical: 10,
        paddingHorizontal: 20,
        marginHorizontal: 10,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    stopRandomConversationsButtonText: {
        fontSize: 14,
        textAlign: 'center',
    },
});

const AcceptConversationsSection = ({
    activeConversation,
    activeConversationID,
    setNeedsAccept,
    removeConversations,
    can_receive,
    toggleRandomConversations,
    goBackToPreviousScreen,
    theme,
}) => {
    return (
        <Animatable.View
            animation="fadeIn"
            easing="ease-in"
            delay={50}
            duration={Platform.select({ios: 200, android: 1})}
            style={{
                ...styles.acceptConversationSection,
                backgroundColor: theme.BACKGROUND,
            }}
        >
            {activeConversation.is_random === 1 ? (
                <T
                    style={{
                        ...styles.acceptConversationTitle,
                        color: theme.TEXT,
                    }}
                >
                    {lang().you_have_random_message_from}{' '}
                    {activeConversation && activeConversation.anonymous_nickname
                        ? activeConversation.anonymous_nickname
                        : ''}{' '}
                    {lang().do_you_want_to_view_it}
                </T>
            ) : (
                <T
                    style={{
                        ...styles.acceptConversationTitle,
                        color: theme.TEXT,
                    }}
                >
                    {lang().you_have_message_from}{' '}
                    {activeConversation && activeConversation.anonymous_nickname
                        ? activeConversation.anonymous_nickname
                        : ''}{' '}
                    {lang().do_you_want_to_view_it}
                </T>
            )}
            <View
                style={styles.acceptConversationButtonsSection}
                testID={`acceptConversationSection${activeConversationID}`}
            >
                <TouchableOpacity
                    onPress={() => {
                        AsyncStorage.setItem(
                            `conversation_${activeConversationID}_accepted`,
                            'true',
                        ).then(() => {
                            setNeedsAccept(false);
                        });
                    }}
                    style={{
                        ...styles.acceptConversationButton,
                        backgroundColor: theme.SUCCESS,
                    }}
                    testID="acceptConversationButton"
                >
                    <T
                        style={{
                            ...styles.acceptConversationButtonText,
                            color: theme.TEXT_WHITE,
                        }}
                    >
                        {lang().accept}
                    </T>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={async () => {
                        removeConversations({
                            willBeRemovedConversations: [activeConversationID],
                        });
                        goBackToPreviousScreen();
                    }}
                    style={{
                        ...styles.rejectConversationButton,
                        backgroundColor: theme.SECONDARY,
                    }}
                >
                    <T
                        style={{
                            ...styles.rejectConversationButtonText,
                            color: theme.TEXT,
                        }}
                    >
                        {lang().reject_and_delete}
                    </T>
                </TouchableOpacity>
            </View>
            {activeConversation.is_random === 1 && can_receive === 1 && (
                <View>
                    <TouchableOpacity
                        onPress={() => {
                            if (can_receive === 1) {
                                toggleRandomConversations({});
                                removeConversations({
                                    willBeRemovedConversations: [
                                        activeConversationID,
                                    ],
                                });
                                goBackToPreviousScreen();
                            }
                        }}
                        style={{
                            ...styles.stopRandomConversationsButton,
                            backgroundColor: theme.SECONDARY,
                        }}
                    >
                        <T
                            style={{
                                ...styles.stopRandomConversationsButtonText,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().reject_and_stop_random_conversations}
                        </T>
                    </TouchableOpacity>
                </View>
            )}
            {/* {isSubscribed !== true && <AcceptConversationBanner />} */}
        </Animatable.View>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    removeConversations: data =>
        appActions.removeConversations(data)(dispatch, getState),
    toggleRandomConversations: data =>
        appActions.toggleRandomConversations(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    can_receive:
        Boolean(state.authReducer.user) &&
        Boolean(state.authReducer.user.can_receive)
            ? state.authReducer.user.can_receive
            : false,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AcceptConversationsSection);
