import React, {useState} from 'react';
import {
    ActivityIndicator,
    Modal,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native';
import WebView from 'react-native-webview';
import {tinyClick} from '../../modules/app/vibrations';

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 9,
        width: '90%',
        height: '80%',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        zIndex: 20,
    },
});

const TwitterLoginModal = ({
    show,
    setShow,
    onTwitterLogin,
}: {
    show: boolean;
    setShow: (value: boolean) => void;
    onTwitterLogin: (data: {token: string}) => void;
}) => {
    const [randomNumber] = useState(Math.random());
    const [isLoaded, setIsLoaded] = useState<null | boolean>(null);

    const hideModal = () => {
        setIsLoaded(false);
        tinyClick();
        setShow(false);
    };

    return (
        <Modal
            animationType="slide"
            transparent
            visible={show}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <WebView
                        incognito
                        onMessage={event => {
                            const token = event.nativeEvent.data;
                            setTimeout(() => {
                                onTwitterLogin({token});
                            }, 300);
                            tinyClick();
                            setShow(false);
                        }}
                        source={{
                            uri: `https://www.fadfadah.net/login/twitter?random=${randomNumber}`,
                        }}
                        onLoadEnd={() => {
                            setIsLoaded(true);
                        }}
                        scrollEnabled
                        containerStyle={{
                            width: '100%',
                        }}
                    />
                    {!isLoaded && (
                        <View
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ActivityIndicator
                                size="large"
                                color="#1EA1F1"
                            />
                        </View>
                    )}
                </View>
                <TouchableOpacity
                    onPress={() => {
                        hideModal();
                    }}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        zIndex: 1,
                        opacity: 0,
                    }}
                />
            </View>
        </Modal>
    );
};

export default TwitterLoginModal;
