import {AnyAction} from 'redux';
import * as t from './actionTypes';

export interface PointsReducer {
    pointsModal: {
        isVisible: boolean;
        amount: number;
        type: string;
    };
}

const initialState: PointsReducer = {
    pointsModal: {
        isVisible: false,
        amount: 0,
        type: '',
    },
};

export type PointsReducerAction = AnyAction & {
    payload: {
        amount: number;
        type: string;
    };
};

const subscriptionReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: PointsReducerAction,
) => {
    switch (action.type) {
        case t.SHOW_MODAL:
            return {
                ...state,
                pointsModal: {
                    isVisible: true,
                    amount: action.payload.amount,
                    type: action.payload.type,
                },
            };

        case t.HIDE_MODAL:
            return {
                ...state,
                pointsModal: {...initialState.pointsModal},
            };

        case 'app/FREE_REDUCER':
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default subscriptionReducer;
