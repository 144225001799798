import {CountryCode} from 'react-native-country-picker-modal';
import {getRemoteConfig} from '../remoteConfig/actions';
import Backend from '../axios/axios';

export async function initiateRandomConversations() {
    return Backend.post('/initiateRandomConversations', {
        num_of_allowed_random_conversations: parseInt(
            getRemoteConfig('num_of_allowed_random_conversations'),
            10,
        ),
    });
}

export async function getRandomUsers({
    get_subscribers,
    country_code = null,
}: {
    get_subscribers: boolean;
    country_code: CountryCode | null;
}) {
    return Backend.post('/getRandomUsers', {
        get_subscribers,
        country_code,
    });
}

export async function reportRandomUser({userLink}: {userLink: string}) {
    return Backend.post('/reportRandomUser', {
        user_link: userLink,
    });
}

export async function createRandomConversation({userLink}: {userLink: string}) {
    return Backend.post('/createRandomConversation', {
        user_link: userLink,
    });
}
