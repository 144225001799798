import React from 'react';
import {View, ScrollView, Dimensions, useWindowDimensions} from 'react-native';
import {isWeb} from '../../../env';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import {Interest} from '../../types/data/interest';
import InterestButton from './InterestButton';

const InterestsSection = ({
    interests,
    title,
    toggleInterest,
    selectedInterests,
    theme,
}: {
    interests: Interest[] | undefined;
    title: string;
    limit?: number;
    toggleInterest: ({
        name,
        isLanguage,
    }: {
        name: string;
        isLanguage?: boolean;
    }) => void;
    selectedInterests: string[];
    theme: Theme;
}) => {
    if (interests) {
        return (
            <View
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginTop: 7,
                    maxWidth: 700,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingHorizontal: 10,
                    }}
                >
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.Bold,
                            fontSize: 17,
                        }}
                    >
                        {title}
                    </T>
                </View>
                <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingHorizontal: 10,
                        maxWidth: 700,
                    }}
                    style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        marginTop: 5,
                    }}
                >
                    <View style={{flexDirection: 'column'}}>
                        {interests.chunk(10000).map(interestChunk => {
                            return (
                                <View
                                    key={interestChunk[0].name}
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: 7,
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {interestChunk.map((interest: Interest) => (
                                        <View
                                            style={{marginBottom: 10}}
                                            key={interest.id}
                                        >
                                            <InterestButton
                                                theme={theme}
                                                toggleInterest={toggleInterest}
                                                interest={interest}
                                                selectedInterests={[
                                                    ...selectedInterests,
                                                ]}
                                            />
                                        </View>
                                    ))}
                                </View>
                            );
                        })}
                    </View>
                </ScrollView>
            </View>
        );
    }
    return <></>;
};

export default InterestsSection;
