import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import React, {useEffect, useReducer, useRef, useState} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    BackHandler,
    ActivityIndicator,
    AppState,
    Platform,
    ScrollView,
} from 'react-native';
import {connect} from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as anonymousSocket from '../modules/conversation/anonymousSocket';
import * as defaultSocket from '../modules/conversation/defaultSocket';
import {actions as conversationActions} from '../modules/conversation';
import Constants from '../assets/Constants';
import {ConversationBody, T, TopBar} from '../components/Shared';
import lang from '../localization';
import store from '../modules/redux/store';
import {
    formatProfilePicturePath,
    generateRandomStrings,
    normalize,
} from '../modules/helpers';
import {ConversationFooter} from '../components/Main';
import {
    conversationInitialState,
    conversationReducer,
} from '../modules/conversation/conversationReducer';
import {NUMBER_OF_WANTED_MESSAGES} from '../../env';
import AcceptConversationsSection from '../components/Main/AcceptConversationsSection';
import ConversationSettingsModal from '../components/Modals/ConversationSettingsModal';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import CustomAlert from '../components/Shared/CustomAlert';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    errorSection: {
        width: '100%',
        paddingHorizontal: 20,
        marginTop: normalize(40),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorText: {
        textAlign: 'center',
        fontSize: normalize(15),
        fontFamily: Constants.fonts.Bold,
        color: '#585858',
    },
});

let socket;

function Conversation({
    route,
    navigation,
    getConversationDataFromConversationLink,
    getMoreMessages,
    sendMessage,
    isTyping,
    isSubscribed,
    user,
    freeActiveConversation,
    markConversationAsActive,
}) {
    const theme = useTheme();
    const {source, conversation} = route.params;
    const isInitiated = !!conversation.conversation_link;
    const [conversationState, dispatch] = useReducer(
        conversationReducer,
        conversationInitialState,
    );
    const {conversationData, messagesData, userData} = conversationState;
    const getLastMessageID = () => {
        if (messagesData && Array.isArray(messagesData)) {
            return messagesData[messagesData.length - 1].id;
        }
        return undefined;
    };
    const scrollViewRef = useRef(React.createRef);
    const [isError, setIsError] = useState(false);
    const isFocused = useIsFocused();
    const [needsAccept, setNeedsAccept] = useState(
        Platform.select({ios: undefined, android: false}),
    );
    const [
        conversationSettingsModalVisible,
        setConversationSettingsModalVisible,
    ] = useState(false);

    // this variable is used for state change checking
    let lastMessageID;
    if (
        !!messagesData &&
        Array.isArray(messagesData) &&
        messagesData.length > 0
    ) {
        lastMessageID = messagesData[0].id;
    }

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if (conversationData) {
            if (isInitiated) {
                const networkKey = conversationData.network_key;
                socket = anonymousSocket.createSocket();
                anonymousSocket.registerListeners({
                    socket,
                    networkKey,
                    userID: conversationData.user_id,
                    onNewMessageReceived,
                    onIsTyping,
                    onUserStatusChanged,
                    conversationID: conversationData.id,
                });
            } else {
                socket = defaultSocket.createSocket();
                defaultSocket.registerListeners({
                    socket,
                    networkKey: user.network_key,
                    userLink: user.user_link,
                    userID: user.id,
                    onNewMessageReceived,
                    onIsTyping,
                    onUserStatusChanged,
                    conversationID: conversationData.id,
                });
            }
        }
        return () => {
            defaultSocket.destroyListeners({socket});
            anonymousSocket.destroyListeners({socket});
        };
    }, [conversationData]);

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${lang().screenTitles.conversationWith(
                userData?.name || '',
            )}`,
        });
    }, [userData?.name]);

    const loadConversationAgain = () => {
        getConversationDataFromConversationLink({
            conversation,
            isInitiated,
        })
            .then(({conversation, messages, user}) => {
                dispatch({
                    type: 'loadConversationAndUserAndMessages',
                    payload: {
                        conversation: {
                            ...conversation,
                            is_loading_more_messages: false,
                            is_fully_loaded: false,
                        },
                        messages,
                        user,
                    },
                });
                if (messages.length < NUMBER_OF_WANTED_MESSAGES) {
                    dispatch({
                        type: 'setIsFullyLoaded',
                    });
                }
                setIsError(false);
            })
            .catch(() => {
                setIsError(true);
            });
    };

    const freeState = () => {
        dispatch({type: 'freeState'});
    };

    useEffect(() => {
        if (isFocused) {
            loadConversationAgain();
            markConversationAsActive(conversation.id);
        } else if (!isFocused) {
            freeState();
            freeActiveConversation();
        }
        return () => {};
    }, [isFocused]);

    const handleAppStateChange = state => {
        if (state === 'background') {
            freeState();
        }
        if (state === 'active' && isFocused) {
            loadConversationAgain();
        }
    };

    useEffect(() => {
        const subscription = AppState.addEventListener(
            'change',
            handleAppStateChange,
        );

        return () => {
            subscription.remove();
        };
    }, []);

    const onNewMessageReceived = ({message}) => {
        dispatch({type: 'receivedMessage', payload: {newMessage: message}});
    };

    const onIsTyping = () => {
        dispatch({type: 'setUserIsTyping'});
        setTimeout(() => {
            dispatch({type: 'unsetUserIsTyping'});
        }, 2500);
    };

    const onUserStatusChanged = ({isOnline}) => {
        if (isOnline) {
            dispatch({type: 'setUserOnline'});
        } else {
            dispatch({type: 'setUserOffline'});
        }
    };

    const onSendMessage = async ({text}) => {
        const userLink = userData.user_link;
        const tempID = generateRandomStrings();

        dispatch({
            type: 'startSendMessage',
            payload: {
                message: {
                    id: tempID,
                    text,
                },
            },
        });

        sendMessage({
            text,
            conversation: {...conversation, ...conversationData},
            userLink,
            isInitiated,
        })
            .then(({message}) => {
                dispatch({
                    type: 'sendMessageSuccess',
                    payload: {
                        message: {
                            tempID,
                            id: message.id,
                            text,
                            created_at: message.created_at,
                        },
                    },
                });
            })
            .catch(() => {
                dispatch({
                    type: 'sendMessageError',
                    payload: {
                        message: {
                            tempID,
                        },
                    },
                });
            });
    };

    const goBackToPreviousScreen = () => {
        if (source === 'CreateConversation') {
            navigation.navigate(SCREENS.WELCOME);
            return;
        }
        navigation.goBack();
    };

    // Hardware button
    useFocusEffect(
        React.useCallback(() => {
            const onBackPress = () => {
                goBackToPreviousScreen();
                return true;
            };
            BackHandler.addEventListener('hardwareBackPress', onBackPress);
            return () =>
                BackHandler.removeEventListener(
                    'hardwareBackPress',
                    onBackPress,
                );
        }, []),
    );

    useEffect(() => {
        const hasUserMessages =
            messagesData &&
            messagesData.some(message => {
                return message && message.sender === 'user';
            });

        if (!hasUserMessages && messagesData !== undefined) {
            AsyncStorage.getItem(
                `conversation_${conversationData?.id}_accepted`,
            ).then(isAccepted => {
                if (isAccepted === 'true') {
                    setNeedsAccept(false);
                } else {
                    setNeedsAccept(true);
                }
            });
        } else {
            setTimeout(() => {
                setNeedsAccept(false);
            }, 10);
        }
    }, [messagesData?.length, conversationData?.id]);

    if (isError) {
        return (
            <ErrorSection
                theme={theme}
                errorText={lang().error_happened}
                goBackToPreviousScreen={goBackToPreviousScreen}
            />
        );
    }

    if (conversationData === undefined) {
        return (
            <PageWrapper
                theme={theme}
                user={userData}
                goBackToPreviousScreen={goBackToPreviousScreen}
            >
                <View
                    style={{
                        height: '100%',
                    }}
                >
                    <View
                        style={{
                            height: '80%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ActivityIndicator
                            size="large"
                            color={theme.TEXT_PRIMARY}
                        />
                    </View>
                </View>
            </PageWrapper>
        );
    }

    if (conversationData && conversationData.is_deleted === 1) {
        return (
            <ErrorSection
                theme={theme}
                errorText={lang().conversation_is_deleted}
                goBackToPreviousScreen={goBackToPreviousScreen}
            />
        );
    }

    if (conversationData && isInitiated && conversationData.is_blocked === 1) {
        return (
            <ErrorSection
                theme={theme}
                errorText={lang().conversation_is_blocked}
                goBackToPreviousScreen={goBackToPreviousScreen}
            />
        );
    }

    // IOS Accept invite handler
    const isIOS = !isInitiated && Platform.OS === 'ios';
    const isRandom =
        !isInitiated &&
        conversationData !== undefined &&
        conversationData.is_random === 1;

    if (isIOS || isRandom) {
        if (
            needsAccept === true &&
            conversationData.id &&
            needsAccept !== undefined
        ) {
            return (
                <PageWrapper
                    theme={theme}
                    user={userData}
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    navigation={navigation}
                >
                    <ScrollView
                        contentContainerStyle={{flexGrow: 1}}
                        testID="conversationBody"
                    >
                        <AcceptConversationsSection
                            theme={theme}
                            goBackToPreviousScreen={goBackToPreviousScreen}
                            activeConversation={conversationData}
                            activeConversationID={conversationData.id}
                            setNeedsAccept={setNeedsAccept}
                        />
                    </ScrollView>
                </PageWrapper>
            );
        }
    }
    // end IOS Accept invite handler

    return (
        <PageWrapper
            theme={theme}
            user={userData}
            goBackToPreviousScreen={goBackToPreviousScreen}
            navigation={navigation}
            alert={alert}
        >
            <View style={{flex: 1, marginTop: 8}}>
                <ConversationBody
                    theme={theme}
                    isAnonymous
                    activeConversation={conversationData}
                    messages={messagesData}
                    scrollViewRef={scrollViewRef}
                    lastMessageID={lastMessageID}
                    isSubscribed={isSubscribed}
                    conversationBodyScrolledToTop={data => {
                        dispatch({
                            type: 'setLoadingMoreMessages',
                        });

                        if (getLastMessageID() !== undefined) {
                            getMoreMessages({
                                conversation: {
                                    conversationData,
                                    ...conversation,
                                },
                                lastMessageID: getLastMessageID(),
                                isInitiated,
                            })
                                .then(({messages}) => {
                                    if (
                                        messages.length <
                                        NUMBER_OF_WANTED_MESSAGES
                                    ) {
                                        dispatch({
                                            type: 'setIsFullyLoaded',
                                        });
                                    }

                                    dispatch({
                                        type: 'loadMoreMessages',
                                        payload: {
                                            messages,
                                        },
                                    });

                                    if (data.nativeEvent) {
                                        setTimeout(() => {
                                            data.scrollTo({
                                                y:
                                                    Platform.OS === 'ios'
                                                        ? data.nativeEvent
                                                              .layoutMeasurement
                                                              .height - 275
                                                        : data.nativeEvent
                                                              .layoutMeasurement
                                                              .height + 500,
                                                animated: false,
                                            });
                                        }, 0);
                                    }

                                    dispatch({
                                        type: 'unsetLoadingMoreMessages',
                                    });
                                })
                                .catch(() => {
                                    // setIsError(true);
                                    dispatch({
                                        type: 'unsetLoadingMoreMessages',
                                    });
                                });
                        }
                    }}
                    setAlert={setAlert}
                />
                <ConversationFooter
                    theme={theme}
                    showSettingsMenu
                    sendMessage={onSendMessage}
                    setConversationSettingsModalVisible={value =>
                        setConversationSettingsModalVisible(value)
                    }
                    onTyping={async () => {
                        const conversationID = conversationData.id;
                        const userID = conversationData.user_id;
                        isTyping({
                            conversationID,
                            userID,
                            socket,
                            isInitiated,
                        });
                    }}
                    setAlert={setAlert}
                />
                <ConversationSettingsModal
                    theme={theme}
                    conversation={conversation}
                    show={conversationSettingsModalVisible}
                    activeConversation={conversationData.id}
                    setShow={value =>
                        setConversationSettingsModalVisible(value)
                    }
                    goBack={() => goBackToPreviousScreen()}
                    setAlert={setAlert}
                />
            </View>
        </PageWrapper>
    );
}

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    getConversationDataFromConversationLink: data =>
        conversationActions.getConversationDataFromConversationLink(data)(
            dispatch,
            getState,
        ),
    sendMessage: data =>
        conversationActions.sendMessage(data)(dispatch, getState),
    isTyping: data => conversationActions.isTyping(data)(dispatch, getState),
    markConversationAsActive: conversationID =>
        conversationActions.markConversationAsActive(conversationID)(
            dispatch,
            getState,
        ),
    freeActiveConversation: () =>
        conversationActions.freeActiveConversation()(dispatch, getState),
    getMoreMessages: data =>
        conversationActions.getMoreMessages(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    user: state.authReducer.user,
    isSubscribed: state.subscriptionReducer.isSubscribed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);

const ErrorSection = ({errorText, goBackToPreviousScreen, theme}) => {
    return (
        <PageWrapper
            theme={theme}
            goBackToPreviousScreen={goBackToPreviousScreen}
        >
            <View
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <View style={styles.errorSection}>
                    <T style={{...styles.errorText, color: theme.TEXT}}>
                        {errorText}
                    </T>
                </View>
            </View>
        </PageWrapper>
    );
};

const PageWrapper = ({
    children,
    user,
    goBackToPreviousScreen,
    navigation,
    theme,
    alert,
}) => {
    return (
        <SafeAreaView
            style={[styles.container, {backgroundColor: theme.BACKGROUND}]}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                type="conversation"
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText="Loading"
                user={user}
                viewImage={() => {
                    if (navigation && user && user.profile_picture_path) {
                        navigation.push('ImageViewer', {
                            imageURL: formatProfilePicturePath({
                                profilePicture: user.profile_picture_path,
                            }),
                        });
                    }
                }}
            />
            {children}
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
        </SafeAreaView>
    );
};
