import React from 'react';
import {View} from 'react-native';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {useTheme} from '../../modules/app/theme';
import {T} from '../Shared';

const LoadOfferingsError = () => {
    const theme = useTheme();

    return (
        <View style={{marginTop: 80, marginBottom: 50}}>
            <T style={{color: theme.DANGER, fontFamily: Constants.fonts.Bold}}>
                {lang().could_not_load_offerings}
            </T>
        </View>
    );
};

export default LoadOfferingsError;
