import React from 'react';
import {TouchableOpacity, Image, ScrollView} from 'react-native';
import moment from 'moment';
import 'moment/locale/en-gb';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import lang from '../../localization';
import WhatsAppButton from '../Main/WhatsAppButton';
import {SCREENS} from '../../screens/Screens';
import {useGetPortalSessionURL} from '../../modules/subscription/api';
import {isWeb} from '../../../env';

const diamondIcon = require('../../assets/images/diamondIcon.png');

const SubscriberView = ({
    activeSubscription,
    navigation,
    config,
    theme,
    setIsLoading,
}) => {
    const getPortalSessionURL = useGetPortalSessionURL();

    const onPressSubscriptionSettings = () => {
        setIsLoading(true);
        getPortalSessionURL.mutate(undefined, {
            onSuccess(data) {
                const {url} = data.data.data;
                window.location.href = url;
            },
            onError(error) {
                console.log({error});
            },
            onSettled() {
                setIsLoading(false);
            },
        });
    };

    return (
        <ScrollView
            style={{
                flex: 1,
            }}
            contentContainerStyle={{
                marginTop: 80,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Image
                source={diamondIcon}
                style={{height: 100, tintColor: theme.SUCCESS}}
                resizeMode="contain"
            />
            <T
                style={{
                    fontSize: 22,
                    fontFamily: Constants.fonts.Bold,
                    color: theme.SUCCESS,
                    marginBottom: 20,
                }}
            >
                {lang().thanks_for_subscribing}
            </T>
            {/* <T
                style={{
                    fontSize: 13,
                    color: theme.TEXT,
                    marginTop: 20,
                }}
            >
                {lang().your_subscription_will_end_at}
            </T> */}
            {/* <T
                style={{
                    fontSize: 13,
                    fontFamily: Constants.fonts.Bold,
                    color: theme.TEXT_MUTED,
                    marginTop: 6,
                    marginBottom: 10,
                }}
            >
                ({' '}
                {moment(activeSubscription.end_date)
                    .locale('en')
                    .format('Y/M/D  h:mm A')}{' '}
                )
            </T> */}
            {config?.whatsapp_contact_number && (
                <WhatsAppButton
                    theme={theme}
                    whatsAppNumber={config?.whatsapp_contact_number}
                />
            )}
            <TouchableOpacity
                onPress={() => {
                    try {
                        if (navigation.getState().routes.length === 1) {
                            navigation.navigate(SCREENS.WELCOME);
                        } else {
                            navigation.goBack();
                        }
                    } catch (e) {
                        // console.log({ e })
                    }
                }}
                style={{
                    borderColor: theme.TEXT_PRIMARY,
                    borderWidth: 1,
                    paddingHorizontal: 25,
                    paddingVertical: 10,
                    paddingBottom: 13,
                    marginTop: 30,
                    borderRadius: 100,
                }}
            >
                <T
                    style={{
                        color: theme.TEXT_PRIMARY,
                        fontFamily: Constants.fonts.Bold,
                    }}
                >
                    {lang().cancel}
                </T>
            </TouchableOpacity>

            {isWeb && (
                <TouchableOpacity
                    onPress={onPressSubscriptionSettings}
                    style={{
                        borderColor: theme.TEXT_PRIMARY,
                        marginTop: 30,
                        borderRadius: 100,
                    }}
                >
                    <T
                        style={{
                            color: theme.DISABLED,
                            fontFamily: Constants.fonts.Bold,
                            fontSize: 10,
                        }}
                    >
                        {lang().subscription_settings}
                    </T>
                </TouchableOpacity>
            )}
        </ScrollView>
    );
};

export default SubscriberView;
