import React, {MutableRefObject, useEffect} from 'react';
import {ActivityIndicator, Platform, View} from 'react-native';
import {LinkingOptions, NavigationContainer} from '@react-navigation/native';
import {useDispatch} from 'react-redux';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import {navigationRef} from './src/modules/navigation';
import {Screens} from './src/screens';
import './src/modules/helpers/prototypes';
import emitter from './src/modules/helpers/Emitter';
import {
    initialThemeDecider,
    useThemeMode,
    useTheme,
} from './src/modules/app/theme';
import {Theme} from './src/assets/Colors';
import {
    useLocalaization,
    useSetUpLocalization,
} from './src/modules/app/localization';
import {logScreenView} from './src/modules/app/tracking';
import {notificationsListener} from './src/modules/app/notifications';
// @ts-ignore
import Bugsnag from './src/modules/auth/bugsnag';

const linking: LinkingOptions<{}> = {
    prefixes: [
        'fadfadah:https://fadfadah.net',
        'fadfadah://',
        'http://localhost:19006',
        'https://localhost:19006',
    ],
    async getInitialURL() {
        const url = await Linking.getInitialURL();
        return url;
    },
    subscribe(listener: any) {
        const onReceiveURL = ({url}: {url: string}) => listener(url);
        // Listen to incoming links from deep linking
        Linking.addEventListener('url', onReceiveURL);

        if (!emitter.eventNames().includes('notificationOpened')) {
            emitter.addListener('notificationOpened', ({data, type}) => {
                let url;
                if (type === 'newMessage') {
                    url = `fadfadah://conversation/${data.conversation_id}/false/undefined`;
                }
                if (type === 'newConversation') {
                    url = `fadfadah://conversation/${data.conversation_id}/true/undefined`;
                }
                if (type === 'newMessageAnonymous') {
                    url = `fadfadah://conversation/undefined/false/${data.conversation_link}`;
                }
                listener(url);
            });
        }

        const subscription =
            Notifications.addNotificationResponseReceivedListener(response => {
                const data = (response?.notification?.request?.trigger as any)
                    ?.payload;
                const type = data?.type;
                if (data && type) {
                    emitter.emit('notificationOpened', {
                        data,
                        type,
                    });
                }
            });

        if (Platform.OS === 'android') {
            notificationsListener();
        }

        return () => {
            // Linking.removeAllListeners('url');
            subscription.remove();
            emitter.removeListener('notificationOpened', () => {});
        };
    },
    config: {
        screens: {
            Subscription: 'subscription/:result',
            CreateConversation: 'user/:userLink',
            ConversationAdapter:
                'conversation/:conversation_id/:is_new/:conversation_link',
        },
    },
};

const NavigationContainerWrapper = ({
    routeNameRef,
}: {
    routeNameRef: MutableRefObject<any>;
}) => {
    const themeMode = useThemeMode();
    const theme = useTheme();
    const dispatch = useDispatch();
    const localization = useLocalaization();

    useSetUpLocalization();

    useEffect(() => {
        initialThemeDecider({themeModeFromReducer: themeMode})(dispatch);
    }, [themeMode]);

    if (themeMode === undefined || localization === undefined) {
        return <SimpleLoading theme={theme} />;
    }

    return (
        <NavigationContainer
            theme={{
                dark: themeMode === 'dark',
                colors: {
                    background: theme.BACKGROUND,
                    text: theme.TEXT,
                    border: theme.BACKGROUND,
                    notification: theme.BACKGROUND,
                    card: theme.SECONDARY,
                    primary: theme.PRIMARY,
                },
            }}
            fallback={<SimpleLoading theme={theme} />}
            linking={linking}
            ref={navigationRef}
            onReady={() => {
                routeNameRef.current =
                    navigationRef.current.getCurrentRoute().name;
            }}
            onStateChange={async state => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName =
                    navigationRef.current.getCurrentRoute().name;

                if (
                    previousRouteName !== currentRouteName &&
                    Platform.OS !== 'web'
                ) {
                    Bugsnag.leaveBreadcrumb('Screen View', {
                        from: previousRouteName,
                        to: currentRouteName,
                    });
                    logScreenView({screenName: currentRouteName});
                }
            }}
        >
            <View
                // @ts-ignore
                dir={localization.direction}
                style={{flex: 1}}
            >
                <Screens />
            </View>
        </NavigationContainer>
    );
};

const SimpleLoading = ({theme}: {theme: Theme}) => {
    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.BACKGROUND,
            }}
        >
            <ActivityIndicator
                size="large"
                color={theme.TEXT_PRIMARY}
            />
        </View>
    );
};

export default NavigationContainerWrapper;
