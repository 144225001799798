import React, {useEffect, useState} from 'react';
import {
    ActivityIndicator,
    SafeAreaView,
    TouchableOpacity,
    View,
    Platform,
    useWindowDimensions,
    Dimensions,
} from 'react-native';
import {connect} from 'react-redux';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import store from '../../modules/redux/store';
import {T} from '../Shared';
import ConversationListItem, {BUTTON_HEIGHT} from './ConversationListItem';
import ConversationsListHeader from './ConversationsListHeader';
import EmptyConversationsList from './EmptyConversationsList';
import CreateConversationFloatingButton from './CreateConversationFloatingButton';
import FlashList from '../../../PackageWrappers/Components/FlashList';
import {isWeb} from '../../../env';
import CustomAlert from '../Shared/CustomAlert';

const INITIAL_NUMBER_OF_ITEMS = 20;

const ConversationsTab = ({conversations, theme, userID}) => {
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [numberOfItems, setNumberOfItems] = useState(INITIAL_NUMBER_OF_ITEMS);
    const [appliedFilter, setAppliedFilter] = useState('all'); // all, via_link, random
    const {height} = useWindowDimensions();

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsInitialLoading(false);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        setNumberOfItems(INITIAL_NUMBER_OF_ITEMS);
    }, [appliedFilter]);

    if (conversations === undefined || isInitialLoading) {
        return (
            <View
                style={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.BACKGROUND,
                }}
            >
                <ActivityIndicator
                    color={theme.TEXT_PRIMARY}
                    size="large"
                />
            </View>
        );
    }

    const filteredConversations = conversationFilter({
        conversations,
        numberOfItems,
        appliedFilter,
        userID,
    });

    const FlashListData =
        conversations !== undefined && conversations.length > 0
            ? filteredConversations
            : [];

    const renderFooter = () => {
        if (filteredConversations.length < numberOfItems) {
            return <></>;
        }

        return (
            <TouchableOpacity
                onPress={() => {
                    setNumberOfItems(numberOfItems + 50);
                }}
                style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: 10,
                }}
            >
                <T
                    style={{
                        fontFamily: Constants.fonts.Bold,
                        color: theme.TEXT_MUTED,
                    }}
                >
                    {lang().conversations_list.load_more}
                </T>
            </TouchableOpacity>
        );
    };

    return (
        <SafeAreaView
            style={{
                zIndex: 100,
                position: 'absolute',
                height: Platform.select({web: height - 50, native: '100%'}),
                width: '100%',
                backgroundColor: theme.BACKGROUND,
                alignItems: isWeb || Platform.isPad ? 'center' : undefined,
            }}
        >
            <View style={{width: '100%', maxWidth: 800, flex: 1}}>
                <FlashList
                    ListFooterComponent={renderFooter}
                    ListHeaderComponent={
                        <ConversationsListHeader
                            appliedFilter={appliedFilter}
                            setAppliedFilter={setAppliedFilter}
                            theme={theme}
                        />
                    }
                    data={FlashListData}
                    renderItem={({item}) => (
                        <ConversationListItem
                            item={{item}}
                            theme={theme}
                            setAlert={setAlert}
                        />
                    )}
                    ListEmptyComponent={
                        <EmptyConversationsList theme={theme} />
                    }
                    estimatedItemSize={BUTTON_HEIGHT || 30}
                    keyExtractor={item => {
                        return `${item.key}`;
                    }}
                    contentContainerStyle={{
                        paddingBottom: 150,
                    }}
                />
            </View>

            <View style={{width: '100%', maxWidth: 800}}>
                <CreateConversationFloatingButton theme={theme} />
            </View>
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
        </SafeAreaView>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({});

const mapStateToProps = state => ({
    conversations: state.conversationsReducer.conversations,
    userID: state.authReducer?.user?.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsTab);

export const isNew = conversation => {
    if (conversation.isInitiated && conversation.is_new_user === 1) {
        return true;
    }
    if (!conversation.isInitiated && conversation.is_new_anonymous === 1) {
        return true;
    }
    if (!conversation.isInitiated && conversation.is_new === 1) {
        return true;
    }
    return false;
};

const conversationFilter = ({
    conversations,
    numberOfItems,
    appliedFilter,
    userID,
}) => {
    const conversationIDs = [];

    let filteredConversations = [...conversations];

    // adding my data
    filteredConversations = filteredConversations.map(_conversation => {
        return {
            ..._conversation,
            app_is_new: isNew(_conversation),
            last_interacted_at_time: new Date(
                _conversation.last_interacted_at,
            ).getTime(),
        };
    });

    // sort conversations by last_interacted_with
    const filterByInteractionTime = (_conversationA, _conversationB) => {
        return (
            _conversationB.last_interacted_at_time -
            _conversationA.last_interacted_at_time
        );
    };

    // filteredConversations = filteredConversations.sort(filterByInteractionTime);

    // putting new conversations at top
    const newConversations = filteredConversations
        .filter(_conversation => {
            return _conversation.app_is_new;
        })
        .sort(filterByInteractionTime);

    const notNewConversations = filteredConversations
        .filter(_conversation => {
            return !_conversation.app_is_new;
        })
        .sort(filterByInteractionTime);

    filteredConversations = [...newConversations, ...notNewConversations];

    // filtering by applied filter
    switch (appliedFilter) {
        case 'via_link':
            filteredConversations = filteredConversations.filter(
                _conversation => {
                    if (!_conversation.is_random) {
                        return true;
                    }
                    return false;
                },
            );
            break;
        case 'random':
            filteredConversations = filteredConversations.filter(
                _conversation => {
                    if (_conversation.is_random) {
                        return true;
                    }
                    return false;
                },
            );
            break;
        case 'all':
        default:
    }

    // remove duplicates
    filteredConversations = filteredConversations.map(item => {
        if (conversationIDs.includes(item.id)) {
            return {
                ...item,
                key: `${item.id}_duplicate}`,
            };
        }
        conversationIDs.push(item.id);
        return {
            ...item,
            key: `${item.id}`,
        };
    });

    // limiting
    filteredConversations = filteredConversations.slice(0, numberOfItems);

    return filteredConversations;
};
