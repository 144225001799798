import * as React from 'react';
import Svg, {
    SvgProps,
    Defs,
    LinearGradient,
    Stop,
    G,
    Path,
} from 'react-native-svg';
import {useTheme} from '../../modules/app/theme';

const NezmoLogo = (props: SvgProps) => {
    const theme = useTheme();

    return (
        <Svg
            viewBox="200 180 600 600"
            {...props}
            opacity="0.8"
        >
            <Defs>
                <LinearGradient
                    id="a"
                    x1={761.04}
                    y1={277.22}
                    x2={331.36}
                    y2={790.51}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop
                        offset={0}
                        stopColor="#67b3e4"
                    />
                    <Stop
                        offset={0.91}
                        stopColor="#1e6e96"
                    />
                </LinearGradient>
                <LinearGradient
                    id="b"
                    x1={520.82}
                    y1={76.13}
                    x2={91.14}
                    y2={589.42}
                />
            </Defs>
            <G
                data-name="Layer 1"
                style={{
                    isolation: 'isolate',
                }}
            >
                <Path
                    d="M730.16 509.22v202.13H667a64.46 64.46 0 0 1-64.46-64.47V509.22a93.51 93.51 0 0 0-31.81-70.34c-19.67-17.38-44.55-25.12-70.08-21.82l-2.73.33c-43.82 6.5-76.93 45.8-76.93 91.34v138.15a64.46 64.46 0 0 1-64.46 64.47h-63.2V508.73a218.85 218.85 0 0 1 11.78-71 220.17 220.17 0 0 1 10.74-25.9c1.5.1 3 .14 4.56.14a69.83 69.83 0 0 0 41.86-13.87L400 435.9V319.69a212.61 212.61 0 0 1 79.27-28.56l5.09-.75A217 217 0 0 1 655 343q5.21 4.55 10.13 9.45a221.35 221.35 0 0 1 65.03 156.77Z"
                    fill={theme.MODE === 'dark' ? 'white' : 'url(#a)'}
                />
                <Path
                    d="M301.47 221.87A84.93 84.93 0 1 0 357.05 371l29.35 29.35v-93.56a84.92 84.92 0 0 0-84.93-84.92ZM255.41 283h92.15v17.51h-92.15Zm47.91 52h-47.91v-17.5h47.91Z"
                    fill={theme.MODE === 'dark' ? 'white' : 'url(#a)'}
                />
            </G>
        </Svg>
    );
};

export default NezmoLogo;
