import React, {useState, useEffect} from 'react';
import {Animated, View} from 'react-native';

const SlideFromBottom = ({disabled = false, delay = 40, style, children}) => {
    const [slideAnim] = useState(new Animated.Value(-300)); // Initial value for opacity: 0
    const [fadeAnim] = useState(new Animated.Value(0)); // Initial value for opacity: 0

    useEffect(() => {
        Animated.timing(slideAnim, {
            toValue: 0,
            delay,
            duration: 500,
            useNativeDriver: false,
        }).start();
        Animated.timing(fadeAnim, {
            toValue: 1,
            delay,
            duration: 600,
            useNativeDriver: false,
        }).start();
    }, [slideAnim, fadeAnim]);

    if (disabled === true) {
        return <View style={{...style}}>{children}</View>;
    }
    return (
        <Animated.View // Special animatable View
            style={{
                ...style,
                bottom: slideAnim, // Bind opacity to animated value
                opacity: style.opacity ? style.opacity : fadeAnim,
            }}
        >
            {children}
        </Animated.View>
    );
};

export default SlideFromBottom;
