import React, {useState} from 'react';
import {
    ActivityIndicator,
    Image,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native';
import {useStore} from 'react-redux';
import InAppReview from 'react-native-in-app-review';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';
import Modal, {ModalProps} from '../Shared/Modal';
import {actions as appActions} from '../../modules/app';
import {getDarkModeHomeButtonSectionBackground} from '../../modules/app/theme';
import {isWeb} from '../../../env';

const LikeIcon = require('../../assets/images/likeIcon.png');
const CheckIcon = require('../../assets/images/checkIconLarge.png');

const RateUsModal = ({
    isVisible,
    closeModal,
    theme,
    setShowRateUsSection,
}: ModalProps & {setShowRateUsSection: (value: boolean) => void}) => {
    const [ratingResult, setRatingResult] = useState<
        'positive' | 'negative' | 'thankyou' | undefined
    >(undefined);
    const [isSendingRating, setIsSendingRating] = useState(false);
    const [review, setReview] = useState('');
    const store = useStore();

    const onModalClose = () => {
        closeModal();
        setReview('');
        setIsSendingRating(false);
        setRatingResult(undefined);
    };

    const stopShowingRateUsSection = async () => {
        AsyncStorage.getItem('userHasOpenedRatePage').then(
            userHasOpenedRatePage => {
                if (userHasOpenedRatePage !== 'true') {
                    setShowRateUsSection(false);
                    AsyncStorage.setItem('userHasOpenedRatePage', 'true');
                }
            },
        );
    };

    const launchAppStoreRating = () => {
        stopShowingRateUsSection();
        if (!isWeb && InAppReview.isAvailable()) {
            InAppReview.RequestInAppReview()
                .then(hasFlowFinishedSuccessfully => {
                    if (hasFlowFinishedSuccessfully) {
                        onModalClose();
                    }
                })
                .catch(error => {
                    onModalClose();
                });
        } else {
            onModalClose();
        }
    };

    return (
        <Modal
            isVisible={isVisible}
            closeModal={() => {
                onModalClose();
            }}
            theme={theme}
        >
            <View style={{minHeight: 100, paddingVertical: 30}}>
                {ratingResult === undefined && (
                    <>
                        <View
                            style={{
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <T
                                style={{
                                    color: theme.TEXT_PRIMARY,
                                    fontFamily: Constants.fonts.SemiBold,
                                    fontSize: normalize(14),
                                }}
                            >
                                {lang().rating_modal_title}
                            </T>
                        </View>

                        <View
                            style={{
                                width: '100%',
                                marginTop: 40,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    launchAppStoreRating();
                                }}
                                style={{
                                    ...(theme.MODE === 'light'
                                        ? {
                                              borderColor: theme.SUCCESS,
                                              borderWidth: 1,
                                          }
                                        : {
                                              backgroundColor:
                                                  getDarkModeHomeButtonSectionBackground(
                                                      theme.SUCCESS,
                                                  ) as string,
                                          }),
                                    borderRadius: 100,
                                    padding: 20,
                                    paddingVertical: 23,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Image
                                    source={LikeIcon}
                                    style={{
                                        height: normalize(30),
                                        width: normalize(30),
                                        resizeMode: 'contain',
                                        tintColor: theme.SUCCESS,
                                        marginHorizontal: 5,
                                    }}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    setRatingResult('negative');
                                }}
                                style={{
                                    ...(theme.MODE === 'light'
                                        ? {
                                              borderColor: theme.DANGER,
                                              borderWidth: 1,
                                          }
                                        : {
                                              backgroundColor:
                                                  getDarkModeHomeButtonSectionBackground(
                                                      theme.DANGER,
                                                  ) as string,
                                          }),
                                    borderRadius: 100,
                                    padding: 20,
                                    paddingVertical: 23,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Image
                                    source={LikeIcon}
                                    style={{
                                        height: normalize(30),
                                        width: normalize(30),
                                        resizeMode: 'contain',
                                        tintColor: theme.DANGER,
                                        marginHorizontal: 5,
                                        transform: [{scaleY: -1}],
                                    }}
                                />
                            </TouchableOpacity>
                        </View>
                    </>
                )}
                {ratingResult === 'negative' && (
                    <View
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: theme.TEXT_PRIMARY,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(14),
                            }}
                        >
                            {lang().rating_modal_comment_placeholder}
                        </T>
                        <View style={{width: '85%', marginTop: 30}}>
                            <TextInput
                                onChangeText={value => {
                                    setReview(value);
                                }}
                                value={review}
                                multiline
                                autoFocus
                                selectionColor={theme.PRIMARY}
                                numberOfLines={5}
                                style={{
                                    fontFamily: Constants.fonts.SemiBold,
                                    borderRadius: 10,
                                    paddingTop: 10,
                                    paddingHorizontal: 10,
                                    color: theme.TEXT,
                                    backgroundColor: theme.INPUT_BACKGROUND,
                                    width: '100%',
                                    minHeight: 100,
                                }}
                            />
                        </View>

                        <View
                            style={{
                                width: '100%',
                                marginTop: 30,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            <TouchableOpacity
                                disabled={
                                    isSendingRating || review.length === 0
                                }
                                onPress={() => {
                                    stopShowingRateUsSection();
                                    appActions.sendRatingToSlack({
                                        rating: 'bad',
                                        review,
                                    })(null, store.getState);
                                    setIsSendingRating(true);
                                    setTimeout(() => {
                                        setRatingResult('thankyou');
                                    }, 500);
                                }}
                                style={{
                                    ...(theme.MODE === 'light'
                                        ? {
                                              borderColor: theme.SUCCESS,
                                              borderWidth: 1,
                                          }
                                        : {
                                              backgroundColor:
                                                  getDarkModeHomeButtonSectionBackground(
                                                      theme.SUCCESS,
                                                  ) as string,
                                          }),
                                    opacity:
                                        isSendingRating || review.length === 0
                                            ? 0.5
                                            : 1,
                                    borderRadius: 100,
                                    paddingHorizontal: 40,
                                    paddingVertical: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSendingRating && (
                                    <View
                                        style={{
                                            marginVertical: 5,
                                            paddingHorizontal: 20,
                                        }}
                                    >
                                        <ActivityIndicator
                                            color={theme.SUCCESS}
                                            size="small"
                                        />
                                    </View>
                                )}
                                {!isSendingRating && (
                                    <T
                                        style={{
                                            color: theme.SUCCESS,
                                            fontFamily:
                                                Constants.fonts.SemiBold,
                                            fontSize: normalize(14),
                                        }}
                                    >
                                        {lang().rating_modal_send_button}
                                    </T>
                                )}
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
                {ratingResult === 'thankyou' && (
                    <View
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: theme.TEXT_PRIMARY,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(17),
                                paddingHorizontal: 10,
                                lineHeight: normalize(25),
                                textAlign: 'center',
                            }}
                        >
                            {lang().rating_modal_thankyou}
                        </T>
                        <T
                            style={{
                                color: theme.TEXT_PRIMARY,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(11),
                                paddingHorizontal: 10,
                                lineHeight: normalize(25),
                                textAlign: 'center',
                            }}
                        >
                            {
                                lang()
                                    .rating_modal_we_promise_to_provide_better_service
                            }
                        </T>

                        <View
                            style={{
                                width: '100%',
                                marginTop: 30,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    onModalClose();
                                }}
                                style={{
                                    ...(theme.MODE === 'light'
                                        ? {
                                              borderColor: theme.SUCCESS,
                                              borderWidth: 1,
                                          }
                                        : {
                                              backgroundColor:
                                                  getDarkModeHomeButtonSectionBackground(
                                                      theme.SUCCESS,
                                                  ) as string,
                                          }),
                                    borderRadius: 100,
                                    padding: 40,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Image
                                    source={CheckIcon}
                                    style={{
                                        height: normalize(45),
                                        width: normalize(45),
                                        resizeMode: 'contain',
                                        tintColor: theme.SUCCESS,
                                        marginHorizontal: 5,
                                    }}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </View>
        </Modal>
    );
};

export default RateUsModal;
