import React from 'react';
import {View, Text, Image, Platform, Pressable} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Constants from '../../assets/Constants';
import {ProfilePicture} from '../Shared';
import lang from '../../localization';
import shapes from './shapes';
import {Theme} from '../../assets/Colors';
import {SCREENS} from '../../screens/Screens';
import {IS_SUBSCRIPTIONS_ENABLED} from '../../modules/subscription/actions';

const lockIcon = require('../../assets/images/lockIcon.png');
const checkIcon = require('../../assets/images/checkIcon.png');

const SelectShapeSection = ({
    selectedShape,
    setSelectedShape,
    selectedColor,
    isSubscribed,
    theme,
}: {
    selectedShape: string | undefined;
    setSelectedShape: (shapeName: string | undefined) => void;
    selectedColor: string;
    isSubscribed: boolean;
    theme: Theme;
}) => {
    const navigation = useNavigation();

    return (
        <View
            style={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                paddingHorizontal: 10,
                marginTop: 20,
            }}
        >
            <Text
                style={{
                    fontFamily: Constants.fonts.SemiBold,
                    color: theme.TEXT_PRIMARY,
                    fontSize: 19,
                }}
            >
                {lang().select_frame_shape}
            </Text>
            <View
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: 20,
                }}
            >
                {Object.values(shapes).map(shape => {
                    const isLocked = () => {
                        if (IS_SUBSCRIPTIONS_ENABLED === false) {
                            return false;
                        }
                        if (isSubscribed) {
                            return false;
                        }
                        if (shape.isLocked) {
                            return true;
                        }
                        return false;
                    };
                    const isSelected = shape.value === selectedShape;

                    const onFramePressed = () => {
                        if (isLocked()) {
                            navigation.navigate(SCREENS.SUBSCRIPTION as never);
                        } else {
                            setSelectedShape(shape.value);
                        }
                    };
                    return (
                        <Pressable
                            accessibilityHint={shape.name}
                            accessibilityLabel={shape.name}
                            disabled={isSelected}
                            onPress={onFramePressed}
                            key={shape.name}
                            android_ripple={{
                                color: Constants.colors.main.light,
                                borderless: true,
                                radius: 30,
                            }}
                            delayLongPress={0}
                            style={({pressed}) => [
                                {
                                    borderRadius: 100,
                                    zIndex: 1,
                                    opacity: pressed ? 0.9 : 1,
                                    backgroundColor: pressed
                                        ? Constants.colors.blackSecondary
                                        : theme.BACKGROUND,
                                    marginHorizontal: 10,
                                    marginVertical: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                            ]}
                        >
                            <ProfilePicture
                                isLoading={false}
                                disableImageViewer
                                dimensions={50}
                                shadowRadius={Platform.select({
                                    ios: 14,
                                    android: 0,
                                })}
                                customization={{
                                    frame: shape.value,
                                    color: selectedColor,
                                }}
                                theme={theme}
                            />
                            {isLocked() && (
                                <Image
                                    source={lockIcon}
                                    style={{
                                        position: 'absolute',
                                        height: 30,
                                        width: 30,
                                        opacity: 0.8,
                                        resizeMode: 'contain',
                                        zIndex: 3,
                                    }}
                                />
                            )}
                            {isSelected && (
                                <View
                                    style={{
                                        position: 'absolute',
                                        width: '70%',
                                        height: '70%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor:
                                            Constants.colors.success,
                                        borderRadius: 100,
                                        opacity: 0.8,
                                        zIndex: 3,
                                    }}
                                >
                                    <Image
                                        source={checkIcon}
                                        style={{
                                            position: 'absolute',
                                            height: 20,
                                            width: 20,
                                            opacity: 0.8,
                                            resizeMode: 'contain',
                                        }}
                                    />
                                </View>
                            )}
                        </Pressable>
                    );
                })}
            </View>
        </View>
    );
};

export default SelectShapeSection;
