import {Platform} from 'react-native';

// PROD
export const url = 'https://www.fadfadah.net/api/v1';
export const domain = 'https://fadfadah.net';
export const network_domain = 'https://fadfadah.net';
export const dynamic_link_domain = `https://chat.fadfadah.net`;

// TEST
// export const url = 'http://192.168.68.131:8000/api/v1';
// export const domain = 'http://192.168.68.131:8000';
// export const dynamic_link_domain = 'https://chat.fadfadah.net';
// export const network_domain = 'http://192.168.68.131:3000'; // IOS + Detox

// GENERAL
export const NUMBER_OF_WANTED_MESSAGES = 30;

export const TESTING_ACCOUNTS = {
    default: {
        email: 'e2e@test.test',
        password: '112233',
        name: 'E2E TESTING',
        user_link: 'FXYaYi4eUQ',
    },
};

export const HEADLESS_PUPPETEER = true;

export const isWeb = Platform.OS === 'web';
