import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        {...props}
    >
        <Path d="m404.5 62.6 24.8-3.1c11.1-1.4 19-11.5 17.7-22.7-1.3-10.3-9.7-18.7-22.7-17.7l-74.6 9.3c-11.1 1.4-19 11.5-17.7 22.6l9.2 75.6c1.4 11.1 11.5 19.1 22.6 17.7 11.1-1.4 19.1-11.5 17.7-22.7l-3.4-28.1c70.1 52 99.2 142.2 72.3 225.7-26.9 83.5-105.9 140.8-193.2 141-12.9 0-21.1 8.6-21.4 19.8-.2 11.2 7.8 20.8 21.4 20.8 104.8 0 199.7-68.9 231.9-169.2 32-99.1-2-206.4-84.6-269zM170.7 385.3c-1.4-11.1-11.5-19.1-22.6-17.7-11.1 1.4-19.1 11.5-17.7 22.7l3.4 28.1c-70.1-52-99.2-142.2-72.3-225.7 26.9-83.5 105.9-140.8 193.2-141 12.9 0 21.1-8.6 21.4-19.8.2-11.2-7.8-20.8-21.4-20.8C150 11 55.1 79.9 22.9 180.2c-32 99.3 2 206.6 84.6 269.2l-24.8 3.1c-11.1 1.4-19 11.5-17.7 22.7 1.3 10.3 9.7 18.7 22.7 17.7l74.6-9.3c11.1-1.4 19-11.5 17.7-22.6l-9.3-75.7z" />
    </Svg>
);

export default SvgComponent;
