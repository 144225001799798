import {NavigationProp} from '@react-navigation/native';
import React from 'react';
import {Image, View} from 'react-native';
import {Theme} from '../../../assets/Colors';
import lang from '../../../localization';
import {getDarkModeHomeButtonTextColor} from '../../../modules/app/theme';
import {SCREENS} from '../../../screens/Screens';
import {MainTabSection, MainSectionBody} from '../MainTabBody';

const diamondIcon = require('../../../assets/images/diamondIcon.png');

const SubscribeSection = ({
    theme,
    color,
    navigation,
}: {
    theme: Theme;
    color: string;
    navigation: NavigationProp<any>;
}) => {
    return (
        <MainTabSection
            onPress={() => {
                navigation.navigate(SCREENS.SUBSCRIPTION);
            }}
            mainColor={color}
            theme={theme}
        >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image
                    source={diamondIcon}
                    style={{
                        height: 14,
                        width: 14,
                        marginEnd: 10,
                        tintColor: getDarkModeHomeButtonTextColor(
                            color,
                        ) as string,
                    }}
                    resizeMode="contain"
                />
                <MainSectionBody
                    style={{marginTop: -2}}
                    text={lang().subscribe_to_remove_ads}
                    color={color}
                    theme={theme}
                />
            </View>
        </MainTabSection>
    );
};

export default SubscribeSection;
