import React, {useState, useEffect} from 'react';
import {Animated} from 'react-native';

const FadeInView = props => {
    const [fadeAnim] = useState(new Animated.Value(0)); // Initial value for opacity: 0
    const {duration, style} = props;

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: duration || 500,
            useNativeDriver: false,
        }).start();
    }, [fadeAnim, duration]);

    return (
        <Animated.View // Special animatable View
            style={{
                ...style,
                opacity: fadeAnim, // Bind opacity to animated value
            }}
        >
            {props.children}
        </Animated.View>
    );
};

export default FadeInView;
