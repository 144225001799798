import React from 'react';
import {
    SafeAreaView,
    StyleSheet,
    View,
    TouchableOpacity,
    Modal,
    Image,
} from 'react-native';
import {connect} from 'react-redux';
import ImageViewer from 'react-native-image-zoom-viewer';
import store from '../modules/redux/store';
import {actions as conversationActions} from '../modules/conversation';
import Constants from '../assets/Constants';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {useTheme} from '../modules/app/theme';
import {useLocalaization} from '../modules/app/localization';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const backArrow = require('../assets/images/backArrow.png');

function ImageViewerScreen({route, navigation}) {
    const theme = useTheme();
    const localization = useLocalaization();

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    const {imageURL} = route.params;

    const images = [
        {
            url: imageURL,
        },
    ];

    return (
        <SafeAreaView style={{...styles.container}}>
            <StatusBarAware theme={theme} />
            <Modal
                visible
                transparent
                animationType="none"
            >
                <View style={{height: '100%', zIndex: 1}}>
                    <ImageViewer
                        imageUrls={images}
                        enableSwipeDown
                        onSwipeDown={() => {
                            goBackToPreviousScreen();
                        }}
                        backgroundColor={theme.BACKGROUND}
                    />
                </View>
                <View
                    style={{
                        position: 'absolute',
                        top: 20,
                        height: 100,
                        zIndex: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        start: 0,
                    }}
                >
                    <TouchableOpacity
                        accessibilityHint="Go back to previous screen"
                        accessibilityLabel="Go back to previous screen"
                        onPress={() => {
                            goBackToPreviousScreen();
                        }}
                        style={{
                            paddingHorizontal: 25,
                            borderBottomEndRadius: 100,
                            borderTopEndRadius: 100,
                            backgroundColor: theme.PRIMARY,
                            paddingStart: 10,
                            paddingEnd: 20,
                            height: 48,
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...Constants.elevation.two,
                        }}
                    >
                        <Image
                            source={backArrow}
                            style={{
                                height: 22,
                                width: 22,
                                transform: [
                                    {
                                        scaleX:
                                            localization.direction === 'rtl'
                                                ? -1
                                                : 1,
                                    },
                                ],
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </Modal>
        </SafeAreaView>
    );
}

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    getUserDataFromUserLink: data =>
        conversationActions.getUserDataFromUserLink(data)(dispatch, getState),
    createConversation: data =>
        conversationActions.createConversation(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    user: state.authReducer.user,
    isSubscribed: state.subscriptionReducer.isSubscribed,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewerScreen);
