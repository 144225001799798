import {Platform} from 'react-native';
import remoteConfig from '../app/react-native-firebase-remote-config';
import {RemoteConfig} from '../../types/helpers/remoteConfig';
import defaultValues from './defaultValues';

export function loadRemoteConfig() {
    return async () => {
        // @ts-ignore
        global.remoteConfig = defaultValues;

        if (Platform.OS === 'web') {
            return;
        }

        await remoteConfig().setConfigSettings({
            minimumFetchIntervalMillis: __DEV__ ? 0 : 4 * 60 * 60 * 1000, // cache for 2 hours
        });

        remoteConfig()
            .setDefaults(defaultValues)
            .then(() => remoteConfig().fetchAndActivate())
            .then(fetchedRemotely => {
                if (__DEV__) {
                    if (fetchedRemotely) {
                        console.log('[REMOTE CONFIG SOURCE]: backend.');
                    } else {
                        console.log('[REMOTE CONFIG SOURCE]: local configs');
                    }
                }
            })
            .then(() => {
                const parameters = remoteConfig().getAll();
                const remoteConfigs: RemoteConfig = {};

                Object.entries(parameters).forEach($ => {
                    const [key, entry] = $;
                    remoteConfigs[key] = entry.asString();
                });
                // @ts-ignore
                global.remoteConfig = remoteConfigs;
                if (__DEV__) {
                    // @ts-ignore
                    console.log('[REMOTE CONFIG]:', global.remoteConfig);
                }
            })
            .catch(e => {
                console.log({e});
            });
    };
}

export function getRemoteConfig(key: string) {
    // @ts-ignore
    return global.remoteConfig[key];
}
