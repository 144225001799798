import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    View,
    KeyboardAvoidingView,
    Dimensions,
    Platform,
    StyleSheet,
    I18nManager,
    TextInput,
    Image,
    TouchableOpacity,
} from 'react-native';
import debounce from 'lodash.debounce';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {SlideFromBottom} from '../Animations';
import {mediumClick} from '../../modules/app/vibrations';
import {hasBadWords} from '../../modules/helpers/checkBadWords';
import {SCREENS} from '../../screens/Screens';
import {useLocalaization} from '../../modules/app/localization';

const SendMessageIcon = require('../../assets/images/sendMessageIcon.png');
const settingsFilledIcon = require('../../assets/images/settingsFilledIcon.png');
const lockIcon = require('../../assets/images/lockIcon.png');

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const styles = StyleSheet.create({
    containerWrapper: {
        borderTopWidth: 0.3,
        borderTopColor: Constants.borderColor.light,
        marginTop: Platform.isPad ? 20 : 0,
        alignItems: 'center',
    },
    container: {
        backgroundColor: 'white',
        justifyContent: 'center',
        paddingStart: 10,
        width: '100%',
        maxWidth: 700,
        paddingEnd: 30,
        paddingVertical: 2,
    },
    mainInputWrapper: {
        marginVertical: 10,
        borderRadius: 18,
        height: 40,
        width: '80%',
    },
    mainInputStyle: {
        fontSize: 12,
        borderRadius: 18,
        height: 40,
        borderColor: Constants.borderColor.light,
        borderWidth: 0.5,
        backgroundColor: Constants.colors.inputBackground,
        paddingHorizontal: 10,
        fontFamily: Constants.fonts.Regular,
        color: '#262626',
    },
    sendButtonContainer: {
        // backgroundColor: 'purple',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: '4%',
    },
    sendButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 53,
        height: 53,
        borderRadius: 100,
    },
    sendMessageIcon: {
        width: 29,
        height: 29,
        resizeMode: 'contain',
        position: 'relative',
        start: I18nManager.isRTL ? -1 : 1,
    },
    settingsButtonWrapper: {
        position: 'absolute',
        end: 7,
        top: 1,
    },
    settingsButton: {
        width: 38,
        height: 38,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    settingsIcon: {
        width: 25,
        height: 25,
    },
});

const ConversationFooter = ({
    hasSkippedLogin,
    navigation,
    showSettingsMenu,
    activeConversation,
    setConversationSettingsModalVisible,
    sendMessage,
    onTyping = () => {},
    theme,
    setAlert,
}) => {
    const [messageText, setMessageText] = useState('');
    const messageInputRef = useRef(React.createRef());
    const localization = useLocalaization();

    useEffect(() => {
        setMessageText('');
    }, [activeConversation]);

    const onSendMessage = () => {
        if (hasSkippedLogin) {
            navigation.navigate(SCREENS.LOGIN, {
                isCompleteSignup: true,
            });
            return;
        }

        if (messageText.length > 0) {
            if (hasBadWords(messageText)) {
                setAlert({
                    show: true,
                    message: lang().warn_message_contains_bad_word,
                    confirmText: lang().confirm_sending_bad_word,
                    confirmColor: theme.DANGER,
                    cancelText: lang().cancel_sending_bad_word,
                    theme,
                    onConfirmPressed: () => {
                        sendMessage({text: messageText});
                        setMessageText('');
                        setAlert(null);
                    },
                    onCancelPressed: () => {
                        setAlert(null);
                    },
                    onDismiss: () => {
                        setAlert(null);
                    },
                });
            } else {
                sendMessage({text: messageText});
                setMessageText('');
            }
        }
        messageInputRef.current.focus();
    };

    const keyboardAvoidingViewOffset = () => {
        if (Platform.OS === 'android') {
            return 0;
        }

        const addAnonymousOffset = offset => offset + 72;

        if (Platform.isPad) {
            return addAnonymousOffset(22);
        }

        if (PHONE_HAS_A_NOTCH) {
            return addAnonymousOffset(42);
        }

        return addAnonymousOffset(18);
    };

    const memoOnTyping = useCallback(
        debounce(onTyping, 3000, {
            leading: true,
            trailing: false,
            maxWait: 2500,
        }),
        [],
    );

    return (
        <KeyboardAvoidingView
            // enabled={Platform.OS == = 'ios'}
            behavior={Platform.OS === 'ios' ? 'position' : 'height'}
            style={{paddingBottom: Platform.OS === 'ios' ? 10 : 0}}
            keyboardVerticalOffset={keyboardAvoidingViewOffset()}
        >
            <SlideFromBottom
                disabled
                style={{
                    ...styles.containerWrapper,
                    borderTopColor: theme.SECONDARY,
                }}
            >
                <View
                    style={{
                        ...styles.container,
                        backgroundColor: theme.BACKGROUND,
                        flexDirection:
                            localization.direction === 'rtl'
                                ? 'row'
                                : 'row-reverse',
                    }}
                >
                    <View style={styles.sendButtonContainer}>
                        <TouchableOpacity
                            onPress={onSendMessage}
                            testID="sendMessageButton"
                        >
                            <View
                                colors={
                                    hasSkippedLogin
                                        ? Constants.colors.topBarTitle
                                        : Constants.gradient.conversation
                                }
                                style={{
                                    ...styles.sendButton,
                                }}
                            >
                                {hasSkippedLogin ? (
                                    <Image
                                        source={lockIcon}
                                        style={{
                                            ...styles.settingsIcon,
                                            tintColor: theme.TEXT,
                                        }}
                                    />
                                ) : (
                                    <Image
                                        source={SendMessageIcon}
                                        style={{
                                            ...styles.sendMessageIcon,
                                            tintColor: theme.PRIMARY,
                                        }}
                                    />
                                )}
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.mainInputWrapper}>
                        <TextInput
                            placeholder={lang().sendMessagePlaceholder}
                            style={{
                                ...styles.mainInputStyle,
                                textAlign: lang().align,
                                writingDirection: 'rtl',
                                backgroundColor: theme.TERTIARY,
                                borderColor: theme.SECONDARY,
                                color: theme.TEXT,
                            }}
                            autoCorrect={false}
                            autoCompleteType="off"
                            ref={messageInputRef}
                            value={messageText}
                            returnKeyType="send"
                            selectionColor={theme.TEXT_PRIMARY}
                            enablesReturnKeyAutomatically
                            placeholderTextColor={theme.TEXT_MUTED}
                            allowFontScaling={false}
                            blurOnSubmit={false}
                            onChangeText={text => {
                                if (text.length <= 400) {
                                    memoOnTyping();
                                    setMessageText(text);
                                }
                            }}
                            onSubmitEditing={onSendMessage}
                            testID="messageTextInput"
                        />
                        {showSettingsMenu && messageText.length === 0 && (
                            <View style={styles.settingsButtonWrapper}>
                                <TouchableOpacity
                                    style={styles.settingsButton}
                                    onPress={() => {
                                        setConversationSettingsModalVisible(
                                            true,
                                        );
                                        mediumClick();
                                    }}
                                    testID="conversationSettingsButton"
                                >
                                    <Image
                                        source={settingsFilledIcon}
                                        style={{
                                            ...styles.settingsIcon,
                                            tintColor: theme.SECONDARY,
                                        }}
                                    />
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                </View>
            </SlideFromBottom>
        </KeyboardAvoidingView>
    );
};

export default ConversationFooter;
