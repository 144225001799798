import React, {useEffect, useState} from 'react';
import {
    Alert,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    StyleSheet,
    useWindowDimensions,
    View,
} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {CountryCode} from 'react-native-country-picker-modal/lib';
import {NavigationProp} from '../types/navigation/navigationProp';
import {ReducerState} from '../types/reducer/reducer';
import {ActionParameter} from '../types/helpers/helpers';
import {ReduxDispatch, ReduxGetState} from '../types/redux/redux';
import store from '../modules/redux/store';
import {actions as randomActions} from '../modules/random';
import lang from '../localization';
import {TopBar} from '../components/Shared';
import {normalize, checkIfAsyncStorageWasUsedBefore} from '../modules/helpers';
import {FadeInViewIOS} from '../components/Animations';
import {
    LeftConversationsCounter,
    LoadingRandomConversations,
    RandomConversationFooter,
    ExternalUserInfo,
} from '../components/RandomConversations';
import ReportModal from '../components/Modals/ReportModal';
import InterstitialAdController from '../components/RandomConversations/InterstitialAdController';
import RewardedAdController from '../components/RandomConversations/RewardedAdController';
import RandomConversationsWelcomeModal from '../components/Modals/RandomConversationsWelcomeModal';
import {getRemoteConfig} from '../modules/remoteConfig/actions';
import {RandomUser} from '../types/data/randomUser';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {useLocalaization} from '../modules/app/localization';
import {isWeb} from '../../env';
import {logEvent} from '../modules/app/tracking';

const NUMBER_OF_REWARDED_RANDOM_CONVERSATION = isWeb ? 0 : 5;

const waveDesign = require('../assets/images/waveDesign.png');

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const styles = StyleSheet.create({
    container: {
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    initiateRandomConversationsModule: () =>
        randomActions.initiateRandomConversationsModule()(),
    getRandomUsers: (
        data: ActionParameter<typeof randomActions.getRandomUsers>,
    ) => randomActions.getRandomUsers(data)(),
    reportRandomUser: (
        data: ActionParameter<typeof randomActions.reportRandomUser>,
    ) => randomActions.reportRandomUser(data)(),
    createRandomConversation: (
        data: ActionParameter<typeof randomActions.createRandomConversation>,
    ) => randomActions.createRandomConversation(data)(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    loggedUser: state.authReducer.user,
    isSubscribed: state.subscriptionReducer.isSubscribed,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
};

function RandomConversation({
    navigation,
    initiateRandomConversationsModule,
    getRandomUsers,
    reportRandomUser,
    createRandomConversation,
    isSubscribed,
    loggedUser,
}: NavigationProp<{isCompleteSignup: boolean}> & Props) {
    const theme = useTheme();
    const {width} = useWindowDimensions();
    const localization = useLocalaization();

    const userHasSkippedLogin = !!(loggedUser && loggedUser.hasSkippedLogin);
    const [
        isRandomConversationsModuleInitiated,
        setIsRandomConversationsModuleInitiated,
    ] = useState(false);
    const [numberOfLeftConversations, setNumberOfLeftConversations] =
        useState(0);
    const [
        numberOfLeftRewardedConversations,
        setNumberOfLeftRewardedConversations,
    ] = useState(0);
    const [shouldShowRewardedAd, setShouldShowRewardedAd] = useState(false);
    const [isRewardedAdLoaded, setIsRewardedAdLoaded] = useState(false);
    const [currentRandomUser, setCurrentRandomUser] = useState<
        RandomUser | undefined
    >(undefined);
    const [randomUsersQueue, setRandomUsersQueue] = useState<RandomUser[]>([]);
    const [isReportModalVisible, setIsReportModalVisible] = useState(false);
    const [isCreatingConversation, setIsCreatingConversation] = useState(false);
    const [numberOfSearches, setNumberOfSearches] = useState(0);
    const [isRandomWelcomeModalVisible, setIsRandomWelcomeModuleInitiated] =
        useState(false);
    const [searchCountry, setSearchCountry] = useState<CountryCode | undefined>(
        undefined,
    );

    const canMakeRandomConversations = () => {
        return numberOfLeftConversations > 0 || isSubscribed;
    };

    const canGetRewardedConversations = () => {
        return numberOfLeftRewardedConversations > 0 && isRewardedAdLoaded;
    };

    const hideRandomUser = ({userLink}: {userLink: string}) => {
        AsyncStorage.getItem('hiddenRandomUsers').then(
            hiddenRandomUsersStorage => {
                if (hiddenRandomUsersStorage === null) {
                    AsyncStorage.setItem(
                        'hiddenRandomUsers',
                        JSON.stringify([userLink]),
                    );
                } else {
                    let hiddenRandomUsersArray = JSON.parse(
                        hiddenRandomUsersStorage,
                    );
                    hiddenRandomUsersArray = [
                        ...hiddenRandomUsersArray,
                        userLink,
                    ];
                    AsyncStorage.setItem(
                        'hiddenRandomUsers',
                        JSON.stringify(hiddenRandomUsersArray),
                    );
                }
            },
        );
    };

    const onCreateConversation = () => {
        if (userHasSkippedLogin) {
            navigation.navigate(SCREENS.LOGIN, {
                isCompleteSignup: true,
            });
            return;
        }

        if (currentRandomUser && Boolean(currentRandomUser.user_link)) {
            setIsCreatingConversation(true);
            const createRandomConversationError = () => {
                if (isWeb) {
                    window.alert(lang().error_happened);
                }
                Alert.alert(
                    lang().error_happened,
                    '',
                    [
                        {
                            text: lang().skip,
                            onPress: () => {},
                        },
                    ],
                    {cancelable: true},
                );
            };
            createRandomConversation({userLink: currentRandomUser.user_link})
                .then(({data}: {data: any}) => {
                    setIsCreatingConversation(false);
                    if (data.code === 1) {
                        hideRandomUser({userLink: currentRandomUser.user_link});
                        setNumberOfLeftConversations(
                            numberOfLeftConversations - 1,
                        );
                        searchForAnotherRandomUser();
                        const {conversation} = data.data;
                        logEvent('createRandomConversation');
                        navigation.push('Conversation', {
                            conversation,
                            source: 'RandomConversation',
                        });
                    } else {
                        createRandomConversationError();
                    }
                })
                .catch(() => {
                    setIsCreatingConversation(false);
                    createRandomConversationError();
                });
        }
    };

    const goBackToPreviousScreen = () => {
        navigation.navigate(SCREENS.WELCOME);
    };

    useEffect(() => {
        setRandomUsersQueue([]);
        setCurrentRandomUser(undefined);
        setIsRandomConversationsModuleInitiated(false);

        if (searchCountry) {
            AsyncStorage.setItem('searchCountry', searchCountry);
        }
    }, [searchCountry]);

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${lang().screenTitles.createRandomConversation}`,
        });
    }, []);

    const handleError = (errorTitle: string, shouldRedirectToHome = true) => {
        if (isWeb) {
            window.alert(errorTitle);
        }
        Alert.alert(
            errorTitle,
            '',
            [
                {
                    text: lang().skip,
                    onPress: () => {},
                },
            ],
            {cancelable: true},
        );
        if (shouldRedirectToHome) {
            goBackToPreviousScreen();
        }
    };

    const searchForAnotherRandomUser = () => {
        setNumberOfSearches(numberOfSearches + 1);
        logEvent('searchForNewRandomUser');
        setCurrentRandomUser(undefined);
        setTimeout(() => {
            const users = [...randomUsersQueue];
            if (users.length > 0) {
                setCurrentRandomUser(users.pop());
                setRandomUsersQueue([...users]);
            } else {
                fillRandomUsers();
            }
        }, 500);
    };

    const checkIfCountryIsAvailable = ({
        users,
        countryCode,
    }: {
        users: RandomUser[];
        countryCode: CountryCode;
    }) => {
        const hasUsersWithSameCountryCode = users.some(user => {
            if (user.country_code === countryCode) {
                return true;
            }
            return false;
        });

        if (!hasUsersWithSameCountryCode) {
            handleError(lang().error_no_users_with_selected_country, false);
        }
    };

    const fillRandomUsers = async () => {
        AsyncStorage.getItem('hiddenRandomUsers').then(
            async hiddenRandomUsersStorage => {
                const hiddenRandomUsers =
                    hiddenRandomUsersStorage === null
                        ? []
                        : JSON.parse(hiddenRandomUsersStorage);

                // @ts-ignore
                const storageSearchCountry: CountryCode | null =
                    await AsyncStorage.getItem('searchCountry');
                let countryCode =
                    storageSearchCountry !== null
                        ? storageSearchCountry
                        : loggedUser?.country_code;
                countryCode = countryCode !== undefined ? countryCode : 'EG';

                setNumberOfSearches(numberOfSearches + 1);
                getRandomUsers({
                    isFirstSearch: numberOfSearches === 0,
                    searchCountry: countryCode,
                })
                    .then(async ({data}: {data: any}) => {
                        if (data.code !== 1) {
                            handleError(lang().error_happened);
                        } else {
                            let {users} = data.data;
                            if (!Array.isArray(users) || users.length === 0) {
                                handleError(lang().no_online_random_users);
                            }
                            users = users.filter((user: RandomUser) => {
                                return !hiddenRandomUsers.includes(
                                    user.user_link,
                                );
                            });
                            if (users.length === 0) {
                                return handleError(
                                    lang().no_online_random_users,
                                );
                            }

                            if (countryCode !== undefined) {
                                checkIfCountryIsAvailable({users, countryCode});
                            }

                            setCurrentRandomUser(users.pop(0));
                            setRandomUsersQueue(
                                __DEV__
                                    ? [
                                          ...users,
                                          {
                                              user_link: 'eg2w5VV2aP',
                                              name: 'Wadah Esam',
                                              profile_picture_path:
                                                  '/images/mobileDefaultProfilePicture.jpg',
                                          },
                                      ]
                                    : [...users],
                            );
                        }
                    })
                    .catch(() => {
                        // console.log({error});
                        handleError(lang().error_happened);
                    });
            },
        );
    };

    useEffect(() => {
        if (!isRandomConversationsModuleInitiated) {
            initiateRandomConversationsModule()
                .then(({data}: {data: any}) => {
                    if (data.code !== 1) {
                        throw new Error('wrong response');
                    }
                    // got the data
                    const {
                        isBlocked,
                        blockExpiresAt,
                        blockReason,
                        blockText,
                        numberOfLeftFreeRandomConversations,
                        numberOfAllowedFreeRandomConversations,
                    } = data.data;
                    if (isBlocked) {
                        goBackToPreviousScreen();
                        setTimeout(() => {
                            navigation.navigate(SCREENS.BLOCK_REASON, {
                                blockReason,
                                blockText,
                                blockExpiresAt,
                            });
                        }, 200);

                        // return blockAlert({blockExpiresAt, blockReason});
                    } else {
                        checkIfAsyncStorageWasUsedBefore({
                            key: 'RandomConversation',
                        }).then(wasUsedBefore => {
                            // setIsRandomWelcomeModuleInitiated(true);
                            if (
                                getRemoteConfig(
                                    'show_welcome_random_conversations_modal',
                                ) === 'true' &&
                                !wasUsedBefore &&
                                !isSubscribed &&
                                numberOfLeftFreeRandomConversations ===
                                    numberOfAllowedFreeRandomConversations
                            ) {
                                setIsRandomWelcomeModuleInitiated(true);
                            }
                        });

                        setTimeout(() => {
                            const getInitialNumberOfLeftConversations = () => {
                                if (
                                    numberOfLeftFreeRandomConversations >=
                                    NUMBER_OF_REWARDED_RANDOM_CONVERSATION
                                ) {
                                    return (
                                        numberOfLeftFreeRandomConversations -
                                        NUMBER_OF_REWARDED_RANDOM_CONVERSATION
                                    );
                                }
                                return 0;
                            };

                            const getInitialNumberOfRewardedLeftConversations =
                                () => {
                                    if (
                                        numberOfLeftFreeRandomConversations <=
                                        NUMBER_OF_REWARDED_RANDOM_CONVERSATION
                                    ) {
                                        return numberOfLeftFreeRandomConversations;
                                    }
                                    return NUMBER_OF_REWARDED_RANDOM_CONVERSATION;
                                };

                            setNumberOfLeftRewardedConversations(
                                getInitialNumberOfRewardedLeftConversations(),
                            );
                            setNumberOfLeftConversations(
                                getInitialNumberOfLeftConversations(),
                            );
                            fillRandomUsers();
                            setIsRandomConversationsModuleInitiated(true);
                        }, 500);
                    }
                })
                .catch(() => {
                    goBackToPreviousScreen();
                });
        }
    }, [isRandomConversationsModuleInitiated]);

    if (!isRandomConversationsModuleInitiated) {
        return (
            <LoadingRandomConversations
                theme={theme}
                goBackToPreviousScreen={goBackToPreviousScreen}
            />
        );
    }

    return (
        <SafeAreaView style={styles.container}>
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={lang().create_random_conversation}
            />
            <FadeInViewIOS
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Fixed style */}
                <LinearGradient
                    useAngle
                    angle={200}
                    colors={theme.GRADIENTS.PRIMARY}
                    style={{
                        position: 'absolute',
                        height: '70%',
                        width: '100%',
                        bottom: 0,
                    }}
                >
                    <Image
                        source={waveDesign}
                        style={{
                            height: '80%',
                            width: width / 1.2,
                            resizeMode: 'contain',
                            position: 'absolute',
                            transform: [
                                {scaleX: localization?.direction ? 1 : -1},
                            ],
                            bottom: PHONE_HAS_A_NOTCH ? '-20%' : '-10%',
                            opacity: theme.MODE === 'dark' ? 0.3 : 1,
                            end: '10%',
                        }}
                    />
                </LinearGradient>

                {/* Profile Info section */}
                <View
                    style={{
                        flex: 1.1,
                        paddingTop: normalize(50),
                        backgroundColor: theme.BACKGROUND,
                        width: '105%',
                        borderRadius: 100000,
                        zIndex: 3,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {/* Left Conversations */}
                    <View style={{zIndex: 4, position: 'absolute', top: 0}}>
                        <LeftConversationsCounter
                            theme={theme}
                            setShouldShowRewardedAd={setShouldShowRewardedAd}
                            canGetRewardedConversations={canGetRewardedConversations()}
                            numberOfLeftConversations={
                                numberOfLeftConversations
                            }
                            isSubscribed={isSubscribed}
                        />
                    </View>
                    <ExternalUserInfo
                        theme={theme}
                        user={currentRandomUser}
                    />
                </View>

                <RandomConversationFooter
                    theme={theme}
                    navigation={navigation}
                    setShouldShowRewardedAd={setShouldShowRewardedAd}
                    canGetRewardedConversations={canGetRewardedConversations()}
                    canMakeRandomConversations={canMakeRandomConversations()}
                    searchForAnotherRandomUser={searchForAnotherRandomUser}
                    currentRandomUser={currentRandomUser}
                    setIsReportModalVisible={setIsReportModalVisible}
                    isCreatingConversation={isCreatingConversation}
                    onCreateConversation={onCreateConversation}
                    isSubscribed={isSubscribed}
                    setSearchCountry={setSearchCountry}
                    searchCountry={searchCountry}
                />
            </FadeInViewIOS>
            <ReportModal
                theme={theme}
                show={isReportModalVisible}
                setShow={setIsReportModalVisible}
                currentRandomUser={currentRandomUser}
                reportRandomUser={reportRandomUser}
                hideRandomUser={hideRandomUser}
                searchForAnotherRandomUser={searchForAnotherRandomUser}
            />
            <RewardedAdController
                isRewardedAdLoaded={isRewardedAdLoaded}
                setIsRewardedAdLoaded={setIsRewardedAdLoaded}
                shouldShowRewardedAd={shouldShowRewardedAd}
                setShouldShowRewardedAd={setShouldShowRewardedAd}
                defaultNumberOfRewardedRandomConversations={
                    NUMBER_OF_REWARDED_RANDOM_CONVERSATION
                }
                setNumberOfLeftConversations={setNumberOfLeftConversations}
            />
            <InterstitialAdController
                numberOfSearches={numberOfSearches}
                isSubscribed={isSubscribed}
            />
            <RandomConversationsWelcomeModal
                theme={theme}
                show={isRandomWelcomeModalVisible}
                setShow={setIsRandomWelcomeModuleInitiated}
            />
        </SafeAreaView>
    );
}

export default connector(RandomConversation);
