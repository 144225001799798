import React from 'react';
import {View} from 'react-native';
// @ts-ignore
import FastImage from '../../../PackageWrappers/Components/FastImage';
import Constants from '../../assets/Constants';
import {getRemoteConfig} from '../../modules/remoteConfig/actions';

const CountryFlagAvatar = ({
    countryCode = undefined,
    size = 25,
}: {
    countryCode?: string;
    size?: number;
}) => {
    if (countryCode === undefined) {
        return <></>;
    }

    if (getRemoteConfig('show_country_flag') !== 'true') {
        return <></>;
    }

    return (
        <View
            style={{
                marginStart: !countryCode ? 0 : 13,
            }}
        >
            <FastImage
                resizeMode="contain"
                style={{
                    height: size,
                    width: size,
                    borderColor: Constants.colors.main.light,
                    borderWidth: 0.2,
                    borderRadius: 1000,
                }}
                source={{
                    uri:
                        countryCode !== null
                            ? `https://www.fadfadah.net/images/flags/64/${countryCode.toLowerCase()}_64.png`
                            : '',
                }}
            />
        </View>
    );
};

export default CountryFlagAvatar;
