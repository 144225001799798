import React, {useEffect, useState} from 'react';
import {
    ScrollView,
    TouchableOpacity,
    Image,
    Platform,
    RefreshControl,
    View,
    Dimensions,
} from 'react-native';
import * as Linking from 'expo-linking';
import {connect, ConnectedProps} from 'react-redux';
import RNRestart from 'react-native-restart';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from '@react-navigation/native';
import store from '../../modules/redux/store';
import {actions as appActions} from '../../modules/app';
import Constants from '../../assets/Constants';
import {constructUserLink, normalize} from '../../modules/helpers';
import {mediumClick} from '../../modules/app/vibrations';
import {ReduxDispatch, ReduxGetState} from '../../types/redux/redux';
import {ReducerState} from '../../types/reducer/reducer';
import {ActionParameter} from '../../types/helpers/helpers';
import {Theme} from '../../assets/Colors';
import MainTabHeaderSection from './MainTabHeaderSection';
import MainTabBody from './MainTabBody';
import {SCREENS} from '../../screens/Screens';
import {T} from '../Shared';
import {showSnackBarGlobal} from '../../modules/app/actions';
import lang from '../../localization';
import RateUsModal from '../Modals/RateUsModal';
import {
    getDarkModeHomeButtonSectionBackground,
    getDarkModeHomeButtonTextColor,
} from '../../modules/app/theme';
import CustomAlert, {CustomAlertProps} from '../Shared/CustomAlert';

const settingsIcon = require('../../assets/images/settingsIcon.png');
const starIcon = require('../../assets/images/starIcon.png');
const whatsAppIcon = require('../../assets/images/whatsappIcon.png');

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' &&
    Dimensions.get('screen').height >= 812 &&
    !Platform.isPad;

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    showSnackBar: (data: ActionParameter<typeof appActions.showSnackBar>) =>
        appActions.showSnackBar(data)(),
    shareLink: (data: ActionParameter<typeof appActions.shareLink>) =>
        appActions.shareLink(data)(dispatch, getState),
    changeProfilePicture: (
        data: ActionParameter<typeof appActions.changeProfilePicture>,
    ) => appActions.changeProfilePicture(data)(dispatch, getState),
    sendRatingToSlack: (
        data: ActionParameter<typeof appActions.sendRatingToSlack>,
    ) => appActions.sendRatingToSlack(data)(dispatch, getState),
    openChangeUsernameMenu: () =>
        appActions.openChangeUsernameMenu()(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    user: state.authReducer.user,
    config: state.authReducer.config,
    isNotificationGranted: state.appReducer.isNotificationGranted,
    isProfilePictureUploading: state.appReducer.isProfilePictureUploading,
    isSubscribed: state.subscriptionReducer.isSubscribed,
    converesations: state.conversationsReducer.conversations,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
    goToTab: ({index}: {index: number}) => void;
    theme: Theme;
};

const MainTab = ({
    user,
    config,
    isSubscribed,
    changeProfilePicture,
    isProfilePictureUploading,
    openChangeUsernameMenu,
    goToTab,
    isNotificationGranted,
    theme,
    converesations,
}: Props) => {
    const navigation = useNavigation();

    const conversationsLength = converesations?.length || 0;
    const [alert, setAlert] = useState<CustomAlertProps | null>(null);

    if (!user || !config) {
        return <></>;
    }

    const link = constructUserLink(user, config);

    return (
        <View style={{flex: 1}}>
            <SettingsButton
                theme={theme}
                navigation={navigation}
            />

            <View
                style={{
                    width: '100%',
                    zIndex: 2,
                }}
            >
                <MainTabHeaderSection
                    changeProfilePicture={changeProfilePicture as any}
                    isProfilePictureUploading={isProfilePictureUploading}
                    openChangeUsernameMenu={openChangeUsernameMenu}
                    user={user}
                    theme={theme}
                    isSubscribed={isSubscribed}
                    setAlert={setAlert}
                />
            </View>
            <ScrollView
                nestedScrollEnabled
                disableScrollViewPanResponder={false}
                // stickyHeaderIndices={[0]}
                alwaysBounceVertical={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    paddingBottom: 50,
                }}
                refreshControl={
                    <RefreshControl
                        style={{zIndex: 1000}}
                        enabled
                        refreshing={false}
                        tintColor={Platform.select({
                            ios: theme.TEXT,
                            android: theme.PRIMARY,
                        })}
                        progressBackgroundColor={Platform.select({
                            ios: theme.BACKGROUND,
                            android: 'white',
                        })}
                        onRefresh={() => {
                            AsyncStorage.setItem(
                                'is_manually_restarted',
                                'true',
                            ).then(() => {
                                RNRestart.Restart();
                            });
                        }}
                    />
                }
            >
                <View style={{width: '100%', alignItems: 'center'}}>
                    <MainTabBody
                        theme={theme}
                        link={link}
                        navigation={navigation}
                        goToTab={goToTab}
                        isNotificationGranted={isNotificationGranted}
                        isSubscribed={isSubscribed}
                        conversationsLength={conversationsLength}
                    />
                </View>
            </ScrollView>
            <View style={{width: '100%', alignItems: 'center'}}>
                <StickyBottomSection
                    theme={theme}
                    whatsAppNumber={config.whatsapp_contact_number}
                    conversationsLength={conversationsLength}
                    isSubscribed={isSubscribed}
                />
            </View>
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
        </View>
    );
};

const StickyBottomSection = ({
    theme,
    whatsAppNumber,
    conversationsLength,
    isSubscribed = false,
}: {
    theme: Theme;
    whatsAppNumber?: string;
    conversationsLength: number;
    isSubscribed?: boolean;
}) => {
    const [showRateUsSection, setShowRateUsSection] = useState(false);
    const [isRateModalVisible, setIsRateModalVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(async () => {
            const userHasOpenedRatePage = await AsyncStorage.getItem(
                'userHasOpenedRatePage',
            );

            if (conversationsLength > 0 && userHasOpenedRatePage !== 'true') {
                setShowRateUsSection(true);
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [conversationsLength]);

    return (
        <View
            style={{
                height: 40,
                width: '100%',
                maxWidth: 700,
                position: 'absolute',
                bottom: Platform.select({
                    ios: PHONE_HAS_A_NOTCH ? 85 : 90,
                    android: 70,
                    web: 70,
                }),
                flexDirection: 'row',
            }}
        >
            <RateUsModal
                theme={theme}
                isVisible={isRateModalVisible}
                closeModal={() => {
                    setIsRateModalVisible(false);
                }}
                setShowRateUsSection={setShowRateUsSection}
            />
            {showRateUsSection && (
                <TouchableOpacity
                    onPress={() => setIsRateModalVisible(true)}
                    style={{
                        opacity: theme.MODE === 'dark' ? 0.8 : 1,
                        start: 12,
                        position: 'absolute',
                        height: '100%',
                        backgroundColor:
                            theme.MODE === 'dark'
                                ? (getDarkModeHomeButtonSectionBackground(
                                      theme.ORANGE,
                                  ) as string)
                                : theme.BACKGROUND,
                        borderColor: theme.ORANGE,
                        borderWidth: theme.MODE === 'light' ? 0.5 : 0,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingHorizontal: 15,
                        borderRadius: 100,
                    }}
                >
                    <Image
                        source={starIcon}
                        style={{
                            height: 20,
                            width: 20,
                            marginEnd: 10,
                            tintColor:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.ORANGE,
                                      ) as string)
                                    : theme.ORANGE,
                        }}
                        resizeMode="contain"
                    />
                    <T
                        style={{
                            fontFamily: Constants.fonts.SemiBold,
                            fontSize: normalize(11),
                            color:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.ORANGE,
                                      ) as string)
                                    : theme.ORANGE,
                        }}
                    >
                        {lang().rateUsSectionText}
                    </T>
                </TouchableOpacity>
            )}

            {whatsAppNumber && isSubscribed && (
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(
                            `whatsapp://send?phone=${whatsAppNumber}`,
                        ).catch(() => {
                            showSnackBarGlobal({
                                textMessage: lang().you_dont_have_whatsapp,
                                position: 'top',
                                type: 'danger',
                                delay: 1500,
                            });
                        });
                    }}
                    style={{
                        end: 12,
                        position: 'absolute',
                        height: '100%',
                        backgroundColor:
                            theme.MODE === 'dark'
                                ? (getDarkModeHomeButtonSectionBackground(
                                      theme.SUCCESS,
                                  ) as string)
                                : theme.BACKGROUND,
                        flexDirection: 'row',
                        borderColor: theme.SUCCESS,
                        borderWidth: theme.MODE === 'light' ? 0.5 : 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingHorizontal: 15,
                        borderRadius: 100,
                    }}
                >
                    <T
                        style={{
                            fontFamily: Constants.fonts.SemiBold,
                            fontSize: normalize(11),
                            color:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.SUCCESS,
                                      ) as string)
                                    : theme.SUCCESS,
                        }}
                    >
                        {lang().for_help}
                    </T>
                    <Image
                        source={whatsAppIcon}
                        style={{
                            height: 20,
                            width: 20,
                            marginStart: 10,
                            tintColor:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.SUCCESS,
                                      ) as string)
                                    : theme.SUCCESS,
                        }}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            )}
        </View>
    );
};

const SettingsButton = ({
    navigation,
    theme,
}: {
    navigation: any;
    theme: Theme;
}) => {
    const getTopPosition = () => {
        if (PHONE_HAS_A_NOTCH) {
            return 50;
        }
        if (Platform.OS === 'android') {
            return 20;
        }
        return 20;
    };

    return (
        <TouchableOpacity
            accessibilityHint="Settings"
            accessibilityLabel="Settings"
            onPress={() => {
                try {
                    mediumClick();
                    navigation.navigate(SCREENS.SETTINGS as never);
                } catch (e) {
                    //
                }
            }}
            style={{
                height: 40,
                width: 48,
                backgroundColor: theme.TERTIARY,
                marginTop: 10,
                position: 'absolute',
                top: getTopPosition(),
                end: 0,
                paddingStart: 10,
                paddingEnd: 3,
                borderTopStartRadius: 50,
                borderBottomStartRadius: 50,
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 40,
                ...Constants.elevation.two,
                shadowColor: theme.BACKGROUND,
            }}
        >
            <Image
                source={settingsIcon}
                resizeMode="contain"
                style={{
                    tintColor: theme.TEXT_PRIMARY,
                    height: 23,
                    width: 23,
                }}
            />
        </TouchableOpacity>
    );
};

export default connector(MainTab);
