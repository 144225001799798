import React, {useState, useEffect} from 'react';
import {
    SafeAreaView,
    StyleSheet,
    View,
    Dimensions,
    TouchableOpacity,
    ActivityIndicator,
    Platform,
} from 'react-native';
import {connect} from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import store from '../modules/redux/store';
import {actions as conversationActions} from '../modules/conversation';
import Constants from '../assets/Constants';
import {Input, ProfilePicture, T, TopBar} from '../components/Shared';
import lang from '../localization';
import {normalize} from '../modules/helpers';
import {CreateConversationBanner} from '../components/Banners';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {isWeb} from '../../env';

const IMAGE_DIMENSIONS = 100;
const SHARE_TEXT_COLOR = Constants.colors.main.dark;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    backgroundContainer: {
        width: Dimensions.get('window').width, // for full screen
        height: Dimensions.get('window').height, // for full screen
    },
    fixed: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    profileInfoSection: {
        backgroundColor: 'rgba(219, 219, 219, 0.3)',
        width: '85%',
        maxWidth: 500,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 10,
        paddingBottom: normalize(13),
        borderWidth: 0.5,
    },
    profilePictureContainer: {
        height: normalize(IMAGE_DIMENSIONS),
        width: normalize(IMAGE_DIMENSIONS),
        minHeight: 100,
        minWidth: 100,
        borderRadius: 100,
        backgroundColor: Constants.colors.main.dark,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        shadowColor: '#5E5B83',
        shadowOpacity: 0.45,
        shadowRadius: 14,
        shadowOffset: {width: 0, height: 0},
    },
    profilePicture: {
        resizeMode: 'contain',
        height: normalize(IMAGE_DIMENSIONS) - 6,
        width: normalize(IMAGE_DIMENSIONS) - 6,
        borderRadius: 100,
        zIndex: 2,
    },
    usernameSection: {
        width: '100%',
        height: normalize(40),
        marginTop: normalize(5),
        paddingBottom: normalize(5),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30,
    },
    usernameSectionText: {
        fontSize: normalize(19),
        fontFamily: Constants.fonts.Bold,
        color: '#585858',
    },
    createConversationDescriptionSection: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: normalize(8),
        marginBottom: normalize(12),
    },
    createConversationDescriptionText: {
        marginTop: normalize(8),
        fontSize: normalize(14),
        color: SHARE_TEXT_COLOR,
        fontFamily: Constants.fonts.Bold,
    },
    nicknameSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: normalize(13),
        marginHorizontal: 30,
    },
    nicknameText: {
        textAlign: 'center',
        fontSize: normalize(13),
        color: SHARE_TEXT_COLOR,
        fontFamily: Constants.fonts.Bold,
        width: '40%',
    },
    nicknameInput: {
        height: 39,
        width: 200,
        backgroundColor: 'white',
        flexGrow: 1,
        borderRadius: 5,
        ...Constants.elevation.one,
        paddingHorizontal: 10,
        fontSize: 11,
        fontFamily: Constants.fonts.Regular,
        ...Platform.select({
            ios: {paddingTop: 4, paddingBottom: 4},
            android: {paddingBottom: 0},
        }),
        color: '#262626',
    },
    startConversationButton: {
        marginTop: normalize(5),
        paddingHorizontal: 25,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: 8,
    },
    disabledStartConversationButton: {
        opacity: 0.5,
    },
    startConversationText: {
        color: 'white',
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(12),
    },
    logo: {
        height: 80,
        marginTop: 25,
        resizeMode: 'contain',
        opacity: 0.7,
    },
    errorSection: {
        width: '100%',
        paddingHorizontal: 20,
        marginTop: normalize(40),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorText: {
        textAlign: 'center',
        fontSize: normalize(15),
        fontFamily: Constants.fonts.Bold,
        color: '#585858',
    },
});

function CreateConversation({
    route,
    navigation,
    getUserDataFromUserLink,
    createConversation,
    isSubscribed,
}) {
    const theme = useTheme();
    const [userData, setUserData] = useState(undefined);
    const [isError, setIsError] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [nickname, setNickname] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const {url, userLink} = route.params;

    const getUserLink = () => {
        if (userLink) {
            return userLink;
        }
        if (url) {
            return url.split('/')[url.split('/').length - 1];
        }
    };

    const link = getUserLink();

    const isValidNickname = () => {
        return (
            nickname !== undefined &&
            nickname.length >= 2 &&
            nickname.length <= 15
        );
    };

    const goBackToPreviousScreen = () => {
        navigation.navigate(SCREENS.WELCOME);
        // navigation.goBack();
    };

    useEffect(() => {
        getUserDataFromUserLink({userLink: link})
            .then(userDataResponse => {
                setIsError(false);
                setUserData(userDataResponse);
            })
            .catch(error => {
                if (error.message === 'isBlocked') {
                    setIsBlocked(true);
                } else {
                    setIsError(true);
                }
            });
        return () => {};
    }, [link]);

    useEffect(() => {
        navigation.setOptions({
            title: lang().screenTitles.createConversation(userData?.name || ''),
        });
    }, [userData?.name]);

    const onCreateConversation = () => {
        setIsCreating(true);
        createConversation({userLink: link, nickname})
            .then(({conversation}) => {
                setIsError(false);
                setIsCreating(false);
                navigation.navigate(SCREENS.CONVERSATION, {
                    conversation,
                    source: 'CreateConversation',
                });
            })
            .catch(() => {
                setIsCreating(false);
                setIsError(true);
            });
    };

    if (isBlocked) {
        return (
            <SafeAreaView
                style={{...styles.container, backgroundColor: theme.BACKGROUND}}
            >
                <StatusBarAware theme={theme} />
                <TopBar
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    titleText={lang().user_has_blocked_you}
                />
                <View
                    style={{
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <View style={styles.errorSection}>
                        <T style={{...styles.errorText, color: theme.TEXT}}>
                            {lang().user_has_blocked_you}
                        </T>
                    </View>
                </View>
            </SafeAreaView>
        );
    }

    if (isError) {
        return (
            <SafeAreaView
                style={{...styles.container, backgroundColor: theme.BACKGROUND}}
            >
                <StatusBarAware theme={theme} />
                <TopBar
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    titleText={lang().create_new_conversation}
                />
                <View
                    style={{
                        height: '100%',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <View style={styles.errorSection}>
                        <T style={{...styles.errorText, color: theme.TEXT}}>
                            {lang().get_user_data_error}
                        </T>
                    </View>
                </View>
            </SafeAreaView>
        );
    }

    if (userData === undefined) {
        return (
            <SafeAreaView
                style={{...styles.container, backgroundColor: theme.BACKGROUND}}
            >
                <StatusBarAware theme={theme} />
                <TopBar
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    titleText={lang().create_new_conversation}
                />
                <View
                    style={{
                        height: '90%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ActivityIndicator
                        size="large"
                        color={theme.PRIMARY}
                    />
                </View>
            </SafeAreaView>
        );
    }

    // if (user === null || user === undefined) {
    //   return (
    //     <SafeAreaView
    //       style={{...styles.container, backgroundColor: theme.BACKGROUND}}>
    //       <StatusBarAware theme={theme} />
    //       <TopBar
    //         goBackToPreviousScreen={goBackToPreviousScreen}
    //         titleText={lang().create_new_conversation}
    //       />
    //       <View
    //         style={{
    //           height: '100%',
    //           flexDirection: 'column',
    //           alignItems: 'center',
    //         }}>
    //         <View style={styles.errorSection}>
    //           <T style={{...styles.errorText, color: theme.TEXT, lineHeight: 30}}>
    //             {lang().not_logged_in_trying_to_create_conversation}
    //           </T>
    //         </View>
    //       </View>
    //     </SafeAreaView>
    //   );
    // }

    return (
        <SafeAreaView
            style={{...styles.container, backgroundColor: theme.BACKGROUND}}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={lang().create_new_conversation}
            />
            <View
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ProfilePicture
                    path={userData.profile_picture_path}
                    dimensions={Dimensions.get('screen').height * 0.11}
                    disableImageViewer
                    shadowColorOpacity={0.2}
                    customization={{
                        frame: userData.frame_shape,
                        color: userData.frame_color,
                    }}
                />
                <View
                    style={{
                        ...styles.usernameSection,
                        borderColor: theme.PRIMARY_DEVIDER,
                    }}
                >
                    <T
                        style={{
                            ...styles.usernameSectionText,
                            color: theme.TEXT,
                        }}
                    >
                        {userData.name}
                    </T>
                </View>
                <View
                    style={{
                        ...styles.profileInfoSection,
                        backgroundColor: theme.TERTIARY,
                        borderColor: theme.PRIMARY_DEVIDER,
                    }}
                    testID="profileSection"
                >
                    <View style={styles.createConversationDescriptionSection}>
                        <T
                            style={{
                                ...styles.createConversationDescriptionText,
                                color: theme.TEXT_PRIMARY,
                            }}
                        >
                            {lang().avoid_using_your_real_name}
                        </T>
                        <View style={styles.nicknameSection}>
                            <Input
                                numberOfLines={1}
                                placeholder={lang().your_nickname}
                                placeholderTextColor={theme.TEXT_MUTED}
                                style={{
                                    ...styles.nicknameInput,
                                    backgroundColor: theme.INPUT_BACKGROUND,
                                    color: theme.TEXT,
                                }}
                                maxLength={15}
                                value={nickname}
                                onChangeText={text => {
                                    setNickname(text);
                                }}
                            />
                        </View>
                    </View>
                    <TouchableOpacity
                        disabled={isCreating || !isValidNickname()}
                        onPress={() => {
                            onCreateConversation();
                        }}
                        testID="createConversationButton"
                    >
                        <LinearGradient
                            colors={[theme.SUCCESS, theme.SUCCESS]}
                            style={[
                                styles.startConversationButton,
                                isValidNickname()
                                    ? {}
                                    : styles.disabledStartConversationButton,
                            ]}
                        >
                            {isCreating ? (
                                <ActivityIndicator
                                    color="white"
                                    style={{paddingHorizontal: 30}}
                                />
                            ) : (
                                <T style={styles.startConversationText}>
                                    {lang().start_conversation}
                                </T>
                            )}
                        </LinearGradient>
                    </TouchableOpacity>
                </View>
                {isSubscribed !== true && !isWeb && (
                    <View style={{marginTop: 30}}>
                        <CreateConversationBanner />
                    </View>
                )}
            </View>
        </SafeAreaView>
    );
}

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    getUserDataFromUserLink: data =>
        conversationActions.getUserDataFromUserLink(data)(dispatch, getState),
    createConversation: data =>
        conversationActions.createConversation(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    user: state.authReducer.user,
    isSubscribed: state.subscriptionReducer.isSubscribed,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateConversation);
