import socketIOClient from 'socket.io-client';
import {network_domain} from '../../../env';

export function createSocket() {
    return socketIOClient(network_domain, {
        transports: ['websocket', 'polling'],
    });
}

export function registerListeners({
    socket,
    networkKey,
    userLink,
    userID,
    onNewMessageReceived,
    onIsTyping,
    conversationID,
}) {
    socket.on('connect', () => {
        socket.emit('join', {
            anonymousNetworkKey: networkKey,
            link: userLink,
            type: 'user',
            id: userID,
        });
    });

    socket.on(networkKey, broadcast => {
        if (broadcast.type === 'newMessage') {
            const message = broadcast.data;
            if (conversationID === message.conversation_id) {
                onNewMessageReceived({message});
            }
        }
    });

    socket.on(`user_id_${userID}`, broadcast => {
        if (
            broadcast.type === 'anonymousIsTyping' &&
            broadcast.conversation_id !== undefined &&
            `${broadcast.conversation_id}` === `${conversationID}`
        ) {
            onIsTyping();
        }
    });
}

export function destroyListeners({socket}) {
    if (socket) {
        socket.close();
    }
}
