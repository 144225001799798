import moment from 'moment';
import React, {ReactChild, useEffect, useState} from 'react';
import {
    View,
    Image,
    ScrollView,
    I18nManager,
    TouchableOpacity,
} from 'react-native';
import * as Linking from 'expo-linking';
import {Props} from 'react-native-image-zoom-viewer/built/image-viewer.type';
import LinearGradient from 'react-native-linear-gradient';
import {useDispatch} from 'react-redux';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {actions as pointsActions} from '../modules/points';
import Constants from '../assets/Constants';
import BuyPointsModal from '../components/Points/BuyWithPointsModal';
import {ProfilePicture, T, TopBar} from '../components/Shared';
import lang from '../localization';
import {arabicNumbersToEnglishNumbers, normalize} from '../modules/helpers';
import {
    BlockExpiresAt,
    BlockReason as BlockReasonType,
    BlockText,
} from '../types/data/block';
import {NavigationProp} from '../types/navigation/navigationProp';
import {
    getDarkModeHomeButtonSectionBackground,
    getDarkModeHomeButtonTextColor,
    useTheme,
} from '../modules/app/theme';
import {Theme} from '../assets/Colors';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {isWeb} from '../../env';
import {IS_POINTS_ENABLED} from '../modules/subscription/actions';

const warningIcon = require('../assets/images/warningIcon.png');

const BlockReason = ({
    navigation,
    route,
}: Props &
    NavigationProp<{
        blockReason: BlockReasonType | null;
        blockText: BlockText | null;
        blockExpiresAt: BlockExpiresAt;
    }>) => {
    const theme = useTheme();
    const blockReason = route?.params?.blockReason;
    const blockText = route?.params?.blockText;
    const blockExpiresAt = route?.params?.blockExpiresAt;

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    return (
        <PageWrapper
            goBackToPreviousScreen={goBackToPreviousScreen}
            theme={theme}
        >
            <ScrollView
                contentContainerStyle={{
                    paddingTop: normalize(20),
                    backgroundColor: theme.BACKGROUND,
                    width: '100%',
                    borderRadius: 100000,
                    zIndex: 3,
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image
                    source={warningIcon}
                    style={{
                        height: 40,
                        width: 40,
                        resizeMode: 'contain',
                        tintColor: theme.DANGER,
                    }}
                />
                <T
                    style={{
                        marginTop: 10,
                        color: theme.DANGER,
                        fontFamily: Constants.fonts.Bold,
                        fontSize: normalize(11),
                    }}
                >
                    {
                        lang()
                            .you_have_been_blocked_from_using_random_conversations
                    }
                </T>

                <T
                    style={{
                        marginTop: 15,
                        color: theme.TEXT,
                        fontFamily: Constants.fonts.SemiBold,
                        fontSize: normalize(11),
                    }}
                >
                    {lang().block_reason}
                </T>
                <T
                    style={{
                        color: theme.TEXT,
                        marginVertical: 5,
                        fontFamily: Constants.fonts.SemiBold,
                        fontSize: normalize(13),
                    }}
                >
                    {lang().reason_text(blockReason)}
                </T>
                <BlockReasonEvidence
                    blockReason={blockReason}
                    blockText={blockText}
                />
                {blockExpiresAt && (
                    <UnblockOptions
                        blockExpiresAt={blockExpiresAt}
                        theme={theme}
                    />
                )}
                <BuyPointsModal />
            </ScrollView>
        </PageWrapper>
    );
};

const UnblockOptions = ({
    blockExpiresAt,
    theme,
}: {
    blockExpiresAt: number;
    theme: Theme;
}) => {
    return (
        <LinearGradient
            colors={theme.GRADIENTS.PRIMARY}
            useAngle
            angle={-180}
            style={{
                marginTop: 15,
                marginBottom: -500,
                paddingBottom: 700,
                paddingHorizontal: 10,
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: 600,
                }}
            >
                <UnblockTimeOption
                    blockExpiresAt={blockExpiresAt}
                    theme={theme}
                />
                <T
                    style={{
                        fontSize: normalize(14),
                        marginTop: 10,
                        fontFamily: Constants.fonts.Bold,
                        color: theme.TEXT_WHITE,
                    }}
                >
                    {lang().points_system.you_also_can}
                </T>
                {IS_POINTS_ENABLED && <UnblockPointsOption theme={theme} />}
                {IS_POINTS_ENABLED && (
                    <T
                        style={{
                            fontSize: normalize(14),
                            marginTop: 10,
                            fontFamily: Constants.fonts.Bold,
                            color: Constants.colors.whiteMain,
                        }}
                    >
                        {lang().or}
                    </T>
                )}
                <UnblockContactOption theme={theme} />
                <View
                    style={{
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-around',
                        marginTop: 40,
                        maxWidth: 250,
                        opacity: 0.8,
                    }}
                >
                    <TouchableOpacity
                        onPress={async () => {
                            Linking.openURL(
                                Constants.links.privacyPolicy,
                            ).catch(() => {});
                        }}
                    >
                        <T
                            style={{
                                fontSize: 10,
                                fontFamily: Constants.fonts.Bold,
                                color: 'white',
                            }}
                        >
                            {lang().privacy_policy}
                        </T>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={async () => {
                            Linking.openURL(Constants.links.termsOfUse).catch(
                                () => {},
                            );
                        }}
                    >
                        <T
                            style={{
                                fontSize: 10,
                                fontFamily: Constants.fonts.Bold,
                                color: 'white',
                            }}
                        >
                            {lang().terms_of_use}
                        </T>
                    </TouchableOpacity>
                </View>
            </View>
        </LinearGradient>
    );
};

const UnblockPointsOption = ({theme}: {theme: Theme}) => {
    const dispatch = useDispatch();

    return (
        <View
            style={{
                width: '90%',
                backgroundColor: theme.TERTIARY,
                marginTop: 10,
                paddingVertical: 5,
                borderRadius: 4,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <T
                    style={{
                        fontSize: normalize(12),
                        marginTop: 5,
                        fontFamily: Constants.fonts.SemiBold,
                        color: theme.TEXT_PRIMARY,
                        textAlign: 'center',
                        paddingHorizontal: 2,
                    }}
                >
                    {lang().points_system.use_points.a}
                </T>
                <T
                    style={{
                        fontSize: normalize(12),
                        marginTop: 5,
                        fontFamily: Constants.fonts.Bold,
                        color: theme.ORANGE,
                        textAlign: 'center',
                        paddingHorizontal: 3,
                    }}
                >
                    {lang().points_system.use_points.b}
                </T>
                <T
                    style={{
                        fontSize: normalize(12),
                        marginTop: 5,
                        fontFamily: Constants.fonts.SemiBold,
                        color: theme.TEXT_PRIMARY,
                        textAlign: 'center',
                        paddingHorizontal: 2,
                    }}
                >
                    {lang().points_system.use_points.c}
                </T>
            </View>
            <View
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingHorizontal: 30,
                }}
            >
                <TouchableOpacity
                    onPress={() => {
                        pointsActions.initiateBuyWithPoints({
                            amount: 10,
                            type: 'unblock',
                        })(dispatch);
                    }}
                    style={{
                        backgroundColor:
                            theme.MODE === 'light'
                                ? theme.ORANGE
                                : getDarkModeHomeButtonSectionBackground(
                                      theme.ORANGE,
                                  ),
                        borderRadius: 20,
                        paddingHorizontal: 15,
                        paddingVertical: 5,
                    }}
                >
                    <T
                        style={{
                            fontSize: normalize(10),
                            fontFamily: Constants.fonts.SemiBold,
                            color:
                                theme.MODE === 'light'
                                    ? theme.TEXT_WHITE
                                    : getDarkModeHomeButtonTextColor(
                                          theme.ORANGE,
                                      ),
                            textAlign: 'center',
                        }}
                    >
                        {lang().points_system.use_points_button(10)}
                    </T>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const UnblockContactOption = ({theme}: {theme: Theme}) => {
    return (
        <View
            style={{
                width: '90%',
                backgroundColor: theme.TERTIARY,
                marginTop: 10,
                paddingVertical: 5,
                borderRadius: 4,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <T
                style={{
                    fontSize: normalize(11),
                    marginTop: 5,
                    fontFamily: Constants.fonts.SemiBold,
                    color: theme.TEXT_PRIMARY,
                    textAlign: 'center',
                    paddingHorizontal: 20,
                }}
            >
                {lang().contact_us_if_you_think_block_is_wrong}
            </T>
            <View
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingHorizontal: 30,
                }}
            >
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.facebookPage).catch(
                            () => {},
                        );
                    }}
                    style={{
                        backgroundColor: Constants.colors.facebook,
                        borderRadius: 20,
                        paddingHorizontal: 15,
                        paddingVertical: 5,
                    }}
                >
                    <T
                        style={{
                            fontSize: normalize(10),
                            fontFamily: Constants.fonts.SemiBold,
                            color: Constants.colors.whiteMain,
                            textAlign: 'center',
                        }}
                    >
                        {lang().block_contact.facebook}
                    </T>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.instagramPage).catch(
                            () => {},
                        );
                    }}
                    style={{
                        backgroundColor: Constants.colors.instagram,
                        borderRadius: 20,
                        paddingHorizontal: 15,
                        paddingVertical: 5,
                    }}
                >
                    <T
                        style={{
                            fontSize: normalize(10),
                            fontFamily: Constants.fonts.SemiBold,
                            color: Constants.colors.whiteMain,
                            textAlign: 'center',
                        }}
                    >
                        {lang().block_contact.instagram}
                    </T>
                </TouchableOpacity>
            </View>
        </View>
    );
};

const UnblockTimeOption = ({
    blockExpiresAt,
    theme,
}: {
    blockExpiresAt: number;
    theme: Theme;
}) => {
    const [durationDiff, setDurationDiff] = useState(
        moment.duration(moment(new Date(blockExpiresAt)).diff(new Date())),
    );

    const humanized = durationDiff
        .locale(I18nManager.isRTL ? 'ar-sa' : 'en-gb')
        .humanize();

    useEffect(() => {
        setInterval(() => {
            setDurationDiff(
                moment.duration(
                    moment(new Date(blockExpiresAt)).diff(new Date()),
                ),
            );
        }, 1000);
    }, []);

    return (
        <View
            style={{
                width: '90%',
                backgroundColor: theme.TERTIARY,
                marginTop: 20,
                borderRadius: 4,
                paddingVertical: 5,
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <T
                style={{
                    fontSize: normalize(14),
                    marginTop: 5,
                    fontFamily: Constants.fonts.SemiBold,
                    color: theme.TEXT_PRIMARY,
                }}
            >
                {lang().time_left_for_block_to_expire}
            </T>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <T
                    style={{
                        fontSize: normalize(18),
                        marginBottom: 2,
                        fontFamily: Constants.fonts.SemiBold,
                        color: theme.TEXT_PRIMARY,
                        marginEnd: 10,
                    }}
                >
                    {`${Math.trunc(durationDiff.asDays())}:${
                        Math.trunc(durationDiff.asHours()) % 24
                    }:${Math.trunc(durationDiff.asMinutes()) % 60}:${
                        Math.trunc(durationDiff.asSeconds()) % 60
                    }`}
                </T>
                <T
                    style={{
                        fontSize: normalize(15),
                        marginBottom: 2,
                        fontFamily: Constants.fonts.SemiBold,
                        color: theme.TEXT_PRIMARY,
                    }}
                >
                    {arabicNumbersToEnglishNumbers(`(${humanized})`)}
                </T>
            </View>
        </View>
    );
};

const BlockReasonEvidence = ({
    blockReason,
    blockText,
}: {
    blockReason: BlockReasonType | null | undefined;
    blockText: BlockText | null | undefined;
}) => {
    const theme = useTheme();

    if (!blockText) {
        return <></>;
    }

    switch (blockReason) {
        case 'Bad-Image':
            return (
                <View style={{marginTop: 10}}>
                    <ProfilePicture
                        theme={theme}
                        path={blockText}
                        dimensions={90}
                        shadowRadius={5}
                    />
                </View>
            );

        case 'Bad-Name':
            return (
                <View style={{marginTop: 10, paddingVertical: 10}}>
                    <View
                        style={{
                            backgroundColor: Constants.colors.secondary,
                            paddingHorizontal: 10,
                            borderRadius: 40,
                            paddingVertical: 2,
                        }}
                    >
                        <T
                            style={{
                                color: Constants.colors.whiteMain,
                                fontFamily: Constants.fonts.Bold,
                                fontSize: normalize(18),
                            }}
                        >
                            {blockText}
                        </T>
                    </View>
                </View>
            );

        case 'Bad-Messages':
            return (
                <View
                    style={{
                        marginTop: 10,
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginStart: 20,
                    }}
                >
                    <View
                        style={{
                            backgroundColor: Constants.colors.main.light,
                            paddingHorizontal: 10,
                            borderRadius: 40,
                            height: 35,
                            marginTop: 5,
                            justifyContent: 'center',
                            borderTopStartRadius: 20,
                            borderBottomStartRadius: 0,
                            borderTopEndRadius: 20,
                            borderBottomEndRadius: 20,
                        }}
                    >
                        <T
                            style={{
                                color: Constants.colors.whiteMain,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(12),
                            }}
                        >
                            ................
                        </T>
                    </View>
                    <View
                        style={{
                            backgroundColor: Constants.colors.main.light,
                            paddingHorizontal: 10,
                            paddingEnd: 20,
                            paddingVertical: 10,
                            maxWidth: 350,
                            minHeight: 35,
                            marginTop: 5,
                            borderTopStartRadius: 0,
                            borderBottomStartRadius: 0,
                            borderTopEndRadius: 20,
                            borderBottomEndRadius: 20,
                            justifyContent: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: Constants.colors.whiteMain,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(12),
                            }}
                        >
                            {blockText}
                        </T>
                    </View>
                    <View
                        style={{
                            backgroundColor: Constants.colors.main.light,
                            paddingHorizontal: 10,
                            borderRadius: 40,
                            height: 35,
                            marginTop: 5,
                            borderTopStartRadius: 0,
                            borderBottomStartRadius: 0,
                            borderTopEndRadius: 20,
                            borderBottomEndRadius: 20,
                            justifyContent: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: Constants.colors.whiteMain,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(12),
                            }}
                        >
                            ...................
                        </T>
                    </View>
                    <View
                        style={{
                            backgroundColor: Constants.colors.main.light,
                            paddingHorizontal: 10,
                            height: 35,
                            marginTop: 5,
                            borderTopStartRadius: 0,
                            borderBottomStartRadius: 20,
                            borderTopEndRadius: 20,
                            borderBottomEndRadius: 20,
                            justifyContent: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: Constants.colors.whiteMain,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(12),
                            }}
                        >
                            ...............
                        </T>
                    </View>
                </View>
            );
        default:
            return <></>;
    }
};

export default BlockReason;

const PageWrapper = ({
    children,
    goBackToPreviousScreen,
    theme,
}: {
    children: ReactChild;
    goBackToPreviousScreen: () => void;
    theme: Theme;
}) => {
    const insets = useSafeAreaInsets();

    return (
        <View
            style={{
                paddingTop: insets.top,
                backgroundColor: theme.BACKGROUND,
                height: '100%',
            }}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={lang().create_random_conversation}
            />
            {children}
        </View>
    );
};
