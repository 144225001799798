import {NUMBER_OF_WANTED_MESSAGES} from '../../../env';
import {formatAPITime} from '../helpers';
import Backend from '../axios/axios';

export function getUserDataFromUserLink({
    userLink,
    navigation,
}: {
    userLink: string;
    navigation: any;
}) {
    return Backend.post('/getUserDataFromUserLink', {
        user_link: userLink,
    });
}

export function createConversation({
    userLink,
    nickname,
    initiatorID,
}: {
    userLink: string;
    nickname: string;
    initiatorID: string;
}) {
    return Backend.post('/createConversation', {
        user_link: userLink,
        anonymous_nickname: nickname,
        initiator_id: initiatorID,
    });
}

export function extractUserLinkFromURL({link}: {link: string}) {
    return Backend.post('/getUserLinkFromLink', {
        link,
    });
}

export function getConversationDataFromConversationLink({
    conversationLink,
}: {
    conversationLink: string;
}) {
    return Backend.post('/getConversationDataFromConversationLink', {
        conversation_link: conversationLink,
        number_of_wanted_messages: NUMBER_OF_WANTED_MESSAGES,
    });
}

export function anonymousGetMoreMessages({
    conversationLink,
    lastMessageID,
}: {
    conversationLink: string;
    lastMessageID: string;
}) {
    return Backend.post('/anonymousGetMoreMessages', {
        conversation_link: conversationLink,
        last_message_id: lastMessageID,
        number_of_wanted_messages: NUMBER_OF_WANTED_MESSAGES,
    });
}

export function sendMessage({
    text,
    networkKey,
    userLink,
}: {
    text: string;
    networkKey: string;
    userLink: string;
}) {
    return Backend.post('/anonymousSendMessage', {
        message: text,
        network_key: networkKey,
        user_link: userLink,
        time: formatAPITime(),
    });
}

export async function getInitiatedConversations({
    returnType,
}: {
    returnType: string;
}) {
    return Backend.post('/getInitiatedConversations', {
        return_type: returnType,
    });
}

export async function anonymousIsTyping({
    networkKey,
    userLink,
    conversationID,
}: {
    networkKey: string;
    userLink: string;
    conversationID: string;
}) {
    return Backend.post('/anonymousIsTyping', {
        network_key: networkKey,
        user_link: userLink,
        conversation_id: `${conversationID}`,
    });
}
