const messaging = () => {
    return {
        hasPermission: () => Promise.resolve(true),
        subscribeToTopic: () => Promise.resolve(),
        unsubscribeFromTopic: () => Promise.resolve(),
        getToken: () => Promise.resolve(''),
        onMessage: () => () => {},
        onNotificationOpenedApp: () => () => {},
        onTokenRefresh: () => () => {},
        getInitialNotification: () => Promise.resolve(),
    };
};

export default messaging;
