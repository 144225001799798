import {CountryCode} from 'react-native-country-picker-modal';
import {ReduxDispatch, ReduxGetState} from '../../types/redux/redux';
import * as api from './api';
import {actions as conversationsActions} from '../conversations';

export function initiateRandomConversationsModule() {
    return () => {
        return api.initiateRandomConversations();
    };
}

export function getRandomUsers({
    isFirstSearch,
    searchCountry = null,
}: {
    isFirstSearch: boolean;
    searchCountry: CountryCode | undefined | null;
}) {
    return () => {
        return api.getRandomUsers({
            get_subscribers: isFirstSearch,
            country_code: searchCountry,
        });
    };
}

export function reportRandomUser({userLink}: {userLink: string}) {
    return () => {
        return api.reportRandomUser({userLink});
    };
}

export function createRandomConversation({userLink}: {userLink: string}) {
    return (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        return api.createRandomConversation({userLink}).then(data => {
            conversationsActions.checkUpdates()(dispatch, getState);
            return data;
        });
    };
}
