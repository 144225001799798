import axios, {
    AxiosError,
    AxiosHeaders,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
} from 'axios';
import store from '../redux/store';
import {logout} from '../app/actions';
import {getToken} from '../helpers';

type ConnectionConfig = {
    baseURL: string;
    headers?: any;
};

type ApiConnectorLocalVariables = {
    accessToken?: string;
};

type CustomAxiosInstance = AxiosInstance & {
    updateHeaders: (headers: AxiosRequestHeaders) => void;
};

const DEFAULT_HEADERS: Partial<AxiosRequestHeaders> = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
};

const DEFAULT_CONFIG = {
    timeout: 10000,
};

const ApiConnector = (() => {
    const instances: Record<string, CustomAxiosInstance> = {};

    function createInstance(
        name: string,
        config: ConnectionConfig,
    ): CustomAxiosInstance {
        const {headers: inputHeaders, ...axiosConfig} = config;

        // prepare headers (merge default headers with input headers)
        const headers: Partial<AxiosRequestHeaders> = {
            ...DEFAULT_HEADERS,
            ...(inputHeaders || {}),
        };

        // create the instance
        let instance = axios.create({
            ...DEFAULT_CONFIG,
            ...axiosConfig,
            headers,
        });

        // store the instance in the instances object
        instances[name] = instance as CustomAxiosInstance;

        // tracking tokens and last refresh attempt
        const tokens: ApiConnectorLocalVariables = {
            accessToken: undefined,
        };

        // request interceptor to add the token to the headers
        async function authorizationRequestInterceptor(
            requestConfig: AxiosRequestConfig,
        ): Promise<AxiosRequestConfig> {
            if (tokens.accessToken === undefined) {
                const tokenFromStorage = await getToken();
                if (tokenFromStorage) {
                    tokens.accessToken = tokenFromStorage;
                    return {
                        ...requestConfig,
                        headers: {
                            ...requestConfig.headers,
                            Authorization: `Bearer ${tokenFromStorage}`,
                        },
                    };
                }
            }
            if (tokens?.accessToken) {
                return {
                    ...requestConfig,
                    headers: {
                        ...requestConfig.headers,
                        Authorization: `Bearer ${tokens.accessToken}`,
                    },
                };
            }
            return requestConfig;
        }

        // storing the new tokens in the store and persistor (happens when login or refresh token)
        function storeNewUserInfo(user: any) {
            tokens.accessToken = user?.accessToken;
        }

        // response interceptor to update tokens if the response contains them (for example when logging in)
        function storeTokensResponseInterceptor(
            response: AxiosResponse<any>,
        ): AxiosResponse<any> {
            const {accessToken} = response?.data || {};
            if (accessToken) {
                storeNewUserInfo(response.data);
            }
            return response;
        }

        // response interceptor to refresh the token if the response is 401
        function refreshTokenResponseInterceptor(error: AxiosError) {
            const {response, config} = error;

            if (response?.status === 401) {
                // I should logout
                logout()(store.dispatch, store.getState);
            }

            return Promise.reject(error);
        }

        // exposed to enable updating the headers instance
        function updateHeaders(headers: AxiosRequestHeaders) {
            // @ts-ignore
            instance = {
                ...instance,
                defaults: {
                    ...instance.defaults,
                    headers: {
                        ...instance.defaults.headers,
                        ...headers,
                    },
                },
            };
        }

        // Add a request interceptor
        instance.interceptors.request.use(
            authorizationRequestInterceptor as any,
        );

        // Add a response interceptor
        instance.interceptors.response.use(
            storeTokensResponseInterceptor,
            refreshTokenResponseInterceptor,
        );

        return {
            ...instance,
            updateHeaders,
        } as CustomAxiosInstance;
    }

    function getInstance(
        name = 'default',
        config?: ConnectionConfig,
    ): CustomAxiosInstance {
        return config ? createInstance(name, config) : instances[name];
    }

    return Object.freeze({
        getInstance,
    });
})();

export default ApiConnector;
