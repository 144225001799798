import {combineReducers, AnyAction} from 'redux';
import {reducer as authReducer} from '../auth';
import {reducer as appReducer} from '../app';
import {reducer as conversationsReducer} from '../conversations';
import {reducer as messagesReducer} from '../messages';
import {reducer as subscriptionReducer} from '../subscription';
import {reducer as pointsReducer} from '../points';

// Combine all the reducers
const mainReducer = combineReducers({
    authReducer,
    appReducer,
    conversationsReducer,
    messagesReducer,
    subscriptionReducer,
    pointsReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
    return mainReducer(state, action);
};

export default rootReducer;
