import {React} from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Image,
    Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {T} from '../Shared';
import lang from '../../localization';
import {tinyClick} from '../../modules/app/vibrations';
import {actions as appActions} from '../../modules/app';
import store from '../../modules/redux/store';
import Constants from '../../assets/Constants';
import Modal from '../Shared/Modal';
import {useLocalaization} from '../../modules/app/localization';

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    modalView: {
        borderRadius: 9,
        alignItems: 'center',
        paddingTop: 20,
    },
    settingsModalButton: {
        paddingVertical: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginStart: 60,
    },
    settingsModalButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: 14,
        textAlign: 'center',
        marginTop: -1,
    },
    deleteIcon: {
        width: 20,
        height: 20,
        marginEnd: 10,
    },
    innerDivider: {
        width: '100%',
        borderWidth: 0.5,
        marginVertical: 8,
    },
    cancelButton: {
        paddingBottom: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cancelButtonText: {fontFamily: Constants.fonts.Bold},
    openButton: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    modalTitle: {
        marginBottom: 1,
        fontFamily: Constants.fonts.Bold,
        opacity: 0.8,
        fontSize: 16,
        alignSelf: 'flex-start',
        textAlign: 'right',
        marginHorizontal: 25,
        marginTop: -10,
    },
});

const deleteIcon = require('../../assets/images/deleteIcon.png');
const blockIcon = require('../../assets/images/blockIcon.png');
const reportIcon = require('../../assets/images/reportIcon.png');

const ConversationSettingsModal = ({
    show,
    setShow,
    removeConversations,
    activeConversation,
    conversation,
    blockConversation,
    reportConversation,
    goBack,
    theme,
    setAlert,
}) => {
    const hideModal = (vibrate = false) => {
        if (vibrate) {
            tinyClick();
        }
        setShow(false);
    };

    const {isInitiated, is_blocked} = conversation;
    const localization = useLocalaization();

    return (
        <Modal
            isVisible={show}
            theme={theme}
            closeModal={() => {
                hideModal();
            }}
        >
            <View dir={localization?.direction}>
                <TouchableOpacity
                    activeOpacity={1}
                    style={styles.modalView}
                    testID="conversationSettingsSection"
                >
                    <T style={{...styles.modalTitle, color: theme.TEXT}}>
                        {lang().options}
                    </T>
                    <View
                        style={{
                            ...styles.innerDivider,
                            borderColor: theme.SECONDARY,
                        }}
                    />
                    <TouchableOpacity
                        style={styles.settingsModalButton}
                        onPress={() => {
                            hideModal();
                            setTimeout(
                                () => {
                                    setAlert({
                                        show: true,
                                        message:
                                            lang().remove_conversation_confirm,
                                        confirmText: lang().confirm_removal,
                                        confirmColor: theme.DANGER,
                                        cancelText: lang().cancel,
                                        theme,
                                        onConfirmPressed: () => {
                                            setAlert(null);
                                            goBack();
                                            removeConversations({
                                                willBeRemovedConversations: [
                                                    activeConversation,
                                                ],
                                            });
                                        },
                                        onCancelPressed: () => {
                                            setAlert(null);
                                        },
                                        onDismiss: () => {
                                            setAlert(null);
                                        },
                                    });
                                },
                                Platform.OS === 'ios' ? 500 : 0,
                            );
                        }}
                        testID={`conversationRemoveButton${activeConversation}`}
                    >
                        <Image
                            source={deleteIcon}
                            style={{
                                ...styles.deleteIcon,
                                tintColor: theme.DANGER,
                            }}
                        />
                        <T
                            style={{
                                ...styles.settingsModalButtonText,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().remove_conversation}
                        </T>
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={styles.settingsModalButton}
                        onPress={() => {
                            hideModal();
                            setTimeout(
                                () => {
                                    setAlert({
                                        show: true,
                                        message: lang().block_user_confirm,
                                        confirmText: lang().confirm,
                                        confirmColor: theme.DANGER,
                                        cancelText: lang().cancel,
                                        theme,
                                        onConfirmPressed: () => {
                                            setAlert(null);
                                            goBack();
                                            blockConversation({
                                                conversationID:
                                                    activeConversation,
                                            });
                                        },
                                        onCancelPressed: () => {
                                            setAlert(null);
                                        },
                                        onDismiss: () => {
                                            setAlert(null);
                                        },
                                    });
                                },
                                Platform.OS === 'ios' ? 500 : 0,
                            );
                        }}
                        testID={`conversationBlockButton${activeConversation}`}
                    >
                        <Image
                            source={blockIcon}
                            style={{
                                ...styles.deleteIcon,
                                tintColor: theme.DANGER,
                            }}
                        />
                        <T
                            style={{
                                ...styles.settingsModalButtonText,
                                color: theme.TEXT,
                            }}
                        >
                            {is_blocked
                                ? lang().unblock_user
                                : lang().block_user}
                        </T>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={styles.settingsModalButton}
                        onPress={() => {
                            hideModal();
                            setTimeout(
                                () => {
                                    setAlert({
                                        show: true,
                                        message: lang().report_user_confirm,
                                        confirmText: lang().confirm,
                                        confirmColor: theme.DANGER,
                                        cancelText: lang().cancel,
                                        theme,
                                        onConfirmPressed: () => {
                                            setAlert(null);
                                            goBack();
                                            reportConversation({
                                                conversationID:
                                                    activeConversation,
                                            });
                                        },
                                        onCancelPressed: () => {
                                            setAlert(null);
                                        },
                                        onDismiss: () => {
                                            setAlert(null);
                                        },
                                    });
                                },
                                Platform.OS === 'ios' ? 500 : 0,
                            );
                        }}
                        testID={`conversationReportButton${activeConversation}`}
                    >
                        <Image
                            source={reportIcon}
                            style={{
                                ...styles.deleteIcon,
                                tintColor: theme.DANGER,
                            }}
                        />
                        <T
                            style={{
                                ...styles.settingsModalButtonText,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().report_user}
                        </T>
                    </TouchableOpacity>
                    <View
                        style={{
                            ...styles.innerDivider,
                            borderColor: theme.SECONDARY,
                        }}
                    />
                    <TouchableOpacity
                        style={styles.cancelButton}
                        onPress={hideModal}
                        testID="conversationSettingsModalCancelButton"
                    >
                        <T
                            style={{
                                ...styles.cancelButtonText,
                                color: theme.TEXT_MUTED,
                            }}
                        >
                            {lang().cancel}
                        </T>
                    </TouchableOpacity>
                </TouchableOpacity>
            </View>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    removeConversations: data =>
        appActions.removeConversations(data)(dispatch, getState),
    blockConversation: data =>
        appActions.blockConversation(data)(dispatch, getState),
    reportConversation: data =>
        appActions.reportConversation(data)(dispatch, getState),
});

const mapStateToProps = state => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConversationSettingsModal);
