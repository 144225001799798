import axios from 'axios';
import Backend from '../axios/axios';

export function sendRatingToSlack({
    name,
    userID,
    user_link,
    rating,
    review,
}: {
    name: string;
    userID: string;
    user_link: string;
    rating: number;
    review: string;
}) {
    axios({
        method: 'post',
        url: 'https://hooks.slack.com/services/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',
        data: {
            blocks: [
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: `User ID: ${userID} | User Name: ${name} | User Link: ${user_link}`,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: `Rating: ${rating}`,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: `Review: ${review}`,
                    },
                },
            ],
        },
    });
}

export async function resetPassword({email}: {email: string}) {
    return Backend.post('/resetPassword', {
        email,
    });
}
