import React, {useState} from 'react';
import {
    View,
    Image,
    StyleSheet,
    TouchableOpacity,
    Platform,
} from 'react-native';
import * as AppleAuthentication from 'expo-apple-authentication';
import LinearGradient from 'react-native-linear-gradient';
import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-auth-session/providers/facebook';
import {ResponseType} from 'expo-auth-session';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import TwitterLoginModal from './TwitterLoginModal';
import {NavigationProp} from '../../types/navigation/navigationProp';
import {ActionParameter} from '../../types/helpers/helpers';
import {actions as authActions} from '../../modules/auth';
import {SCREENS} from '../../screens/Screens';
import {Theme} from '../../assets/Colors';
import {isWeb} from '../../../env';

WebBrowser.maybeCompleteAuthSession();

const TwitterIcon = require('../../assets/images/twitter.png');
const FacebookIcon = require('../../assets/images/facebook.png');

const styles = StyleSheet.create({
    androidSocialMediaButtonsSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // minWidth: 270,
        maxWidth: 280,
        height: normalize(50),
        minHeight: 45,
        maxHeight: 60,
        marginBottom: 0,
    },
    iOSSocialMediaButtonsSection: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: normalize(50),
        minHeight: 45,
        maxHeight: 60,
        paddingHorizontal: 40,
    },
    socialMediaButton: {
        // flex: 1,
        marginVertical: 8,
        marginHorizontal: 10,
        width: 50,
    },
    iOSSocialMediaButton: {
        marginVertical: 8,
        height: 40,
        width: 45,
        borderRadius: 10,
    },
    socialMediaButtonGradient: {
        flex: 1,
        borderRadius: Constants.borderRadius.large,
        backgroundColor: Constants.colors.secondaryBackground,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: Platform.select({web: 10}),
    },
    iOSSocialMediaButtonGradient: {
        flex: 1,
        borderRadius: 5,
        backgroundColor: Constants.colors.secondaryBackground,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        direction: 'rtl',
    },
    socialMediaButtonText: {
        textAlign: 'center',
        color: 'white',
        fontWeight: '500',
        fontSize: normalize(12),
        marginEnd: 15,
    },
    iOSSocialMediaButtonText: {
        textAlign: 'center',
        color: 'white',
        fontWeight: '500',
        fontSize: 14,
        marginEnd: 15,
    },
    socialMediaButtonIcon: {
        width: normalize(13),
        height: normalize(13),
        resizeMode: 'contain',
    },
    iOSSocialMediaButtonIcon: {
        width: 13,
        height: 13,
        resizeMode: 'contain',
    },
    signinButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(13),
        color: 'white',
        textAlign: 'center',
    },
    signupButtonText: {
        fontSize: normalize(12),
        textAlign: 'center',
    },
    orSection: {
        marginVertical: normalize(3),
        marginTop: normalize(7 + 12),
    },
    orText: {
        fontSize: normalize(12),
    },
    appleButton: {
        width: 45,
        height: 40,
    },
});

const SocialLoginButtons = (
    props: NavigationProp<{}> & {
        twitterAuth: (
            data: ActionParameter<typeof authActions.twitterAuth>,
        ) => void;
        facebookAuth: (
            data: ActionParameter<typeof authActions.facebookAuth>,
        ) => void;
        appleAuthentication: (
            data: ActionParameter<typeof authActions.appleAuthentication>,
        ) => void;
    } & {
        theme: Theme;
    },
) => {
    const [isTwitterLogin, setIsTwitterLogin] = useState(false);
    const {theme} = props;

    const onTwitterLogin = ({token}: {token: string}) => {
        const redirectToMainScreen = () => {
            props.navigation.navigate(SCREENS.WELCOME);
        };
        props.twitterAuth({token, redirectToMainScreen});
    };

    return (
        <>
            {Platform.select({
                android: (
                    <AndroidAndWeb
                        {...props}
                        setIsTwitterLogin={setIsTwitterLogin}
                        theme={theme}
                    />
                ),
                ios: (
                    <IOS
                        {...props}
                        setIsTwitterLogin={setIsTwitterLogin}
                        theme={theme}
                    />
                ),
                web: (
                    <AndroidAndWeb
                        {...props}
                        setIsTwitterLogin={setIsTwitterLogin}
                        theme={theme}
                    />
                ),
            })}
            <TwitterLoginModal
                onTwitterLogin={onTwitterLogin}
                show={isTwitterLogin}
                setShow={setIsTwitterLogin}
            />
        </>
    );
};

const AndroidAndWeb = (
    props: NavigationProp<{}> & {
        facebookAuth: (
            data: ActionParameter<typeof authActions.facebookAuth>,
        ) => void;
        setIsTwitterLogin: (value: boolean) => void;
        theme: Theme;
    },
) => {
    const [request, response, promptAsync] = Facebook.useAuthRequest({
        clientId: '286275538778968',
        responseType: ResponseType.Token,
        scopes: ['public_profile', 'email'],
    });

    React.useEffect(() => {
        if (response?.type === 'success') {
            const {access_token} = response.params;
            const redirectToMainScreen = () => {
                props.navigation.navigate(SCREENS.WELCOME);
            };
            props.facebookAuth({redirectToMainScreen, token: access_token});
        }
    }, [response]);

    return (
        <>
            <View style={styles.orSection}>
                <T style={{...styles.orText, color: props.theme.TEXT}}>
                    {lang().or_auth}
                </T>
            </View>
            <View style={styles.androidSocialMediaButtonsSection}>
                <TouchableOpacity
                    accessibilityLabel="facebook"
                    accessibilityHint="facebook login button"
                    style={styles.socialMediaButton}
                    disabled={!request}
                    onPress={() => {
                        if (isWeb) {
                            promptAsync();
                        } else {
                            const redirectToMainScreen = () => {
                                props.navigation.navigate(SCREENS.WELCOME);
                            };
                            props.facebookAuth({redirectToMainScreen});
                        }
                    }}
                >
                    <LinearGradient
                        colors={Constants.gradient.facebook}
                        style={{
                            ...styles.socialMediaButtonGradient,
                        }}
                    >
                        <Image
                            source={FacebookIcon}
                            style={styles.socialMediaButtonIcon}
                        />
                    </LinearGradient>
                </TouchableOpacity>

                {!isWeb && (
                    <TouchableOpacity
                        style={styles.socialMediaButton}
                        onPress={() => {
                            props.setIsTwitterLogin(true);
                        }}
                    >
                        <LinearGradient
                            colors={Constants.gradient.twitter}
                            style={{
                                ...styles.socialMediaButtonGradient,
                            }}
                        >
                            <Image
                                source={TwitterIcon}
                                style={styles.socialMediaButtonIcon}
                            />
                        </LinearGradient>
                    </TouchableOpacity>
                )}
            </View>
        </>
    );
};

const IOS = (
    props: NavigationProp<{}> & {
        facebookAuth: (
            data: ActionParameter<typeof authActions.facebookAuth>,
        ) => void;
        appleAuthentication: (
            data: ActionParameter<typeof authActions.appleAuthentication>,
        ) => void;
        setIsTwitterLogin: (value: boolean) => void;
        theme: Theme;
    },
) => {
    return (
        <>
            <View style={styles.orSection}>
                <T style={{...styles.orText, color: props.theme.TEXT}}>
                    {lang().or_auth}
                </T>
            </View>
            <View style={styles.iOSSocialMediaButtonsSection}>
                {/* {Platform.OS !== 'ios' && ( */}
                <TouchableOpacity
                    style={styles.iOSSocialMediaButton}
                    onPress={() => {
                        const redirectToMainScreen = () => {
                            props.navigation.navigate(SCREENS.WELCOME);
                        };
                        props.facebookAuth({redirectToMainScreen});
                    }}
                >
                    <LinearGradient
                        colors={Constants.gradient.facebook}
                        style={{
                            ...styles.iOSSocialMediaButtonGradient,
                        }}
                    >
                        <Image
                            source={FacebookIcon}
                            style={styles.iOSSocialMediaButtonIcon}
                        />
                    </LinearGradient>
                </TouchableOpacity>
                {/* )} */}
                <TouchableOpacity
                    style={styles.iOSSocialMediaButton}
                    onPress={() => {
                        props.setIsTwitterLogin(true);
                    }}
                >
                    <LinearGradient
                        colors={Constants.gradient.twitter}
                        style={{
                            ...styles.iOSSocialMediaButtonGradient,
                        }}
                    >
                        <Image
                            source={TwitterIcon}
                            style={styles.iOSSocialMediaButtonIcon}
                        />
                    </LinearGradient>
                </TouchableOpacity>
                <AppleAuthentication.AppleAuthenticationButton
                    buttonType={
                        AppleAuthentication.AppleAuthenticationButtonType
                            .SIGN_IN
                    }
                    buttonStyle={
                        AppleAuthentication.AppleAuthenticationButtonStyle.BLACK
                    }
                    cornerRadius={5}
                    style={styles.appleButton}
                    onPress={() => {
                        const redirectToMainScreen = () => {
                            props.navigation.navigate(SCREENS.WELCOME);
                        };
                        props.appleAuthentication({redirectToMainScreen});
                    }}
                />
            </View>
        </>
    );
};

export default SocialLoginButtons;
