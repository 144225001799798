import React, {useState} from 'react';
import {SafeAreaView, View, StyleSheet, ScrollView} from 'react-native';
import Settings from '../components/Main/Settings';
import {TopBar} from '../components/Shared';
import StatusBarAware from '../components/Shared/StatusBarAware';
import lang from '../localization';
import {useTheme} from '../modules/app/theme';
import {NavigationProp} from '../types/navigation/navigationProp';
import CustomAlert, {CustomAlertProps} from '../components/Shared/CustomAlert';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
});

const SettingsScreen = ({navigation}: NavigationProp<{}>) => {
    const theme = useTheme();

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    const [alert, setAlert] = useState<CustomAlertProps | null>(null);

    return (
        <SafeAreaView
            style={[
                styles.container,
                {
                    backgroundColor: theme.BACKGROUND,
                },
            ]}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={lang().settings}
            />
            <View>
                <ScrollView
                    style={{marginTop: 5}}
                    contentContainerStyle={{
                        paddingBottom: 100,
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: theme.BACKGROUND,
                    }}
                >
                    <Settings
                        goBackToPreviousScreen={goBackToPreviousScreen}
                        navigation={navigation}
                        theme={theme}
                        setAlert={setAlert}
                    />
                </ScrollView>
            </View>
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
        </SafeAreaView>
    );
};

export default SettingsScreen;
