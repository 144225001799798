import React from 'react';
import {View} from 'react-native';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import lang from '../../localization';
import {
    getDarkModeHomeButtonSectionBackground,
    getDarkModeHomeButtonTextColor,
} from '../../modules/app/theme';
import {normalize} from '../../modules/helpers';

const CurrentPoints = ({points, theme}: {points?: number; theme: Theme}) => {
    return (
        <View
            style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <T
                style={{
                    fontFamily: Constants.fonts.Bold,
                    fontSize: normalize(18),
                    color: theme.TEXT_PRIMARY,
                }}
            >
                {lang().points_system.your_current_points}
            </T>
            <View
                style={{
                    backgroundColor:
                        theme.MODE === 'light'
                            ? theme.GOLD
                            : getDarkModeHomeButtonSectionBackground(
                                  theme.GOLD,
                              ),
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: 13,
                    paddingVertical: 4,
                    borderRadius: 50,
                    marginTop: 15,
                }}
            >
                <T
                    style={{
                        color:
                            theme.MODE === 'light'
                                ? theme.TEXT_WHITE
                                : getDarkModeHomeButtonTextColor(theme.GOLD),
                        fontFamily: Constants.fonts.Bold,
                        fontSize: normalize(20),
                        marginEnd: 10,
                    }}
                >
                    {`${points}`}
                </T>
                <T
                    style={{
                        color:
                            theme.MODE === 'light'
                                ? theme.TEXT_WHITE
                                : getDarkModeHomeButtonTextColor(theme.GOLD),
                        fontFamily: Constants.fonts.Bold,
                        fontSize: normalize(12),
                    }}
                >
                    {lang().points_system.point}
                </T>
            </View>
        </View>
    );
};

export default CurrentPoints;
