import React, {Fragment} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {
    TouchableOpacity,
    Text,
    View,
    TouchableWithoutFeedback,
    Platform,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import Constants from '../../assets/Constants';
import {normalize} from '../../modules/helpers';
import lang from '../../localization';
import {Interest} from '../../types/data/interest';
import {T} from '../Shared';
import {Theme} from '../../assets/Colors';

const UserInterests = ({
    interests = [],
    theme,
}: {
    interests: Interest[];
    theme: Theme;
}) => {
    const languages = interests.filter(
        interest => interest.type === 'language',
    );
    return (
        <TouchableWithoutFeedback onPress={() => {}}>
            <ScrollView
                nestedScrollEnabled
                horizontal
                style={{
                    width: '100%',
                    opacity: 0.9,
                }}
                contentContainerStyle={{
                    marginTop: 20,
                    paddingStart: 20,
                    paddingEnd: 20,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    minWidth: '100%',
                }}
                showsHorizontalScrollIndicator={false}
            >
                <TouchableWithoutFeedback>
                    <View style={{flexDirection: 'row'}}>
                        {languages && languages.length > 0 && (
                            <InterestsScreenButton
                                key={languages[0].id}
                                languages={languages}
                                theme={theme}
                            />
                        )}
                        {interests
                            .filter(interest => interest.type !== 'language')
                            .map(interest => {
                                return (
                                    <InterestsScreenButton
                                        key={interest.id}
                                        interest={interest}
                                        theme={theme}
                                    />
                                );
                            })}
                        <InterestsScreenButton
                            key="AddButton"
                            isAddButton
                            isAddButtonWithText={false}
                            theme={theme}
                        />
                    </View>
                </TouchableWithoutFeedback>
            </ScrollView>
        </TouchableWithoutFeedback>
    );
};

export const InterestsScreenButton = ({
    isAddButton = false,
    isAddButtonWithText = true,
    interest,
    languages,
    theme,
}: {
    isAddButton?: boolean;
    isAddButtonWithText?: boolean;
    interest?: Interest;
    languages?: Interest[];
    theme: Theme;
}) => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() => {
                // @ts-ignore
                navigation.navigate({name: 'Interests'});
            }}
            style={{
                backgroundColor: theme.TERTIARY,
                paddingHorizontal: 10,
                paddingVertical: 2,
                borderRadius: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginHorizontal: 4,
                opacity: isAddButton ? 0.9 : 1,
                ...Constants.elevation.one,
                shadowColor: theme.TERTIARY,
            }}
        >
            {languages && languages.length > 0 && (
                <>
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.SemiBold,
                            marginEnd: 4,
                            fontSize: normalize(
                                Platform.select({
                                    ios: 10,
                                    android: 10,
                                }) as number,
                            ),
                            position: 'relative',
                            bottom: Platform.select({ios: 2, android: 0}),
                        }}
                    >
                        {/* @ts-ignore */}
                        🗣
                    </T>
                    {languages.map((language, index) => {
                        return (
                            <Fragment key={language.id}>
                                <T
                                    style={{
                                        color: theme.TEXT_PRIMARY,
                                        fontFamily: Constants.fonts.SemiBold,
                                        fontSize: normalize(
                                            Platform.select({
                                                ios: 11,
                                                android: 10,
                                            }) as number,
                                        ),
                                    }}
                                >
                                    {/* @ts-ignore */}
                                    {language[lang().lang]}
                                </T>
                                {index !== languages.length - 1 && (
                                    <T
                                        style={{
                                            color: theme.TEXT_PRIMARY,
                                            fontFamily:
                                                Constants.fonts.SemiBold,
                                            fontSize: normalize(
                                                Platform.select({
                                                    ios: 11,
                                                    android: 10,
                                                }) as number,
                                            ),
                                        }}
                                    >
                                        {lang().lang === 'ar' ? ' ،' : ', '}
                                    </T>
                                )}
                            </Fragment>
                        );
                    })}
                </>
            )}
            {isAddButton && (
                <>
                    <View
                        style={{justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Text
                            style={{
                                color: theme.TEXT_PRIMARY,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(
                                    Platform.select({
                                        ios: 14,
                                        android: 10,
                                    }) as number,
                                ),
                                bottom: Platform.select({ios: 2, android: 0}),
                            }}
                        >
                            +
                        </Text>
                    </View>
                    {isAddButtonWithText && (
                        <Text
                            style={{
                                color: theme.TEXT_PRIMARY,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(11),
                                marginStart: 6,
                            }}
                        >
                            {lang().interests.add_interests}
                        </Text>
                    )}
                </>
            )}
            {interest && (
                <>
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.SemiBold,
                            marginEnd: 4,
                            fontSize: normalize(
                                Platform.select({
                                    ios: 10,
                                    android: 10,
                                }) as number,
                            ),
                        }}
                    >
                        {/* @ts-ignore */}
                        {String.fromCodePoint(`0x${interest.emoji}`)}
                    </T>
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.SemiBold,
                            fontSize: normalize(
                                Platform.select({
                                    ios: 11,
                                    android: 10,
                                }) as number,
                            ),
                        }}
                    >
                        {/* @ts-ignore */}
                        {interest[lang().lang]}
                    </T>
                </>
            )}
        </TouchableOpacity>
    );
};

export default UserInterests;
