import React from 'react';

import {View, StyleSheet, Image, TouchableOpacity, Alert} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import pSBC from 'shade-blend-color';
import moment from 'moment';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {T} from '../Shared';
import {mediumClick} from '../../modules/app/vibrations';
import {normalize} from '../../modules/helpers';
import {isWeb} from '../../../env';

const MESSAGE_BORDER_RADIUS = 22;

const clockIcon = require('../../assets/images/clockIcon.png');
const checkIcon = require('../../assets/images/checkIcon.png');

const styles = StyleSheet.create({
    keyboardAvoidingViewContainer: {flex: 1},
    container: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    scrollViewContent: {flexGrow: 1},
    testText: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: 'red',
        paddingStart: 10,
    },
    messagesContainer: {
        paddingHorizontal: 8,
        paddingTop: 10,
        paddingBottom: 7,
        flexDirection: 'column',
    },
    messageRowContainer: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    anonymousMessageRowContainer: {
        alignItems: 'flex-end',
    },
    userMessageRowContainer: {
        alignItems: 'flex-start',
    },
    messageTextContainer: {
        marginVertical: 1.5,
        paddingHorizontal: 12,
        paddingVertical: 5,
        paddingEnd: 15,
        maxWidth: '85%',
        minWidth: 20,
        minHeight: 33,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    firstMessage: {
        borderTopEndRadius: MESSAGE_BORDER_RADIUS,
        borderTopStartRadius: MESSAGE_BORDER_RADIUS,
    },
    lastMessage: {
        borderBottomEndRadius: MESSAGE_BORDER_RADIUS,
        borderBottomStartRadius: MESSAGE_BORDER_RADIUS,
    },
    anonymousMessageTextContainer: {
        borderTopStartRadius: MESSAGE_BORDER_RADIUS,
        borderBottomStartRadius: MESSAGE_BORDER_RADIUS,
    },
    notConfirmedUserMessageTextContainer: {
        borderTopEndRadius: MESSAGE_BORDER_RADIUS,
        borderBottomEndRadius: MESSAGE_BORDER_RADIUS,
    },
    failedMessageTextContainer: {
        backgroundColor: Constants.colors.danger,
        borderTopEndRadius: MESSAGE_BORDER_RADIUS,
        borderBottomEndRadius: MESSAGE_BORDER_RADIUS,
    },
    userMessageTextContainer: {
        backgroundColor: Constants.colors.main.xLight,
        borderTopEndRadius: MESSAGE_BORDER_RADIUS,
        borderBottomEndRadius: MESSAGE_BORDER_RADIUS,
    },
    anonymousMessageText: {
        fontSize: 12,
        lineHeight: 20,
        color: 'white',
        textAlign: 'right',
        fontFamily: Constants.fonts.SemiBold,
    },
    userMessageText: {
        fontSize: 12,
        lineHeight: normalize(20),
        color: 'white',
        textAlign: 'left',
        fontFamily: Constants.fonts.SemiBold,
    },
    timeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 5,
    },
    anonymousTimeContainer: {
        justifyContent: 'flex-end',
    },
    userTimeContainer: {
        justifyContent: 'flex-start',
    },
    timeTextContainer: {
        color: '#b3b3b3',
        fontSize: 10,
        padding: 0,
        marginVertical: 0,
        marginHorizontal: 2,
    },
    clockIcon: {
        tintColor: '#5A5A5A',
        opacity: 0.7,
        height: 9,
        width: 9,
        resizeMode: 'contain',
        marginHorizontal: 2,
    },
    checkIcon: {
        tintColor: '#8ABE8A',
        opacity: 1,
        height: 10,
        width: 10,
        resizeMode: 'contain',
        marginHorizontal: 2,
    },
    loadingSectionContainer: {
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    noMessagesSectionContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        marginBottom: 4,
    },
    noMessagesTextContainer: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    noMessagesText: {
        fontSize: 20,
        fontFamily: Constants.fonts.Bold,
        color: '#ffbc53',
    },
    loadingMoreMessagesSection: {
        marginTop: 20,
    },
    conversationStartContainer: {
        backgroundColor: '#A0A0A0',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        paddingVertical: 2,
        paddingHorizontal: 8,
        marginBottom: 8,
    },
    conversationStartText: {
        color: 'white',
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
    },
});

const MessagesSection = ({messages, theme, setAlert}) => {
    const renderMessages = () => {
        // to prevent duplicate messages
        const renderedMessages = [];

        return messages.map((message, index) => {
            const momentTime = moment(message.created_at);

            const isFirstMessage = () => {
                if (messages[index - 1] === undefined) {
                    return true;
                }
                if (messages[index - 1].sender !== message.sender) {
                    return true;
                }
                return false;
            };

            const isLastMessage = () => {
                if (messages[index + 1] === undefined) {
                    return true;
                }
                if (messages[index + 1].sender !== message.sender) {
                    return true;
                }
                return false;
            };

            if (renderedMessages.includes(message.id)) {
                return <View key={`${message.id}${message.created_at}`} />;
            }
            renderedMessages.push(message.id);
            return (
                <View key={message.id}>
                    <MessageBubble
                        theme={theme}
                        isFirstMessage={isFirstMessage()}
                        isLastMessage={isLastMessage()}
                        {...message}
                        momentTime={momentTime}
                        setAlert={setAlert}
                    />
                </View>
            );
        });
    };
    return <>{renderMessages()}</>;
};

export default MessagesSection;

const MessageBubble = React.memo(
    ({
        isFirstMessage,
        isLastMessage,
        isUploading,
        hasFailed,
        sender,
        text,
        time,
        theme,
        setAlert,
        momentTime,
    }) => {
        const isAnonymous = sender === 'anonymous' && !isUploading;
        const isUser = sender === 'user' && !isUploading && !hasFailed;

        const onMessageLongPress = () => {
            mediumClick();
            setAlert({
                show: true,
                message: `${text} \n\n ${
                    momentTime?.format('yyyy/MM/DD - h:mmA') || time
                }`,
                confirmText: lang().copy,
                cancelText: lang().cancel,
                theme,
                onConfirmPressed: () => {
                    Clipboard.setString(text);
                    setAlert(null);
                },
                onCancelPressed: () => {
                    setAlert(null);
                },
                onDismiss: () => {
                    setAlert(null);
                },
            });
        };

        return (
            <View
                style={[
                    styles.messageRowContainer,
                    isAnonymous
                        ? styles.anonymousMessageRowContainer
                        : styles.userMessageRowContainer,
                ]}
            >
                <TouchableOpacity
                    delayLongPress={300}
                    onLongPress={onMessageLongPress}
                    style={[
                        styles.messageTextContainer,
                        isUploading
                            ? styles.notConfirmedUserMessageTextContainer
                            : {},
                        hasFailed
                            ? {
                                  ...styles.failedMessageTextContainer,
                                  backgroundColor: theme.DANGER,
                              }
                            : {},
                        isAnonymous
                            ? {
                                  ...styles.anonymousMessageTextContainer,
                                  backgroundColor: theme.PRIMARY,
                              }
                            : {},
                        isUser
                            ? {
                                  ...styles.userMessageTextContainer,
                                  backgroundColor: pSBC(
                                      theme.MODE === 'light' ? -0.4 : -0.1,
                                      theme.SECONDARY,
                                  ),
                              }
                            : {},
                        isFirstMessage ? styles.firstMessage : {},
                        isLastMessage ? styles.lastMessage : {},
                    ]}
                >
                    <T
                        style={
                            isAnonymous
                                ? styles.anonymousMessageText
                                : styles.userMessageText
                        }
                    >
                        {text}
                    </T>
                </TouchableOpacity>
                {isLastMessage && hasFailed !== true && (
                    <View style={styles.timeContainer}>
                        {isUser && isUploading !== true && (
                            <Image
                                source={checkIcon}
                                style={styles.checkIcon}
                                testID="checkIcon"
                            />
                        )}
                        <T style={styles.timeTextContainer}>
                            {momentTime?.format('h:mm A') || time}
                        </T>
                        {isAnonymous && (
                            <Image
                                source={clockIcon}
                                style={styles.clockIcon}
                            />
                        )}
                    </View>
                )}
            </View>
        );
    },
    (prevProps, nextProps) => {
        const shouldNotReRender = () => {
            return (
                prevProps.id === nextProps.id &&
                prevProps.isFirstMessage === nextProps.isFirstMessage &&
                prevProps.isLastMessage === nextProps.isLastMessage &&
                prevProps.isUploading === nextProps.isUploading &&
                prevProps.hasFailed === nextProps.hasFailed &&
                prevProps.time === nextProps.time &&
                prevProps.text === nextProps.text
            );
        };

        // if (!shouldNotReRender()) {
        //   console.log('component re-rendered');
        //   console.log(`message id: ${nextProps.id}`);
        //   console.log({prevProps, nextProps});
        // }

        // if it equals true, it will not rerender
        return shouldNotReRender();
    },
);
