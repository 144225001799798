import React, {ReactChild} from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';
import {Theme} from '../../assets/Colors';
// @ts-ignore
import ShadowViewLocal from '../../../PackageWrappers/Components/ShadowView';

const LeftConversationsCounter = ({
    setShouldShowRewardedAd,
    canGetRewardedConversations,
    numberOfLeftConversations,
    isSubscribed,
    theme,
}: {
    setShouldShowRewardedAd: (value: boolean) => void;
    canGetRewardedConversations: boolean;
    numberOfLeftConversations: number;
    isSubscribed: boolean;
    theme: Theme;
}) => {
    if (isSubscribed) {
        return (
            <Wrapper theme={theme}>
                <T
                    style={{
                        paddingTop: 10,
                        paddingBottom: 12,
                        paddingHorizontal: 10,
                        fontFamily: Constants.fonts.Bold,
                        color: theme.SUCCESS,
                        fontSize: normalize(11),
                    }}
                >
                    {lang().you_have_unlimited_number_of_random_conversations}
                </T>
            </Wrapper>
        );
    }
    if (numberOfLeftConversations > 0) {
        return (
            <Wrapper theme={theme}>
                <View
                    style={{
                        backgroundColor: theme.DANGER,
                        paddingHorizontal: 8,
                        paddingTop: 4,
                        paddingBottom: 5,
                        borderRadius: 100,
                    }}
                >
                    <T
                        style={{
                            color: 'white',
                            fontFamily: Constants.fonts.Bold,
                            fontSize: normalize(11),
                        }}
                    >
                        {`${numberOfLeftConversations}`}
                    </T>
                </View>
            </Wrapper>
        );
    }
    if (numberOfLeftConversations === 0) {
        if (canGetRewardedConversations) {
            return (
                <TouchableOpacity
                    onPress={() => {
                        setShouldShowRewardedAd(true);
                    }}
                >
                    <Wrapper theme={theme}>
                        <T
                            style={{
                                paddingTop: 10,
                                paddingBottom: 12,
                                paddingHorizontal: 10,
                                fontFamily: Constants.fonts.Bold,
                                color: theme.GOLD,
                                fontSize: normalize(11),
                            }}
                        >
                            {lang().watch_ad_to_get_more_conversations}
                        </T>
                    </Wrapper>
                </TouchableOpacity>
            );
        }
        return (
            <Wrapper theme={theme}>
                <T
                    style={{
                        paddingTop: 10,
                        paddingBottom: 12,
                        paddingHorizontal: 10,
                        fontFamily: Constants.fonts.Bold,
                        color: theme.TEXT_PRIMARY,
                        fontSize: normalize(11),
                    }}
                >
                    {lang().no_free_random_conversations}
                </T>
            </Wrapper>
        );
    }
    return <></>;
};

const Wrapper = ({
    children,
    theme,
}: {
    children: ReactChild | ReactChild[];
    theme: Theme;
}) => {
    return (
        <View
            style={{
                marginTop: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ShadowViewLocal
                style={{
                    backgroundColor: theme.TERTIARY,
                    shadowRadius: 5,
                    borderRadius: 1000,
                    shadowOpacity: Platform.select({ios: 0.2, android: 0.3}),
                    shadowOffset: {width: 0, height: 0},
                    shadowColor: '#000000',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {children}
            </ShadowViewLocal>
        </View>
    );
};

export default LeftConversationsCounter;
