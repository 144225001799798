import {Appearance, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useSelector} from 'react-redux';
import pSBC from 'shade-blend-color';
import * as SystemUI from 'expo-system-ui';

import * as t from './actionTypes';

import {ReduxDispatch} from '../../types/redux/redux';
import {ReducerState} from '../../types/reducer/reducer';
import {ThemeColors} from '../../assets/Colors';

export const getDarkModeHomeButtonTextColor = (color: string) => {
    return pSBC(0.2, color, 'c') as string;
};

export const getDarkModeHomeButtonSectionBackground = (color: string) => {
    return pSBC(-0.7, color) as string;
};

function changeStorageThemeMode({themeMode}: {themeMode: 'dark' | 'light'}) {
    AsyncStorage.setItem('themeMode', themeMode);
}

function changeReducerThemeMode({themeMode}: {themeMode: 'dark' | 'light'}) {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.CHANGE_THEME_MODE,
            data: {themeMode},
        });
    };
}

function changeSystemBackground({themeMode}: {themeMode: 'dark' | 'light'}) {
    SystemUI.setBackgroundColorAsync(
        themeMode === 'dark'
            ? ThemeColors.dark.BACKGROUND
            : ThemeColors.light.BACKGROUND,
    ).catch(() => {
        console.log('Error happened');
    });
}

export function changeThemeMode({themeMode}: {themeMode: 'dark' | 'light'}) {
    return (dispatch: ReduxDispatch) => {
        changeSystemBackground({themeMode});
        changeReducerThemeMode({themeMode})(dispatch);
        changeStorageThemeMode({themeMode});
    };
}

export function useThemeMode() {
    return useSelector((state: ReducerState) => {
        return state.appReducer.themeMode;
    });
}

export function useTheme() {
    return useSelector((state: ReducerState) => {
        const mode = state.appReducer.themeMode || 'light';
        return ThemeColors[mode];
    });
}

const getDeviceThemeMode = () => {
    const deviceThemeMode = Appearance.getColorScheme();
    if (deviceThemeMode) {
        return deviceThemeMode;
    }
    return 'light';
};

export const initialThemeDecider = ({
    themeModeFromReducer,
}: {
    themeModeFromReducer: 'dark' | 'light' | undefined;
}) => {
    return (dispatch: ReduxDispatch) => {
        // check theme in reduer
        if (themeModeFromReducer === undefined) {
            // check theme in async storage
            AsyncStorage.getItem('themeMode').then(storageThemeMode => {
                if (storageThemeMode === null) {
                    // check device theme
                    changeThemeMode({themeMode: getDeviceThemeMode()})(
                        dispatch,
                    );
                } else if (
                    storageThemeMode === 'light' ||
                    storageThemeMode === 'dark'
                ) {
                    changeThemeMode({themeMode: storageThemeMode})(dispatch);
                } else {
                    changeThemeMode({themeMode: 'light'})(dispatch);
                }
            });
        }
    };
};
