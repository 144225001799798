import React, {PropsWithChildren, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import T from './Text';
import {useTheme} from '../../modules/app/theme';

const Checkbox = ({
    text,
    component,
    isChecked,
    setIsChecked,
}: {
    text?: string;
    component?: React.ReactNode;
    isChecked: boolean;
    setIsChecked: (isChecked: boolean) => void;
}) => {
    const theme = useTheme();

    return (
        <View
            style={{
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <BouncyCheckbox
                size={25}
                fillColor={theme.PRIMARY}
                unfillColor="#FFFFFF"
                style={{
                    marginEnd: -5,
                }}
                isChecked={isChecked}
                innerIconStyle={{borderWidth: 2}}
                disableBuiltInState
                onPress={() => {
                    setIsChecked(!isChecked);
                }}
            />
            {text ? <T style={{flex: 1}}>{text}</T> : null}
            {component ? (
                <TouchableOpacity
                    style={{flex: 1}}
                    onPress={() => {
                        setIsChecked(!isChecked);
                    }}
                >
                    {component}
                </TouchableOpacity>
            ) : (
                <></>
            )}
        </View>
    );
};

export default Checkbox;
