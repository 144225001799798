import React from 'react';
import {View} from 'react-native';
import {isWeb} from '../../../env';
import {Theme} from '../../assets/Colors';
import {useLocalaization} from '../../modules/app/localization';
import {T} from '../Shared';

const Disclaimers = ({
    disclaimers,
    theme,
}: {
    disclaimers: string[];
    theme: Theme;
}) => {
    const localization = useLocalaization();

    const renderDisclaimers = () => {
        return disclaimers.map(disclaimer => {
            return (
                <View
                    key={disclaimer}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            marginTop: 5,
                            height: 5,
                            width: 5,
                            backgroundColor: '#969696',
                            borderRadius: 100,
                            marginEnd: 10,
                        }}
                    />
                    <T
                        style={{
                            fontSize: 10,
                            width: '100%',
                            color: theme.TEXT_MUTED,
                            textAlign:
                                isWeb && localization?.direction === 'rtl'
                                    ? 'right'
                                    : 'left',
                            lineHeight: 16,
                        }}
                    >
                        {disclaimer}
                    </T>
                </View>
            );
        });
    };

    if (disclaimers && Array.isArray(disclaimers)) {
        return <View>{renderDisclaimers()}</View>;
    }

    return <View />;
};

export default Disclaimers;
