import React, {useEffect, useState} from 'react';
import {ActivityIndicator, View, useWindowDimensions} from 'react-native';
import {CountryCode} from 'react-native-country-picker-modal';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {normalize} from '../../modules/helpers';
import {RandomUser} from '../../types/data/randomUser';
import {User} from '../../types/data/user';
import {FadeInView} from '../Animations';
import colors from '../Customization/colors';
import {CountryFlagAvatar, ProfilePicture, T} from '../Shared';
import RandomUserInterests from './RandomUserInterests';

// @ts-ignore
const MAX_IMAGE_DIMENSION = 180;
export const getImageDimenstions = (ScreenWidth: number) => {
    return ScreenWidth / 3 > MAX_IMAGE_DIMENSION
        ? MAX_IMAGE_DIMENSION
        : ScreenWidth / 3;
};

const ExternalUserInfo = ({
    user,
    theme,
}: {
    user: User | RandomUser | undefined;
    theme: Theme;
}) => {
    const {width} = useWindowDimensions();

    const [isProfilePictureBlurred, setIsProfilePictureBlurred] =
        useState(false);

    useEffect(() => {
        if (user && user.shouldBlurProfilePicture !== undefined) {
            setIsProfilePictureBlurred(user.shouldBlurProfilePicture);
        }
    }, [user]);

    const getUsernameColor = () => {
        if (user?.frame_color) {
            if (
                (user?.frame_color === 'white' && theme.MODE === 'light') ||
                (user?.frame_color === 'black' && theme.MODE === 'dark')
            ) {
                return theme.TEXT;
            }
            return colors[user?.frame_color].code;
        }
        return theme.TEXT;
    };

    if (user) {
        return (
            <FadeInView
                key={user.user_link}
                duration={500}
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <RandomUserInterests
                    theme={theme}
                    dimensions={getImageDimenstions(width)}
                    interests={user.interests}
                />
                <ProfilePicture
                    path={user.profile_picture_path}
                    dimensions={getImageDimenstions(width)}
                    shadowRadius={10}
                    shouldBeBlurred={isProfilePictureBlurred}
                    customization={{
                        color: user.frame_color,
                        frame: user.frame_shape,
                    }}
                    theme={theme}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <T
                        style={{
                            marginTop: 10,
                            fontFamily: Constants.fonts.Bold,
                            color: getUsernameColor(),
                            fontSize: normalize(21),
                            paddingBottom: normalize(9),
                        }}
                    >
                        {user.name}
                    </T>
                    {user.country_code && (
                        <CountryFlagAvatar
                            countryCode={user.country_code as CountryCode}
                        />
                    )}
                </View>
            </FadeInView>
        );
    }

    return (
        <FadeInView
            duration={1000}
            style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ActivityIndicator
                size="large"
                color={theme.TEXT_PRIMARY}
            />
        </FadeInView>
    );
};

export default ExternalUserInfo;
