import {AnyAction} from 'redux';
import * as t from './actionTypes';
import Constants from '../../assets/Constants';

export type SnackBarPosition = 'top' | 'bottom';

export interface AppReducer {
    localization?: {
        language: string;
        direction: 'rtl' | 'ltr';
    };
    isNotificationGranted: undefined | boolean;
    isSnackBarVisible: boolean;
    snackBarText: string;
    snackBarPosition: SnackBarPosition;
    snackBarActionHandler: (params: any) => void;
    snackBarBackgroundColor: string;
    snackBarAccentColor: string;
    isListenerRegistered: boolean;
    isProfilePictureUploading: boolean;
    mainLoading: boolean;
    themeMode: undefined | 'dark' | 'light';
}

const initialState: AppReducer = {
    localization: undefined,
    isNotificationGranted: undefined,
    isSnackBarVisible: false,
    snackBarText: 'init',
    snackBarPosition: 'top',
    snackBarActionHandler: () => {},
    snackBarBackgroundColor: '#FFFFFF',
    snackBarAccentColor: 'black',
    isListenerRegistered: false,
    isProfilePictureUploading: false,
    mainLoading: false,
    themeMode: undefined,
};

export type AppReducerAction = AnyAction & {
    data: AppReducer & {
        image?: string;
        type: 'success' | 'danger';
        textMessage: string;
        position: SnackBarPosition;
        language: string;
        direction: 'rtl' | 'ltr';
    };
};

const appReducer = (state = initialState, action: AppReducerAction) => {
    switch (action.type) {
        case t.SHOW_SNACKBAR:
            let backgroundColor;
            let accentColor;
            switch (action.data.type) {
                case 'success':
                    backgroundColor = '#92AF92';
                    accentColor = 'white';
                    break;
                case 'danger':
                    backgroundColor = Constants.colors.danger;
                    accentColor = 'white';
                    break;
                default:
                    backgroundColor = '#FFFFFF';
                    accentColor = 'black';
            }
            return {
                ...state,
                isSnackBarVisible: true,
                snackBarText: action.data.textMessage,
                snackBarPosition: action.data.position,
                snackBarBackgroundColor: backgroundColor,
                snackBarAccentColor: accentColor,
            };

        case t.HIDES_SNACKBAR:
            return {
                ...state,
                isSnackBarVisible: false,
                snackBarText: 'init',
                snackBarPosition: 'top',
                snackBarBackgroundColor: '#FFFFFF',
                snackBarAccentColor: 'black',
            };

        case t.REGISTER_MAIN_LISTENER:
            return {
                ...state,
                isListenerRegistered: true,
            };

        case t.ENABLE_NOTIFICATIONS:
            return {
                ...state,
                isNotificationGranted: true,
            };

        case t.DISABLE_NOTIFICATIONS:
            return {
                ...state,
                isNotificationGranted: false,
            };

        case t.START_PROFILE_PICTURE_UPLOADING:
            return {
                ...state,
                isProfilePictureUploading: true,
            };

        case t.STOP_PROFILE_PICTURE_UPLOADING:
            return {
                ...state,
                isProfilePictureUploading: false,
            };

        case t.FREE_REDUCER:
            return {
                ...initialState,
            };

        case t.SET_MAIN_LOADING:
            return {
                ...state,
                mainLoading: true,
            };

        case t.UNSET_MAIN_LOADING:
            return {
                ...state,
                mainLoading: false,
            };

        case t.CHANGE_THEME_MODE:
            return {
                ...state,
                themeMode: action.data.themeMode,
            };

        case t.CHANGE_LOCALIZATION:
            return {
                ...state,
                localization: {
                    language: action.data.language,
                    direction: action.data.direction,
                },
            };

        default:
            return state;
    }
};

export default appReducer;
