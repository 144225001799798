import React from 'react';
import {
    Dimensions,
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {ConnectedProps, connect} from 'react-redux';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {useLocalaization} from '../../modules/app/localization';
import {Conversation} from '../../types/data/conversation';
import {ReducerState} from '../../types/reducer/reducer';
import {T} from '../Shared';
import AppType from '../../../AppType';

const homeIcon = require('../../assets/images/homeIcon.png');
const conversationsIcon = require('../../assets/images/tabConversationsIcon.png');
const randomIcon = require('../../assets/images/tabRandomIcon.png');

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const getTabBarHeight = () => {
    if (PHONE_HAS_A_NOTCH) {
        return 70;
    }
    return 55;
};

const getTabBarBottomPadding = () => {
    if (PHONE_HAS_A_NOTCH) {
        return 11;
    }
    return 3;
};

const Tab = ({
    isActive = false,
    tab,
    tabsConfig,
    goToTab,
    notifications,
}: {
    isActive?: boolean;
    tab: string;
    tabsConfig: any;
    goToTab: ({index}: {index: number}) => void;
    notifications?: any;
}) => {
    const localization = useLocalaization();

    return (
        <TouchableOpacity
            onPress={() => {
                goToTab({index: tabsConfig[tab].index});
            }}
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {notifications && !isActive && (
                <View style={{position: 'absolute', top: 0, zIndex: 2}}>
                    <View
                        style={{
                            minWidth: 15,
                            minHeight: 15,
                            position: 'absolute',
                            borderRadius: 50,
                            backgroundColor: '#FF4E4E',
                            top: Platform.select({native: 8, web: 2}),
                            ...(localization?.direction === 'rtl'
                                ? {
                                      start: -15,
                                  }
                                : {
                                      end: -15,
                                  }),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: 'white',
                                fontSize: 9,
                                fontFamily: Constants.fonts.Bold,
                            }}
                        >
                            {notifications}
                        </T>
                    </View>
                </View>
            )}
            <View
                style={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: isActive ? 1 : 0.6,
                }}
            >
                <Image
                    source={tabsConfig[tab].icon}
                    style={{
                        height: 35,
                        width: 35,
                        resizeMode: 'contain',
                        marginBottom: -3,
                        marginTop: -3,
                        ...tabsConfig[tab].style,
                    }}
                />
                <T
                    style={{
                        fontFamily: Constants.fonts.Bold,
                        color: 'white',
                        fontSize: 8,
                    }}
                >
                    {tabsConfig[tab].title}
                </T>
            </View>
        </TouchableOpacity>
    );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: ReducerState) => ({
    conversations: state.conversationsReducer.conversations,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = {
    goToTab: ({index}: {index: number}) => void;
    currentTab: number;
    conversations: Conversation[];
    theme: Theme;
} & PropsFromRedux;

const BottomTabBar = ({goToTab, currentTab, conversations, theme}: Props) => {
    const localization = useLocalaization();
    const weirdCase =
        Platform.OS === 'android' && localization?.direction === 'rtl';

    const tabsConfig = {
        home: {
            title: lang().tabs.home,
            icon: homeIcon,
            index: weirdCase ? 1 : 0,
            style: {
                height: 22,
                top: 5,
                marginBottom: 8,
                tintColor: 'white',
            },
            key: 'home',
        },
        conversations: {
            title: lang().tabs.conversations,
            icon: conversationsIcon,
            index: weirdCase ? 0 : 1,
            key: 'conversations',
        },
        random: {
            title: lang().tabs.random_conversation,
            icon: randomIcon,
            index: 2,
            key: 'random',
        },
    };

    const getConversationsNumberOfNotifications = () => {
        if (conversations !== undefined && Array.isArray(conversations)) {
            const newConversations = conversations.filter(c => c.is_new);
            if (newConversations.length > 0) {
                return newConversations.length;
            }
        }
        return undefined;
    };

    return (
        <View
            style={{
                height: getTabBarHeight(),
                width: '100%',
                position: 'absolute',
                bottom: 0,
                zIndex: 20,
                opacity: 1,
            }}
        >
            <LinearGradient
                colors={theme.GRADIENTS.PRIMARY}
                useAngle
                angle={90}
                style={{
                    opacity: 1,
                    backgroundColor: theme.GRADIENTS.PRIMARY[0],
                    paddingBottom: getTabBarBottomPadding(),
                    height: '100%',
                    width: '100%',
                    paddingHorizontal: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '100%',
                        maxWidth: 700,
                    }}
                >
                    {Object.keys(tabsConfig).map(tabKey => {
                        if (
                            tabKey === 'random' &&
                            AppType.CURRENT_APP === 'NEZMO'
                        ) {
                            return null;
                        }
                        return (
                            <Tab
                                key={tabKey}
                                tab={tabKey}
                                isActive={
                                    currentTab ===
                                    tabsConfig[
                                        tabKey as
                                            | 'home'
                                            | 'conversations'
                                            | 'random'
                                    ].index
                                }
                                tabsConfig={tabsConfig}
                                goToTab={goToTab}
                                notifications={
                                    tabKey === 'conversations'
                                        ? getConversationsNumberOfNotifications()
                                        : undefined
                                }
                            />
                        );
                    })}
                </View>
            </LinearGradient>
        </View>
    );
};

// @ts-ignore
export default connector(BottomTabBar);
