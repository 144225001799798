import React, {memo, useState} from 'react';
import {Image, Platform, TouchableOpacity, View} from 'react-native';
import Animated, {
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';
import {useDispatch, useStore} from 'react-redux';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {
    formatProfilePicturePath,
    getConversationData,
    limitStringLength,
    normalize,
} from '../../modules/helpers';
import {RedDot, T} from '../Shared';
import {openConversation, removeConversations} from '../../modules/app/actions';
import FastImage from '../../../PackageWrappers/Components/FastImage';
import {logEvent} from '../../modules/app/tracking';
import useFastCallback from '../../modules/customHooks/useFastCallback';

const AnimatedTouchableOpacity =
    Animated.createAnimatedComponent(TouchableOpacity);

const deleteIcon = require('../../assets/images/deleteIcon.png');

const IMAGE_DIMENSIONS = 45;
export const BUTTON_HEIGHT = 68; // if changed remember to change the sectionList itemLayout (height + margin)

const questionMarkImage = require('../../assets/images/questionMarkImage.png');

const Triangle = ({color, direction = 'top'}) => {
    return (
        <View
            style={[
                {
                    width: 0,
                    height: 0,
                    backgroundColor: 'transparent',
                    borderStyle: 'solid',
                    borderLeftWidth: 4,
                    borderRightWidth: 4,
                    borderBottomWidth: direction === 'top' ? 8 : 0,
                    borderTopWidth: direction === 'bottom' ? 8 : 0,
                    borderLeftColor: 'transparent',
                    borderRightColor: 'transparent',
                    borderBottomColor: color,
                    borderTopColor: color,
                    marginHorizontal: 5,
                    opacity: 0.5,
                },
            ]}
        />
    );
};

const Tag = ({backgroundColor, color, label, blink, theme}) => {
    if (blink) {
        return (
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginStart: -5,
                    marginBottom: 4,
                }}
            >
                <RedDot style={{backgroundColor: theme.DANGER}} />
            </View>
        );
    }

    return (
        <View
            style={{
                backgroundColor:
                    theme.MODE === 'light' ? backgroundColor : color,
                borderRadius: 20,
                paddingHorizontal: 6,
                paddingVertical: Platform.select({ios: 2, android: 1}),
                marginEnd: 3,
            }}
        >
            <T
                style={{
                    fontSize: 8,
                    color: theme.MODE === 'light' ? color : theme.TEXT,
                }}
            >
                {label}
            </T>
        </View>
    );
};

const ConversationListItem = ({item, theme, setAlert}) => {
    const conversation = item.item;
    const {isInitiated, anonymous_nickname, is_random, app_is_new, is_blocked} =
        conversation;
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const containerWidthSV = useSharedValue('100%');
    const containerScaleSV = useSharedValue(1);
    const containerBorderWidth = useSharedValue(0);
    const containerBorderRadius = useSharedValue(0);
    const containerZIndex = useSharedValue(1);
    const containerPositionY = useSharedValue(0);
    const containerHeight = useSharedValue(BUTTON_HEIGHT);
    const optionsOpacity = useSharedValue(0);

    const dispatch = useDispatch();
    const store = useStore();

    const onConversationPressLocal = useFastCallback(() => {
        openConversation({
            conversation,
        })(dispatch, store.getState);
    }, [conversation.id]);

    const getProfileImage = () => {
        if (
            formatProfilePicturePath({
                profilePicture: getConversationData(conversation).picture,
            }) === ''
        ) {
            return questionMarkImage;
        }
        return {
            uri: formatProfilePicturePath({
                profilePicture: getConversationData(conversation).picture,
            }),
        };
    };

    const animtedStyle = useAnimatedStyle(() => {
        return {
            width: containerWidthSV.value,
            paddingVertical: 6,
            alignSelf: 'center',
            paddingHorizontal: 10,
            borderColor: theme.PRIMARY_DEVIDER,
            borderRadius: containerBorderRadius.value,
            borderWidth: containerBorderWidth.value,
            transform: [
                {scale: containerScaleSV.value},
                {translateY: containerPositionY.value},
            ],
        };
    }, []);

    const wrapperAnimatedStyle = useAnimatedStyle(() => {
        return {
            height: containerHeight.value,
            justifyContent: 'center',
            alignItems: 'center',
        };
    }, []);

    const onConversationFocus = () => {
        'worklet';

        const ANIMATION_DURATION = 300;
        containerWidthSV.value = withTiming('80%', {
            duration: ANIMATION_DURATION,
        });
        containerScaleSV.value = withTiming(1.1, {
            duration: ANIMATION_DURATION,
        });
        containerBorderWidth.value = withTiming(1, {
            duration: ANIMATION_DURATION,
        });
        containerBorderRadius.value = withTiming(20, {
            duration: ANIMATION_DURATION,
        });
        containerPositionY.value = withTiming(-25, {
            duration: ANIMATION_DURATION,
        });
        containerZIndex.value = withTiming(10, {
            duration: ANIMATION_DURATION,
        });
        containerHeight.value = withTiming(150, {
            duration: ANIMATION_DURATION,
        });
        optionsOpacity.value = withTiming(1, {
            duration: ANIMATION_DURATION,
        });
        runOnJS(setIsOptionsOpen)(true);
        setTimeout(() => {
            onConversationBlur();
        }, 3000);
    };

    const onConversationBlur = () => {
        const ANIMATION_DURATION = 300;
        containerWidthSV.value = withTiming('100%', {
            duration: ANIMATION_DURATION,
        });
        containerScaleSV.value = withTiming(1, {duration: ANIMATION_DURATION});
        containerBorderWidth.value = withTiming(0, {
            duration: ANIMATION_DURATION,
        });
        containerBorderRadius.value = withTiming(0, {
            duration: ANIMATION_DURATION,
        });
        containerPositionY.value = withTiming(0, {
            duration: ANIMATION_DURATION,
        });
        containerZIndex.value = withTiming(0, {
            duration: ANIMATION_DURATION,
        });
        containerHeight.value = withTiming(BUTTON_HEIGHT, {
            duration: ANIMATION_DURATION,
        });
        optionsOpacity.value = withTiming(
            0,
            {
                duration: ANIMATION_DURATION - 100,
            },
            finished => {
                if (finished) {
                    runOnJS(setIsOptionsOpen)(false);
                }
            },
        );
    };

    return (
        <Animated.View style={{...wrapperAnimatedStyle}}>
            <Animated.View
                style={[
                    animtedStyle,
                    {
                        backgroundColor: theme.BACKGROUND,
                        borderBottomColor: theme.PRIMARY_DEVIDER,
                    },
                ]}
            >
                <TouchableOpacity
                    activeOpacity={0.6}
                    onLongPress={() => {
                        onConversationFocus();
                    }}
                    onPress={onConversationPressLocal}
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingVertical: 7,
                    }}
                >
                    <FastImage
                        source={getProfileImage()}
                        style={{
                            resizeMode: 'contain',
                            height: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                            width: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                            borderRadius: 1000,
                            zIndex: 2,
                            borderColor: Constants.colors.main.dark,
                            borderWidth: 0.5,
                        }}
                    />
                    <View
                        style={{
                            flexGrow: 1,
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginStart: 10,
                            position: 'relative',
                            bottom: 2,
                        }}
                    >
                        <View
                            style={{flexDirection: 'row', alignItems: 'center'}}
                        >
                            <T
                                style={{
                                    color: theme.TEXT,
                                    fontSize: 12,
                                    fontFamily: Constants.fonts.Bold,
                                    marginBottom: 2,
                                }}
                            >
                                {limitStringLength(
                                    getConversationData(conversation).name,
                                    18,
                                )}
                            </T>
                            {isInitiated ? (
                                <Triangle
                                    direction="top"
                                    color={Constants.colors.success}
                                />
                            ) : (
                                <Triangle
                                    direction="bottom"
                                    color={Constants.colors.main.dark}
                                />
                            )}
                            {app_is_new === true && (
                                <Tag
                                    theme={theme}
                                    blink
                                    backgroundColor="#E3BABA"
                                    color="#B97E7E"
                                    label={
                                        lang().conversations_list
                                            .new_conversation_tag
                                    }
                                />
                            )}
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            {Boolean(is_random) && (
                                <Tag
                                    theme={theme}
                                    backgroundColor="#BACFFF"
                                    color="#6F8AC9"
                                    label={
                                        lang().conversations_list
                                            .random_conversation_tag
                                    }
                                />
                            )}
                            {!is_random && (
                                <Tag
                                    theme={theme}
                                    backgroundColor="#FFDFBA"
                                    color="#BC9363"
                                    label={
                                        lang().conversations_list.from_link_tag
                                    }
                                />
                            )}
                            {isInitiated && !is_random && (
                                <Tag
                                    theme={theme}
                                    backgroundColor="#EDEDED"
                                    color="#A3A3A3"
                                    label={`${
                                        lang().your_nickname
                                    } (${limitStringLength(
                                        anonymous_nickname,
                                        18,
                                    )}) `}
                                />
                            )}
                            {!isInitiated &&
                                (is_blocked === 1 || is_blocked === true) && (
                                    <Tag
                                        theme={theme}
                                        backgroundColor="#EDEDED"
                                        color="#A3A3A3"
                                        label={
                                            lang().conversations_list.blocked
                                        }
                                    />
                                )}
                        </View>
                    </View>
                    <View />
                </TouchableOpacity>
            </Animated.View>
            {isOptionsOpen && (
                <ConversationDeleteButton
                    optionsOpacity={optionsOpacity}
                    conversationID={item.item.id}
                    onConversationBlur={onConversationBlur}
                    setAlert={setAlert}
                    theme={theme}
                />
            )}
        </Animated.View>
    );
};

const ConversationDeleteButton = ({
    optionsOpacity,
    conversationID,
    onConversationBlur,
    setAlert,
    theme,
}) => {
    const dispatch = useDispatch();
    const store = useStore();

    const animtedStyle = useAnimatedStyle(() => {
        return {
            opacity: optionsOpacity.value,
            backgroundColor: Constants.colors.danger,
            position: 'absolute',
            paddingVertical: 7,
            paddingHorizontal: 17,
            borderRadius: 20,
            bottom: 10,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        };
    });

    return (
        <AnimatedTouchableOpacity
            onPress={() => {
                setAlert({
                    show: true,
                    message: lang().remove_conversation_confirm,
                    confirmText: lang().confirm_removal,
                    confirmColor: theme.DANGER,
                    cancelText: lang().cancel,
                    theme,
                    onConfirmPressed: () => {
                        setAlert(null);
                        onConversationBlur();
                        logEvent('RemoveConversationFromLongPress');
                        removeConversations({
                            willBeRemovedConversations: [conversationID],
                        })(dispatch, store.getState);
                    },
                    onCancelPressed: () => {
                        onConversationBlur();
                        setAlert(null);
                    },
                    onDismiss: () => {
                        onConversationBlur();
                        setAlert(null);
                    },
                });
            }}
            style={{
                ...animtedStyle,
            }}
        >
            <Image
                source={deleteIcon}
                style={{
                    tintColor: Constants.colors.whiteMain,
                    width: 18,
                    height: 18,
                    marginEnd: 10,
                }}
            />
            <T
                style={{
                    color: Constants.colors.whiteMain,
                    fontFamily: Constants.fonts.Bold,
                    fontSize: normalize(11),
                    textAlign: 'center',
                    marginTop: -1,
                }}
            >
                {lang().remove_conversation}
            </T>
        </AnimatedTouchableOpacity>
    );
};

export default ConversationListItem;
