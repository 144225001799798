import React, {useEffect, useState} from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    ActivityIndicator,
    Image,
} from 'react-native';
import {T} from '../Shared';
import lang from '../../localization';
import {tinyClick} from '../../modules/app/vibrations';
import Constants from '../../assets/Constants';
import Modal from '../Shared/Modal';
import {normalize} from '../../modules/helpers';
import analytics from '../../modules/app/react-native-firebase-analytics';

const checkIcon = require('../../assets/images/checkIcon.png');
const warningIcon = require('../../assets/images/warningIcon.png');

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    modalView: {
        borderRadius: 9,
        paddingBottom: 10,
    },
    settingsModalButton: {
        paddingHorizontal: 10,
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
    },
    settingsModalButtonText: {
        color: '#808080',
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(14),
        textAlign: 'center',
    },
    deleteIcon: {
        tintColor: '#C37272',
        width: 20,
        height: 20,
        marginStart: 10,
    },
    divider: {
        width: '100%',
        borderWidth: 0.5,
    },
    confirmButton: {
        paddingVertical: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
    },
    confirmButtonText: {color: '#9D9ABF', fontFamily: Constants.fonts.Bold},
    cancelButtonText: {color: '#BCBCBC', fontFamily: Constants.fonts.Bold},
    openButton: {
        backgroundColor: '#F194FF',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
});

const ReportModal = ({
    show,
    setShow,
    currentRandomUser,
    reportRandomUser,
    hideRandomUser,
    searchForAnotherRandomUser,
    theme,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isFinishedReporting, setIsFinishedReporting] = useState(false);
    const [reason, setReason] = useState(undefined);

    const hideModal = (vibrate = false) => {
        if (vibrate) {
            tinyClick();
        }
        setIsLoading(false);
        setIsFinishedReporting(false);
        setShow(false);
    };

    useEffect(() => {
        setReason(undefined);
    }, [show]);

    const renderModalBody = () => {
        if (currentRandomUser === undefined) {
            return <View />;
        }

        if (isFinishedReporting) {
            return (
                <TouchableOpacity
                    accessibilityHint="Report modal, report sent"
                    accessibilityLabel="Report modal, report sent"
                    activeOpacity={1}
                    style={[
                        styles.modalView,
                        {
                            height: 150,
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    ]}
                    testID="conversationSettingsSection"
                >
                    <Image
                        source={checkIcon}
                        style={{
                            tintColor: Constants.colors.success,
                            height: 50,
                            width: 50,
                            resizeMode: 'contain',
                        }}
                    />
                </TouchableOpacity>
            );
        }

        if (isLoading) {
            return (
                <TouchableOpacity
                    activeOpacity={1}
                    style={[
                        styles.modalView,
                        {
                            height: 150,
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    ]}
                    testID="conversationSettingsSection"
                >
                    <ActivityIndicator
                        size="large"
                        color={theme.TEXT_PRIMARY}
                    />
                </TouchableOpacity>
            );
        }

        if (reason) {
            return (
                <TouchableOpacity
                    activeOpacity={1}
                    style={styles.modalView}
                    testID="conversationSettingsSection"
                >
                    <View style={{marginVertical: 20}}>
                        <View style={styles.settingsModalButton}>
                            <T
                                style={{
                                    ...styles.settingsModalButtonText,
                                    color: theme.TEXT,
                                }}
                            >
                                {lang().report_random_user_modal_title(
                                    currentRandomUser.name,
                                )}
                            </T>
                        </View>

                        <View style={{alignItems: 'center', marginTop: 15}}>
                            <Image
                                source={warningIcon}
                                style={{
                                    height: 45,
                                    width: 45,
                                    resizeMode: 'contain',
                                    marginBottom: 5,
                                    tintColor: theme.DANGER,
                                }}
                            />
                            <T
                                style={{
                                    fontFamily: Constants.fonts.SemiBold,
                                    textAlign: 'center',
                                    fontSize: normalize(12),
                                    color: theme.DANGER,
                                }}
                            >
                                {lang().report_random_user_modal_warning}
                            </T>
                        </View>
                    </View>

                    <View
                        style={{
                            ...styles.divider,
                            borderColor: theme.PRIMARY_DEVIDER,
                        }}
                    />
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            paddingTop: 10,
                        }}
                    >
                        <TouchableOpacity
                            style={styles.confirmButton}
                            onPress={() => {
                                const finishedReporting = () => {
                                    setIsLoading(false);
                                    setIsFinishedReporting(true);
                                    setTimeout(() => {
                                        hideModal();
                                        searchForAnotherRandomUser();
                                    }, 1000);
                                };
                                hideRandomUser({
                                    userLink: currentRandomUser.user_link,
                                });
                                setIsLoading(true);
                                reportRandomUser({
                                    userLink: currentRandomUser.user_link,
                                })
                                    .then(({data}) => {
                                        analytics().logEvent(
                                            'reportedRandomUser',
                                        );
                                        finishedReporting();
                                    })
                                    .catch(error => {
                                        finishedReporting();
                                    });
                            }}
                            testID="conversationSettingsModalCancelButton"
                        >
                            <T
                                style={{
                                    ...styles.confirmButtonText,
                                    color: theme.TEXT_PRIMARY,
                                }}
                            >
                                {lang().report_random_user_modal_confirm}
                            </T>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.confirmButton}
                            onPress={hideModal}
                            testID="conversationSettingsModalCancelButton"
                        >
                            <T
                                style={{
                                    ...styles.cancelButtonText,
                                    color: theme.TEXT_MUTED,
                                }}
                            >
                                {lang().report_random_user_modal_cancel}
                            </T>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            );
        }

        return (
            <TouchableOpacity
                activeOpacity={1}
                style={styles.modalView}
                testID="conversationSettingsSection"
            >
                <View style={{marginVertical: 10, paddingHorizontal: 20}}>
                    <View style={styles.settingsModalButton}>
                        <T
                            style={{
                                ...styles.settingsModalButtonText,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().report_random_user_modal_chose_reason}
                        </T>
                    </View>

                    <TouchableOpacity
                        onPress={() => {
                            setReason('image');
                        }}
                        style={{
                            marginTop: 20,
                            alignItems: 'center',
                            paddingHorizontal: 13,
                            paddingVertical: 10,
                            borderRadius: 20,
                            backgroundColor: theme.TERTIARY,
                            ...Constants.elevation.one,
                        }}
                    >
                        <T
                            style={{
                                fontFamily: Constants.fonts.SemiBold,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().report_random_user_modal_bad_image}
                        </T>
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            setReason('name');
                        }}
                        style={{
                            alignItems: 'center',
                            marginTop: 10,
                            paddingHorizontal: 13,
                            paddingVertical: 10,
                            borderRadius: 20,
                            backgroundColor: theme.TERTIARY,
                            ...Constants.elevation.one,
                        }}
                    >
                        <T
                            style={{
                                fontFamily: Constants.fonts.SemiBold,
                                color: theme.TEXT,
                            }}
                        >
                            {lang().report_random_user_modal_bad_name}
                        </T>
                    </TouchableOpacity>
                </View>

                <TouchableOpacity
                    style={styles.confirmButton}
                    onPress={hideModal}
                    testID="conversationSettingsModalCancelButton"
                >
                    <T
                        style={{
                            ...styles.cancelButtonText,
                            color: theme.TEXT_MUTED,
                        }}
                    >
                        {lang().report_random_user_modal_cancel}
                    </T>
                </TouchableOpacity>
            </TouchableOpacity>
        );
    };
    return (
        <Modal
            isVisible={show}
            theme={theme}
            closeModal={hideModal}
        >
            {renderModalBody()}
        </Modal>
    );
};

export default ReportModal;
