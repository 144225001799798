import * as React from 'react';
import {View} from 'react-native';
import Animated, {
    SharedValue,
    useAnimatedProps,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withSequence,
    withSpring,
    withTiming,
} from 'react-native-reanimated';
import Svg, {SvgProps, Path} from 'react-native-svg';
import {isWeb} from '../../../../env';

const AnimatedPath = Animated.createAnimatedComponent(Path);

const ConversationIcon = (props: SvgProps) => {
    const firstTextWidth = useSharedValue(40);
    const secondTextWidth = useSharedValue(90);
    const thirdTextWidth = useSharedValue(20);

    const opacitySV = useSharedValue(0);

    const getFirstPath = (textWidth: SharedValue<number>) => {
        'worklet';

        return `M178.666 170.7h-54c-5.5 0-10 4.5-10 10s4.5 10 10 10h${textWidth.value}c5.5 0 10-4.5 10-10s-4.5-10-10-10z`;
    };

    const getSecondPath = (textWidth: SharedValue<number>) => {
        'worklet';

        return `M248.666 210.7h-124c-5.5 0-10 4.5-10 10s4.5 10 10 10h${textWidth.value}c5.5 0 10-4.5 10-10s-4.5-10-10-10z`;
    };

    const animatedProps = useAnimatedProps(() => {
        return {
            d: getFirstPath(firstTextWidth),
        };
    }, []);

    const animatedProps2 = useAnimatedProps(() => {
        return {
            d: getSecondPath(secondTextWidth),
        };
    }, []);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: opacitySV.value,
        };
    }, []);

    React.useEffect(() => {
        if (!isWeb) {
            firstTextWidth.value = withRepeat(
                withDelay(
                    3000,
                    withSequence(
                        withTiming(100, {duration: 750}),
                        withTiming(40, {duration: 750}),
                    ),
                ),
                -1,
                true,
            );
            secondTextWidth.value = withRepeat(
                withDelay(
                    3000,
                    withSequence(
                        withTiming(20, {duration: 750}),
                        withTiming(90, {duration: 750}),
                    ),
                ),
                -1,
                true,
            );
            thirdTextWidth.value = withRepeat(
                withDelay(
                    3000,
                    withSequence(
                        withTiming(30, {duration: 750}),
                        withTiming(20, {duration: 750}),
                    ),
                ),
                -1,
                true,
            );
            opacitySV.value = withTiming(1, {duration: 300});
        }
    }, []);

    if (isWeb) {
        return (
            <View style={{opacity: 1}}>
                <Svg
                    viewBox="0 0 373.232 373.232"
                    {...props}
                >
                    <Path d="M187.466 0c-.1.1-.3.1-.6.1-101.2 0-183.5 82.3-183.5 183.5 0 41.3 14.1 81.4 39.9 113.7l-26.7 62c-2.2 5.1.2 11 5.2 13.1 1.8.8 3.8 1 5.7.7l97.9-17.2c19.6 7.1 40.2 10.7 61 10.6 101.2 0 183.5-82.3 183.5-183.5.2-100.9-81.5-182.9-182.4-183zm-1 346.6c-19.3 0-38.4-3.5-56.5-10.3-1.7-.7-3.5-.8-5.3-.5l-82.4 14.4 21.8-50.7c1.5-3.5.9-7.6-1.6-10.5-11.8-13.7-21.2-29.3-27.8-46.2-7.4-18.9-11.2-39-11.2-59.3 0-90.2 73.4-163.5 163.5-163.5 89.9-.2 162.9 72.5 163 162.4v.6c0 90.3-73.4 163.6-163.5 163.6z" />
                    <Path d={getFirstPath(firstTextWidth)} />
                    <Path d={getSecondPath(secondTextWidth)} />
                </Svg>
            </View>
        );
    }

    return (
        <Animated.View style={[animatedStyle]}>
            <Svg
                viewBox="0 0 373.232 373.232"
                {...props}
            >
                <Path d="M187.466 0c-.1.1-.3.1-.6.1-101.2 0-183.5 82.3-183.5 183.5 0 41.3 14.1 81.4 39.9 113.7l-26.7 62c-2.2 5.1.2 11 5.2 13.1 1.8.8 3.8 1 5.7.7l97.9-17.2c19.6 7.1 40.2 10.7 61 10.6 101.2 0 183.5-82.3 183.5-183.5.2-100.9-81.5-182.9-182.4-183zm-1 346.6c-19.3 0-38.4-3.5-56.5-10.3-1.7-.7-3.5-.8-5.3-.5l-82.4 14.4 21.8-50.7c1.5-3.5.9-7.6-1.6-10.5-11.8-13.7-21.2-29.3-27.8-46.2-7.4-18.9-11.2-39-11.2-59.3 0-90.2 73.4-163.5 163.5-163.5 89.9-.2 162.9 72.5 163 162.4v.6c0 90.3-73.4 163.6-163.5 163.6z" />
                <AnimatedPath animatedProps={animatedProps} />
                <AnimatedPath animatedProps={animatedProps2} />
            </Svg>
        </Animated.View>
    );
};
export default ConversationIcon;
