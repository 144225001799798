import React, {useState, useEffect} from 'react';
import {Animated, Platform} from 'react-native';

const FadeInViewIOS = props => {
    const [fadeAnim] = useState(new Animated.Value(0)); // Initial value for opacity: 0

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
        }).start();
    }, [fadeAnim]);

    const style = {
        ...props.style,
        opacity: Platform.OS === 'ios' ? fadeAnim : 1,
    };

    return <Animated.View style={style}>{props.children}</Animated.View>;
};

export default FadeInViewIOS;
