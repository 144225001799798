import {initializeApp} from 'firebase/app';
import {
    Analytics,
    getAnalytics,
    logEvent as logEventWeb,
    setUserId,
    setUserProperties,
} from 'firebase/analytics';
import {Platform} from 'react-native';
import {User} from '../../types/data/user';
import {isWeb} from '../../../env';
import analytics from './react-native-firebase-analytics';

const firebaseConfig = {
    apiKey: 'AIzaSyB6LhJYv-ioKOZlFbc2CwLcOOgkzdnrbwk',
    authDomain: 'fadfadah-bc6ca.firebaseapp.com',
    databaseURL: 'https://fadfadah-bc6ca.firebaseio.com',
    projectId: 'fadfadah-bc6ca',
    storageBucket: 'fadfadah-bc6ca.appspot.com',
    messagingSenderId: '197414229179',
    appId: '1:197414229179:web:1ca0e59be5f5e6d399859e',
    measurementId: 'G-1PXGVPTP68',
};

let app: any = null;
if (isWeb) {
    app = initializeApp(firebaseConfig);
}

export const firebaseWebAnalyticsInstance = app ? getAnalytics(app) : {};

export const logEvent = (event: string, data: any = undefined) => {
    if (isWeb && app) {
        logEventWeb(firebaseWebAnalyticsInstance as Analytics, event, data);
    } else {
        analytics().logEvent(event, data);
    }
};

export const setUser = ({user}: {user: User}) => {
    const properties = {
        unique_id: `${user.user_link}-${user.id}-${user.name}`,
        user_link: user.user_link,
        username: user.name,
        platform: isWeb ? 'mobile' : 'web',
        os: `${isWeb ? 'web' : Platform.OS}`,
    };

    if (isWeb && app) {
        setUserId(firebaseWebAnalyticsInstance as Analytics, `${user.id}`);
        setUserProperties(
            firebaseWebAnalyticsInstance as Analytics,
            properties,
        );
    } else {
        analytics().setUserId(`${user.id}`);
        analytics().setUserProperties(properties);
    }
};

export const logSignup = ({
    skipLogin = false,
    method = 'email',
}: {
    skipLogin?: boolean;
    method: string;
}) => {
    if (isWeb) {
        logEvent('signUp', {method});
    } else {
        analytics().logSignUp({method});
    }

    logLogin({method});

    if (skipLogin) {
        logEvent('skippedLogin');
    }
};

export const logLogin = ({method = 'email'}: {method: string}) => {
    if (isWeb) {
        logEvent('login', {method});
    } else {
        analytics().logLogin({method});
    }
};

export const logShare = (data: any) => {
    if (isWeb) {
        logEvent('share', data);
    } else {
        analytics().logShare(data);
    }
};

export const logScreenView = ({screenName}: {screenName: string}) => {
    if (isWeb) {
        logEvent('screen_view', {screen_name: screenName});
    } else {
        analytics().logScreenView({
            screen_name: screenName,
            screen_class: screenName,
        });
    }
};
