import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import {Vibration, Platform} from 'react-native';

/**
 * iOS possible values :
 * "selection",
 * "impactLight",
 * "impactMedium",
 * "impactHeavy",
 * "notificationSuccess",
 * "notificationWarning",
 * "notificationError",
 */
function vibrate({iosMethod, androidTime}) {
    setTimeout(() => {
        Platform.select({
            ios: () => {
                ReactNativeHapticFeedback.trigger(iosMethod, {
                    enableVibrateFallback: false,
                });
            },
            android: () => {
                const androidVersion = Platform.Version;
                if (androidVersion >= 28) {
                    Vibration.vibrate(androidTime);
                }
            },
            web: () => {
                console.log('zzzzz');
            },
        })();
    }, 0);
}

export function tinyClick() {
    vibrate({iosMethod: 'impactMedium', androidTime: 1});
}

export function mediumClick() {
    vibrate({iosMethod: 'impactHeavy', androidTime: 5});
}

export function successVibration() {
    vibrate({iosMethod: 'notificationSuccess', androidTime: [0, 20, 50, 20]});
}

export function errorVibration() {
    vibrate({iosMethod: 'notificationError', androidTime: [0, 100, 50, 100]});
}
