import {ImageSourcePropType} from 'react-native';

export const frameSources: {[frameName: string]: ImageSourcePropType} = {
    crown: require('../../assets/images/frames/crownIcon.png'),
    ant: require('../../assets/images/frames/ant.png'),
    hair: require('../../assets/images/frames/hair.png'),
    cat: require('../../assets/images/frames/cat.png'),
    devil: require('../../assets/images/frames/devil.png'),
    graduation: require('../../assets/images/frames/graduationCap.png'),
    rabbit: require('../../assets/images/frames/rabbit.png'),
    curlyHair: require('../../assets/images/frames/curlyHair.png'),
    star: require('../../assets/images/frames/star.png'),
    flash: require('../../assets/images/frames/flash.png'),
    fruit: require('../../assets/images/frames/fruit.png'),
};

const shapes: {
    [colorName: string]: {
        value: undefined | string;
        name: string;
        isLocked: boolean;
    };
} = {
    undefined: {
        value: undefined,
        name: 'undefined',
        isLocked: false,
    },
    ant: {
        value: 'ant',
        name: 'ant',
        isLocked: false,
    },
    hair: {
        value: 'hair',
        name: 'hair',
        isLocked: false,
    },
    cat: {
        value: 'cat',
        name: 'cat',
        isLocked: true,
    },
    crown: {
        value: 'crown',
        name: 'crown',
        isLocked: true,
    },
    devil: {
        value: 'devil',
        name: 'devil',
        isLocked: true,
    },
    graduation: {
        value: 'graduation',
        name: 'graduation',
        isLocked: true,
    },
    rabbit: {
        value: 'rabbit',
        name: 'rabbit',
        isLocked: true,
    },
    curlyHair: {
        value: 'curlyHair',
        name: 'curlyHair',
        isLocked: true,
    },
    star: {
        value: 'star',
        name: 'star',
        isLocked: true,
    },
    flash: {
        value: 'flash',
        name: 'flash',
        isLocked: true,
    },
    fruit: {
        value: 'fruit',
        name: 'fruit',
        isLocked: true,
    },
};

export default shapes;
