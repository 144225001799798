import {AnyAction} from 'redux';
import {Conversation} from '../../types/data/conversation';
import * as t from './actionTypes';

export interface ConversationsReducer {
    activeConversation?: number;
    conversations?: Conversation[];
}

const initialState: ConversationsReducer = {
    activeConversation: undefined,
    conversations: undefined,
};

export type ConversationsReducerAction = AnyAction & {
    data: Conversation & {
        image?: string;
        shouldRemoveDeletedConversations?: boolean;
        conversationID?: number;
        id?: number;
        willBeRemovedConversations?: number[];
        conversations?: Conversation[];
    };
};

const conversationsReducer = (
    state = initialState,
    action: ConversationsReducerAction,
) => {
    switch (action.type) {
        case t.LOAD_CONVERSATIONS:
            const {conversations} = action.data;
            const getConversations = () => {
                let currentConversations = state.conversations
                    ? [...state.conversations]
                    : undefined;
                if (conversations && currentConversations) {
                    // Adding and updating new conversations to the current conversations
                    conversations.forEach(newConversation => {
                        let didUpdateConversation = false;
                        if (currentConversations) {
                            for (
                                let i = 0;
                                i < currentConversations.length;
                                i++
                            ) {
                                if (
                                    currentConversations[i].id ===
                                    newConversation.id
                                ) {
                                    if (
                                        currentConversations[i].id ===
                                        state.activeConversation
                                    ) {
                                        currentConversations[i].is_new = 0;
                                    } else {
                                        currentConversations[i].is_new =
                                            newConversation.is_new;
                                        currentConversations[i].is_new_user =
                                            newConversation.is_new_user;
                                        currentConversations[
                                            i
                                        ].is_new_anonymous =
                                            newConversation.is_new_anonymous;
                                    }
                                    didUpdateConversation = true;
                                    break;
                                }
                            }
                        }
                        if (!didUpdateConversation) {
                            currentConversations?.unshift({...newConversation});
                        }
                    });

                    // removing conversations that are not in the new conversations
                    // from the current conversations
                    if (action.data.shouldRemoveDeletedConversations) {
                        const conversationsIDs = conversations.map(c => c.id);
                        const shouldBeRemovedIDs = currentConversations?.filter(
                            c => {
                                return !conversationsIDs.includes(c.id);
                            },
                        );
                        currentConversations = currentConversations?.filter(
                            c => !shouldBeRemovedIDs?.includes(c),
                        );
                    }
                    return currentConversations;
                }
                return conversations;
            };

            return {
                ...state,
                conversations: getConversations(),
            };

        case t.UNLOAD_CONVERSATIONS:
            return {
                ...state,
                conversations: undefined,
            };

        case t.MARK_CONVERSATION_AS_ACTIVE:
            return {
                ...state,
                activeConversation: action.data.conversationID,
            };

        case t.UNSET_IS_NEW:
            if (!state.conversations) {
                return {...state};
            }
            const newConversations = state.conversations.map(
                conversationObject => {
                    if (conversationObject.id === action.data.conversationID) {
                        conversationObject = {
                            ...conversationObject,
                            is_new: 0,
                            is_new_anonymous: 0,
                            is_new_user: 0,
                        };
                    }
                    return conversationObject;
                },
            );
            return {
                ...state,
                conversations: newConversations,
            };

        case t.SET_IS_NEW:
            if (!state.conversations) {
                return {...state};
            }
            const newConversations5 = state.conversations.map(
                conversationObject => {
                    if (conversationObject.id === action.data.conversationID) {
                        if (conversationObject.isInitiated) {
                            conversationObject = {
                                ...conversationObject,
                                is_new_user: 1,
                            };
                        } else {
                            conversationObject = {
                                ...conversationObject,
                                is_new_anonymous: 1,
                            };
                        }
                        conversationObject = {...conversationObject, is_new: 1};
                    }
                    return conversationObject;
                },
            );
            return {
                ...state,
                conversations: newConversations5,
            };

        case t.FREE_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation: undefined,
            };

        case t.NEW_CONVERSATION_RECEIVED:
            if (state.conversations === undefined) {
                return {
                    ...state,
                };
            }
            const conversationAlreadyExist = () => {
                const sameIDConversations = state?.conversations?.filter(
                    conversation => {
                        if (conversation.id === action.data.id) {
                            return true;
                        }
                        return false;
                    },
                );
                if (sameIDConversations && sameIDConversations.length > 0) {
                    return true;
                }
                return false;
            };

            if (conversationAlreadyExist()) {
                return {
                    ...state,
                };
            }
            const clonedConversations = [
                {
                    id: action.data.id,
                    anonymous_nickname: action.data.anonymous_nickname,
                    picture: action.data.picture,
                    gender: action.data.gender,
                    is_new: 1,
                    is_blocked: 0,
                    is_random: action.data.is_random,
                },
                ...state.conversations,
            ];

            return {
                ...state,
                conversations: clonedConversations,
            };

        case t.REMOVE_CONVERSATIONS:
            const {willBeRemovedConversations} = action.data;

            const newConversations6: Conversation[] = state.conversations
                ? state.conversations.filter(conversationObject => {
                      return !willBeRemovedConversations?.includes(
                          conversationObject.id,
                      );
                  })
                : [];

            return {
                ...state,
                conversations: [...newConversations6],
            };

        case t.SET_CONVERSATION_IS_TYPING:
            if (state.conversations === undefined) {
                return {
                    ...state,
                };
            }
            const newConversations7 = state.conversations.map(
                conversationObject => {
                    if (
                        `${conversationObject.id}` ===
                        `${action.data.conversationID}`
                    ) {
                        conversationObject = {
                            ...conversationObject,
                            isTyping: true,
                        };
                    }
                    return conversationObject;
                },
            );
            return {
                ...state,
                conversations: [...newConversations7],
            };

        case t.UNSET_CONVERSATION_IS_TYPING:
            if (state.conversations === undefined) {
                return {
                    ...state,
                };
            }
            const newConversation8 = state.conversations.map(
                conversationObject => {
                    if (
                        `${conversationObject.id}` ===
                        `${action.data.conversationID}`
                    ) {
                        conversationObject = {
                            ...conversationObject,
                            isTyping: false,
                        };
                    }
                    return conversationObject;
                },
            );
            return {
                ...state,
                conversations: [...newConversation8],
            };

        case t.BLOCK_CONVERSATION:
            const newConversation10: Conversation[] = state?.conversations
                ? state.conversations.map(conversationObject => {
                      if (
                          conversationObject.id === action.data.conversationID
                      ) {
                          conversationObject = {
                              ...conversationObject,
                              is_blocked: 1,
                          };
                          return conversationObject;
                      }
                      return conversationObject;
                  })
                : [];
            return {
                ...state,
                conversations: [...newConversation10],
            };

        case t.UNBLOCK_CONVERSATION:
            const newConversation11: Conversation[] = state?.conversations
                ? state.conversations.map(conversationObject => {
                      if (
                          conversationObject.id === action.data.conversationID
                      ) {
                          conversationObject = {
                              ...conversationObject,
                              is_blocked: 0,
                          };
                          return conversationObject;
                      }
                      return conversationObject;
                  })
                : [];
            return {
                ...state,
                conversations: [...newConversation11],
            };

        default:
            return state;
    }
};

export default conversationsReducer;
