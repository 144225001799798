import React, {useState} from 'react';
import {
    SafeAreaView,
    StyleSheet,
    View,
    TouchableOpacity,
    Image,
    ScrollView,
} from 'react-native';
import {connect} from 'react-redux';
import store from '../modules/redux/store';
import Constants from '../assets/Constants';
import {T, TopBar} from '../components/Shared';
import lang from '../localization';
import {getConversationData, normalize} from '../modules/helpers';
import {actions as appActions} from '../modules/app';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import FastImage from '../../PackageWrappers/Components/FastImage';
import CustomAlert from '../components/Shared/CustomAlert';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    settingsCustomizedSectionContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: 0,
        paddingBottom: 20,
        borderRadius: 12,
        marginTop: 20,
        zIndex: 1,
    },
    noConversationsToDeleteText: {
        marginTop: 5,
        fontSize: normalize(13),
        fontFamily: Constants.fonts.Bold,
    },

    settingsCustomizedTitleContainer: {
        backgroundColor: 'red',
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 20,
        marginBottom: -20,
        marginTop: 10,
        zIndex: 2,
        ...Constants.elevation.three,
    },
    settingsCustomizedTitleText: {
        color: 'white',
        fontSize: normalize(14),
        fontFamily: Constants.fonts.Bold,
    },
    settingsMenuContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    willBeRemovedConversationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '85%',
        borderRadius: 7,
        marginTop: 11,
        height: normalize(50),
        paddingHorizontal: 15,
    },
    willBeRemovedConversationText: {
        color: 'white',
        fontFamily: Constants.fonts.SemiBold,
        fontSize: normalize(13),
    },
    checkIconContainer: {
        borderRadius: 100,
        padding: 5,
    },
    checkIcon: {
        height: normalize(13),
        width: normalize(13),
        resizeMode: 'contain',
    },
    inactiveCheckIconContainer: {
        backgroundColor: 'white',
    },
    inactiveCheckIcon: {
        tintColor: 'rgb(90, 90, 90)',
    },
    activeCheckIconContainer: {
        backgroundColor: Constants.colors.main.dark,
    },
    activeCheckIcon: {
        opacity: 0.9,
    },
    removeButton: {
        backgroundColor: '#bc5959',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        borderRadius: 20,
        height: normalize(35),
        marginTop: 20,
        paddingHorizontal: 10,
        opacity: 0.8,
    },
    removeButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(14),
        marginHorizontal: 10,
        color: 'white',
    },
    deleteIcon: {
        height: normalize(20),
        width: normalize(20),
        marginHorizontal: 10,
        resizeMode: 'contain',
    },
});

const checkIcon = require('../assets/images/checkIcon.png');
const deleteIcon = require('../assets/images/deleteIcon.png');

function RemoveConversationsScreen({navigation, ...props}) {
    const theme = useTheme();

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    const {conversations} = props;
    const filteredConversations = Array.isArray(conversations)
        ? conversations
        : [];
    const [willBeRemovedConversations, setWillBeRemovedConversations] =
        useState([]);
    const [alert, setAlert] = useState(null);

    const markConversationToBeRemoved = id => {
        if (willBeRemovedConversations.includes(id)) {
            setWillBeRemovedConversations(
                willBeRemovedConversations.filter(
                    activeConID => activeConID !== id,
                ),
            );
        } else {
            setWillBeRemovedConversations([...willBeRemovedConversations, id]);
        }
    };

    const renderWillBeRemovedConversations = () => {
        if (filteredConversations === undefined) {
            return <View />;
        }

        if (filteredConversations.length === 0) {
            return (
                <View testID="noConversationsSection">
                    <T
                        style={{
                            ...styles.noConversationsToDeleteText,
                            color: theme.TEXT,
                        }}
                    >
                        {lang().no_conversations}
                    </T>
                </View>
            );
        }

        return filteredConversations.map(conversation => {
            const isActive = willBeRemovedConversations.includes(
                conversation.id,
            );

            return (
                <TouchableOpacity
                    testID={`willBeRemovedConversation${conversation.id}`}
                    onPress={() => {
                        markConversationToBeRemoved(conversation.id);
                    }}
                    key={`${conversation.id}${conversation.isInitiated}`}
                    style={{
                        ...styles.willBeRemovedConversationContainer,
                        backgroundColor: theme.SECONDARY,
                    }}
                >
                    <T
                        style={{
                            ...styles.willBeRemovedConversationText,
                            color: theme.TEXT,
                        }}
                    >
                        {getConversationData(conversation).name}
                    </T>
                    <View
                        style={[
                            styles.checkIconContainer,
                            isActive
                                ? {
                                      backgroundColor: theme.PRIMARY,
                                  }
                                : styles.inactiveCheckIconContainer,
                        ]}
                    >
                        <Image
                            source={checkIcon}
                            style={[
                                styles.checkIcon,
                                isActive
                                    ? styles.activeCheckIcon
                                    : styles.inactiveCheckIcon,
                            ]}
                        />
                    </View>
                </TouchableOpacity>
            );
        });
    };

    return (
        <SafeAreaView
            style={{...styles.container, backgroundColor: theme.BACKGROUND}}
        >
            <StatusBarAware theme={theme} />
            <View style={{width: '100%'}}>
                <TopBar
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    titleText={lang().remove_conversations}
                />
            </View>
            <ScrollView
                style={{width: '100%', maxWidth: 700}}
                contentContainerStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 100,
                }}
            >
                <View style={styles.settingsCustomizedSectionContainer}>
                    {renderWillBeRemovedConversations()}
                    {willBeRemovedConversations.length > 0 && (
                        <TouchableOpacity
                            style={styles.removeButton}
                            onPress={() => {
                                setAlert({
                                    show: true,
                                    title: lang()
                                        .remove_conversations_alert_title,
                                    message: `(${
                                        willBeRemovedConversations.length
                                    }) ${
                                        lang()
                                            .remove_conversations_alert_message
                                    }`,
                                    confirmText: lang().confirm_removal,
                                    confirmColor: theme.DANGER,
                                    cancelText: lang().cancel,
                                    theme,
                                    onConfirmPressed: () => {
                                        setAlert(null);
                                        navigation.navigate(SCREENS.WELCOME);
                                        props.removeConversations({
                                            willBeRemovedConversations,
                                        });
                                        setWillBeRemovedConversations([]);
                                    },
                                    onCancelPressed: () => {
                                        setAlert(null);
                                    },
                                    onDismiss: () => {
                                        setAlert(null);
                                    },
                                });
                            }}
                            testID="removeConversationsSettingsButton"
                        >
                            <T style={styles.removeButtonText}>
                                {lang().remove}
                            </T>
                            <FastImage
                                source={deleteIcon}
                                style={styles.deleteIcon}
                            />
                        </TouchableOpacity>
                    )}
                    <TouchableOpacity
                        style={Constants.hiddenComponent}
                        onPress={() => {
                            if (Array.isArray(filteredConversations)) {
                                const allConversationsIDs =
                                    filteredConversations.map(c => c.id);
                                navigation.navigate(SCREENS.WELCOME);
                                props.removeConversations({
                                    willBeRemovedConversations:
                                        allConversationsIDs,
                                });
                            }
                        }}
                        testID="removeAllConversationsSettingsButton"
                    />
                </View>
            </ScrollView>
            {/* </View> */}
            {alert && (
                <CustomAlert
                    show={alert.show}
                    message={alert.message}
                    confirmText={alert.confirmText}
                    confirmColor={alert.confirmColor}
                    cancelText={alert.cancelText}
                    theme={alert.theme}
                    onConfirmPressed={alert.onConfirmPressed}
                    onCancelPressed={alert.onCancelPressed}
                    onDismiss={alert.onDismiss}
                />
            )}
        </SafeAreaView>
    );
}

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    removeConversations: data =>
        appActions.removeConversations(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    conversations: state.conversationsReducer.conversations,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RemoveConversationsScreen);
