import React from 'react';
import {View, TouchableOpacity, Text, Image} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import colors from './colors';
import {Theme} from '../../assets/Colors';
import {SCREENS} from '../../screens/Screens';
// @ts-ignore
import ShadowViewLocal from '../../../PackageWrappers/Components/ShadowView';
import {IS_SUBSCRIPTIONS_ENABLED} from '../../modules/subscription/actions';

const lockIcon = require('../../assets/images/lockIcon.png');
const checkIcon = require('../../assets/images/checkIcon.png');

const SelectColorSection = ({
    selectedColor,
    setSelectedColor,
    isSubscribed,
    theme,
}: {
    selectedColor: string;
    setSelectedColor: (colorCode: string) => void;
    isSubscribed: boolean;
    theme: Theme;
}) => {
    return (
        <View
            style={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                paddingHorizontal: 10,
                marginTop: 30,
            }}
        >
            <Text
                style={{
                    fontFamily: Constants.fonts.SemiBold,
                    color: theme.TEXT_PRIMARY,
                    fontSize: 19,
                }}
            >
                {lang().select_frame_color}
            </Text>
            <View
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: 10,
                }}
            >
                {Object.values(colors).map(color => {
                    const isLocked = () => {
                        if (IS_SUBSCRIPTIONS_ENABLED === false) {
                            return false;
                        }
                        if (isSubscribed) {
                            return false;
                        }
                        if (color.isLocked) {
                            return true;
                        }
                        return false;
                    };

                    return (
                        <ColorButton
                            key={color.code}
                            color={color}
                            setSelectedColor={setSelectedColor}
                            isLocked={isLocked()}
                            isSelected={color.name === selectedColor}
                        />
                    );
                })}
            </View>
        </View>
    );
};

const ColorButton = ({
    color,
    isLocked,
    setSelectedColor,
    isSelected,
}: {
    color: {code: string; name: string};
    isLocked: boolean;
    setSelectedColor: (colorCode: string) => void;
    isSelected: boolean;
}) => {
    const navigation = useNavigation();

    return (
        <ShadowViewLocal
            style={{
                marginHorizontal: 10,
                marginVertical: 10,
                height: 40,
                width: 40,
                borderRadius: 1000,
                backgroundColor: 'white',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
                shadowColor: Constants.colors.blackSecondary,
                shadowOpacity: 0.2,
                shadowRadius: 3,
                shadowOffset: {width: 0, height: 0},
            }}
        >
            <TouchableOpacity
                accessibilityHint={color.name}
                disabled={isSelected}
                onPress={() => {
                    if (isLocked) {
                        navigation.navigate(SCREENS.SUBSCRIPTION as never);
                    } else {
                        setSelectedColor(color.name);
                    }
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: color.code,
                    borderRadius: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {color.name === 'white' && (
                    <View
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: 3,
                            opacity: 0.2,
                            backgroundColor: 'grey',
                            transform: [{rotate: '45deg'}],
                        }}
                    />
                )}
                {isLocked && (
                    <Image
                        source={lockIcon}
                        style={{
                            position: 'absolute',
                            height: 20,
                            width: 20,
                            opacity: 0.8,
                            resizeMode: 'contain',
                        }}
                    />
                )}
                {isSelected && (
                    <View
                        style={{
                            position: 'absolute',
                            width: '70%',
                            height: '70%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: Constants.colors.success,
                            borderRadius: 100,
                            opacity: 0.8,
                        }}
                    >
                        <Image
                            source={checkIcon}
                            style={{
                                position: 'absolute',
                                height: 15,
                                width: 15,
                                opacity: 0.8,
                                resizeMode: 'contain',
                            }}
                        />
                    </View>
                )}
            </TouchableOpacity>
        </ShadowViewLocal>
    );
};

export default SelectColorSection;
