export const LOGIN = 'auth/LOGIN';
export const SET_LOGIN_ERROR = 'auth/SET_LOGIN_ERROR';
export const SET_SIGNUP_ERROR = 'auth/SET_SIGNUP_ERROR';
export const FREE_ERRORS = 'auth/FREE_ERRORS';
export const SET_LOGIN_LOADING = 'auth/SET_LOGIN_LOADING';
export const SET_SIGNUP_LOADING = 'auth/SET_SIGNUP_LOADING';
export const UNSET_LOGIN_LOADING = 'auth/UNSET_LOGIN_LOADING';
export const UNSET_SIGNUP_LOADING = 'auth/UNSET_SIGNUP_LOADING';
export const LOAD_USER = 'auth/LOAD_USER';
export const UNLOAD_USER = 'auth/UNLOAD_USER';
export const CHANGE_PROFILE_PICTURE = 'auth/CHANGE_PROFILE_PICTURE';
export const ALLOW_RANDOM_CONVERSATIONS = 'auth/ALLOW_RANDOM_CONVERSATIONS';
export const DISALLOW_RANDOM_CONVERSATIONS =
    'auth/DISALLOW_RANDOM_CONVERSATIONS';
export const FREE_REDUCER = 'app/FREE_REDUCER';
