import {NavigationProp} from '@react-navigation/native';
import React, {ReactNode} from 'react';
import {
    I18nManager,
    Platform,
    Text,
    TouchableOpacity,
    TouchableOpacityProps,
    View,
    ViewProps,
} from 'react-native';
import AppType from '../../../AppType';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {useLocalaization} from '../../modules/app/localization';
import {
    getDarkModeHomeButtonSectionBackground,
    getDarkModeHomeButtonTextColor,
} from '../../modules/app/theme';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';
import ConversationsSection from './MainTabBodySections/ConversationsSection';
import NotificationsDisabledSection from './MainTabBodySections/NotificationsDisabledSection';
import RandomConversationSection from './MainTabBodySections/RandomConversationSection';
import ShareYourLinkSection from './MainTabBodySections/ShareYourLinkSection';
import SubscribeSection from './MainTabBodySections/SubscribeSection';
import {IS_SUBSCRIPTIONS_ENABLED} from '../../modules/subscription/actions';

const MainTabBody = ({
    theme,
    link,
    navigation,
    goToTab,
    isNotificationGranted,
    isSubscribed,
    conversationsLength,
}: {
    theme: Theme;
    link: string;
    navigation: NavigationProp<any>;
    goToTab: ({index}: {index: number}) => void;
    isNotificationGranted?: boolean;
    isSubscribed: boolean;
    conversationsLength: number;
}) => {
    return (
        <View
            style={{
                paddingHorizontal: 20,
                paddingTop: 20,
                maxWidth: 700,
                width: '100%',
            }}
        >
            {AppType.select({
                fadfadah: (
                    <ShareYourLinkSection
                        theme={theme}
                        color={AppType.select({
                            nezmo: '#5B91B4',
                            fadfadah: '#7370A9',
                        })}
                        link={link}
                    />
                ),
                nezmo: <></>,
            })}
            <RandomConversationSection
                theme={theme}
                color={theme.RED}
                navigation={navigation}
            />
            <ConversationsSection
                theme={theme}
                color={theme.GOLD}
                goToTab={goToTab}
            />
            {!isSubscribed &&
                conversationsLength > 0 &&
                IS_SUBSCRIPTIONS_ENABLED && (
                    <SubscribeSection
                        theme={theme}
                        color={theme.SUCCESS}
                        navigation={navigation}
                    />
                )}
            {isNotificationGranted === false && (
                <NotificationsDisabledSection
                    theme={theme}
                    color={theme.DANGER}
                />
            )}
        </View>
    );
};

export const MainSectionBody = ({
    text,
    color,
    style = {},
    theme,
}: {
    text: string;
    color: string;
    style?: any;
    theme: Theme;
}) => {
    const localization = useLocalaization();

    return (
        <T
            style={{
                color:
                    theme.MODE === 'light'
                        ? color
                        : getDarkModeHomeButtonTextColor(color),
                fontFamily: Constants.fonts.SemiBold,
                fontSize: normalize(10),
                textAlign: Platform.select({
                    web: localization?.direction === 'rtl' ? 'right' : 'left',
                    ios: localization?.direction === 'rtl' ? 'left' : 'left',
                }),
                ...style,
            }}
        >
            {text}
        </T>
    );
};

export const MainTabSectionTitle = ({
    text,
    color,
    style = {},
    theme,
    heightLightedNumber,
}: {
    text: string;
    color: string;
    style?: any;
    theme: Theme;
    heightLightedNumber?: number;
}) => {
    return (
        <View
            style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginTop: heightLightedNumber ? -18 : 0,
                height: heightLightedNumber ? 45 : undefined,
            }}
        >
            {heightLightedNumber && (
                <T
                    style={{
                        color:
                            theme.MODE === 'light'
                                ? color
                                : getDarkModeHomeButtonTextColor(color),
                        fontFamily: Constants.fonts.Bold,
                        fontWeight: '900',
                        fontSize: normalize(25),
                        textAlign: 'left',
                        marginEnd: 5,
                        ...style,
                    }}
                >
                    {`${heightLightedNumber}`}
                </T>
            )}
            <T
                style={{
                    color:
                        theme.MODE === 'light'
                            ? color
                            : getDarkModeHomeButtonTextColor(color),
                    fontFamily: Constants.fonts.Bold,
                    fontSize: normalize(14.5),
                    marginBottom: normalize(1),
                    textAlign: I18nManager.isRTL ? 'left' : 'left',
                    ...style,
                }}
            >
                {text}
            </T>
        </View>
    );
};

export const MainTabSection = ({
    mainColor,
    children,
    onPress,
    style = {},
    theme,
}: {
    mainColor: string;
    children: React.ReactNode;
    onPress?: (input: any) => any;
    style?: any;
    theme: Theme;
}) => {
    return (
        <TouchableOpacity
            activeOpacity={onPress ? undefined : 1}
            disabled={
                onPress ? false : Platform.select({native: true, web: false})
            }
            onPress={onPress || undefined}
            style={{
                paddingHorizontal: 20,
                paddingVertical: 10,
                marginBottom: normalize(15),
                width: '100%',
                borderColor: mainColor,
                borderWidth: theme.MODE === 'light' ? 0.4 : 0,
                borderRadius: 7,
                overflow: 'hidden',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor:
                    theme.MODE === 'light'
                        ? '#FEFEFE'
                        : (getDarkModeHomeButtonSectionBackground(
                              mainColor,
                          ) as string),
                ...(theme.MODE === 'light' &&
                    Platform.OS === 'ios' &&
                    Constants.elevation.one),
                shadowColor: mainColor,
                ...style,
            }}
        >
            {children}
        </TouchableOpacity>
    );
};

const SectionWrapper = ({
    children,
    props,
}: {
    children: ReactNode;
    props: TouchableOpacityProps | ViewProps | any;
}) => {
    if (props.onPress) {
        return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
    }
    return <View {...props}>{children}</View>;
};

export default MainTabBody;
