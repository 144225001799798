const remoteConfig = () => {
    return {
        setConfigSettings: () => {},
        setDefaults: () => {},
        fetchAndActivate: () => {},
        getAll: () => {},
    };
};

export default remoteConfig;
