import React, {useEffect} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import {openConversationUsingID} from '../modules/app/actions';
import {NavigationProp} from '../types/navigation/navigationProp';
import {checkUpdates} from '../modules/conversations/actions';
import {SCREENS} from './Screens';
import {ReducerState} from '../types/reducer/reducer';

const ConversationAdapter = ({
    route,
}: NavigationProp<{
    conversation_id?: string;
    is_new?: 'true' | 'false';
    conversation_link?: string;
}>) => {
    const dispatch = useDispatch();
    const store = useStore();

    const conversations = useSelector((state: ReducerState) => {
        return state.conversationsReducer.conversations;
    });

    const navigate = useNavigation();
    const conversation_id = route?.params?.conversation_id;
    const conversation_link = route?.params?.conversation_link;
    const is_new = route?.params?.is_new;

    useEffect(() => {
        async function start() {
            try {
                if (
                    conversations !== undefined &&
                    (conversation_id || conversation_link)
                ) {
                    if (is_new === 'true') {
                        await checkUpdates()(dispatch, store.getState);
                    }
                    navigate.goBack();
                    if (conversation_id && conversation_id !== 'undefined') {
                        openConversationUsingID({
                            conversationID: parseInt(conversation_id, 10),
                            conversationLink: null,
                        })(dispatch, store.getState);
                    } else if (
                        conversation_link &&
                        conversation_link !== 'undefined'
                    ) {
                        // @ts-ignore
                        navigate.navigate(SCREENS.CONVERSATION, {
                            conversation: {
                                conversation_link,
                            },
                        });
                    }
                }
            } catch (e) {
                navigate.goBack();
            }
        }
        start();
    }, [conversations]);

    return <></>;
};

export default ConversationAdapter;
