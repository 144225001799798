import React, {useEffect} from 'react';
import {View, StyleSheet, ViewStyle, I18nManager} from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

const styles = StyleSheet.create({
    redDot: {
        height: 12,
        width: 12,
        borderRadius: 100,
        marginStart: 12,
    },
});

const RedDot = (props: {style?: ViewStyle}) => {
    const opacitySV = useSharedValue(0);
    const scaleSV = useSharedValue(1);
    const translateX = useSharedValue(0);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: opacitySV.value,
            transform: [{scale: scaleSV.value}, {translateX: translateX.value}],
        };
    });

    useEffect(() => {
        opacitySV.value = withRepeat(withTiming(1, {duration: 700}), -1, true);
        scaleSV.value = withRepeat(withTiming(0.7, {duration: 700}), -1, true);
        translateX.value = withRepeat(
            withTiming(I18nManager.isRTL ? -4 : 4, {duration: 700}),
            -1,
            true,
        );
    }, []);

    return (
        <Animated.View style={[animatedStyle]}>
            <View
                {...props}
                style={{...styles.redDot, ...props.style}}
            />
        </Animated.View>
    );
};

export default RedDot;
