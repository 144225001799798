import React from 'react';
import {ActivityIndicator, SafeAreaView, StyleSheet, View} from 'react-native';
import lang from '../../localization';
import {T, TopBar} from '../Shared';
import StatusBarAware from '../Shared/StatusBarAware';
import {Theme} from '../../assets/Colors';

const styles = StyleSheet.create({
    container: {
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const LoadingRandomConversations = ({
    goBackToPreviousScreen,
    theme,
}: {
    goBackToPreviousScreen: () => void;
    theme: Theme;
}) => {
    return (
        <SafeAreaView style={styles.container}>
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={lang().create_random_conversation}
            />
            <View
                style={{
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <View style={{position: 'relative', bottom: '10%'}}>
                    <ActivityIndicator
                        size="large"
                        color={theme.TEXT_PRIMARY}
                    />
                    <T style={{color: theme.TEXT_PRIMARY, marginTop: 10}}>
                        {lang().is_loading}
                    </T>
                </View>
            </View>
        </SafeAreaView>
    );
};

export default LoadingRandomConversations;
