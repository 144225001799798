import React, {useEffect, useState} from 'react';
import {
    RewardedAd,
    AdEventType,
    RewardedAdEventType,
    TestIds,
} from 'react-native-google-mobile-ads';
import {Platform} from 'react-native';
import {logEvent} from '../../modules/app/tracking';

const adUnitId: string = __DEV__
    ? TestIds?.REWARDED
    : (Platform.select({
          ios: 'ca-app-pub-4746201201889248/3913897498',
          android: 'ca-app-pub-4746201201889248/1376846996',
      }) as string);

const createdRewardedAd = () => {
    return RewardedAd?.createForAdRequest(adUnitId, {
        requestNonPersonalizedAdsOnly: false,
        keywords: ['social', 'fashion', 'clothing'],
    });
};

const RewardedAdController = ({
    isRewardedAdLoaded,
    setIsRewardedAdLoaded,
    shouldShowRewardedAd,
    setShouldShowRewardedAd,
    defaultNumberOfRewardedRandomConversations,
    setNumberOfLeftConversations,
}: {
    isRewardedAdLoaded: boolean;
    setIsRewardedAdLoaded: (value: boolean) => void;
    shouldShowRewardedAd: boolean;
    setShouldShowRewardedAd: (value: boolean) => void;
    defaultNumberOfRewardedRandomConversations: number;
    setNumberOfLeftConversations: (value: number) => void;
}) => {
    const [rewarded, setRewarded] = useState(createdRewardedAd());

    useEffect(() => {
        if (
            shouldShowRewardedAd &&
            isRewardedAdLoaded &&
            Platform.OS !== 'web'
        ) {
            try {
                setIsRewardedAdLoaded(false);
                setShouldShowRewardedAd(false);
                rewarded.show();
                logEvent('showRewardedAd');
            } catch (e) {
                // maybe it is not loaded yet
            }
        }
    }, [shouldShowRewardedAd]);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            const unsubscribeLoaded = rewarded.addAdEventListener(
                RewardedAdEventType.LOADED,
                () => {
                    setIsRewardedAdLoaded(true);
                    // console.log('loaded');
                },
            );

            const unsubscribeEarned = rewarded.addAdEventListener(
                RewardedAdEventType.EARNED_REWARD,
                reward => {
                    // console.log('got rewarded')
                    setNumberOfLeftConversations(
                        defaultNumberOfRewardedRandomConversations,
                    );
                },
            );

            const unsubscribeClosed = rewarded.addAdEventListener(
                AdEventType.CLOSED,
                () => {
                    // console.log('closed')
                    setRewarded(createdRewardedAd());
                    setTimeout(() => {
                        rewarded.load();
                    }, 200);
                },
            );

            // Start loading the rewarded ad straight away
            rewarded.load();

            // Unsubscribe from events on unmount
            return () => {
                unsubscribeLoaded();
                unsubscribeEarned();
                unsubscribeClosed();
            };
        }
    }, []);

    return <></>;
};

export default RewardedAdController;
