const colors: {
    [colorName: string]: {code: string; name: string; isLocked: boolean};
} = {
    white: {
        code: '#FFFFFF',
        name: 'white',
        isLocked: false,
    },
    blue: {
        code: '#0296e6',
        name: 'blue',
        isLocked: false,
    },
    green: {
        code: '#98C348',
        name: 'green',
        isLocked: false,
    },
    black: {
        code: '#3C3C3C',
        name: 'black',
        isLocked: false,
    },
    red: {
        code: '#C73737',
        name: 'red',
        isLocked: true,
    },
    gold: {
        code: '#ffd026',
        name: 'gold',
        isLocked: true,
    },
    pink: {
        code: '#ed5a9a',
        name: 'pink',
        isLocked: true,
    },
    teal: {
        code: '#62BE97',
        name: 'teal',
        isLocked: true,
    },
    lavender: {
        code: '#d198ed',
        name: 'lavender',
        isLocked: true,
    },
    navyBlue: {
        code: '#2b3dff',
        name: 'navyBlue',
        isLocked: true,
    },
    orange: {
        code: '#f7941b',
        name: 'orange',
        isLocked: true,
    },
    purple: {
        code: '#8537C7',
        name: 'purple',
        isLocked: true,
    },
};

export default colors;
