import React, {useState} from 'react';
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import CountryPicker, {CountryCode} from 'react-native-country-picker-modal';
import * as Animatable from 'react-native-animatable';
import pSBC from 'shade-blend-color';
import lang from '../../localization';
import {tinyClick} from '../../modules/app/vibrations';
import {normalize} from '../../modules/helpers';
import {SlideFromBottom} from '../Animations';
import {T} from '../Shared';
import {getRemoteConfig} from '../../modules/remoteConfig/actions';
import {NavigationProp} from '../../types/navigation/navigationProp';
import {RandomUser} from '../../types/data/randomUser';
import Constants from '../../assets/Constants';
import {SCREENS} from '../../screens/Screens';
import {Theme} from '../../assets/Colors';
import {useLocalaization} from '../../modules/app/localization';
import {IS_SUBSCRIPTIONS_ENABLED} from '../../modules/subscription/actions';

const randomIcon = require('../../assets/images/randomIcon.png');
const diamondIcon = require('../../assets/images/diamondIcon.png');
const lockIcon = require('../../assets/images/lockIcon.png');

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const RandomConversationFooter = ({
    navigation,
    setShouldShowRewardedAd,
    canGetRewardedConversations,
    canMakeRandomConversations,
    searchForAnotherRandomUser,
    currentRandomUser,
    setIsReportModalVisible,
    isCreatingConversation,
    onCreateConversation,
    isSubscribed,
    setSearchCountry,
    searchCountry = 'EG',
    theme,
}: NavigationProp<{isCompleteSignup: boolean}> & {
    setShouldShowRewardedAd: (value: boolean) => void;
    canGetRewardedConversations: boolean;
    canMakeRandomConversations: boolean;
    searchForAnotherRandomUser: () => void;
    currentRandomUser: RandomUser | undefined;
    setIsReportModalVisible: (value: boolean) => void;
    isCreatingConversation: boolean;
    onCreateConversation: () => void;
    isSubscribed: boolean;
    setSearchCountry: (value: CountryCode) => void;
    searchCountry: CountryCode | undefined;
    theme: Theme;
}) => {
    const {width} = useWindowDimensions();

    const [isMainButtonThrottled, setIsMainButtonThrottled] = useState(false);

    const onMainButtonClicked = () => {
        if (!isMainButtonThrottled) {
            setIsMainButtonThrottled(true);
            setTimeout(() => {
                setIsMainButtonThrottled(false);
            }, 400);
            tinyClick();
            if (!canMakeRandomConversations && canGetRewardedConversations) {
                setShouldShowRewardedAd(true);
            } else if (__DEV__) {
                onCreateConversation();
            } else if (!canMakeRandomConversations) {
                if (IS_SUBSCRIPTIONS_ENABLED) {
                    navigation.navigate(SCREENS.SUBSCRIPTION);
                }
                return null;
            } else if (
                currentRandomUser &&
                Boolean(currentRandomUser.user_link) &&
                !isCreatingConversation
            ) {
                onCreateConversation();
            }
        }
    };

    const getCreateConversationButtonColor = () => {
        if (canMakeRandomConversations) {
            return theme.SUCCESS;
        }
        if (canGetRewardedConversations) {
            return theme.GOLD;
        }
        return theme.DISABLED;
    };

    const renderCreatingConversationContent = () => {
        if (isCreatingConversation) {
            return (
                <>
                    <T
                        style={{
                            fontSize: normalize(15),
                        }}
                    >
                        {' '}
                    </T>
                    <ActivityIndicator
                        size="small"
                        color={theme.TEXT_WHITE}
                    />
                </>
            );
        }

        if (canMakeRandomConversations) {
            return (
                <T
                    style={{
                        fontFamily: Constants.fonts.Bold,
                        color: theme.TEXT_WHITE,
                        fontSize: normalize(14),
                    }}
                >
                    {lang().start_random_conversation}
                </T>
            );
        }

        if (canGetRewardedConversations) {
            return (
                <>
                    <T
                        style={{
                            fontFamily: Constants.fonts.Bold,
                            color: theme.TEXT_WHITE,
                            fontSize: normalize(14),
                        }}
                    >
                        {' '}
                    </T>
                    <T
                        style={{
                            fontFamily: Constants.fonts.Bold,
                            color: theme.TEXT_WHITE,
                            fontSize: normalize(10),
                        }}
                    >
                        {lang().watch_ad_to_continue}
                    </T>
                </>
            );
        }

        return (
            <>
                <T
                    style={{
                        fontSize: normalize(14),
                    }}
                >
                    {' '}
                </T>
                <Image
                    source={lockIcon}
                    style={{
                        height: normalize(19),
                        width: normalize(29),
                        resizeMode: 'contain',
                    }}
                />
            </>
        );
    };

    return (
        <SlideFromBottom
            delay={400}
            style={{
                flex: 1,
                width: '100%',
                marginBottom: PHONE_HAS_A_NOTCH ? 40 : 70,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: 600,
                paddingHorizontal: width < 350 ? 15 : 30,
            }}
        >
            <ChangeConversationButton
                isSubscribed={isSubscribed}
                searchCountry={searchCountry}
                setSearchCountry={setSearchCountry}
                navigation={navigation}
                theme={theme}
            />
            <View
                style={{
                    marginTop: normalize(30),
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    marginVertical: normalize(12),
                }}
            >
                <TouchableOpacity
                    onPress={onMainButtonClicked}
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            opacity: isCreatingConversation ? 0.6 : 1,
                            shadowColor: 'black',
                            shadowOpacity: 0.3,
                            shadowRadius: Platform.select({
                                ios: 4,
                                android: 5,
                                web: 4,
                            }),
                            shadowOffset: {width: 0, height: 3},
                            backgroundColor: getCreateConversationButtonColor(),
                            borderRadius: 100,
                            paddingHorizontal: 20,
                            paddingVertical: normalize(11),
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {renderCreatingConversationContent()}
                    </View>
                </TouchableOpacity>

                <View style={{width: '5%', flex: 0.1}} />

                <TouchableOpacity
                    disabled={!currentRandomUser || !currentRandomUser.name}
                    onPress={() => {
                        tinyClick();
                        setIsReportModalVisible(true);
                    }}
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            shadowColor: 'black',
                            shadowOpacity: 0.3,
                            shadowRadius: Platform.select({
                                ios: 4,
                                android: 5,
                                web: 4,
                            }),
                            shadowOffset: {width: 0, height: 3},
                            backgroundColor: pSBC(0.1, theme.DANGER) as string,
                            borderRadius: 100,
                            paddingHorizontal: 15,
                            paddingVertical: normalize(11),
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <T
                            style={{
                                fontFamily: Constants.fonts.Bold,
                                color: theme.TEXT_WHITE,
                                fontSize: normalize(14),
                            }}
                        >
                            {lang().report_random_user}
                        </T>
                    </View>
                </TouchableOpacity>
            </View>

            <TouchableOpacity
                disabled={currentRandomUser === undefined}
                onPress={() => {
                    tinyClick();
                    searchForAnotherRandomUser();
                }}
                style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginVertical: normalize(12),
                    width: '100%',
                }}
            >
                <View
                    style={{
                        shadowColor: theme.MODE === 'light' ? 'white' : 'black',
                        shadowOpacity: 0.3,
                        shadowRadius: Platform.select({ios: 4, android: 5}),
                        shadowOffset: {width: 0, height: 0},
                        backgroundColor: theme.TERTIARY,
                        borderRadius: 100,
                        paddingHorizontal: 15,
                        paddingVertical: normalize(12),
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <>
                        <Image
                            style={{
                                height: 20,
                                width: 20,
                                resizeMode: 'contain',
                                marginEnd: 15,
                                tintColor: theme.TEXT_PRIMARY,
                            }}
                            source={randomIcon}
                        />
                        <T
                            style={{
                                fontFamily: Constants.fonts.Bold,
                                color: theme.TEXT_PRIMARY,
                                fontSize: normalize(14),
                            }}
                        >
                            {lang().find_another_random_user}
                        </T>
                    </>
                </View>
            </TouchableOpacity>

            {!isSubscribed && IS_SUBSCRIPTIONS_ENABLED && (
                <Animatable.View
                    animation="pulse"
                    easing="ease-in"
                    iterationCount={
                        getRemoteConfig('subscribe_button_pulsing') === 'true'
                            ? 'infinite'
                            : 1
                    }
                    iterationDelay={2000}
                    delay={2000}
                    duration={1000}
                >
                    <View>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate(SCREENS.SUBSCRIPTION);
                            }}
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginVertical: normalize(12),
                                paddingVertical: 10,
                                paddingHorizontal: 10,
                                marginTop: 2,
                            }}
                        >
                            <Image
                                style={{
                                    height: 17,
                                    width: 17,
                                    resizeMode: 'contain',
                                    marginEnd: 15,
                                    tintColor: theme.TEXT_WHITE,
                                }}
                                source={diamondIcon}
                            />
                            <T
                                style={{
                                    fontFamily: Constants.fonts.Bold,
                                    color: theme.TEXT_WHITE,
                                    fontSize: normalize(10),
                                }}
                            >
                                {lang().get_unlimited_random_conversations}
                            </T>
                        </TouchableOpacity>
                    </View>
                </Animatable.View>
            )}
        </SlideFromBottom>
    );
};

const ChangeConversationButton = ({
    setSearchCountry,
    searchCountry = 'SA',
    isSubscribed,
    navigation,
    theme,
}: NavigationProp<{isCompleteSignup: boolean}> & {
    setSearchCountry: (value: CountryCode) => void;
    searchCountry: CountryCode;
    isSubscribed: boolean;
    theme: Theme;
}) => {
    const localization = useLocalaization();
    const [isCountryModalVisible, setIsCountryModalVisible] = useState(false);

    return (
        <>
            {!isSubscribed && IS_SUBSCRIPTIONS_ENABLED && (
                <View
                    style={{
                        opacity: 0.4,
                        position: 'relative',
                        top: 40,
                        backgroundColor: 'red',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image
                        source={lockIcon}
                        style={{
                            position: 'absolute',
                            height: normalize(20),
                            width: normalize(20),
                            tintColor: 'white',
                            resizeMode: 'contain',
                        }}
                    />
                </View>
            )}
            <TouchableOpacity
                style={{marginTop: 30, marginBottom: -20}}
                onPress={() => {
                    if (!isSubscribed && IS_SUBSCRIPTIONS_ENABLED) {
                        navigation.navigate(SCREENS.SUBSCRIPTION);
                    } else {
                        setIsCountryModalVisible(true);
                    }
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <T
                        style={{
                            color: 'white',
                            paddingHorizontal: 10,
                            fontFamily: Constants.fonts.Bold,
                            opacity: isSubscribed ? 0.8 : 0.6,
                            fontSize: 13,
                            marginEnd: -10,
                        }}
                    >
                        {lang().change_search_country}
                    </T>
                </View>
                <View style={{opacity: 0, height: 0, backgroundColor: 'red'}}>
                    <CountryPicker
                        excludeCountries={['IL']}
                        countryCode={searchCountry}
                        withFilter
                        theme={{
                            backgroundColor: theme.BACKGROUND,
                            filterPlaceholderTextColor: theme.TEXT_MUTED,
                            fontFamily: Constants.fonts.SemiBold,
                            primaryColorVariant: theme.TEXT,
                            onBackgroundTextColor: theme.TEXT,
                        }}
                        preferredCountries={[
                            'SA',
                            'EG',
                            'IR',
                            'DZ',
                            'YE',
                            'JO',
                            'US',
                            'AE',
                            'SY',
                            'KW',
                            'QA',
                            'TR',
                            'TN',
                            'MA',
                        ]}
                        containerButtonStyle={{
                            width: 0,
                        }}
                        // @ts-ignore
                        translation={
                            localization?.direction === 'rtl' ? 'ar' : 'common'
                        }
                        onSelect={value => {
                            setSearchCountry(value.cca2);
                        }}
                        onClose={() => {
                            setIsCountryModalVisible(false);
                        }}
                        visible={isCountryModalVisible}
                    />
                </View>
            </TouchableOpacity>
        </>
    );
};

export default RandomConversationFooter;
