/* eslint-disable prefer-spread */
/* eslint-disable consistent-this */
/* eslint-disable no-extend-native */
// @ts-ignore
Object.defineProperty(Array.prototype, 'chunk', {
    value(chunkSize: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const array = this;
        return [].concat.apply(
            [],
            array.map((elem: any, i: number) => {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            }),
        );
    },
});
