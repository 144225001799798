export const SHOW_SNACKBAR = 'app/SHOW_SNACKBAR';
export const HIDES_SNACKBAR = 'app/HIDES_SNACKBAR';
export const REGISTER_MAIN_LISTENER = 'app/REGISTER_MAIN_LISTENER';
export const ENABLE_NOTIFICATIONS = 'app/ENABLE_NOTIFICATIONS';
export const DISABLE_NOTIFICATIONS = 'app/DISABLE_NOTIFICATIONS';
export const START_PROFILE_PICTURE_UPLOADING =
    'app/START_PROFILE_PICTURE_UPLOADING';
export const STOP_PROFILE_PICTURE_UPLOADING =
    'app/STOP_PROFILE_PICTURE_UPLOADING';
export const DISCONNECT_FROM_INTERNET = 'app/DISCONNECT_FROM_INTERNET';
export const FREE_REDUCER = 'app/FREE_REDUCER';
export const SET_MAIN_LOADING = 'app/SET_MAIN_LOADING';
export const UNSET_MAIN_LOADING = 'app/UNSET_MAIN_LOADING';
export const CHANGE_THEME_MODE = 'app/CHANGE_THEME_MODE';
export const CHANGE_LOCALIZATION = 'app/CHANGE_LOCALIZATION';
