import React, {useEffect, useState} from 'react';
import {
    View,
    Platform,
    ScrollView,
    StyleSheet,
    Keyboard,
    Dimensions,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import {T} from '.';
import {FadeInView} from '../Animations';
import lang from '../../localization';
import Constants from '../../assets/Constants';
import MessagesSection from '../Main/MessagesSection';
import {NoConversationsBanner} from '../Banners';
import {isWeb} from '../../../env';

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const styles = StyleSheet.create({
    scrollViewContent: {flexGrow: 1, alignItems: 'center'},
    container: {
        flex: 1,
        width: '100%',
        maxWidth: 700,
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    loadingMoreMessagesSection: {
        marginTop: 20,
    },
    messagesContainer: {
        paddingHorizontal: 8,
        paddingTop: 3,
        paddingBottom: 7,
        // backgroundColor: 'blue',
        flexDirection: 'column',
    },
    messageRowContainer: {
        // backgroundColor: 'green',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    conversationStartContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        paddingVertical: 2,
        paddingHorizontal: 8,
        marginBottom: 8,
    },
    conversationStartText: {
        color: 'white',
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
    },
    loadingSectionContainer: {
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    noMessagesSectionContainer: {
        // backgroundColor: 'red',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        flex: 1,
        marginBottom: 4,
    },
    noMessagesTextContainer: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    noMessagesText: {
        fontSize: 20,
        fontFamily: Constants.fonts.Bold,
    },
    swipeHintSection: {},
    swipeIcon: {
        opacity: 0.8,
        height: 80,
        width: 80,
        position: 'relative',
        marginEnd: 80,
    },
    swipeHintText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: 10,
        color: '#BD5858',
        marginTop: 20,
        opacity: 0.8,
        textAlign: 'center',
    },
});

const ConversationBody = ({
    scrollViewRef,
    activeConversation,
    messages,
    lastMessageID,
    conversationBodyScrolledToTop,
    isSubscribed,
    theme,
    setAlert,
}) => {
    const isCloseToBottom = ({
        layoutMeasurement,
        contentOffset,
        contentSize,
    }) => {
        return (
            contentOffset.y <= 20 &&
            contentSize.height > layoutMeasurement.height
        );
    };
    const [alreadyLoggedScrollTop, setAlreadyLoggedScrollTop] = useState(false);

    const [isBuggyScroll, setIsBuggyScroll] = useState(true);
    const isBuggy = isBuggyScroll && Platform.OS === 'android';
    const {height: ScreenHeight} = useWindowDimensions();

    // handle scrolling to the bottom when
    useEffect(() => {
        if (!!scrollViewRef && !!scrollViewRef.current) {
            if (Platform.OS === 'ios') {
                if (
                    !!scrollViewRef &&
                    !!scrollViewRef.current &&
                    !!scrollViewRef.current.scrollToEnd
                ) {
                    setTimeout(() => {
                        try {
                            scrollViewRef.current.scrollToEnd({
                                animated: false,
                                duration: 0,
                            });
                        } catch (e) {
                            //
                        }
                    }, 100);
                }
            } else if (
                !!scrollViewRef &&
                !!scrollViewRef.current &&
                !!scrollViewRef.current.scrollToEnd
            ) {
                setTimeout(() => {
                    try {
                        scrollViewRef.current.scrollToEnd({
                            animated: false,
                            duration: 0,
                        });
                    } catch (e) {
                        //
                    }
                }, 10);
            }
        }
    }, [lastMessageID]);

    // handle keyboard events
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);
    const [isKeyboardHeight, setKeyboardHeight] = useState(0);
    useEffect(() => {
        const keyboardWillShowListener = Keyboard.addListener(
            'keyboardWillShow',
            e => {
                setKeyboardVisible(true); // or some other action
                setKeyboardHeight(e.endCoordinates.height);
                if (Platform.OS === 'ios') {
                    setTimeout(() => {
                        try {
                            if (scrollViewRef) {
                                scrollViewRef.current.scrollToEnd({
                                    animated: true,
                                });
                            }
                        } catch (error) {
                            //
                        }
                    }, 100);
                }
            },
        );
        const keyboardWillHideListener = Keyboard.addListener(
            'keyboardWillHide',
            e => {
                setKeyboardVisible(false); // or some other action
                setKeyboardHeight(0);
                setTimeout(() => {
                    if (
                        scrollViewRef &&
                        scrollViewRef.current &&
                        scrollViewRef.current.scrollToEnd
                    ) {
                        try {
                            scrollViewRef.current.scrollToEnd({
                                animated: true,
                            });
                        } catch (error) {
                            //
                        }
                    }
                }, 10);
            },
        );
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            e => {
                if (Platform.OS === 'android') {
                    setTimeout(() => {
                        if (
                            scrollViewRef &&
                            scrollViewRef.current &&
                            scrollViewRef.current.scrollToEnd
                        ) {
                            try {
                                scrollViewRef.current.scrollToEnd({
                                    animated: true,
                                });
                            } catch (error) {
                                //
                            }
                        }
                    }, 50);
                }
            },
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {},
        );
        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
            keyboardWillShowListener.remove();
            keyboardWillHideListener.remove();
        };
    }, []);

    const getBottomPadding = () => {
        if (isKeyboardVisible && Platform.OS === 'ios') {
            return PHONE_HAS_A_NOTCH ? isKeyboardHeight - 40 : isKeyboardHeight;
        }
        return 0;
    };

    const containerStyle = {
        ...styles.container,
        paddingBottom: getBottomPadding(),
    };

    // reversing the messages
    if (messages !== undefined) {
        messages = messages.slice().reverse();
    }

    const renderConversationsBody = () => {
        return (
            <ScrollView
                // code to activate loading more messages
                onScroll={event => {
                    if (
                        isCloseToBottom(event.nativeEvent) &&
                        !alreadyLoggedScrollTop && // fix multiple events
                        !isBuggy && // skip initial event registration (android)
                        !!activeConversation &&
                        activeConversation.is_loading_more_messages !== true &&
                        activeConversation.is_fully_loaded !== true // disable when all messages are loaded
                    ) {
                        setAlreadyLoggedScrollTop(true);
                        setTimeout(() => {
                            setAlreadyLoggedScrollTop(false);
                        }, 300);
                        conversationBodyScrolledToTop({
                            scrollTo: data => {
                                try {
                                    scrollViewRef.current.scrollTo(data);
                                } catch (e) {
                                    //
                                }
                            },
                            nativeEvent: event.nativeEvent,
                            scrollViewRef, // passing the scrollViewRef to scroll after loading messages
                        });
                    }
                    setIsBuggyScroll(false);
                }}
                scrollEventThrottle={200}
                ref={scrollViewRef}
                contentContainerStyle={styles.scrollViewContent}
                style={{
                    height: ScreenHeight - 300,
                }}
                testID="conversationBody"
            >
                <View style={containerStyle}>
                    {!!activeConversation &&
                        activeConversation.is_loading_more_messages && (
                            <View
                                style={styles.loadingMoreMessagesSection}
                                testID="loadingMessagesIndicator"
                            >
                                <ActivityIndicator
                                    size="small"
                                    color={theme.TEXT_PRIMARY}
                                />
                            </View>
                        )}
                    {!!messages && messages.length > 0 && (
                        <FadeInView
                            key={`${
                                activeConversation && activeConversation.id
                                    ? activeConversation.id
                                    : ''
                            }`}
                            style={styles.messagesContainer}
                        >
                            {!!activeConversation &&
                                activeConversation.is_fully_loaded === true && (
                                    <View style={[styles.messageRowContainer]}>
                                        <View
                                            style={{
                                                ...styles.conversationStartContainer,
                                                backgroundColor:
                                                    theme.SECONDARY,
                                            }}
                                        >
                                            <T
                                                style={{
                                                    ...styles.conversationStartText,
                                                    color: theme.TEXT,
                                                }}
                                                testID="conversationStartText"
                                            >
                                                {lang().conversation_start}
                                            </T>
                                        </View>
                                    </View>
                                )}
                            <MessagesSection
                                theme={theme}
                                messages={messages}
                                setAlert={setAlert}
                            />
                        </FadeInView>
                    )}
                    {messages === undefined && (
                        <FadeInView
                            key={`${lastMessageID} ${
                                activeConversation ? activeConversation.id : '.'
                            }`}
                            style={styles.loadingSectionContainer}
                        >
                            <ActivityIndicator
                                color={theme.TEXT_PRIMARY}
                                size="large"
                            />
                        </FadeInView>
                    )}
                    {!!messages && messages.length === 0 && (
                        <FadeInView
                            key={`${lastMessageID} ${
                                activeConversation ? activeConversation.id : ''
                            }`}
                            style={styles.noMessagesSectionContainer}
                        >
                            <View style={styles.noMessagesTextContainer}>
                                <T
                                    style={{
                                        ...styles.noMessagesText,
                                        color: theme.ORANGE,
                                    }}
                                >
                                    {lang().no_messages_in_conversation}
                                </T>
                            </View>
                            {isSubscribed !== true && !isWeb && (
                                <NoConversationsBanner />
                            )}
                        </FadeInView>
                    )}
                </View>
            </ScrollView>
        );
    };

    return <>{renderConversationsBody()}</>;
};

export default ConversationBody;
