import React, {useEffect, useState} from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Switch,
} from 'react-native';
import * as Linking from 'expo-linking';
import {connect, useDispatch, ConnectedProps} from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import switchTheme from 'react-native-theme-switch-animation';
import {T} from '../Shared';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import store from '../../modules/redux/store';
import {actions as appActions} from '../../modules/app';
import {SettingsButton} from '../Settings';
import LoadingModal from '../Shared/LoadingModal';
import {setNotificationSound} from '../../modules/app/actions';
import {changeThemeMode} from '../../modules/app/theme';
import {ReduxDispatch, ReduxGetState} from '../../types/redux/redux';
import {ActionParameter} from '../../types/helpers/helpers';
import {NavigationProp} from '../../types/navigation/navigationProp';
import {ReducerState} from '../../types/reducer/reducer';
import {Colors, Theme, ThemeColors} from '../../assets/Colors';
import {SCREENS} from '../../screens/Screens';
import {changeLanguage, useLocalaization} from '../../modules/app/localization';
import {isWeb} from '../../../env';
import {expo} from '../../../app.json';
// @ts-ignore
import RNRestart from '../../../PackageWrappers/Modules/RNRestart';
import {logEvent} from '../../modules/app/tracking';
import {
    IS_POINTS_ENABLED,
    IS_SUBSCRIPTIONS_ENABLED,
} from '../../modules/subscription/actions';

const deleteIcon = require('../../assets/images/deleteIcon.png');
const logoutIcon = require('../../assets/images/logoutIcon.png');
const diamondIcon = require('../../assets/images/diamondIcon.png');
const deleteAccountIcon = require('../../assets/images/deleteAccountIcon.png');
const editIcon = require('../../assets/images/editIcon.png');
const dayAndNightIcon = require('../../assets/images/dayAndNight.png');

const styles = StyleSheet.create({
    // Settings Menu
    settingsMenuContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: 600,
        paddingTop: 10,
    },
    developerMessageText: {
        fontSize: 11,
        color: 'white',
    },
    acceptTermsNoteSection: {
        marginTop: 8,
        paddingHorizontal: 5,
        paddingVertical: 5,
        flexDirection: 'row',
    },
    acceptTermsNoteText: {
        fontSize: 9,
        color: '#A0A0A0',
        fontFamily: Constants.fonts.Bold,
    },
    contactUsSection: {
        marginTop: 20,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 0.6,
    },
    from_here_text: {
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
        marginHorizontal: 4,
    },
    contactUsText: {fontSize: 10},
    contactUsRow: {flexDirection: 'row'},
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    logout: () => appActions.logout()(dispatch, getState),
    toggleLanguage: () => appActions.toggleLanguage(),
    toggleRandomConversations: (
        data: ActionParameter<typeof appActions.toggleRandomConversations>,
    ) => appActions.toggleRandomConversations(data)(dispatch, getState),
    deleteMyAccount: () => appActions.deleteMyAccount()(dispatch, getState),
    openChangeUsernameMenu: () =>
        appActions.openChangeUsernameMenu()(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    isSubscribed: state.subscriptionReducer.isSubscribed,
    can_receive:
        Boolean(state.authReducer.user) &&
        Boolean(state?.authReducer?.user?.can_receive)
            ? state?.authReducer?.user?.can_receive
            : false,
    points: state.authReducer.user && state.authReducer.user.points,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = {
    goBackToPreviousScreen: () => void;
    setAlert: any;
    theme: Theme;
} & PropsFromRedux &
    NavigationProp<{}>;

const Settings = ({
    points,
    toggleRandomConversations,
    isSubscribed,
    navigation,
    openChangeUsernameMenu,
    goBackToPreviousScreen,
    logout,
    deleteMyAccount,
    can_receive,
    theme,
    setAlert,
}: {
    points: number | undefined;
} & Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNotificationSoundEnabled, setIsNotificationSoundEnabled] =
        useState<boolean | undefined>(undefined);
    const onChangeIsRandomConversationAllowed = async () => {
        setIsLoading(true);
        // @ts-ignore
        await toggleRandomConversations({setIsLoading: setIsLoading as any});
    };
    const dispatch = useDispatch();
    const localization = useLocalaization();

    useEffect(() => {
        AsyncStorage.getItem('settings_notification_sound').then(response => {
            if (response === null) {
                setNotificationSound(true).then(() => {
                    setIsNotificationSoundEnabled(true);
                });
            }
            if (response === 'true') {
                setIsNotificationSoundEnabled(true);
            }
            if (response === 'false') {
                setIsNotificationSoundEnabled(false);
            }
        });
    }, []);

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${lang().screenTitles.settings}`,
        });
    }, []);

    useEffect(() => {
        setNotificationSound(isNotificationSoundEnabled);
    }, [isNotificationSoundEnabled]);

    const getSubscriptionButtonColor = () => {
        if (isSubscribed) {
            return theme.SUCCESS;
        }
        if (theme.MODE === 'dark') {
            return theme.TERTIARY;
        }
        return theme.PRIMARY;
    };

    return (
        <View style={styles.settingsMenuContainer}>
            {isSubscribed !== undefined && IS_SUBSCRIPTIONS_ENABLED && (
                <SettingsButton
                    theme={theme}
                    color={getSubscriptionButtonColor()}
                    title={
                        isSubscribed
                            ? lang().you_are_a_subscriber
                            : lang().subscribe_to_remove_ads
                    }
                    icon={diamondIcon}
                    onPress={() => {
                        logEvent('openSubscriptionScreenFromSettings');
                        navigation.navigate(SCREENS.SUBSCRIPTION);
                    }}
                    testID="logoutButton"
                />
            )}
            {!isWeb && IS_POINTS_ENABLED && (
                <PointsButton
                    points={points}
                    navigation={navigation}
                    theme={theme}
                />
            )}
            <SettingsButton
                theme={theme}
                color={theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY}
                title={
                    theme.MODE === 'light'
                        ? lang().change_theme_mode.to_dark
                        : lang().change_theme_mode.to_light
                }
                icon={dayAndNightIcon}
                onPress={() => {
                    switchTheme({
                        switchThemeFunction: () => {
                            changeThemeMode({
                                themeMode:
                                    theme.MODE === 'light' ? 'dark' : 'light',
                            })(dispatch);
                        },
                        animationConfig: {
                            type: 'fade',
                            duration: 400,
                        },
                    });
                }}
            />
            <SettingsButton
                theme={theme}
                color={theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY}
                title={lang().change_account_name}
                icon={editIcon}
                onPress={() => {
                    openChangeUsernameMenu();
                }}
            />
            <SettingsButton
                theme={theme}
                color={theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY}
                title={lang().remove_conversations}
                icon={deleteIcon}
                onPress={() => {
                    navigation.navigate(SCREENS.REMOVE_CONVERSATIONS);
                }}
            />
            <SettingsButton
                theme={theme}
                color={theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY}
                title={lang().notification_sound}
                onPress={() => {
                    setIsNotificationSoundEnabled(!isNotificationSoundEnabled);
                }}
                endComponent={
                    <Switch
                        trackColor={{
                            false: theme.SECONDARY,
                            true:
                                Platform.OS === 'ios'
                                    ? theme.SUCCESS
                                    : theme.SECONDARY,
                        }}
                        thumbColor={Platform.select({
                            ios: theme.BACKGROUND,
                            android: isNotificationSoundEnabled
                                ? theme.SUCCESS
                                : theme.TEXT_MUTED,
                            web: isNotificationSoundEnabled
                                ? theme.SUCCESS
                                : theme.TEXT_MUTED,
                        })}
                        ios_backgroundColor={Constants.colors.muted}
                        value={isNotificationSoundEnabled}
                        onValueChange={value => {
                            setIsNotificationSoundEnabled(value);
                        }}
                    />
                }
            />
            <SettingsButton
                theme={theme}
                title={lang().enable_random_conversations}
                color={theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY}
                onPress={() => {
                    onChangeIsRandomConversationAllowed();
                }}
                endComponent={
                    <Switch
                        trackColor={{
                            false: theme.SECONDARY,
                            true:
                                Platform.OS === 'ios'
                                    ? theme.SUCCESS
                                    : theme.SECONDARY,
                        }}
                        thumbColor={Platform.select({
                            ios: theme.BACKGROUND,
                            android: can_receive
                                ? theme.SUCCESS
                                : theme.TEXT_MUTED,
                            web: can_receive ? theme.SUCCESS : theme.TEXT_MUTED,
                        })}
                        ios_backgroundColor={Constants.colors.muted}
                        value={can_receive === 1}
                        onValueChange={() => {
                            onChangeIsRandomConversationAllowed();
                        }}
                    />
                }
            />
            <SettingsButton
                theme={theme}
                color={theme.PEACH}
                title={lang().logout}
                icon={logoutIcon}
                onPress={() => {
                    setAlert({
                        show: true,
                        title: lang().logout,
                        message: lang().confirm_logout,
                        confirmText: lang().confirm,
                        confirmColor: theme.DANGER,
                        cancelText: lang().cancel,
                        theme,
                        onConfirmPressed: () => {
                            setAlert(null);
                            goBackToPreviousScreen();
                            logout();
                        },
                        onCancelPressed: () => {
                            setAlert(null);
                        },
                        onDismiss: () => {
                            setAlert(null);
                        },
                    });
                }}
                testID="logoutButton"
            />
            <SettingsButton
                theme={theme}
                color={theme.DANGER}
                title={lang().delete_account.delete_account_settings_button}
                icon={deleteAccountIcon}
                onPress={() => {
                    setAlert({
                        show: true,
                        title: lang().delete_account
                            .delete_account_settings_button,
                        message:
                            lang().delete_account
                                .are_you_sure_you_want_to_delete_account,
                        confirmText: lang().confirm,
                        confirmColor: theme.DANGER,
                        cancelText: lang().cancel,
                        theme,
                        onConfirmPressed: () => {
                            setAlert({
                                show: true,
                                title: lang().delete_account
                                    .delete_account_settings_button,
                                message:
                                    lang().delete_account
                                        .your_account_will_be_removed_forever,
                                confirmText: lang().confirm,
                                confirmColor: theme.DANGER,
                                cancelText: lang().cancel,
                                theme,
                                onConfirmPressed: () => {
                                    setAlert(null);
                                    deleteMyAccount();
                                },
                                onCancelPressed: () => {
                                    setAlert(null);
                                },
                                onDismiss: () => {
                                    setAlert(null);
                                },
                            });
                        },
                        onCancelPressed: () => {
                            setAlert(null);
                        },
                        onDismiss: () => {
                            setAlert(null);
                        },
                    });
                }}
                testID="logoutButton"
            />
            <SettingsButton
                theme={theme}
                color={ThemeColors.dark.PRIMARY}
                centered
                title={lang().change_langauge_button}
                onPress={() => {
                    if (!isWeb) {
                        goBackToPreviousScreen();
                    }
                    changeLanguage({
                        lang:
                            localization?.language === 'ar'
                                ? 'en'
                                : ('ar' as any),
                    })(dispatch);
                    setTimeout(() => {
                        if (isWeb) {
                            RNRestart.Restart();
                        }
                    }, 10);
                }}
            />
            <View style={styles.contactUsSection}>
                <View style={styles.contactUsRow}>
                    <T
                        style={{
                            ...styles.contactUsText,
                            color: theme.TEXT_MUTED,
                        }}
                    >
                        {lang().contact_us_at_facebook}
                    </T>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL(
                                Constants.links.facebookPage,
                            ).catch();
                        }}
                    >
                        <T
                            style={{
                                ...styles.contactUsText,
                                color: theme.TEXT,
                                marginStart: 2,
                            }}
                        >
                            {lang().fromHere}
                        </T>
                    </TouchableOpacity>
                </View>
                <T style={{...styles.contactUsText, color: theme.TEXT_MUTED}}>
                    {lang().or_contact_us_by_email}
                </T>
            </View>
            <View style={styles.acceptTermsNoteSection}>
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.privacyPolicy).catch(
                            () => {},
                        );
                    }}
                >
                    <T
                        style={{
                            ...styles.acceptTermsNoteText,
                            color: theme.TEXT,
                        }}
                    >
                        {lang().privacy_policy}
                    </T>
                </TouchableOpacity>
                <T
                    style={{
                        ...styles.acceptTermsNoteText,
                        color: theme.TEXT,
                        marginHorizontal: 5,
                    }}
                >
                    {`${lang().and}`}
                </T>
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.termsOfUse).catch(
                            () => {},
                        );
                    }}
                >
                    <T
                        style={{
                            ...styles.acceptTermsNoteText,
                            color: theme.TEXT,
                        }}
                    >
                        {lang().terms_of_use}
                    </T>
                </TouchableOpacity>
            </View>
            <T
                style={{
                    ...styles.contactUsText,
                    color: theme.TEXT_MUTED,
                    marginTop: 10,
                }}
            >
                {expo.version}
            </T>
            <LoadingModal show={isLoading} />
        </View>
    );
};

const PointsButton = ({
    navigation,
    points,
    theme,
}: NavigationProp<{}> & {points: number | undefined; theme: Theme}) => {
    const isLight = theme.MODE === 'light';
    const UniqueColor = theme.MODE === 'dark' ? theme.TERTIARY : theme.PRIMARY;

    return (
        <TouchableOpacity
            onPress={() => {
                logEvent('openBuyPointsScreen');
                navigation.navigate(SCREENS.BUY_POINTS);
            }}
            style={{
                height: 60,
                width: '90%',
                backgroundColor: isLight ? 'white' : UniqueColor,
                marginTop: 8,
                borderRadius: 5,
                justifyContent: 'space-between',
                paddingHorizontal: 22,
                alignItems: 'center',
                flexDirection: 'row',
                borderColor: isLight ? UniqueColor : theme.BACKGROUND,
                borderWidth: 0.5,
                marginBottom: 5,
            }}
        >
            <T
                style={{
                    fontFamily: Constants.fonts.SemiBold,
                    fontSize: 13,
                    color: isLight ? UniqueColor : theme.TEXT,
                }}
            >
                {lang().points_system.buy_points}
            </T>
            <View
                style={{
                    backgroundColor: isLight ? UniqueColor : Colors.WHITE,
                    paddingHorizontal: 10,
                    paddingVertical: 2,
                    borderRadius: 100,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <T
                    style={{
                        fontSize: 10,
                        color: isLight ? Colors.WHITE : UniqueColor,
                        fontFamily: Constants.fonts.Bold,
                    }}
                >
                    {lang().points_system.your_current_points}
                </T>
                {!Number.isNaN(points as number) && (
                    <T
                        style={{
                            fontSize: 10,
                            color: isLight ? Colors.WHITE : UniqueColor,
                            fontFamily: Constants.fonts.Bold,
                            marginStart: 5,
                        }}
                    >
                        {`${points}`}
                    </T>
                )}
            </View>
        </TouchableOpacity>
    );
};

export default connector(Settings);
