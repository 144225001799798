import React from 'react';
import {
    Text,
    View,
    StyleSheet,
    TouchableOpacity,
    Image,
    ActivityIndicator,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import moment from 'moment';
import {useNavigation} from '@react-navigation/native';
import {ProfilePicture, T} from '.';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {humanize, normalize} from '../../modules/helpers';
import {useTheme} from '../../modules/app/theme';
import {User} from '../../types/data/user';
import {SCREENS} from '../../screens/Screens';
import {useLocalaization} from '../../modules/app/localization';

const backArrow = require('../../assets/images/backArrow.png');

const IMAGE_DIMENSIONS = 38;

const styles = StyleSheet.create({
    container: {
        marginTop: 11,
        height: 48,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    titleSection: {
        backgroundColor: '#EAEAEA',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 220,
        zIndex: 2,
        paddingStart: 10,
        borderTopStartRadius: 100,
        borderBottomStartRadius: 100,
    },
    titleContent: {height: '100%', flexGrow: 1, paddingHorizontal: 10},
    titleText: {
        fontFamily: Constants.fonts.SemiBold,
        textAlign: 'center',
        fontSize: normalize(11),
    },
    subtitleText: {
        marginTop: -7,
        fontSize: 12,
        color: '#7F7F7F',
        fontFamily: Constants.fonts.Regular,
        textAlign: 'center',
    },
    actionSection: {
        width: 50,
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingEnd: 14,
        zIndex: 2,
        borderTopEndRadius: 100,
        borderBottomEndRadius: 100,
    },
    titlePadding: {
        backgroundColor: '#BEBBC0',
        width: 40,
        borderTopStartRadius: 100,
        borderBottomStartRadius: 100,
        zIndex: 1,
    },
    profilePicture: {
        resizeMode: 'contain',
        height: normalize(IMAGE_DIMENSIONS) - 3,
        width: normalize(IMAGE_DIMENSIONS) - 3,
        borderRadius: 100,
        zIndex: 2,
    },
    profilePictureContainer: {
        height: normalize(IMAGE_DIMENSIONS),
        width: normalize(IMAGE_DIMENSIONS),
        minHeight: IMAGE_DIMENSIONS,
        minWidth: IMAGE_DIMENSIONS,
        borderRadius: IMAGE_DIMENSIONS,
        backgroundColor: Constants.colors.main.dark,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        shadowColor: '#5E5B83',
        shadowOpacity: 0.45,
        shadowRadius: 14,
        shadowOffset: {width: 0, height: 0},
    },
});

function TopBar({
    goBackToPreviousScreen,
    titleText,
    type = 'title',
    user = undefined,
}: {
    goBackToPreviousScreen: () => void;
    titleText: string;
    type?: string;
    user?:
        | (User & {isOnline?: boolean; last_seen?: string; isTyping?: boolean})
        | undefined;
}) {
    const navigation = useNavigation();
    const theme = useTheme();
    const localization = useLocalaization();

    const renderTitleContent = () => {
        switch (type) {
            case 'title':
                return (
                    <View
                        style={[
                            styles.titleContent,
                            {justifyContent: 'center', alignItems: 'center'},
                        ]}
                    >
                        <Text style={[styles.titleText, {color: theme.TEXT}]}>
                            {titleText}
                        </Text>
                    </View>
                );

            case 'conversation':
                if (user === undefined) {
                    return (
                        <View
                            style={[
                                styles.titleContent,
                                {
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                },
                            ]}
                        >
                            <ActivityIndicator color={theme.TEXT_PRIMARY} />
                        </View>
                    );
                }
                return (
                    <TouchableOpacity
                        onPress={() => {
                            if (user !== undefined) {
                                navigation.navigate(
                                    SCREENS.EXTERNAL_PROFILE as never,
                                    {user} as never,
                                );
                            }
                        }}
                        disabled={user === undefined}
                        style={[
                            styles.titleContent,
                            {
                                flexDirection: 'row-reverse',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                            },
                        ]}
                    >
                        {user.profile_picture_path && (
                            <ProfilePicture
                                theme={theme}
                                path={user.profile_picture_path}
                                dimensions={
                                    user.is_subscribed === 1
                                        ? IMAGE_DIMENSIONS - 5
                                        : IMAGE_DIMENSIONS
                                }
                                shadowRadius={theme.MODE === 'dark' ? 5 : 10}
                                shouldNormalizeDimensions={false}
                                customization={{
                                    frame: user.frame_shape,
                                    color: user.frame_color,
                                }}
                            />
                        )}
                        <View style={{paddingHorizontal: 20}}>
                            <Text
                                style={[styles.titleText, {color: theme.TEXT}]}
                            >
                                {user.name}
                            </Text>
                            {user.isTyping && user.isOnline !== false && (
                                <Animatable.View
                                    animation="fadeIn"
                                    easing="ease-in"
                                    iterationCount={1}
                                    delay={0}
                                    duration={1000}
                                    style={{marginTop: 4}}
                                >
                                    <T
                                        style={{
                                            ...styles.subtitleText,
                                            color: theme.TEXT_MUTED,
                                        }}
                                    >
                                        {lang().is_typing}
                                    </T>
                                </Animatable.View>
                            )}
                            {user.last_seen &&
                                user.isTyping !== true &&
                                !user.isOnline && (
                                    <View style={{marginTop: 6}}>
                                        <T
                                            style={{
                                                ...styles.subtitleText,
                                                color: theme.TEXT_MUTED,
                                            }}
                                        >
                                            {humanize(
                                                moment(
                                                    user.last_seen,
                                                ).valueOf(),
                                            )}
                                        </T>
                                    </View>
                                )}
                        </View>
                        <View>
                            {user.isOnline === true && (
                                <View
                                    style={{
                                        height: 12,
                                        width: 12,
                                        backgroundColor: '#7FAD78',
                                        borderRadius: 100,
                                        borderWidth: 0.5,
                                        borderColor: 'white',
                                    }}
                                />
                            )}
                            {user.isOnline === false && (
                                <View
                                    style={{
                                        height: 12,
                                        width: 12,
                                        backgroundColor: '#AD7878',
                                        borderRadius: 100,
                                        borderWidth: 0.5,
                                        borderColor: 'white',
                                    }}
                                />
                            )}
                        </View>
                    </TouchableOpacity>
                );
            default:
                return <></>;
        }
    };

    return (
        <View style={styles.container}>
            <View style={{flexDirection: 'row'}}>
                <TouchableOpacity
                    style={{zIndex: 2}}
                    onPress={() => {
                        goBackToPreviousScreen();
                    }}
                    testID="goBackButton"
                >
                    <View
                        style={[
                            styles.actionSection,
                            {backgroundColor: theme.PRIMARY},
                        ]}
                    >
                        <Image
                            source={backArrow}
                            style={{
                                height: 20,
                                width: 20,
                                marginStart: 5,
                                transform: [
                                    {
                                        scaleX:
                                            localization?.direction === 'rtl'
                                                ? -1
                                                : 1,
                                    },
                                ],
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row'}}>
                <View
                    style={[
                        styles.titleSection,
                        {backgroundColor: theme.SECONDARY},
                    ]}
                >
                    {renderTitleContent()}
                </View>
            </View>
        </View>
    );
}

export default TopBar;
