import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import SignupScreen from './SignupScreen';
import WelcomeScreen from './WelcomeScreen';
import CreateConversation from './CreateConversation';
import SettingsScreen from './SettingsScreen';
import Subscription from './Subscription';
import RemoveConversationsScreen from './RemoveConversationsScreen';
import ImageViewerScreen from './ImageViewerScreen';
import RandomConversation from './RandomConversation';
import InputScreen from './InputScreen';
import LoginScreen from './LoginScreen';
import Conversation from './Conversation';
import CreateConversationFromLink from './CreateConversationFromLink';
import Customization from './Customization';
import MaintenanceMode from './MaintenanceMode';
import Interests from './Interests';
import ConversationAdapter from './ConversationAdapter';
import ExternalUserProfile from './ExternalUserProfile';
import BlockReason from './BlockReason';
import BuyPoints from './BuyPoints';
import EmailAuthentication from './EmailAuthentication';

export const SCREENS = {
    WELCOME: 'Welcome',
    LOGIN: 'Login',
    SIGNUP: 'Signup',
    CREATE_CONVERSATION: 'CreateConversation',
    CONVERSATION: 'Conversation',
    SETTINGS: 'Settings',
    SUBSCRIPTION: 'Subscription',
    REMOVE_CONVERSATIONS: 'RemoveConversations',
    IMAGE_VIEWER: 'ImageViewer',
    RANDOM_CONVERSATION: 'RandomConversation',
    INPUT_SCREEN: 'InputScreen',
    CREATE_CONVERSATION_FROM_LINK: 'CreateConversationFromLink',
    CUSTOMIZATION: 'Customization',
    MAINTENANCE_MODE: 'MaintenanceMode',
    INTERESTS: 'Interests',
    CONVERSATION_ADAPTER: 'ConversationAdapter',
    EXTERNAL_PROFILE: 'ExternalUserProfile',
    BLOCK_REASON: 'BlockReason',
    BUY_POINTS: 'BuyPoints',
    EMAIL_AUTHENTICATION: 'EmailAuthentication',
};

const Screens = () => {
    const Stack = createStackNavigator();

    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
            }}
        >
            <Stack.Screen
                name={SCREENS.WELCOME}
                component={WelcomeScreen as any}
            />
            <Stack.Screen
                name={SCREENS.LOGIN}
                component={LoginScreen as any}
            />
            <Stack.Screen
                name={SCREENS.SIGNUP}
                component={SignupScreen as any}
            />
            <Stack.Screen
                name={SCREENS.CREATE_CONVERSATION}
                component={CreateConversation as any}
            />
            <Stack.Screen
                name={SCREENS.CONVERSATION}
                component={Conversation as any}
            />
            <Stack.Screen
                name={SCREENS.SETTINGS}
                component={SettingsScreen as any}
            />
            <Stack.Screen
                name={SCREENS.SUBSCRIPTION}
                component={Subscription as any}
            />
            <Stack.Screen
                name={SCREENS.REMOVE_CONVERSATIONS}
                component={RemoveConversationsScreen as any}
            />
            <Stack.Screen
                options={{animationEnabled: false}}
                name={SCREENS.IMAGE_VIEWER}
                component={ImageViewerScreen as any}
            />
            <Stack.Screen
                name={SCREENS.RANDOM_CONVERSATION}
                component={RandomConversation as any}
            />
            <Stack.Screen
                name={SCREENS.INPUT_SCREEN}
                component={InputScreen as any}
            />
            <Stack.Screen
                name={SCREENS.CREATE_CONVERSATION_FROM_LINK}
                component={CreateConversationFromLink}
            />
            <Stack.Screen
                name={SCREENS.CUSTOMIZATION}
                options={{
                    presentation: 'modal',
                }}
                component={Customization as any}
            />
            <Stack.Screen
                name={SCREENS.MAINTENANCE_MODE}
                component={MaintenanceMode as any}
            />
            <Stack.Screen
                name={SCREENS.INTERESTS}
                options={{
                    presentation: 'modal',
                }}
                component={Interests as any}
            />
            <Stack.Screen
                name={SCREENS.CONVERSATION_ADAPTER}
                component={ConversationAdapter}
                options={{animationEnabled: false}}
            />
            <Stack.Screen
                name={SCREENS.EXTERNAL_PROFILE}
                component={ExternalUserProfile as any}
            />
            <Stack.Screen
                name={SCREENS.BLOCK_REASON}
                component={BlockReason as any}
            />
            <Stack.Screen
                name={SCREENS.BUY_POINTS}
                component={BuyPoints as any}
            />
            <Stack.Screen
                name={SCREENS.EMAIL_AUTHENTICATION}
                component={EmailAuthentication}
            />
        </Stack.Navigator>
    );
};

export default Screens;
