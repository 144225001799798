import React, {useState} from 'react';
import {TouchableOpacity, View, StyleSheet, Image} from 'react-native';
import {launchImageLibrary} from 'react-native-image-picker';
import {useNavigation} from '@react-navigation/native';
import Constants from '../../assets/Constants';
import {
    checkIfAsyncStorageWasUsedBefore,
    normalize,
} from '../../modules/helpers';
import {ProfilePicture, T} from '../Shared';
import lang from '../../localization';
import {User} from '../../types/data/user';
import {InterestsScreenButton} from './UserInterests';
import {Colors, Theme} from '../../assets/Colors';
import {SCREENS} from '../../screens/Screens';
// @ts-ignore
import FastImage from '../../../PackageWrappers/Components/FastImage';
import {CustomAlertProps} from '../Shared/CustomAlert';

const customizationButton = require('../../assets/images/customizationButton.png');
const diamondIcon = require('../../assets/images/diamondIcon.png');

const IMAGE_DIMENSIONS = normalize(70);
const CUSTOMIZE_BUTTON_DIMENSIONS = IMAGE_DIMENSIONS * 0.45;
const CUSTOMIZATION_BUTTON = normalize(40);

const styles = StyleSheet.create({
    customizationButton: {
        position: 'absolute',
        height: CUSTOMIZATION_BUTTON,
        width: CUSTOMIZATION_BUTTON,
    },
    usernameSection: {
        height: normalize(38),
        marginTop: normalize(4),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    usernameSectionText: {
        fontSize: normalize(15),
        fontFamily: Constants.fonts.Bold,
        color: 'white',
    },
});
const UserInfo = ({
    user,
    changeProfilePicture,
    isProfilePictureUploading,
    openChangeUsernameMenu,
    theme,
    isSubscribed,
    setAlert,
}: {
    user: User;
    changeProfilePicture: (input: {image: string}) => void;
    isProfilePictureUploading: boolean;
    openChangeUsernameMenu: () => void;
    theme: Theme;
    isSubscribed: boolean;
    setAlert: (alert: CustomAlertProps | null) => void;
}) => {
    const {profile_picture_path, name} = user;
    const navigation = useNavigation();
    const {interests} = user;

    const onCustomizationPressed = () => {
        // @ts-ignore
        navigation.navigate({name: 'Customization'});
    };

    const onChangeProfilePressed = async () => {
        const launchPicker = () => {
            launchImageLibrary(
                {
                    mediaType: 'photo',
                    maxWidth: 500,
                    quality: 1,
                    includeBase64: true,
                },
                (response: {
                    errorCode: number;
                    didCancel: boolean;
                    assets: [{base64: string}];
                }) => {
                    if (!response.errorCode && !response.didCancel) {
                        // You can also display the image using data:
                        const image = `data:image/jpeg;base64,${response?.assets[0].base64}`;
                        changeProfilePicture({image});
                    }
                },
            );
        };

        if (
            await checkIfAsyncStorageWasUsedBefore({
                key: 'hasConfirmedToProfilePictureConsent',
            })
        ) {
            launchPicker();
        } else {
            setAlert({
                show: true,
                message: lang().warn_for_first_profile_picture_upload,
                confirmText:
                    lang().warn_for_first_profile_picture_upload_confirm,
                theme,
                onConfirmPressed: () => {
                    launchPicker();
                    setAlert(null);
                },
                onDismiss: () => {
                    setAlert(null);
                },
            });
        }
    };

    return (
        <View
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginHorizontal: 20,
            }}
        >
            <View>
                <ProfilePicture
                    path={profile_picture_path}
                    dimensions={IMAGE_DIMENSIONS}
                    isLoading={isProfilePictureUploading}
                    shadowColorOpacity={0.05}
                    customization={{
                        frame: user.frame_shape,
                        color: user.frame_color,
                    }}
                    setAlert={setAlert}
                    changeProfilePicture={changeProfilePicture as any}
                    theme={theme}
                />
                <TouchableOpacity
                    accessibilityHint="Customize your profile picture"
                    accessibilityLabel="Customize your profile picture"
                    onPress={() => {
                        onCustomizationPressed();
                    }}
                    style={{
                        height: CUSTOMIZE_BUTTON_DIMENSIONS,
                        width: CUSTOMIZE_BUTTON_DIMENSIONS,
                        borderRadius: CUSTOMIZE_BUTTON_DIMENSIONS,
                        position: 'absolute',
                        zIndex: 2,
                        top: 4,
                        start: -5,
                        opacity: 0.9,
                    }}
                >
                    <FastImage
                        source={customizationButton}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                    />
                </TouchableOpacity>
            </View>
            <View
                style={{
                    marginStart: 15,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <TouchableOpacity
                    onPress={() => {
                        openChangeUsernameMenu();
                    }}
                    style={styles.usernameSection}
                >
                    <T style={styles.usernameSectionText}>{name}</T>
                </TouchableOpacity>
                <View style={{flexDirection: 'row'}}>
                    {isSubscribed && <SubscribedTag theme={theme} />}
                    {interests && interests.length === 0 && (
                        <View style={{marginStart: -5}}>
                            <InterestsScreenButton
                                theme={theme}
                                key="AddButton"
                                isAddButton
                            />
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

const SubscribedTag = ({theme}: {theme: Theme}) => {
    const navigation = useNavigation();

    return (
        <TouchableOpacity
            onPress={() => {
                navigation.navigate(SCREENS.SUBSCRIPTION as never);
            }}
            style={{
                backgroundColor: theme.SUCCESS,
                paddingHorizontal: 10,
                marginEnd: 8,
                minHeight: 20,
                borderRadius: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Image
                source={diamondIcon}
                style={{
                    height: 12,
                    width: 12,
                    marginEnd: 5,
                    tintColor: Colors.WHITE,
                }}
                resizeMode="contain"
            />
            <T
                style={{
                    color: Colors.WHITE,
                    fontSize: normalize(9),
                    fontFamily: Constants.fonts.SemiBold,
                }}
            >
                {lang().subscribed}
            </T>
        </TouchableOpacity>
    );
};

export default UserInfo;
