import * as anonymousAPI from './anonymousAPI';
import * as api from './api';
import {actions as conversationsActions} from '../conversations';

export function getUserDataFromUserLink({userLink}) {
    return async (dispatch, getState) => {
        return anonymousAPI
            .getUserDataFromUserLink({userLink})
            .then(response => {
                if (response.data.success) {
                    const userData = response.data.data.user;
                    return userData;
                }
                if (response.data.code === 7012) {
                    throw new Error('isBlocked');
                } else {
                    throw new Error('noUserFound');
                }
            });
    };
}

export function createConversation({userLink, nickname}) {
    return async (dispatch, getState) => {
        const {id} = getState().authReducer.user;
        return anonymousAPI
            .createConversation({userLink, nickname, initiatorID: id})
            .then(response => {
                if (response.data.success) {
                    const {conversation, user} = response.data.data;
                    return {conversation, user};
                }
                throw new Error('wrong data');
            });
    };
}

export function extractUserLinkFromURL({link}) {
    return async (dispatch, getState) => {
        return anonymousAPI.extractUserLinkFromURL({link}).then(response => {
            if (response.data.success) {
                const {user_link} = response.data.data;
                return {user_link};
            }
            if (response.data.code === 4071) {
                throw new Error('LinkNotFound');
            } else {
                throw new Error('error');
            }
        });
    };
}

export function getConversationDataFromConversationLink({
    conversation,
    isInitiated,
}) {
    return async (dispatch, getState) => {
        if (isInitiated) {
            return anonymousAPI
                .getConversationDataFromConversationLink({
                    conversationLink: conversation.conversation_link,
                })
                .then(response => {
                    if (response.data.success) {
                        return response.data.data;
                    }
                    throw new Error('failed');
                });
        }
        return api
            .getInitialMessages({conversation_id: conversation.id})
            .then(response => {
                if (response.data.success) {
                    return response.data.data;
                }
                throw new Error('failed');
            });
    };
}

export function getMoreMessages({conversation, lastMessageID, isInitiated}) {
    return async (dispatch, getState) => {
        if (isInitiated) {
            return anonymousAPI
                .anonymousGetMoreMessages({
                    conversationLink: conversation.conversation_link,
                    lastMessageID,
                })
                .then(response => {
                    if (response.data.success) {
                        return response.data.data;
                    }
                    throw new Error('failed');
                });
        }
        return api
            .getMoreMessages({
                conversation_id: conversation.id,
                last_message_id: lastMessageID,
            })
            .then(response => {
                if (response.data.success) {
                    return response.data.data;
                }
                throw new Error('failed');
            });
    };
}

export function sendMessage({text, conversation, userLink, isInitiated}) {
    return async (dispatch, getState) => {
        if (isInitiated) {
            return anonymousAPI
                .sendMessage({
                    text,
                    networkKey: conversation.network_key,
                    userLink,
                })
                .then(response => {
                    if (response.data.success) {
                        return response.data.data;
                    }
                    throw new Error('failed');
                });
        }
        return api
            .sendMessage({
                text,
                conversationID: conversation.id,
            })
            .then(response => {
                if (response.data.success) {
                    return {message: response.data.data};
                }
                throw new Error('failed');
            });
    };
}

export function getInitiatedConversations({returnType}) {
    return async (dispatch, getState) => {
        return anonymousAPI
            .getInitiatedConversations({returnType})
            .then(response => {
                if (response.data.success) {
                    return response.data.data;
                }
                throw new Error('failed');
            });
    };
}

export function isTyping({conversationID, userID, socket, isInitiated}) {
    return async (dispatch, getState) => {
        try {
            if (socket) {
                if (isInitiated) {
                    socket.emit('isAnonymousTyping', {
                        conversation_id: conversationID,
                        user_id: userID,
                    });
                } else {
                    socket.emit('isUserTyping', {
                        conversation_id: conversationID,
                    });
                }
            }
        } catch (e) {
            //
        }
    };
}

export function markConversationAsActive(conversationID) {
    return (dispatch, getState) => {
        conversationsActions.markConversationAsActive(conversationID)(
            dispatch,
            getState,
        );
    };
}

export function freeActiveConversation() {
    return (dispatch, getState) => {
        conversationsActions.freeActiveConversation()(dispatch, getState);
    };
}
