import Backend from '../axios/axios';

export async function syncPoints() {
    return Backend.post('/syncPoints', {});
}

export async function buyWithPoints({
    productName,
    details,
}: {
    productName: string;
    details?: string;
}) {
    return Backend.post('/buyWithPoints', {
        product_name: productName,
        details,
    });
}
