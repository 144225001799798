// ALL VALUES ARE STRINGS

export default {
    show_welcome_random_conversations_modal: 'true',
    num_of_allowed_random_conversations: '7',
    revenue_cat_offering: 'default_offering',
    subscription_features_types: 'points',
    random_conversation_header_style: 'number_of_left_conversations',
    subscribe_button_pulsing: 'true',
    show_country_flag: 'true',
};
