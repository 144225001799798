import AsyncStorage from '@react-native-async-storage/async-storage';
import * as api from './api';
import * as t from './actionTypes';
import {tinyClick, mediumClick} from '../app/vibrations';

export function saveConversationsToStorage() {
    return (dispatch, getState) => {
        const {conversations} = getState().conversationsReducer;
        if (conversations === undefined) {
            return;
        }

        const filteredConversations = conversations.map(conversation => {
            return {
                ...conversation,
                isTyping: false,
                is_loading_more_messages: false,
                is_fully_loaded: false,
            };
        });
        AsyncStorage.setItem(
            'conversations',
            JSON.stringify(filteredConversations),
        );
    };
}

// function getLastInteractedConversation() {
//   return (dispatch, getState) => {
//     const {conversations} = getState().conversationsReducer;
//     let lastInteractedConversation;
//     conversations.forEach(conversation => {
//       if (
//         lastInteractedConversation === undefined ||
//         new Date(conversation.last_interacted_at).getTime() >
//           new Date(lastInteractedConversation.last_interacted_at).getTime()
//       ) {
//         lastInteractedConversation = conversation;
//       }
//     });
//     return lastInteractedConversation;
//   };
// }

export function loadConversations() {
    return async (dispatch, getState) => {
        try {
            const conversations = (await api.getConversations()).data.data;
            dispatch({
                type: t.LOAD_CONVERSATIONS,
                data: {conversations, shouldRemoveDeletedConversations: true},
            });

            saveConversationsToStorage()(dispatch, getState);
        } catch (e) {
            // console.log(e);
        }
    };
}

export function unloadConversations() {
    return async dispatch => {
        await AsyncStorage.removeItem('conversations');
        dispatch({
            type: t.UNLOAD_CONVERSATIONS,
        });
    };
}

export function markConversationAsActive(conversationID) {
    return async dispatch => {
        tinyClick();
        dispatch({
            type: t.MARK_CONVERSATION_AS_ACTIVE,
            data: {conversationID},
        });
    };
}

export function unsetNewConversation(conversationID) {
    return (dispatch, getState) => {
        dispatch({
            type: t.UNSET_IS_NEW,
            data: {
                conversationID,
            },
        });
        saveConversationsToStorage()(dispatch, getState);
    };
}

export function setNewConversation(conversationID) {
    return (dispatch, getState) => {
        dispatch({
            type: t.SET_IS_NEW,
            data: {
                conversationID,
            },
        });
        saveConversationsToStorage()(dispatch, getState);
    };
}

export function freeActiveConversation() {
    return async dispatch => {
        dispatch({
            type: t.FREE_ACTIVE_CONVERSATION,
            data: undefined,
        });
    };
}

export function newConversationReceived(data) {
    return (dispatch, getState) => {
        const {id, anonymous_nickname, picture, gender, is_random} = data;
        mediumClick();
        dispatch({
            type: t.NEW_CONVERSATION_RECEIVED,
            data: {
                id,
                anonymous_nickname,
                picture,
                gender,
                is_random: is_random ? 1 : 0,
            },
        });
        saveConversationsToStorage()(dispatch, getState);
    };
}

export function removeConversations({willBeRemovedConversations = []}) {
    return async (dispatch, getState) => {
        const response = (
            await api.removeConversations({
                delete_conversation: willBeRemovedConversations,
            })
        ).data;

        if (response.success) {
            dispatch({
                type: t.REMOVE_CONVERSATIONS,
                data: {
                    willBeRemovedConversations,
                },
            });
            saveConversationsToStorage()(dispatch, getState);
        } else {
            throw new Error('Error');
        }
    };
}

export function blockConversation({conversationID = undefined}) {
    return async (dispatch, getState) => {
        const response = (
            await api.blockConversation({
                conversation_id: conversationID,
            })
        ).data;

        if (response.success) {
            if (response.data.is_blocked) {
                dispatch({
                    type: t.BLOCK_CONVERSATION,
                    data: {
                        conversationID,
                    },
                });
                saveConversationsToStorage()(dispatch, getState);
                return true;
            }
            dispatch({
                type: t.UNBLOCK_CONVERSATION,
                data: {
                    conversationID,
                },
            });
            saveConversationsToStorage()(dispatch, getState);
            return false;
        }
        throw new Error('Error');
    };
}

export function reportConversation({conversationID = undefined}) {
    return async () => {
        const response = (
            await api.reportConversation({
                conversation_id: conversationID,
            })
        ).data;

        if (response.success) {
            return true;
        }
        throw new Error('Error');
    };
}

export function setConversationIsTyping({conversationID}) {
    return dispatch => {
        dispatch({
            type: t.SET_CONVERSATION_IS_TYPING,
            data: {
                conversationID,
            },
        });
        setTimeout(() => {
            dispatch({
                type: t.UNSET_CONVERSATION_IS_TYPING,
                data: {
                    conversationID,
                },
            });
        }, 2500);
    };
}

export function checkUpdates() {
    return async (dispatch, getState) => {
        return api
            .checkUpdates()
            .then(response => {
                if (response.data.success) {
                    const {newConversations} = response.data.data;
                    if (newConversations.length > 0) {
                        dispatch({
                            type: t.LOAD_CONVERSATIONS,
                            data: {
                                conversations: newConversations,
                            },
                        });
                        saveConversationsToStorage()(dispatch, getState);
                    }
                }
            })
            .catch(() => {
                // console.log(e);
            });
    };
}

export function markConversationAsSeen({conversationID}) {
    return () => {
        api.markConversationAsSeen({conversation_id: conversationID})
            .then(response => {
                if (response.data.success) {
                    // console.log(`you should update the seen ${conversationID}`);
                }
            })
            .catch(() => {});
    };
}
