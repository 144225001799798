import React from 'react';
import {Platform} from 'react-native';
import BugsnagWeb from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// // @ts-ignore

if (Platform.OS === 'web') {
    BugsnagWeb.start({
        apiKey: '465d8a1dac83e64108f7875f7ec95155',
        plugins: [new BugsnagPluginReact()],
    });
}

const ErrorBoundaryWrapper =
    BugsnagWeb?.getPlugin('react')?.createErrorBoundary(React);

const ErrorBoundary = ({children}: {children: any}) => {
    return (
        // @ts-ignore
        <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>
    );
};

export default ErrorBoundary;
