import React, {useEffect} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    Image,
    KeyboardAvoidingView,
    Platform,
    Dimensions,
    TouchableWithoutFeedback,
    Keyboard,
    AppState,
} from 'react-native';
import RNRestart from 'react-native-restart';
import LinearGradient from 'react-native-linear-gradient';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import {normalize} from '../modules/helpers';
import lang from '../localization';
import {checkUserAndConfig} from '../modules/auth/api';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';

const Images = {
    maintenanceMode: require('../assets/images/maintenanceMode.png'),
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    keyboardAvoidingView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    headerCircle: {
        position: 'absolute',
        width: Dimensions.get('screen').width * 4,
        height: Dimensions.get('screen').width * 4,
        top: '70%',
        right: -Dimensions.get('screen').width * 1.5,
        borderRadius: Dimensions.get('screen').width * 4,
    },
});

const MaintenanceMode = ({navigation}: any) => {
    const theme = useTheme();

    const checkAppIsOutOfMaintenance = () => {
        checkUserAndConfig({isSubscribed: false})
            .then((response: any) => {
                if (
                    response.data &&
                    response.data.data.config &&
                    response.data.data.config.is_maintenance_mode === false
                ) {
                    RNRestart.Restart();
                }
            })
            .catch(e => {
                console.warn({e});
            });
    };

    useEffect(
        () =>
            navigation.addListener('beforeRemove', (e: any) => {
                e.preventDefault();
                checkAppIsOutOfMaintenance();
            }),
        [navigation],
    );

    useEffect(() => {
        const eventListener = AppState.addEventListener('change', state => {
            if (state === 'active') {
                checkAppIsOutOfMaintenance();
            }
        });

        return () => {
            eventListener.remove();
        };
    }, []);

    return (
        <SafeAreaView style={styles.container}>
            <StatusBarAware theme={theme} />
            <KeyboardAvoidingView
                behavior="height"
                keyboardVerticalOffset={Platform.select({ios: 150, android: 0})}
                style={styles.keyboardAvoidingView}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.PRIMARY}
                    style={styles.headerCircle}
                />

                <View
                    style={{
                        zIndex: 2,
                        backgroundColor: 'rgba(256, 256, 256, 0)',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        paddingTop: 100,
                    }}
                >
                    <TouchableWithoutFeedback
                        onPress={() => {
                            Keyboard.dismiss();
                        }}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                marginTop: -80,
                                marginBottom: 80,
                                height: '70%',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                source={Images.maintenanceMode}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    resizeMode: 'contain',
                                }}
                            />
                        </View>
                    </TouchableWithoutFeedback>

                    <TouchableWithoutFeedback
                        onPress={() => {
                            Keyboard.dismiss();
                        }}
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <View
                            style={{
                                height: '30%',
                                width: '100%',
                                maxWidth: 600,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBottom: 20,
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingHorizontal: 25,
                                }}
                            >
                                <T
                                    style={{
                                        color: Constants.colors.whiteMain,
                                        fontFamily: Constants.fonts.Bold,
                                        lineHeight: 20,
                                        marginBottom: 20,
                                        fontSize: normalize(22),
                                    }}
                                >
                                    {lang().maintenance_mode.title}
                                </T>
                                <T
                                    style={{
                                        color: Constants.colors.whiteMain,
                                        fontFamily: Constants.fonts.SemiBold,
                                        lineHeight: 20,
                                        fontSize: normalize(12),
                                        textAlign: 'center',
                                    }}
                                >
                                    {lang().maintenance_mode.description}
                                </T>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

export default MaintenanceMode;
