import React from 'react';

import {StyleSheet, TextInputProps, View} from 'react-native';
import {Input, T} from '../Shared';
import Constants from '../../assets/Constants';
import {useTheme} from '../../modules/app/theme';
import {useLocalaization} from '../../modules/app/localization';
import {isWeb} from '../../../env';
import lang from '../../localization';

const styles = StyleSheet.create({
    input: {
        height: 48,
        borderRadius: Constants.borderRadius.base,
        borderColor: Constants.borderColor.light,
        borderWidth: Constants.borderWidth.tiny,
        backgroundColor: Constants.colors.inputBackground,
        paddingHorizontal: 10,
        marginVertical: 10,
        fontSize: 15,
        color: '#262626',
        width: '100%',
    },
});

const LoginInput = (
    props: {
        style: any;
        children?: JSX.Element;
        error?: string;
    } & TextInputProps,
) => {
    const theme = useTheme();
    const localization = useLocalaization();

    const getLocalizationStyles = () => {
        if (isWeb) {
            return {
                textAlign: localization?.direction === 'ltr' ? 'left' : 'right',
            };
        }
        return {
            writingDirection: lang().direction,
            textAlign: lang().align,
        };
    };

    return (
        <View
            style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Input
                selectionColor={theme.PRIMARY}
                placeholderTextColor={theme.TEXT_MUTED}
                {...props}
                style={{
                    ...styles.input,
                    ...getLocalizationStyles(),
                    ...props.style,
                }}
            >
                {props.children as any}
            </Input>
            {props.error && (
                <View
                    style={{
                        width: '100%',
                        alignItems: 'flex-start',
                        paddingTop: 2,
                    }}
                >
                    <T style={{color: theme.DANGER}}>{props.error}</T>
                </View>
            )}
        </View>
    );
};

export default LoginInput;
