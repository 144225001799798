import React from 'react';
import {Image, Platform, View} from 'react-native';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';

const checkIcon = require('../../assets/images/checkIconLarge.png');

export type Feature = {
    id: number;
    title: string;
    description: string;
};

const Features = ({features, theme}: {features: Feature[]; theme: Theme}) => {
    const renderFeatures = () => {
        return features
            .sort((featureA, featureB) => featureA.id - featureB.id)
            .map(feature => {
                return (
                    <View
                        key={`${feature.title}${feature.description}`}
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingHorizontal: 20,
                            marginVertical: 8,
                        }}
                    >
                        <View
                            style={{
                                height: 35,
                                width: 35,
                                backgroundColor: theme.SUCCESS,
                                borderRadius: 100,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                source={checkIcon}
                                resizeMode="contain"
                                style={{
                                    height: 20,
                                    width: 20,
                                    tintColor: theme.TEXT_WHITE,
                                }}
                            />
                        </View>
                        <View
                            style={{
                                marginStart: 15,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <T
                                style={{
                                    fontFamily: Constants.fonts.SemiBold,
                                    fontSize: normalize(14),
                                    marginBottom: Platform.select({
                                        ios: 3,
                                        android: 1,
                                    }),
                                    color: theme.TEXT_MUTED,
                                }}
                            >
                                {feature.title}
                            </T>
                            <T
                                style={{
                                    color: theme.TEXT_MUTED,
                                    fontSize: normalize(11),
                                    textAlign: 'left',
                                }}
                            >
                                {feature.description}
                            </T>
                        </View>
                    </View>
                );
            });
    };

    if (features && Array.isArray(features)) {
        return <>{renderFeatures()}</>;
    }

    return <View />;
};

export default Features;
