import React, {Fragment, useEffect, useState} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    ScrollView,
    Platform,
    Dimensions,
    ActivityIndicator,
} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import {useMutation, useQuery} from 'react-query';
import {AxiosError, AxiosResponse} from 'axios';
import LinearGradient from 'react-native-linear-gradient';
import Constants from '../assets/Constants';
import {T, TopBar} from '../components/Shared';
import lang from '../localization';
import {NavigationProp} from '../types/navigation/navigationProp';
import {actions as interestsActions} from '../modules/interests';
import {Interest} from '../types/data/interest';
import InterestsSection from '../components/Interests/InterestsSection';
import {syncInterests} from '../modules/interests/api';
import {ReducerState} from '../types/reducer/reducer';
import {ReduxDispatch, ReduxGetState} from '../types/redux/redux';
import store from '../modules/redux/store';
import {actions as authActions} from '../modules/auth';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {ConfirmButton} from './Customization';

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const styles = StyleSheet.create({
    container: {
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    updateUser: () => authActions.updateUser()(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    storedInterests: state.authReducer.user?.interests,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
    storedInterests: Interest[];
};

export const NUMBER_OF_ALLOWED_INTERESTS = 8;

const Interests = ({
    navigation,
    storedInterests = [],
    updateUser,
}: Props & NavigationProp<any>) => {
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const theme = useTheme();

    const toggleInterest = ({name}: {name: string}) => {
        if (selectedInterests.includes(name)) {
            setSelectedInterests(
                selectedInterests.filter(selected => selected !== name),
            );
        } else if (selectedInterests.length < NUMBER_OF_ALLOWED_INTERESTS) {
            setSelectedInterests([...selectedInterests, name]);
        }
    };

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${lang().screenTitles.interests}`,
        });
    }, []);

    const {isLoading, data, isError, isFetched} = useQuery<
        Interest[],
        AxiosError
    >('interests', () => interestsActions.getInterests(), {
        retry: 2,
        retryDelay: 1000,
        enabled: true,
        cacheTime: 0,
    });

    const syncInterestsMutation = useMutation<
        AxiosResponse,
        AxiosError,
        number[],
        unknown
    >(
        interestIDs => {
            return syncInterests(
                interestIDs.map((id: number) => {
                    return {interest_id: id};
                }),
            );
        },
        {
            onSuccess: async () => {
                await updateUser();
                goBackToPreviousScreen();
            },
            onError: () => {
                // console.log({error});
            },
        },
    );

    useEffect(() => {
        setSelectedInterests(storedInterests.map(interest => interest.name));
    }, [storedInterests]);

    const interestsByType: {[name: string]: Interest[]} = {};

    if (isFetched) {
        data?.forEach((interest: Interest) => {
            if (interestsByType[interest.type]) {
                interestsByType[interest.type] = [
                    ...interestsByType[interest.type],
                    interest,
                ];
            } else {
                interestsByType[interest.type] = [interest];
            }
        });
    }

    const limit = NUMBER_OF_ALLOWED_INTERESTS - selectedInterests.length;

    return (
        <SafeAreaView
            style={[styles.container, {backgroundColor: theme.BACKGROUND}]}
        >
            <StatusBarAware theme={theme} />
            <View
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <TopBar
                    goBackToPreviousScreen={goBackToPreviousScreen}
                    titleText={lang().interests.edit_interests}
                />
                <View
                    style={{
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    <ScrollView
                        style={{marginTop: 5, width: '100%'}}
                        contentContainerStyle={{
                            paddingBottom: 200,
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 5,
                        }}
                    >
                        {isLoading && (
                            <ActivityIndicator
                                style={{marginTop: 200}}
                                size="large"
                                color={theme.PRIMARY}
                            />
                        )}
                        {isError && (
                            <T
                                style={{
                                    fontFamily: Constants.fonts.Bold,
                                    color: '#865A5A',
                                    marginTop: 200,
                                }}
                            >
                                {lang().error_happened}
                            </T>
                        )}
                        {isFetched && (
                            <>
                                <InterestsSection
                                    key="language"
                                    interests={interestsByType.language}
                                    title={lang().interests.types.language}
                                    toggleInterest={toggleInterest}
                                    selectedInterests={selectedInterests}
                                    theme={theme}
                                />
                                <InterestsSection
                                    key="general"
                                    interests={interestsByType.general}
                                    title={lang().interests.types.general}
                                    toggleInterest={toggleInterest}
                                    selectedInterests={selectedInterests}
                                    theme={theme}
                                />
                                {Object.values(interestsByType).map(
                                    interestsGroup => {
                                        if (
                                            interestsGroup[0].type ===
                                                'language' ||
                                            interestsGroup[0].type === 'general'
                                        ) {
                                            return (
                                                <Fragment
                                                    key={`${interestsGroup[0].type}2`}
                                                />
                                            );
                                        }
                                        return (
                                            <Fragment
                                                key={interestsGroup[0].type}
                                            >
                                                <InterestsSection
                                                    interests={interestsGroup}
                                                    title={
                                                        // @ts-ignore
                                                        lang().interests.types[
                                                            interestsGroup[0]
                                                                .type
                                                        ]
                                                    }
                                                    toggleInterest={
                                                        toggleInterest
                                                    }
                                                    selectedInterests={
                                                        selectedInterests
                                                    }
                                                    theme={theme}
                                                />
                                            </Fragment>
                                        );
                                    },
                                )}
                            </>
                        )}
                    </ScrollView>
                    <LinearGradient
                        style={{
                            height: 200,
                            width: '100%',
                            position: 'absolute',
                            bottom: 0,
                        }}
                        colors={theme.GRADIENTS.BOTTOM_FADER}
                    />
                </View>
                {isFetched && (
                    <View
                        style={{
                            position: 'absolute',
                            bottom: PHONE_HAS_A_NOTCH ? 0 : 20,
                            width: '100%',
                            paddingHorizontal: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {limit === 0 && (
                            <View style={{marginBottom: 10}}>
                                <T
                                    style={{
                                        color:
                                            limit === 0
                                                ? Constants.colors.danger
                                                : Constants.colors.success,
                                        fontFamily:
                                            limit === 0
                                                ? Constants.fonts.Bold
                                                : Constants.fonts.Bold,
                                        fontSize: 13,
                                    }}
                                >
                                    {`(${lang().interests.limit_reached(
                                        NUMBER_OF_ALLOWED_INTERESTS,
                                    )})`}
                                </T>
                            </View>
                        )}
                        <ConfirmButton
                            theme={theme}
                            isLoading={syncInterestsMutation.isLoading}
                            onPress={() => {
                                const interestIDs = [...selectedInterests].map(
                                    interestName => {
                                        if (data) {
                                            return data.filter(
                                                interest =>
                                                    interest.name ===
                                                    interestName,
                                            )[0].id;
                                        }
                                        return 1;
                                    },
                                );
                                syncInterestsMutation.mutate(interestIDs);
                            }}
                        />
                    </View>
                )}
            </View>
        </SafeAreaView>
    );
};

// @ts-ignore
export default connector(Interests);
