import React from 'react';
import {View, Platform, TouchableOpacity, StyleSheet} from 'react-native';
import * as Linking from 'expo-linking';
import {T} from '../Shared/index';
import lang from '../../localization';
import Constants from '../../assets/Constants';
import {Theme} from '../../assets/Colors';

const styles = StyleSheet.create({
    container: {
        marginTop: Platform.select({
            ios: 12,
            android: 10,
        }),
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 0.6,
    },
    terms_of_use_and_privacy_section: {flexDirection: 'row'},
    by_registering_text: {
        fontSize: 11,
        paddingBottom: 10,
    },
    link_text: {
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
    },
    or_text: {
        fontSize: 10,
        marginHorizontal: 2,
    },
});

const PrivacyTermsSection = ({
    isLogin = false,
    theme,
}: {
    isLogin?: boolean;
    theme: Theme;
}) => {
    return (
        <View style={styles.container}>
            {isLogin && (
                <T
                    style={{
                        ...styles.by_registering_text,
                        color: theme.TEXT_MUTED,
                    }}
                >
                    {lang().by_registering}
                </T>
            )}
            <View style={styles.terms_of_use_and_privacy_section}>
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.termsOfUse).catch(
                            () => {},
                        );
                    }}
                >
                    <T style={{...styles.link_text, color: theme.TEXT_MUTED}}>
                        {lang().terms_of_use}
                    </T>
                </TouchableOpacity>
                <T
                    style={{
                        ...styles.link_text,
                        color: theme.TEXT_MUTED,
                        marginHorizontal: 5,
                    }}
                >{`${lang().and}`}</T>
                <TouchableOpacity
                    onPress={() => {
                        Linking.openURL(Constants.links.privacyPolicy).catch(
                            () => {},
                        );
                    }}
                >
                    <T style={{...styles.link_text, color: theme.TEXT_MUTED}}>
                        {lang().privacy_policy}
                    </T>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default PrivacyTermsSection;
