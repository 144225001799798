import React from 'react';
import {Text, StyleSheet, Platform, TextStyle} from 'react-native';
import {isWeb} from '../../../env';
import Constants from '../../assets/Constants';
import AppType from '../../../AppType';

const hasPadding = Platform.OS === 'ios' || isWeb;
const padding = 4;

const styles = StyleSheet.create({
    text: {
        fontFamily: Constants.fonts.Regular,
        paddingBottom: hasPadding ? padding : 0,
        paddingTop: hasPadding ? padding : 0,
        marginVertical: hasPadding ? -padding : 0,
        color: '#121212',
    },
});

const T = (props: {
    style?: TextStyle;
    children: JSX.Element | string | string[];
}) => {
    return (
        <Text
            {...props}
            style={[
                styles.text,
                props.style,
                AppType.CURRENT_APP === 'NEZMO' &&
                props &&
                props.style &&
                props.style.fontSize
                    ? {fontSize: props.style.fontSize + 1}
                    : {},
            ]}
        >
            {props.children}
        </Text>
    );
};

export default T;
