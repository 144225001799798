import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import {useTheme} from '../../modules/app/theme';

const LoadingOfferings = () => {
    const theme = useTheme();

    return (
        <View style={{marginTop: 80, marginBottom: 50}}>
            <ActivityIndicator
                size="large"
                color={theme.PRIMARY}
            />
        </View>
    );
};

export default LoadingOfferings;
