import React, {useEffect, useState} from 'react';
import {BannerAdSize, BannerAd, TestIds} from 'react-native-google-mobile-ads';

import {View, StyleSheet, Platform, Dimensions} from 'react-native';

const styles = StyleSheet.create({
    acceptConversationBanner: {marginTop: 100},
    ProfileBottomBanner: {
        height: Platform.select({ios: 100, android: 50}),
        marginVertical: 20,
    },
});

const isDev = __DEV__;

const noConversationsBannerUnitID = isDev
    ? TestIds?.BANNER
    : Platform.select({
          ios: 'ca-app-pub-4746201201889248/1966391000',
          android: 'ca-app-pub-4746201201889248/2688809103',
      });

const profileBannerUnitID = isDev
    ? TestIds?.BANNER
    : Platform.select({
          ios: 'ca-app-pub-4746201201889248/9885792361',
          android: 'ca-app-pub-4746201201889248/9801012363',
      });

const createConversationBannerUnitID = isDev
    ? TestIds?.BANNER
    : Platform.select({
          ios: 'ca-app-pub-4746201201889248/7497919285',
          android: 'ca-app-pub-4746201201889248/5604662773',
      });

export const NoConversationsBanner = () => {
    const [shouldViewAd, setShouldViewAd] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldViewAd(true);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    });
    if (!Dimensions.get('screen').height > 650) {
        return <View />;
    }
    return (
        <View>
            {shouldViewAd && (
                <BannerAd
                    unitId={noConversationsBannerUnitID}
                    size={BannerAdSize.MEDIUM_RECTANGLE}
                />
            )}
        </View>
    );
};

export const ProfileBottomBanner = () => {
    return (
        <View style={styles.ProfileBottomBanner}>
            <BannerAd
                unitId={profileBannerUnitID}
                size={Platform.select({
                    ios: BannerAdSize.LARGE_BANNER,
                    android: BannerAdSize.BANNER,
                })}
            />
        </View>
    );
};

export const CreateConversationBanner = () => {
    return (
        <View>
            <BannerAd
                unitId={createConversationBannerUnitID}
                size={BannerAdSize.MEDIUM_RECTANGLE}
            />
        </View>
    );
};
