import {useCallback, useEffect, useState} from 'react';
import uniqueId from 'lodash.uniqueid';
import emitter from '../helpers/Emitter';

const useFastCallback = (
    handler: (value: any) => any,
    dependencyList: any[],
) => {
    const [eventName] = useState(uniqueId());

    useEffect(() => {
        emitter.addListener(eventName, data => {
            handler(data);
        });

        return () => {
            emitter.removeListener(eventName, () => {});
        };
    }, dependencyList);

    const onHandler = useCallback((data: any) => {
        emitter.emit(eventName, data);
    }, []);

    return onHandler;
};

export default useFastCallback;
