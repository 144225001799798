import React from 'react';
import {View} from 'react-native';
import 'moment/locale/en-gb';
import {OfferingSection} from '.';
import LoadingOfferings from './LoadingOfferings';
import LoadOfferingsError from './LoadOfferingsError';
import {logEvent} from '../../modules/app/tracking';

const OfferingsTable = ({offerings, purchasePackage, setIsLoading, theme}) => {
    if (offerings === undefined) {
        return <LoadingOfferings />;
    }
    if (offerings === null) {
        return <LoadOfferingsError />;
    }
    return (
        <View style={{flexDirection: 'row'}}>
            {offerings
                .sort(
                    (offeringA, offeringB) =>
                        parseInt(offeringB.product.price, 10) -
                        parseInt(offeringA.product.price, 10),
                )
                .reverse()
                .map((offering, index) => {
                    const {price} = offering.product;
                    const {packageType} = offering;
                    const previousOfferingPrice =
                        offerings[index - 1]?.product?.price || undefined;

                    const getDiscountPercentage = () => {
                        if (
                            !previousOfferingPrice ||
                            previousOfferingPrice > price
                        ) {
                            return undefined;
                        }
                        if (packageType === 'MONTHLY') {
                            return parseInt(
                                ((price / 30) * 100) /
                                    (previousOfferingPrice / 7),
                                10,
                            );
                        }
                        if (packageType === 'THREE_MONTH') {
                            return parseInt(
                                ((price / 90) * 100) /
                                    (previousOfferingPrice / 30),
                                10,
                            );
                        }
                    };

                    return (
                        <OfferingSection
                            key={offering.identifier}
                            theme={theme}
                            priceString={offering.product.priceString}
                            packageType={packageType}
                            onPress={() => {
                                logEvent('attemptToPurchase');
                                purchasePackage({
                                    selectedPackage: offering,
                                    setIsLoading,
                                });
                            }}
                            discountPercentage={getDiscountPercentage()}
                        />
                    );
                })}
        </View>
    );
};

export default OfferingsTable;
