import React, {useEffect, useState} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Text,
    Platform,
    Dimensions,
    ActivityIndicator,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {connect, ConnectedProps} from 'react-redux';
import Constants from '../assets/Constants';
import {ProfilePicture, TopBar} from '../components/Shared';
import lang from '../localization';
import {NavigationProp} from '../types/navigation/navigationProp';
import SelectColorSection from '../components/Customization/SelectColorSection';
import SelectShapeSection from '../components/Customization/SelectShapeSection';
import {ReducerState} from '../types/reducer/reducer';
import {ReduxDispatch, ReduxGetState} from '../types/redux/redux';
import store from '../modules/redux/store';
import {actions as authActions} from '../modules/auth';
import {ActionParameter} from '../types/helpers/helpers';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {Theme} from '../assets/Colors';
import {actions as appActions} from '../modules/app';
import CustomAlert, {CustomAlertProps} from '../components/Shared/CustomAlert';

const PHONE_HAS_A_NOTCH =
    Platform.OS === 'ios' && Dimensions.get('screen').height >= 812;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    changeCustomizationOptions: (
        data: ActionParameter<typeof authActions.changeCustomizationOptions>,
    ) => authActions.changeCustomizationOptions(data)(dispatch, getState),
    changeProfilePicture: (
        data: ActionParameter<typeof appActions.changeProfilePicture>,
    ) => appActions.changeProfilePicture(data)(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    loggedUser: state.authReducer.user,
    isSubscribed: state.subscriptionReducer.isSubscribed,
    isProfilePictureUploading: state.appReducer.isProfilePictureUploading,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
};

const CustomizationScreen = ({
    navigation,
    loggedUser,
    isSubscribed,
    changeCustomizationOptions,
    changeProfilePicture,
    isProfilePictureUploading,
}: Props & NavigationProp<{}>) => {
    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };
    const theme = useTheme();

    const [selectedColor, setSelectedColor] = useState<string>(
        loggedUser?.frame_color || 'white',
    );
    const [selectedShape, setSelectedShape] = useState<string | undefined>(
        loggedUser?.frame_shape || undefined,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<CustomAlertProps | null>(null);

    useEffect(() => {
        if (selectedColor === 'white') {
            setSelectedShape(undefined);
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        navigation?.setOptions({
            title: `${lang().screenTitles.customization}`,
        });
    }, []);

    if (loggedUser === undefined) {
        return <></>;
    }

    return (
        <>
            <SafeAreaView
                style={[styles.container, {backgroundColor: theme.BACKGROUND}]}
            >
                <StatusBarAware theme={theme} />
                <View
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <TopBar
                        goBackToPreviousScreen={goBackToPreviousScreen}
                        titleText={lang().customize_your_look}
                    />
                    <View
                        style={{
                            flexDirection: 'column',
                            flexGrow: 1,
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            style={{marginTop: 5}}
                            contentContainerStyle={{
                                paddingBottom:
                                    selectedColor === 'white' ? 400 : 200,
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingTop: 45,
                            }}
                        >
                            <ProfilePicture
                                shadowRadius={Platform.select({
                                    ios: 14,
                                    android: 0,
                                })}
                                dimensions={100}
                                path={loggedUser?.profile_picture_path || ''}
                                customization={{
                                    frame: selectedShape,
                                    color: selectedColor,
                                }}
                                setAlert={setAlert}
                                changeProfilePicture={
                                    changeProfilePicture as any
                                }
                                isLoading={isProfilePictureUploading}
                                theme={theme}
                                showWithOverlay
                            />
                            <SelectColorSection
                                selectedColor={selectedColor}
                                setSelectedColor={setSelectedColor}
                                isSubscribed={isSubscribed}
                                theme={theme}
                            />
                            {selectedColor !== 'white' && (
                                <SelectShapeSection
                                    selectedShape={selectedShape}
                                    setSelectedShape={setSelectedShape}
                                    selectedColor={selectedColor}
                                    isSubscribed={isSubscribed}
                                    theme={theme}
                                />
                            )}
                        </ScrollView>
                        <LinearGradient
                            style={{
                                height: 200,
                                width: '100%',
                                position: 'absolute',
                                bottom: 0,
                            }}
                            colors={theme.GRADIENTS.BOTTOM_FADER}
                        />
                    </View>
                    <View
                        style={{
                            position: 'absolute',
                            bottom: PHONE_HAS_A_NOTCH ? 0 : 20,
                            width: '100%',
                            paddingHorizontal: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ConfirmButton
                            theme={theme}
                            isLoading={isLoading}
                            onPress={() => {
                                changeCustomizationOptions({
                                    frameColor: selectedColor,
                                    frameShape: selectedShape,
                                });
                                setIsLoading(true);
                            }}
                        />
                    </View>
                </View>
                {alert && (
                    <CustomAlert
                        show={alert.show}
                        message={alert.message}
                        confirmText={alert.confirmText}
                        confirmColor={alert.confirmColor}
                        cancelText={alert.cancelText}
                        theme={alert.theme}
                        onConfirmPressed={alert.onConfirmPressed}
                        onCancelPressed={alert.onCancelPressed}
                        onDismiss={alert.onDismiss}
                    />
                )}
            </SafeAreaView>
        </>
    );
};

export default connector(CustomizationScreen);

export const ConfirmButton = ({
    onPress,
    isLoading,
    theme,
}: {
    onPress: () => void;
    isLoading: boolean;
    theme: Theme;
}) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={isLoading}
            style={{
                height: 50,
                width: 200,
                borderRadius: 100,
            }}
        >
            <View
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.BACKGROUND,
                }}
            >
                {!isLoading && (
                    <Text
                        style={{
                            fontFamily: Constants.fonts.Bold,
                            fontSize: 16,
                            color: theme.TEXT_PRIMARY,
                        }}
                    >
                        {lang().confirm}
                    </Text>
                )}
                {isLoading && (
                    <ActivityIndicator
                        size="small"
                        color={theme.TEXT_PRIMARY}
                    />
                )}
            </View>
        </TouchableOpacity>
    );
};
