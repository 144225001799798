import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import React, {useState} from 'react';
import {ActivityIndicator, Image, TouchableOpacity, View} from 'react-native';
import {launchImageLibrary} from 'react-native-image-picker';
import {isWeb} from '../../../env';
// @ts-ignore
import FastImage from '../../../PackageWrappers/Components/FastImage';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {
    checkIfAsyncStorageWasUsedBefore,
    formatProfilePicturePath,
    normalize,
} from '../../modules/helpers';
import colors from '../Customization/colors';
import {frameSources} from '../Customization/shapes';
import {CustomAlertProps} from './CustomAlert';

const eyeIcon = require('../../assets/images/eyeIcon.png');
const cameraIcon = require('../../assets/images/cameraIcon.png');
const fullTransparent = require('../../assets/images/fullTransparent.png');

const ProfilePicture = ({
    dimensions,
    isLoading = false,
    path,
    shouldBeBlurred = false,
    shouldNormalizeDimensions = true,
    disableImageViewer = false,
    disableShadowEffect = false,
    shadowRadius = 14,
    disableLoadingEffect = false,
    shadowColorOpacity = 0.45,
    customization = {
        frame: undefined,
        color: Constants.colors.main.dark,
    },
    onPress = undefined,
    setAlert,
    changeProfilePicture = undefined,
    theme,
    showWithOverlay,
}: {
    dimensions: number;
    isLoading?: boolean;
    path?: string;
    isFullyTransparent?: boolean;
    shouldBeBlurred?: boolean;
    shouldNormalizeDimensions?: boolean;
    disableImageViewer?: boolean;
    disableShadowEffect?: boolean;
    shadowRadius?: number;
    disableLoadingEffect?: boolean;
    shadowColorOpacity?: number;
    customization?: {
        frame: null | undefined | string;
        color: null | string;
    };
    onPress?: undefined | (() => void);
    setAlert?: (alert: CustomAlertProps | null) => void;
    changeProfilePicture?: (input: {image: string}) => void;
    theme: Theme;
    showWithOverlay?: boolean;
}) => {
    const IMAGE_DIMENSIONS = shouldNormalizeDimensions
        ? normalize(dimensions)
        : dimensions;
    const [isDownloading, setIsDownloading] = useState(true);
    const [isBlurred, setIsBlurred] = useState(shouldBeBlurred);
    const navigation = useNavigation<StackNavigationProp<any>>();
    const getShadowColorOpacity = () => {
        if (disableShadowEffect) {
            return 0;
        }
        return shadowColorOpacity;
    };

    const onChangeProfilePressed = async () => {
        const launchPicker = () => {
            launchImageLibrary(
                {
                    mediaType: 'photo',
                    maxWidth: 500,
                    quality: 1,
                    includeBase64: true,
                },
                (response: {
                    errorCode: number;
                    didCancel: boolean;
                    assets: [{base64: string}];
                }) => {
                    if (!response.errorCode && !response.didCancel) {
                        // You can also display the image using data:
                        const image = `data:image/jpeg;base64,${response?.assets[0].base64}`;
                        if (changeProfilePicture !== undefined)
                            changeProfilePicture({image});
                    }
                },
            );
        };

        if (
            await checkIfAsyncStorageWasUsedBefore({
                key: 'hasConfirmedToProfilePictureConsent',
            })
        ) {
            launchPicker();
        } else if (setAlert !== undefined) {
            setAlert({
                show: true,
                message: lang().warn_for_first_profile_picture_upload,
                confirmText:
                    lang().warn_for_first_profile_picture_upload_confirm,
                theme,
                onConfirmPressed: () => {
                    launchPicker();
                    setAlert(null);
                },
                onDismiss: () => {
                    setAlert(null);
                },
            });
        }
    };

    const getCustomizationColor = () => {
        if (customization.color === undefined || customization.color === null) {
            return '#FFFFFF';
        }
        const color = colors[customization.color];
        if (color) {
            return color.code;
        }
        return '#FFFFFF';
    };

    return (
        <TouchableOpacity
            accessibilityHint="Profile Picture"
            accessibilityRole="image"
            style={{
                zIndex: 1,
            }}
            disabled={disableImageViewer}
            onPress={() => {
                if (changeProfilePicture !== undefined) {
                    onChangeProfilePressed();
                } else {
                    navigation.push('ImageViewer', {
                        imageURL: formatProfilePicturePath({
                            profilePicture: path,
                        }),
                    });
                }
            }}
        >
            <View
                style={{
                    height: IMAGE_DIMENSIONS,
                    width: IMAGE_DIMENSIONS,
                    borderRadius: 1000,
                    backgroundColor: getCustomizationColor(),
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                    shadowColor: getCustomizationColor(),
                    shadowOpacity: getShadowColorOpacity(),
                    shadowRadius,
                    shadowOffset: {width: 0, height: 0},
                }}
            >
                {customization && customization.frame && (
                    <Image
                        source={frameSources[customization.frame]}
                        style={{
                            position: 'absolute',
                            height: IMAGE_DIMENSIONS * 0.5,
                            width: IMAGE_DIMENSIONS * 0.5,
                            resizeMode: 'contain',
                            top:
                                -(IMAGE_DIMENSIONS * 0.5) +
                                IMAGE_DIMENSIONS * 0.26,
                            tintColor: getCustomizationColor(),
                        }}
                    />
                )}
                {isDownloading && !disableLoadingEffect && (
                    <View
                        style={{
                            position: 'absolute',
                            zIndex: 10,
                            width: IMAGE_DIMENSIONS,
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ActivityIndicator
                            size="large"
                            color="white"
                        />
                    </View>
                )}
                <FastImage
                    onError={() => {
                        setIsDownloading(false);
                    }}
                    onLoadStart={() => {
                        if (!isWeb) {
                            setIsDownloading(true);
                        }
                    }}
                    onLoadEnd={() => {
                        setIsDownloading(false);
                    }}
                    cache
                    source={
                        path
                            ? {
                                  uri: formatProfilePicturePath({
                                      profilePicture: path,
                                  }),
                              }
                            : fullTransparent
                    }
                    resizeMode="contain"
                    style={{
                        height: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                        width: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                        borderRadius: 1000,
                        zIndex: 2,
                    }}
                />
                {isBlurred && (
                    <TouchableOpacity
                        onPress={() => {
                            setIsBlurred(false);
                        }}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            borderRadius: 10000,
                            backgroundColor: '#C3C3C3',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 10,
                            opacity: 0.95,
                        }}
                    >
                        <FastImage
                            source={eyeIcon}
                            resizeMode="contain"
                            style={{
                                width: IMAGE_DIMENSIONS * 0.3,
                                height: IMAGE_DIMENSIONS * 0.3,
                                tintColor: 'white',
                            }}
                        />
                    </TouchableOpacity>
                )}
                {showWithOverlay && (
                    <View
                        style={{
                            height: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                            width: IMAGE_DIMENSIONS - IMAGE_DIMENSIONS * 0.035,
                            borderRadius: 1000,
                            zIndex: 2,
                            elevation: 1,
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            opacity: 0.55,
                            position: 'absolute',
                        }}
                    >
                        <View
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                borderRadius: 10000,
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 10,
                                opacity: 0.85,
                                backgroundColor: theme.TRANS_GRAY,
                            }}
                        >
                            <Image
                                source={cameraIcon}
                                style={{
                                    position: 'absolute',
                                    height: IMAGE_DIMENSIONS * 0.25,
                                    width: IMAGE_DIMENSIONS * 0.25,
                                    resizeMode: 'contain',
                                    tintColor: theme.TEXT_WHITE,
                                }}
                            />
                        </View>
                    </View>
                )}
                {isLoading && (
                    <View style={{position: 'absolute', zIndex: 10}}>
                        <ActivityIndicator
                            size="large"
                            color="white"
                        />
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
};

export default ProfilePicture;
