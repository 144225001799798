import Backend from '../axios/axios';

export function getInterests() {
    return Backend.post('/getInterests', {}).then(response => response.data);
}

export async function syncInterests(interests: {interest_id: number}[]) {
    return Backend.post('/syncInterests', {
        interests,
    });
}
