/* eslint-disable import/extensions */
import AppType from '../../AppType';
import appConfig from '../../app.json';

const namedColors = {
    transparentWhite: 'black',
};

const Constants = {
    isProduction: !__DEV__,
    links: {
        facebookPage: AppType.select({
            nezmo: 'https://www.facebook.com/people/Nezmo/100088243980822/',
            fadfadah: 'https://www.facebook.com/fadfadahChat',
        }),
        instagramPage: AppType.select({
            nezmo: 'https://www.instagram.com/nezmochat/',
            fadfadah: 'https://www.instagram.com/fadfadahchat/',
        }),
        supportEmail: AppType.select({
            fadfadah: 'fadfadahChat@gmail.com',
            nezmo: 'info@nezmo.net',
        }),
        privacyPolicy: AppType.select({
            fadfadah: 'https://www.fadfadah.net/privacy',
            nezmo: 'https://nezmo.net/privacy-policy.html',
        }),
        termsOfUse: AppType.select({
            fadfadah: 'https://www.fadfadah.net/terms',
            nezmo: 'https://nezmo.net/terms-of-use.html',
        }),
    },
    apiKeys: {
        bugsnag: AppType.select({
            fadfadah: 'a112e1e75e3861e671bf3612bc7810b7',
            nezmo: '08db0ecad2aab8a45873ce5d1cf30f13',
        }),
    },
    version: appConfig.expo.version,
    colors: {
        mainBackground: 'white',
        inputBackground: '#fbfbfb',
        secondaryBackground: '#f2f2f2',
        main: {
            xxLight: '#BDBBDD',
            xLight: '#756BBC',
            light: '#756BBC',
            medium: '#877EB5',
            dark: '#58508D',
        },
        facebook: '#466b94',
        instagram: '#9C72E3',
        primary: '',
        secondary: 'grey',
        danger: '#bc6363',
        warning: '',
        success: '#83C18C',
        muted: '',
        mutedSecondary: '#C4C4C4',
        mutedPrimary: '',
        blackMain: '',
        blackSecondary: '',
        whiteMain: '#FFFFFF',
        wellness: '',
        whiteSecondary: '',
        submit: '',
        gold: '#BFA800',
        goldCrown: '#FFC107',
    },
    borderColor: {
        darkest: '',
        dark: '',
        med: '#CDCDCD',
        light: '#DEDEDE',
        lightest: '',
    },
    gradient: {
        fadfadahWelcome: ['#827DBC', '#6560A7'],
        facebook: ['#4b74a3', '#466b94', '#406085'],
        twitter: ['#21a9f7', '#219ae0'],
        instagram: ['#9C72E3', '#B28439'],
        snapchat: ['#CFCE56', '#C1C051'],
        profileConversation: ['#515762', '#656C7A', '#828A97'],
        activeProfileConversation: ['#a0a0a0', '#FFFFFF'],
        topBarTitle: ['#BEBEBE', '#FFFFFF'],
        conversation: ['#756BBC', '#605C91'],
        activeConversation: ['#FFFFFF', '#FFFFFF'],
        fetchingConversations: ['#C3C3C3', '#929292'],
        success: ['#71AE7A', '#689871'],
        createConversation: ['#72BE7D', '#73AF7E'],
        randomConversation: ['#8E87C4', '#605C91'],
        bottomFader: ['rgba(189, 187, 221, 0)', 'rgba(189, 187, 221, 1)'],
    },
    fonts: {
        Bold: 'Medium',
        SemiBold: 'Regular',
        Regular: 'Light',
    },
    borderRadius: {
        small: 4,
        base: 8,
        basePlus: 10,
        large: 22,
        // xLarge: 64,
    },
    borderWidth: {
        tiny: 1,
    },
    hitSlop: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
    },
    elevation: {
        half: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 0.5,
                width: 0,
            },
            shadowRadius: 2,
            shadowOpacity: 0.15,
            elevation: 2,
        },
        one: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 0.5,
                width: 0,
            },
            shadowRadius: 2,
            shadowOpacity: 0.2,
            elevation: 4,
        },
        two: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 4,
                width: 0,
            },
            shadowRadius: 6,
            shadowOpacity: 0.2,
            elevation: 9,
        },
        three: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 5,
                width: 0,
            },
            shadowRadius: 15,
            shadowOpacity: 0.2,
            elevation: 14,
        },
        four: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 10,
                width: 0,
            },
            shadowRadius: 24,
            shadowOpacity: 0.2,
            elevation: 19,
        },
        five: {
            shadowColor: namedColors.transparentWhite,
            shadowOffset: {
                height: 15,
                width: 0,
            },
            shadowRadius: 35,
            shadowOpacity: 0.2,
            elevation: 24,
        },
    },
    iconSize: {
        notification: 12,
        base: 44,
    },
    hiddenComponent: {
        height: 1,
        width: 1,
    },
};

export default Constants;
