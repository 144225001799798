import AppType from '../../AppType';

const ar = {
    createConversation: (name: string) => `إنشاء محادثة مع ${name}`,
    interests: 'الاهتمامات',
    customization: 'تخصيص مظهرك',
    settings: 'الإعدادات',
    conversationWith: (name: string) => `محادثة مع ${name}`,
    createRandomConversation: 'إنشاء محادثة عشوائية',
    login: `${AppType.select({
        fadfadah: 'فضفضة',
        nezmo: 'نيزمو',
    })} - تسجيل الدخول`,
    signup: `${AppType.select({fadfadah: 'فضفضة', nezmo: 'نيزمو'})} - تسجيل`,
};
const en = {
    createConversation: (name: string) => `Create conversation with ${name}`,
    interests: 'interests',
    customization: 'Customization',
    settings: 'Settings',
    conversationWith: (name: string) => `Conversation with ${name}`,
    createRandomConversation: 'Create random conversation',
    login: `${AppType.select({fadfadah: 'Fadfadah', nezmo: 'Nezmo'})} - Login`,
    signup: `${AppType.select({
        fadfadah: 'Fadfadah',
        nezmo: 'Nezmo',
    })} - Signup`,
};

export default {
    ar,
    en,
};
