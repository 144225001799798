import {AnyAction} from 'redux';
import * as t from './actionTypes';

export interface SubscriptionReducer {
    isSubscribed: boolean;
    activeSubscription?: {
        end_date: number;
        start_date: number;
    };
}

const initialState = {
    isSubscribed: false,
    activeSubscription: undefined,
};

export type SubscriptionReducerAction = AnyAction & {
    payload: {
        activeSubscription?: {
            end_date: number;
            start_date: number;
        };
    };
};

const subscriptionReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: SubscriptionReducerAction,
) => {
    switch (action.type) {
        case t.SET_IS_SUBSCRIBED:
            return {
                ...state,
                isSubscribed: true,
                activeSubscription: action.payload.activeSubscription,
            };

        case t.UNSET_IS_SUBSCRIBED:
            return {
                ...state,
                isSubscribed: false,
                activeSubscription: undefined,
            };

        case 'app/FREE_REDUCER':
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default subscriptionReducer;
