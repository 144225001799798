import React from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Image,
    I18nManager,
    Platform,
} from 'react-native';
import 'moment/locale/en-gb';
import LinearGradient from 'react-native-linear-gradient';
import pSBC from 'shade-blend-color';
import Constants from '../../assets/Constants';
import {T} from '../Shared';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {Theme} from '../../assets/Colors';

const diamondIcon = require('../../assets/images/diamondIcon.png');

const offeringsStyles = StyleSheet.create({
    offeringSection: {
        width: '30%',
        marginHorizontal: 5,
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
});

const OfferingSection = ({
    theme,
    priceString,
    packageType,
    discountPercentage,
    onPress,
}: {
    theme: Theme;
    priceString: string;
    packageType: 'WEEKLY' | 'MONTHLY' | 'THREE_MONTH';
    discountPercentage?: number;
    onPress: () => void;
}) => {
    const durationText = lang().duration_text[packageType];

    const onPurchase = () => {
        onPress();
    };

    return (
        <TouchableOpacity
            onPress={onPurchase}
            style={offeringsStyles.offeringSection}
        >
            <LinearGradient
                angle={90}
                colors={
                    theme.MODE === 'light'
                        ? theme.GRADIENTS.PRIMARY
                        : [theme.TERTIARY, pSBC(-0.5, theme.PRIMARY) as string]
                }
                style={{
                    paddingTop: 10,
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: 6,
                }}
            >
                {discountPercentage ? (
                    <View
                        style={{
                            backgroundColor:
                                theme.MODE === 'light'
                                    ? theme.BACKGROUND
                                    : theme.SECONDARY,
                            paddingHorizontal: 7,
                            paddingVertical: Platform.select({
                                ios: 3,
                                android: 1,
                            }),
                            borderRadius: 10,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Image
                            source={diamondIcon}
                            resizeMode="contain"
                            style={{
                                height: 10,
                                width: 10,
                                tintColor: theme.TEXT_PRIMARY,
                            }}
                        />
                        <T
                            style={{
                                fontSize: 10,
                                color: theme.TEXT_PRIMARY,
                                marginStart: 5,
                                fontFamily: Constants.fonts.SemiBold,
                            }}
                        >
                            {lang().discount(discountPercentage)}
                        </T>
                    </View>
                ) : (
                    <View />
                )}
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginVertical: 10,
                    }}
                >
                    <T
                        style={{
                            color: theme.TEXT_WHITE,
                            fontFamily: Constants.fonts.Bold,
                            fontSize: normalize(15),
                            marginBottom: 1,
                        }}
                    >
                        <>
                            {durationText} {I18nManager.isRTL ? 'بـ' : ''}
                        </>
                    </T>
                    <T
                        style={{
                            color: 'white',
                            fontFamily: Constants.fonts.Bold,
                            fontSize: 14,
                            lineHeight: 22,
                        }}
                    >
                        {priceString}
                    </T>
                </View>
                <TouchableOpacity
                    onPress={onPurchase}
                    style={{
                        backgroundColor:
                            theme.MODE === 'light'
                                ? theme.BACKGROUND
                                : theme.SECONDARY,
                        borderRadius: 4,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '80%',
                        marginBottom: 8,
                        paddingVertical: Platform.select({ios: 4, android: 2}),
                    }}
                >
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.SemiBold,
                        }}
                    >
                        {lang().subscribe}
                    </T>
                </TouchableOpacity>
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default OfferingSection;
