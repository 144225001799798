import React from 'react';
import {Image, Platform, TouchableOpacity, View} from 'react-native';
import AppType from '../../../AppType';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';

const searchIcon = require('../../assets/images/searchIcon.png');

const icons = {
    search: searchIcon,
};

const ConversationListHeaderButton = ({
    type,
    setAppliedFilter,
    appliedFilter,
    icon,
    theme,
}) => {
    const isActive = appliedFilter === type;
    return (
        <TouchableOpacity
            disabled={isActive}
            onPress={() => {
                setAppliedFilter(type);
            }}
            hitSlop={{top: 48 / 2, bottom: 48 / 2}}
            style={{
                paddingVertical: 2,
                backgroundColor: isActive ? theme.TERTIARY : undefined,
                borderRadius: 20,
                height: 30,

                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 10,
            }}
        >
            {lang().conversation_tab_header_buttons[type] && (
                <T
                    style={{
                        fontSize: normalize(11),
                        color: theme.TEXT_MUTED,
                        fontFamily: Constants.fonts.Bold,
                    }}
                >
                    {lang().conversation_tab_header_buttons[type]}
                </T>
            )}

            {icon && (
                <Image
                    source={icons[icon]}
                    style={{
                        height: 16,
                        width: 16,
                        paddingVertical: 11,
                        resizeMode: 'contain',
                        tintColor: isActive ? '#6D6D6D' : '#6D6D6D',
                    }}
                />
            )}
        </TouchableOpacity>
    );
};

const ConversationsListHeader = ({appliedFilter, setAppliedFilter, theme}) => {
    if (AppType.CURRENT_APP === 'NEZMO') {
        return <></>;
    }
    return (
        <View
            style={{
                zIndex: 2,
                paddingVertical: 5,
                width: '100%',
            }}
        >
            <View
                style={{
                    backgroundColor: theme.SECONDARY,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: Platform.OS === 'android' ? 10 : 0,
                    marginHorizontal: 30,
                    paddingHorizontal: 20,
                    borderRadius: 20,
                    paddingVertical: 8,
                }}
            >
                <ConversationListHeaderButton
                    type="all"
                    setAppliedFilter={setAppliedFilter}
                    appliedFilter={appliedFilter}
                    theme={theme}
                />
                <ConversationListHeaderButton
                    type="via_link"
                    setAppliedFilter={setAppliedFilter}
                    appliedFilter={appliedFilter}
                    theme={theme}
                />
                <ConversationListHeaderButton
                    type="random"
                    setAppliedFilter={setAppliedFilter}
                    appliedFilter={appliedFilter}
                    theme={theme}
                />
                {/* <ConversationListHeaderButton icon="search" /> */}
            </View>
        </View>
    );
};

export default ConversationsListHeader;
