import React, {useState} from 'react';
import {View, Image} from 'react-native';
import {Theme} from '../../../assets/Colors';
import lang from '../../../localization';
import {getDarkModeHomeButtonTextColor} from '../../../modules/app/theme';
import {MainTabSection, MainSectionBody} from '../MainTabBody';

const alarmIcon = require('../../../assets/images/alarmIcon.png');

const NotificationsDisabledSection = ({
    theme,
    color,
}: {
    theme: Theme;
    color: string;
}) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <MainTabSection
            onPress={() => {
                setShowDetails(!showDetails);
            }}
            mainColor={color}
            theme={theme}
        >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Image
                        source={alarmIcon}
                        style={{
                            height: 14,
                            width: 14,
                            marginEnd: 10,
                            tintColor: getDarkModeHomeButtonTextColor(
                                color,
                            ) as string,
                        }}
                        resizeMode="contain"
                    />
                    <View style={{flex: 1}}>
                        <MainSectionBody
                            style={{marginTop: -3, lineHeight: 20}}
                            text={
                                showDetails
                                    ? lang().notificationWarningDescription
                                    : lang().notificationWarningTitle
                            }
                            color={color}
                            theme={theme}
                        />
                    </View>
                </View>
            </View>
        </MainTabSection>
    );
};

export default NotificationsDisabledSection;
