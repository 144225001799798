import {ReduxDispatch, ReduxGetState} from '../../types/redux/redux';
import {AxiosResponse} from '../../types/helpers/helpers';
import * as api from './api';
import {actions as authActions} from '../auth';
import {delay} from '../helpers';
import * as t from './actionTypes';

export function syncPoints({onlyOnce = false}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const response: AxiosResponse = await api.syncPoints();
        let isUpdated = false;
        let hasNewPoints = false;

        if (
            !onlyOnce &&
            response.data.success === true &&
            response.data.data?.hasNewPoint === false
        ) {
            // run for the second time
            await delay(2000);
            const secondResponse: AxiosResponse = await api.syncPoints();
            if (
                secondResponse.data.success === true &&
                secondResponse.data.data?.hasNewPoint === true
            ) {
                isUpdated =
                    secondResponse.data.data?.userPoints !==
                    getState().authReducer.user?.points;
                hasNewPoints = secondResponse.data.data?.hasNewPoints;
            }
        } else {
            isUpdated =
                response.data.data?.userPoints !==
                getState().authReducer.user?.points;
            hasNewPoints = response.data.data?.hasNewPoints;
        }

        if (isUpdated) {
            await authActions.updateUser()(dispatch, getState);
        }

        return {hasNewPoints};
    };
}

export function initiateBuyWithPoints({
    amount,
    type,
}: {
    amount: number;
    type: string;
}) {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.SHOW_MODAL,
            payload: {
                type,
                amount,
            },
        });
    };
}

export function destroyPointsModal() {
    return (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.HIDE_MODAL,
        });
    };
}

export function buyWithPoints({
    productName,
    details,
}: {
    productName: string;
    details?: string;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const response = await api.buyWithPoints({productName, details});
        await authActions.updateUser()(dispatch, getState);
        return response;
    };
}
