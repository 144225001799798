const initialState = {
    messages: {},
};

// eslint-disable-next-line default-param-last
const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default messagesReducer;
