import React from 'react';
import {View} from 'react-native';
import {Theme} from '../../../assets/Colors';
import lang from '../../../localization';
import {
    MainTabSection,
    MainTabSectionTitle,
    MainSectionBody,
} from '../MainTabBody';
import SocialShareButtons from '../SocialShareButtons';

const ShareYourLinkSection = ({
    theme,
    color,
    link,
}: {
    theme: Theme;
    color: string;
    link: string;
}) => {
    return (
        <MainTabSection
            mainColor={color}
            theme={theme}
        >
            <MainTabSectionTitle
                text={lang().share_your_link}
                color={color}
                theme={theme}
            />
            <MainSectionBody
                style={{marginTop: 2}}
                text={lang().share_your_link_desc}
                color={color}
                theme={theme}
            />
            <View style={{marginTop: 9, marginBottom: 4, opacity: 1}}>
                <SocialShareButtons
                    color={color}
                    link={link}
                    theme={theme}
                />
            </View>
        </MainTabSection>
    );
};

export default ShareYourLinkSection;
