import socketIOClient from 'socket.io-client';
import {network_domain} from '../../../env';
import {playSound} from '../app/sound';

export function createSocket() {
    return socketIOClient(network_domain, {
        transports: ['websocket', 'polling'],
    });
}

export function registerListeners({
    socket,
    networkKey,
    userID,
    onNewMessageReceived,
    onIsTyping,
    onUserStatusChanged,
    conversationID,
}) {
    socket.on('connect', function () {
        socket.emit('join', {
            anonymousNetworkKey: networkKey,
            conversation_id: conversationID,
        });
    });
    socket.on(networkKey, function (broadcast) {
        switch (broadcast.type) {
            case 'newMessage':
                playSound({type: 'lightNewMessage'});
                onNewMessageReceived({message: broadcast.data});
                break;

            default:
                break;
        }
    });

    socket.on(`conversation_id_${conversationID}`, function (broadcast) {
        if (broadcast.type === 'userIsTyping') {
            onIsTyping();
        }
    });

    socket.on(userID, function (data) {
        if (data.message === 'connected') {
            onUserStatusChanged({isOnline: true});
        }

        if (data.message === 'disconnected') {
            onUserStatusChanged({isOnline: false});
        }
    });
}

export function destroyListeners({socket}) {
    if (socket) {
        socket.close();
    }
}
