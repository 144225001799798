import {Alert, Platform} from 'react-native';
import {PURCHASE_TYPE} from 'react-native-purchases';
import {ReduxDispatch, ReduxGetState} from '../../types/redux/redux';
import Purchases from './Purchases';
import lang from '../../localization';
import * as t from './actionTypes';
import {getRemoteConfig} from '../remoteConfig/actions';
import {actions as authActions} from '../auth';
import {actions as pointsActions} from '../points';
import {isWeb} from '../../../env';
import Backend from '../axios/axios';
import AppType from '../../../AppType';

export const IS_SUBSCRIPTIONS_ENABLED = AppType.CURRENT_APP !== 'NEZMO';
export const IS_POINTS_ENABLED = !isWeb && AppType.CURRENT_APP !== 'NEZMO';

export function setUp() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const {user} = getState().authReducer;
        console.log({user});
        if (user === null) {
            return;
        }
        if (user && user.user_link && user.name && user.network_key) {
            Purchases.logIn(`${user.user_link}-${user.network_key}`);
            Purchases.collectDeviceIdentifiers();
            Purchases.setDisplayName(user.name);
            Purchases.setDisplayName(user.name);
            if (user.email !== undefined) {
                Purchases.setEmail(user.email);
            }
            Purchases.setAttributes({user_link: user.user_link});
        }
    };
}

// run after app launces
export function configure() {
    if (Platform.OS !== 'web') {
        Purchases.configure({
            apiKey:
                Platform.select({
                    ios: 'appl_owzbCZFrTZFgqfLjlEBsQvyoGaj',
                    android: 'goog_MJyfCIBNEKgtACMRzRpsrbVLiZs',
                }) || '',
        });
    }
}
export function reset() {
    return () => {
        Purchases.logOut().catch(() => {
            // e
        });
    };
}

export async function getOfferings() {
    try {
        const offerings = await Purchases.getOfferings();
        if (
            offerings.current !== null &&
            offerings.current.availablePackages.length !== 0
        ) {
            return offerings.all[getRemoteConfig('revenue_cat_offering')];
        }
    } catch (e) {
        //
    }
    return undefined;
}

export function getPointsInAppPurchases() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            const products = await Purchases.getProducts(
                [
                    Platform.select({
                        ios: '10_new_fadfadah_points',
                        android: '10_fadfadah_points_a',
                    }) as string,
                ],
                PURCHASE_TYPE.INAPP,
            );

            await pointsActions.syncPoints({onlyOnce: true})(
                dispatch,
                getState,
            );

            return products;
        } catch (e) {
            throw new Error('could not be fetched');
        }
    };
}

export function purchaseInAppPurchases({productId}: {productId: string}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            await Purchases.purchaseProduct(
                productId,
                null,
                PURCHASE_TYPE.INAPP,
            );
            const {hasNewPoints} = await pointsActions.syncPoints({
                onlyOnce: false,
            })(dispatch, getState);

            return {hasNewPoints};
        } catch (e: any) {
            if (!e.userCancelled) {
                throw new Error('error while checking');
            }
            return {hasNewPoints: false};
        }
    };
}

export async function hasActiveSubscription() {
    try {
        const purchaserInfo = await Purchases.getCustomerInfo();
        if (purchaserInfo.activeSubscriptions.length > 0) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
}

export function checkActiveSubscription() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            if (!isWeb) {
                const purchaserInfo = await Purchases.getCustomerInfo();
                if (purchaserInfo.activeSubscriptions.length > 0) {
                    dispatch({
                        type: t.SET_IS_SUBSCRIBED,
                        payload: {
                            activeSubscription: {
                                end_date:
                                    purchaserInfo?.allExpirationDates[
                                        purchaserInfo?.activeSubscriptions[0]
                                    ],
                            },
                        },
                    });
                    setTimeout(() => {
                        updateIsSubscribedValueInDatabase()(dispatch, getState);
                    }, 100);
                } else {
                    setTimeout(() => {
                        updateIsSubscribedValueInDatabase()(dispatch, getState);
                    }, 100);
                    dispatch({
                        type: t.UNSET_IS_SUBSCRIBED,
                    });
                }
            } else {
                Backend.post('getSubscriptionStatus')
                    .then((data: any) => {
                        if (data?.data?.data) {
                            const {is_subscribed, start_date, end_date} =
                                data.data.data;
                            if (is_subscribed) {
                                setSubscriptionActive({start_date, end_date})(
                                    dispatch,
                                );
                            } else {
                                setSunscriptionInActive()(dispatch);
                            }
                        }
                    })
                    .catch(() => {
                        setSunscriptionInActive()(dispatch);
                    });
            }
        } catch (e) {
            console.log({e});
        }
    };
}

export function purchasePackage({
    selectedPackage,
    setIsLoading,
}: {
    selectedPackage: any;
    setIsLoading: (value: boolean) => void;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            setIsLoading(true);
            // @ts-ignore
            const {purchaserInfo} = await Purchases.purchasePackage(
                selectedPackage,
            );
            if (
                typeof purchaserInfo.entitlements.active[
                    'Fadfadah Subscription'
                ] !== 'undefined'
            ) {
                dispatch({
                    type: t.SET_IS_SUBSCRIBED,
                    payload: {
                        activeSubscription:
                            purchaserInfo?.entitlements?.active[
                                'Fadfadah Subscription'
                            ],
                    },
                });
                setTimeout(() => {
                    updateIsSubscribedValueInDatabase()(dispatch, getState);
                }, 100);
                setIsLoading(false);
                return;
            }
            setTimeout(async () => {
                await checkActiveSubscription()(dispatch, getState);
            }, 1000);
            setIsLoading(false);

            return;
        } catch (e: any) {
            setIsLoading(false);
            setTimeout(async () => {
                await checkActiveSubscription()(dispatch, getState);
            }, 1000);
            if (!e.userCancelled) {
                //
            }
        }
    };
}

export function restorePurchase({
    setIsLoading,
}: {
    setIsLoading: (value: boolean) => void;
}) {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        try {
            setIsLoading(true);
            const restore = await Purchases.restorePurchases();
            if (
                typeof restore.entitlements.active['Fadfadah Subscription'] !==
                'undefined'
            ) {
                dispatch({
                    type: t.SET_IS_SUBSCRIBED,
                    payload: {
                        activeSubscription:
                            restore?.entitlements?.active[
                                'Fadfadah Subscription'
                            ],
                    },
                });
                setTimeout(() => {
                    updateIsSubscribedValueInDatabase()(dispatch, getState);
                }, 100);
                Alert.alert(
                    lang().restore_subscription_success,
                    '',
                    [
                        {
                            text: lang().cancel,
                            onPress: () => {
                                setIsLoading(false);
                            },
                            style: 'cancel',
                        },
                    ],
                    {
                        cancelable: false,
                    },
                );
                return;
            }
            Alert.alert(
                lang().restore_subscription_fail,
                '',
                [
                    {
                        text: lang().cancel,
                        onPress: () => {
                            setIsLoading(false);
                        },
                        style: 'cancel',
                    },
                ],
                {
                    cancelable: false,
                },
            );
            return;
        } catch (e) {
            Alert.alert(
                lang().restore_subscription_error,
                '',
                [
                    {
                        text: lang().cancel,
                        onPress: () => {
                            setIsLoading(false);
                        },
                        style: 'cancel',
                    },
                ],
                {
                    cancelable: false,
                },
            );
        }
    };
}

function updateIsSubscribedValueInDatabase() {
    return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
        const isSubscribedInApp = getState().subscriptionReducer.isSubscribed; // true or false

        const isSubscribedInDatabase =
            getState().authReducer &&
            getState().authReducer.user &&
            getState().authReducer.user?.is_subscribed
                ? getState().authReducer.user?.is_subscribed
                : 0; // 1 or 0

        if (isSubscribedInApp === true && isSubscribedInDatabase === 0) {
            await authActions.updateUser()(dispatch, getState);
        }

        if (isSubscribedInApp === false && isSubscribedInDatabase === 1) {
            await authActions.updateUser()(dispatch, getState);
        }
    };
}

export function setSubscriptionActive({
    start_date,
    end_date,
}: {
    end_date: number;
    start_date: number;
}) {
    return async (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.SET_IS_SUBSCRIBED,
            payload: {
                activeSubscription: {
                    start_date,
                    end_date,
                },
            },
        });
    };
}

export function setSunscriptionInActive() {
    return async (dispatch: ReduxDispatch) => {
        dispatch({
            type: t.UNSET_IS_SUBSCRIBED,
        });
    };
}
