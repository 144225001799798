import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    AVPlaybackSource,
    Audio,
    InterruptionModeAndroid,
    InterruptionModeIOS,
} from 'expo-av';
import {AppState} from 'react-native';

Audio.setAudioModeAsync({
    playsInSilentModeIOS: false,
    staysActiveInBackground: false,
    interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
    interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
});

const heavyNewMessageSound = require('../../assets/audio/newMessage.mp3');
const lightNewMessageSound = require('../../assets/audio/newMessageCalm.mp3');
const newConversationSound = require('../../assets/audio/newConversation.mp3');

export type SoundType =
    | 'heavyNewMessage'
    | 'lightNewMessage'
    | 'newConversation';

export async function playSound({type}: {type: SoundType}) {
    try {
        AsyncStorage.getItem('settings_notification_sound').then(
            async isNotificationSoundEnabled => {
                if (isNotificationSoundEnabled === null) {
                    isNotificationSoundEnabled = 'true';
                    AsyncStorage.setItem('settings_notification_sound', 'true');
                }
                if (isNotificationSoundEnabled === 'true') {
                    switch (type) {
                        case 'heavyNewMessage':
                            await soundPlayer(
                                'heavyNewMessage',
                                heavyNewMessageSound,
                            );
                            break;
                        case 'lightNewMessage':
                            await soundPlayer(
                                'lightNewMessage',
                                lightNewMessageSound,
                            );
                            break;
                        case 'newConversation':
                            await soundPlayer(
                                'newConversation',
                                newConversationSound,
                            );
                            break;
                        default:
                            break;
                    }
                }
            },
        );
    } catch (e) {
        console.log(e);
    }
}

let isMediaPlaying = false;

async function soundPlayer(name: string, sound: AVPlaybackSource) {
    if (!isMediaPlaying && AppState.currentState === 'active') {
        isMediaPlaying = true;
        Audio.Sound.createAsync(sound, {shouldPlay: true})
            .then(res => {
                res.sound.setOnPlaybackStatusUpdate(status => {
                    // @ts-ignore
                    if (!status?.didJustFinish) return;
                    res.sound
                        .unloadAsync()
                        .then(() => {
                            isMediaPlaying = false;
                        })
                        .catch(error => {
                            isMediaPlaying = false;
                            console.log('error while unloading sound', error);
                        });
                });
            })
            .catch(error => {
                isMediaPlaying = false;
                console.log('error while playing sound', error);
            });
    }
}
