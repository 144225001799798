import React from 'react';
import {
    View,
    SafeAreaView,
    StatusBar,
    Image,
    ScrollView,
    TouchableOpacity,
    ActivityIndicator,
    Alert,
    Platform,
    useWindowDimensions,
} from 'react-native';
import * as Linking from 'expo-linking';
import LinearGradient from 'react-native-linear-gradient';
import {useMutation, useQuery} from 'react-query';
import {connect, ConnectedProps, useDispatch, useStore} from 'react-redux';
import pSBC from 'shade-blend-color';
import {PurchasesError, PurchasesStoreProduct} from 'react-native-purchases';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import lang from '../localization';
import {normalize} from '../modules/helpers';
import {NavigationProp} from '../types/navigation/navigationProp';
import {ReducerState} from '../types/reducer/reducer';
import {actions as subscriptionActions} from '../modules/subscription';
import {actions as pointsActions} from '../modules/points';
import LoadingModal from '../components/Shared/LoadingModal';
import CurrentPoints from '../components/Points/CurrentPoints';
import {useTheme} from '../modules/app/theme';
import {Theme} from '../assets/Colors';
import {useLocalaization} from '../modules/app/localization';

const arrowPurple = require('../assets/images/backArrow.png');

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: ReducerState) => ({
    points: state.authReducer.user?.points,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {};

const BuyPoints = ({
    navigation,
    points,
    route,
}: Props & NavigationProp<{source?: string}>) => {
    const dispatch = useDispatch();
    const store = useStore();
    const theme = useTheme();
    const {width, height} = useWindowDimensions();
    const localization = useLocalaization();
    const source = route?.params?.source;

    const {isLoading, data, isError, isFetched} = useQuery<
        PurchasesStoreProduct[],
        PurchasesError
    >(
        'interests',
        () =>
            subscriptionActions.getPointsInAppPurchases()(
                dispatch,
                store.getState as any,
            ),
        {
            retry: 2,
            retryDelay: 1000,
            enabled: true,
            cacheTime: 0,
        },
    );

    const PurchaseProductMutation = useMutation<
        {hasNewPoints: boolean},
        PurchasesError,
        {productId: string},
        any
    >(
        ({productId}) => {
            return subscriptionActions.purchaseInAppPurchases({productId})(
                dispatch,
                store.getState as any,
            );
        },
        {
            onSuccess: ({hasNewPoints}) => {
                if (source === 'unblock') {
                    pointsActions.initiateBuyWithPoints({
                        amount: 10,
                        type: 'product',
                    })(dispatch);
                    goBackToPreviousScreen();
                } else if (hasNewPoints) {
                    Alert.alert(
                        lang().points_system.buy_points,
                        lang().points_system.points_added(10),
                        [
                            {
                                text: lang().ok,
                                onPress: () => {},
                            },
                        ],
                        {cancelable: true},
                    );
                }
            },
        },
    );

    const purchaseProduct = (productId: string) => {
        PurchaseProductMutation.mutate({productId});
    };

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: theme.BACKGROUND,
                flexDirection: 'column',
                height,
                overflow: 'hidden',
                justifyContent: 'space-between',
            }}
        >
            <StatusBar
                backgroundColor={
                    theme.MODE === 'light'
                        ? theme.GRADIENTS.PRIMARY[1]
                        : theme.SECONDARY
                }
                barStyle="light-content"
            />
            <View
                style={{
                    height: 150,
                    zIndex: 2,
                    backgroundColor: 'rgba(256, 256, 256, 0)',
                }}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.PRIMARY}
                    style={{
                        position: 'absolute',
                        width: width * 4,
                        height: width * 4,
                        bottom: 0,
                        right: -width * 1.5,
                        borderRadius: width * 4,
                    }}
                />
                <View
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        width,
                        bottom: '25%',
                    }}
                >
                    <T
                        style={{
                            fontFamily: Constants.fonts.Bold,
                            color: theme.TEXT_WHITE,
                            fontSize: 25,
                        }}
                    >
                        {lang().points_system.buy_points}
                    </T>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        try {
                            goBackToPreviousScreen();
                        } catch (e) {
                            //
                        }
                    }}
                    style={{
                        height: 45,
                        width: 50,
                        backgroundColor: theme.TERTIARY,
                        marginTop: 10,
                        position: 'absolute',
                        top: 3,
                        start: 0,
                        paddingEnd: 10,
                        paddingStart: 3,
                        borderTopEndRadius: 50,
                        borderBottomEndRadius: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image
                        source={arrowPurple}
                        resizeMode="contain"
                        style={{
                            height: 20,
                            width: 20,
                            tintColor: theme.TEXT_PRIMARY,
                            transform: [
                                {
                                    scaleX:
                                        localization?.direction === 'rtl'
                                            ? -1
                                            : 1,
                                },
                            ],
                        }}
                    />
                </TouchableOpacity>
            </View>
            <View
                style={{
                    height: '100%',
                    zIndex: 1,
                    alignItems: 'center',
                }}
            >
                <ScrollView
                    contentContainerStyle={{
                        paddingTop: 50,
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingBottom: 200,
                    }}
                    style={{
                        flex: 1,
                        marginTop: -20,
                        paddingTop: 40,
                    }}
                >
                    <CurrentPoints
                        points={points}
                        theme={theme}
                    />
                    <T
                        style={{
                            marginTop: 20,
                            fontFamily: Constants.fonts.SemiBold,
                            fontSize: normalize(10),
                            color: theme.TEXT_MUTED,
                            textAlign: 'center',
                        }}
                    >
                        {lang().points_system.why_points}
                    </T>
                    <T
                        style={{
                            marginTop: 50,
                            fontFamily: Constants.fonts.SemiBold,
                            fontSize: normalize(15),
                            color: theme.TEXT_MUTED,
                        }}
                    >
                        {lang().points_system.get_more_points}
                    </T>
                    {isError && (
                        <View style={{marginTop: 80, marginBottom: 50}}>
                            <T
                                style={{
                                    color: theme.DANGER,
                                    fontFamily: Constants.fonts.Bold,
                                }}
                            >
                                {lang().error_happened}
                            </T>
                        </View>
                    )}
                    {isLoading && (
                        <ActivityIndicator
                            style={{marginTop: 80, marginBottom: 50}}
                            color={theme.PRIMARY}
                            size="large"
                        />
                    )}
                    {isFetched && data && (
                        <ProductsTable
                            products={data}
                            purchaseProduct={purchaseProduct}
                            theme={theme}
                        />
                    )}

                    <View
                        style={{
                            marginTop: 70,
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            maxWidth: 400,
                        }}
                    >
                        <TouchableOpacity
                            onPress={async () => {
                                Linking.openURL(
                                    Constants.links.privacyPolicy,
                                ).catch(() => {});
                            }}
                        >
                            <T
                                style={{
                                    fontSize: 10,
                                    fontFamily: Constants.fonts.Bold,
                                    color: theme.TEXT_PRIMARY,
                                }}
                            >
                                {lang().privacy_policy}
                            </T>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={async () => {
                                Linking.openURL(
                                    Constants.links.termsOfUse,
                                ).catch(() => {});
                            }}
                        >
                            <T
                                style={{
                                    fontSize: 10,
                                    fontFamily: Constants.fonts.Bold,
                                    color: theme.TEXT_PRIMARY,
                                }}
                            >
                                {lang().terms_of_use}
                            </T>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
                <LoadingModal show={PurchaseProductMutation.isLoading} />
            </View>
        </SafeAreaView>
    );
};

const ProductsTable = ({
    products,
    purchaseProduct,
    theme,
}: {
    products: PurchasesStoreProduct[];
    purchaseProduct: (productId: string) => void;
    theme: Theme;
}) => {
    return (
        <View style={{flexDirection: 'row'}}>
            {products.map(product => (
                <ProductSection
                    key={product.identifier}
                    product={product}
                    purchaseProduct={purchaseProduct}
                    theme={theme}
                />
            ))}
        </View>
    );
};

const ProductSection = ({
    product,
    purchaseProduct,
    theme,
}: {
    product: PurchasesStoreProduct;
    purchaseProduct: (productId: string) => void;
    theme: Theme;
}) => {
    return (
        <TouchableOpacity
            onPress={() => {
                purchaseProduct(product.identifier);
            }}
            style={{
                marginHorizontal: 10,
                width: 160,
                marginTop: 15,
                borderRadius: 6,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'stretch',
            }}
        >
            <LinearGradient
                colors={
                    theme.MODE === 'light'
                        ? theme.GRADIENTS.PRIMARY
                        : ([theme.TERTIARY, pSBC(-0.5, theme.PRIMARY)] as any)
                }
                style={{
                    paddingTop: 5,
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: 6,
                }}
            >
                <View
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            marginVertical: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <T
                            style={{
                                color: 'white',
                                fontFamily: Constants.fonts.Bold,
                                fontSize: normalize(16),
                                lineHeight: 28,
                            }}
                        >
                            {`${product.identifier.split('_')[0]} ${
                                lang().points_system.points
                            }`}
                        </T>
                        <T
                            style={{
                                color: 'white',
                                fontFamily: Constants.fonts.Bold,
                                fontSize: 16,
                                lineHeight: 22,
                                paddingBottom: 10,
                            }}
                        >
                            {`${lang().lang === 'ar' ? 'بـ' : ''} ${
                                product.priceString
                            }`}
                        </T>
                    </View>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        purchaseProduct(product.identifier);
                    }}
                    style={{
                        backgroundColor:
                            theme.MODE === 'light'
                                ? theme.BACKGROUND
                                : theme.SECONDARY,
                        borderRadius: 4,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '80%',
                        marginBottom: 8,
                        paddingVertical: Platform.select({ios: 6, android: 2}),
                    }}
                >
                    <T
                        style={{
                            color: theme.TEXT_PRIMARY,
                            fontFamily: Constants.fonts.SemiBold,
                        }}
                    >
                        {lang().points_system.buy}
                    </T>
                </TouchableOpacity>
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default connector(BuyPoints);
