import React, {useEffect, useRef, useState} from 'react';
import {
    View,
    StyleSheet,
    SafeAreaView,
    StatusBar,
    TouchableOpacity,
    Image,
    ScrollView,
    I18nManager,
    Platform,
    useWindowDimensions,
} from 'react-native';
import * as Linking from 'expo-linking';
import 'moment/locale/en-gb';
import LinearGradient from 'react-native-linear-gradient';
import {connect} from 'react-redux';
import Confetti from 'react-native-confetti';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import LoadingModal from '../components/Shared/LoadingModal';
import lang from '../localization';
import store from '../modules/redux/store';
import {actions as subscriptionActions} from '../modules/subscription';
import {Disclaimers, Features, OfferingTable} from '../components/Subscription';
import SubscriberView from '../components/Subscription/SubscriberView';
import {useTheme} from '../modules/app/theme';
import {useLocalaization} from '../modules/app/localization';
import {isWeb} from '../../env';
import WebSubscriptions from '../components/Subscription/WebSubscriptions';
import {normalize} from '../modules/helpers';
import {SCREENS} from './Screens';
import {logEvent} from '../modules/app/tracking';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    footerButtonsText: {
        fontSize: 10,
        fontFamily: Constants.fonts.Bold,
        color: '#6863A9',
    },
});

const arrowPurple = require('../assets/images/backArrow.png');

const Subscription = ({
    navigation,
    purchasePackage,
    restorePurchase,
    isSubscribed,
    activeSubscription,
    checkActiveSubscription,
    config,
}) => {
    const theme = useTheme();
    const [offerings, setOfferings] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const localization = useLocalaization();
    const {features} = lang();
    const disclaimers = lang().subscription_disclaimers;
    const {width} = useWindowDimensions();
    const confettiRef = useRef(null);

    useEffect(() => {
        if (isWeb) {
            // eslint-disable-next-line no-undef
            if (!window.location.href?.includes('subscription/success')) {
                checkActiveSubscription();
            }
            return;
        }

        const timer = setTimeout(async () => {
            await checkActiveSubscription();
            const offering = await subscriptionActions.getOfferings();
            if (!offering) {
                setOfferings(null);
            } else {
                setOfferings(offering.availablePackages);
            }
        });

        const timer2 = setTimeout(() => {
            if (confettiRef.current) {
                confettiRef.current.startConfetti();
            }
        }, 200);

        return () => {
            clearTimeout(timer2);
            clearTimeout(timer);
        };
    }, []);

    return (
        <SafeAreaView
            style={{...styles.container, backgroundColor: theme.BACKGROUND}}
        >
            <StatusBar
                backgroundColor={
                    theme.MODE === 'light'
                        ? theme.GRADIENTS.PRIMARY[1]
                        : theme.SECONDARY
                }
                barStyle="light-content"
            />
            <View
                style={{
                    height: 150,
                    zIndex: 2,
                    backgroundColor: 'rgba(256, 256, 256, 0)',
                }}
            >
                <LinearGradient
                    colors={theme.GRADIENTS.PRIMARY}
                    style={{
                        position: 'absolute',
                        width: width * 4,
                        height: width * 4,
                        bottom: 0,
                        right: -width * 1.5,
                        borderRadius: width * 4,
                    }}
                />
                <View
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        width,
                        bottom: '30%',
                    }}
                >
                    <T
                        style={{
                            fontFamily: Constants.fonts.Bold,
                            color: 'white',
                            fontSize: 25,
                        }}
                    >
                        {lang().subscription_screen_title}
                    </T>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        try {
                            if (navigation.getState().routes.length === 1) {
                                navigation.navigate(SCREENS.WELCOME);
                            } else {
                                navigation.goBack();
                            }
                        } catch (e) {
                            //
                        }
                    }}
                    style={{
                        height: 45,
                        width: 50,
                        backgroundColor: theme.TERTIARY,
                        marginTop: 10,
                        position: 'absolute',
                        top: 3,
                        start: 0,
                        paddingEnd: 10,
                        paddingStart: 3,
                        borderTopEndRadius: 50,
                        borderBottomEndRadius: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image
                        source={arrowPurple}
                        resizeMode="contain"
                        style={{
                            height: 20,
                            width: 20,
                            tintColor: theme.TEXT_PRIMARY,
                            transform: [
                                {
                                    scaleX:
                                        localization.direction === 'rtl'
                                            ? -1
                                            : 1,
                                },
                            ],
                        }}
                    />
                </TouchableOpacity>
            </View>
            <View style={{height: '100%', zIndex: 1}}>
                {isSubscribed && !isWeb && (
                    <View
                        style={{
                            top: -20,
                            position: 'absolute',
                            height: '100%',
                            zIndex: 1,
                            right:
                                I18nManager.isRTL && Platform.OS === 'ios'
                                    ? 0
                                    : undefined,
                        }}
                    >
                        <Confetti
                            ref={confettiRef}
                            confettiCount={100}
                            bsize={2}
                        />
                    </View>
                )}
                {isSubscribed ? (
                    <SubscriberView
                        activeSubscription={activeSubscription}
                        navigation={navigation}
                        config={config}
                        theme={theme}
                        setIsLoading={setIsLoading}
                    />
                ) : (
                    <ScrollView
                        style={{
                            flex: 1,
                            marginTop: -20,
                            paddingTop: 40,
                        }}
                        contentContainerStyle={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingBottom: 230,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'column',
                                marginTop: 0,
                            }}
                        >
                            <Features
                                features={features}
                                theme={theme}
                            />
                        </View>
                        <View
                            style={{
                                marginTop: 20,
                                alignItems: 'center',
                                flex: 1,
                                width,
                                maxWidth: 500,
                            }}
                        >
                            <View style={{marginBottom: normalize(17)}}>
                                <T
                                    style={{
                                        fontFamily: Constants.fonts.Bold,
                                        fontSize: normalize(15),
                                        color: theme.TEXT_MUTED,
                                    }}
                                >
                                    {lang().choose_subscription_period}
                                </T>
                            </View>
                            {isWeb ? (
                                <WebSubscriptions
                                    theme={theme}
                                    setIsLoading={setIsLoading}
                                />
                            ) : (
                                <OfferingTable
                                    offerings={offerings}
                                    purchasePackage={purchasePackage}
                                    setIsLoading={setIsLoading}
                                    theme={theme}
                                />
                            )}
                        </View>
                        <View
                            style={{
                                width: '90%',
                                marginTop: 30,
                                maxWidth: 400,
                            }}
                        >
                            <Disclaimers
                                disclaimers={disclaimers}
                                theme={theme}
                            />
                        </View>
                        <View
                            style={{
                                marginTop: 20,
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-evenly',
                                maxWidth: 400,
                            }}
                        >
                            {!isWeb && (
                                <TouchableOpacity
                                    onPress={() => {
                                        logEvent('restoreSubscription');
                                        restorePurchase({
                                            setIsLoading,
                                        });
                                    }}
                                >
                                    <T
                                        style={{
                                            ...styles.footerButtonsText,
                                            color: theme.TEXT_PRIMARY,
                                        }}
                                    >
                                        {lang().restore_subscription}
                                    </T>
                                </TouchableOpacity>
                            )}
                            <TouchableOpacity
                                onPress={async () => {
                                    Linking.openURL(
                                        Constants.links.privacyPolicy,
                                    ).catch(() => {});
                                }}
                            >
                                <T
                                    style={{
                                        ...styles.footerButtonsText,
                                        color: theme.TEXT_PRIMARY,
                                    }}
                                >
                                    {lang().privacy_policy}
                                </T>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={async () => {
                                    Linking.openURL(
                                        Constants.links.termsOfUse,
                                    ).catch(() => {});
                                }}
                            >
                                <T
                                    style={{
                                        ...styles.footerButtonsText,
                                        color: theme.TEXT_PRIMARY,
                                    }}
                                >
                                    {lang().terms_of_use}
                                </T>
                            </TouchableOpacity>
                        </View>
                    </ScrollView>
                )}
                <LoadingModal show={isLoading} />
            </View>
        </SafeAreaView>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    purchasePackage: data =>
        subscriptionActions.purchasePackage(data)(dispatch, getState),
    restorePurchase: data =>
        subscriptionActions.restorePurchase(data)(dispatch, getState),
    checkActiveSubscription: data =>
        subscriptionActions.checkActiveSubscription(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    isSubscribed: state.subscriptionReducer.isSubscribed,
    activeSubscription: state.subscriptionReducer.activeSubscription,
    user: state.authReducer.user,
    config: state.authReducer.config,
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
