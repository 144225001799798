import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import reducers from './rootReducer'; // Import the root reducer

const middleware = [thunk];

export default createStore(
    reducers,
    // @ts-ignore
    compose(applyMiddleware(...middleware)),
);
