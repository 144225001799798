import React, {ReactChild} from 'react';
import {View, SafeAreaView} from 'react-native';
import {Props} from 'react-native-image-zoom-viewer/built/image-viewer.type';
import {Theme} from '../assets/Colors';
import {ExternalUserInfo} from '../components/RandomConversations';
import {TopBar} from '../components/Shared';
import StatusBarAware from '../components/Shared/StatusBarAware';
import {useTheme} from '../modules/app/theme';
import {normalize} from '../modules/helpers';
import {User} from '../types/data/user';
import {NavigationProp} from '../types/navigation/navigationProp';

const ExternalUserProfile = ({
    navigation,
    route,
}: Props & NavigationProp<{user: User}>) => {
    const theme = useTheme();
    const user = route?.params?.user;

    const goBackToPreviousScreen = () => {
        navigation.goBack();
    };

    if (user !== undefined) {
        return (
            <PageWrapper
                theme={theme}
                goBackToPreviousScreen={goBackToPreviousScreen}
                user={user}
            >
                <View
                    style={{
                        height: '60%',
                        paddingTop: normalize(50),
                        width: '100%',
                        borderRadius: 100000,
                        zIndex: 3,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.BACKGROUND,
                    }}
                >
                    <ExternalUserInfo
                        theme={theme}
                        user={user}
                    />
                </View>
            </PageWrapper>
        );
    }

    return <></>;
};

const PageWrapper = ({
    children,
    goBackToPreviousScreen,
    user,
    theme,
}: {
    children: ReactChild;
    goBackToPreviousScreen: () => void;
    user: User;
    theme: Theme;
}) => {
    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: theme.BACKGROUND,
                flexDirection: 'column',
            }}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={user.name}
            />
            {children}
        </SafeAreaView>
    );
};

export default ExternalUserProfile;
