import React, {useEffect, useRef, useState} from 'react';
import {
    View,
    StatusBar,
    Platform,
    I18nManager,
    ScrollView,
    useWindowDimensions,
} from 'react-native';
import {connect} from 'react-redux';
import {useNavigation} from '@react-navigation/native';
import pSBC from 'shade-blend-color';
// eslint-disable-next-line import/no-relative-packages
import SnackbarComponent from '../packages/react-native-snackbar-component';
import store from '../modules/redux/store';
import {actions as appActions} from '../modules/app';
import LoadingModal from '../components/Shared/LoadingModal';
import {MainTab} from '../components/Main';
import BottomTabBar from '../components/Main/BottomTabBar';
import ConversationsTab from '../components/Main/ConversationsTab';
import {useTheme} from '../modules/app/theme';
import {SCREENS} from './Screens';
import {isWeb} from '../../env';
import {useLocalaization} from '../modules/app/localization';
import AppType from '../../AppType';
import {useRegesterNotifications} from '../modules/app/notifications';

const MainScreen = props => {
    // this will load user from the localStorage
    useRegesterNotifications();

    props.startUp();
    const [currentTab, setCurrentTab] = useState(
        Platform.OS === 'android' && I18nManager.isRTL ? 1 : 0,
    );
    const scrollViewRef = useRef(React.createRef);
    const navigation = useNavigation();
    const theme = useTheme();
    const localization = useLocalaization();
    const {width: ScreenWidth} = useWindowDimensions();

    const goToTab = ({index}) => {
        if (index === 2) {
            navigation.navigate(SCREENS.RANDOM_CONVERSATION);
            return;
        }
        if (scrollViewRef?.current) {
            scrollViewRef?.current?.scrollTo({
                x:
                    isWeb && localization?.direction === 'rtl'
                        ? -1 * index * ScreenWidth
                        : ScreenWidth * index,
                animated: !isWeb,
            });
            setCurrentTab(index);
        }
    };

    const getTopColor = () => {
        if (theme.MODE === 'dark') {
            if (I18nManager.isRTL) {
                if (currentTab === 1) {
                    return theme.GRADIENTS.PRIMARY[0];
                }
                return theme.BACKGROUND;
            }
            if (currentTab === 1) {
                return theme.BACKGROUND;
            }
            return theme.GRADIENTS.PRIMARY[0];
        }

        return theme.GRADIENTS.PRIMARY[0];
    };

    const getTopTextColor = () => {
        if (theme.MODE === 'dark') {
            return 'light-content';
        }
        if (Platform.OS === 'ios' && currentTab === 1) {
            return 'dark-content';
        }
        return 'light-content';
    };

    const onScroll = offset => {
        const currentTabLocal = Math.round(Math.round(offset) / ScreenWidth);
        setCurrentTab(currentTabLocal);
    };

    useEffect(() => {
        navigation.setOptions({
            title: `${AppType.select({
                fadfadah: 'فضفضة',
                nezmo: 'نيزمو',
            })} - ${props?.user?.name || ''}`,
        });
    }, [props?.user?.name]);

    return (
        <View style={{backgroundColor: theme.BACKGROUND, height: '100%'}}>
            {props.user === undefined || props.user === null ? (
                <View>
                    <StatusBar
                        backgroundColor={getTopColor()}
                        barStyle={getTopTextColor()}
                    />
                </View>
            ) : (
                <>
                    <StatusBar
                        backgroundColor={getTopColor()}
                        barStyle={getTopTextColor()}
                    />
                    <SnackbarComponent
                        visible={props.isSnackBarVisible}
                        textMessage={props.snackBarText}
                        position={props.snackBarPosition}
                        actionHandler={props.snackBarActionHandler}
                        backgroundColor={props.snackBarBackgroundColor}
                        accentColor={props.snackBarAccentColor}
                        actionText={' '}
                    />
                    <BottomTabBar
                        goToTab={goToTab}
                        currentTab={currentTab}
                        theme={theme}
                    />
                    <ScrollView
                        horizontal
                        pagingEnabled
                        ref={scrollViewRef}
                        scrollEventThrottle={16}
                        onScroll={e => {
                            if (isWeb) {
                                const offset = e.nativeEvent.contentOffset.x;
                                onScroll(
                                    localization.direction === 'rtl'
                                        ? -offset
                                        : offset,
                                );
                            }
                        }}
                        onMomentumScrollEnd={e => {
                            onScroll(e.nativeEvent.contentOffset.x);
                        }}
                    >
                        <View
                            style={{
                                width: ScreenWidth,
                                flexDirection: 'column',
                                flex: 1,
                            }}
                        >
                            <MainTab
                                currentTab={currentTab}
                                goToTab={goToTab}
                                theme={theme}
                            />
                        </View>
                        <View style={{width: ScreenWidth}}>
                            <ConversationsTab theme={theme} />
                        </View>
                    </ScrollView>
                    <LoadingModal show={props.mainLoading} />
                </>
            )}
        </View>
    );
};

const mapDispatchToProps = (dispatch, getState = store.getState) => ({
    startUp: data => appActions.startUp(data)(dispatch, getState),
});

const mapStateToProps = state => ({
    user: state.authReducer.user,
    isSnackBarVisible: state.appReducer.isSnackBarVisible,
    snackBarText: state.appReducer.snackBarText,
    snackBarPosition: state.appReducer.snackBarPosition,
    snackBarActionHandler: state.appReducer.snackBarActionHandler,
    snackBarBackgroundColor: state.appReducer.snackBarBackgroundColor,
    snackBarAccentColor: state.appReducer.snackBarAccentColor,
    mainLoading: state.appReducer.mainLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
