import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {SafeAreaView, View} from 'react-native';
import LinkExtractor from '../components/InitiatedConversations/LinkExtractor';
import {TopBar} from '../components/Shared';
import StatusBarAware from '../components/Shared/StatusBarAware';
import lang from '../localization';
import {useTheme} from '../modules/app/theme';
import {SCREENS} from './Screens';

const CreateConversationFromLink = () => {
    const navigation = useNavigation();
    const theme = useTheme();

    const goBackToPreviousScreen = () => {
        console.log('goback');
        navigation.navigate(SCREENS.WELCOME);
    };

    return (
        <SafeAreaView
            style={{
                flex: 1,
                backgroundColor: theme.BACKGROUND,
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <StatusBarAware theme={theme} />
            <TopBar
                goBackToPreviousScreen={goBackToPreviousScreen}
                titleText={
                    lang().create_conversation_floating_button
                        .create_conversation_using_link
                }
            />
            <View
                style={{
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <LinkExtractor theme={theme} />
            </View>
        </SafeAreaView>
    );
};

export default CreateConversationFromLink;
