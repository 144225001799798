import React, {useState} from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Platform,
    useWindowDimensions,
} from 'react-native';
import {isWeb} from '../../../env';
import {Theme} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {useLocalaization} from '../../modules/app/localization';
import {normalize} from '../../modules/helpers';
import {T} from '../Shared';
import CreateConversationFloatingButton from './CreateConversationFloatingButton';
import AppType from '../../../AppType';

const smileIcon = require('../../assets/images/smileIcon.png');

const EmptyConversationsList = ({theme}: {theme: Theme}) => {
    const {height} = useWindowDimensions();
    const localization = useLocalaization();
    const [needsMoreHelp, setNeedsMoreHelp] = useState(false);

    return (
        <>
            <View
                style={{
                    // @ts-ignore
                    height: Platform.select({web: height - 50, native: '100%'}),
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 20,
                }}
            >
                <Image
                    source={smileIcon}
                    style={{
                        height: 50,
                        width: 50,
                        resizeMode: 'contain',
                        tintColor: theme.TEXT_PRIMARY,
                    }}
                />
                <T
                    style={{
                        marginTop: 10,
                        color: theme.TEXT_PRIMARY,
                        textAlign: 'center',
                        fontFamily: Constants.fonts.Bold,
                        fontSize: normalize(16),
                    }}
                >
                    {lang().welcome_to_fadfadah}
                </T>
                <T
                    style={{
                        marginTop: 40,
                        textAlign: 'center',
                        fontFamily: Constants.fonts.Bold,
                        color: theme.TEXT,
                        fontSize: normalize(11),
                        opacity: 0.8,
                    }}
                >
                    {
                        lang().no_conversations_tips
                            .create_conversations_to_show_them_here
                    }
                </T>
                {!needsMoreHelp && AppType.CURRENT_APP === 'FADFADAH' && (
                    <TouchableOpacity
                        onPress={() => {
                            setNeedsMoreHelp(true);
                        }}
                        style={{
                            marginTop: 30,
                            backgroundColor: theme.PRIMARY,
                            paddingHorizontal: 20,
                            paddingVertical: 10,
                            borderRadius: 20,
                        }}
                    >
                        <T
                            style={{
                                color: theme.TEXT_WHITE,
                                textAlign: 'center',
                                fontFamily: Constants.fonts.Bold,
                                fontSize: normalize(11),
                            }}
                        >
                            {
                                lang().no_conversations_tips
                                    .how_can_i_send_or_get_conversations
                            }
                        </T>
                    </TouchableOpacity>
                )}
                {needsMoreHelp && (
                    <View style={{marginTop: 30, opacity: 0.8}}>
                        <T
                            style={{
                                color: theme.TEXT,
                                fontFamily: Constants.fonts.SemiBold,
                                fontSize: normalize(11),
                                textAlign:
                                    isWeb && localization?.direction === 'rtl'
                                        ? 'right'
                                        : 'left',
                            }}
                        >
                            {
                                lang().no_conversations_tips
                                    .how_can_i_send_or_get_conversations
                            }
                        </T>
                        <T
                            style={{
                                color: theme.TEXT,
                                marginTop: 20,
                                fontFamily: Constants.fonts.Regular,
                                fontSize: normalize(11),
                                lineHeight: normalize(17),
                                textAlign:
                                    isWeb && localization?.direction === 'rtl'
                                        ? 'right'
                                        : 'left',
                            }}
                        >
                            {lang().no_conversations_tips.tip1}
                        </T>
                        <T
                            style={{
                                color: theme.TEXT,
                                marginTop: 10,
                                fontFamily: Constants.fonts.Regular,
                                fontSize: normalize(11),
                                lineHeight: normalize(17),
                                textAlign:
                                    isWeb && localization?.direction === 'rtl'
                                        ? 'right'
                                        : 'left',
                            }}
                        >
                            {lang().no_conversations_tips.tip2}
                        </T>
                        <T
                            style={{
                                color: theme.TEXT,
                                marginTop: 10,
                                fontFamily: Constants.fonts.Regular,
                                fontSize: normalize(11),
                                lineHeight: normalize(17),
                                textAlign:
                                    isWeb && localization?.direction === 'rtl'
                                        ? 'right'
                                        : 'left',
                            }}
                        >
                            {lang().no_conversations_tips.tip3}
                        </T>
                    </View>
                )}
            </View>
        </>
    );
};

export default EmptyConversationsList;
