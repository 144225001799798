import React from 'react';
import {View, ViewStyle} from 'react-native';

const ShadowViewLocal = ({
    children,
    style,
}: {
    children: any;
    style: ViewStyle;
}) => {
    return <View style={style}>{children}</View>;
};

export default ShadowViewLocal;
