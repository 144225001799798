import socketIOClient from 'socket.io-client';
import {AppState} from 'react-native';
import {actions as conversationsActions} from '../conversations';
import {network_domain} from '../../../env';

import {playSound} from './sound';

let socket;

export function registerListeners(user) {
    return async (dispatch, getState) => {
        socket = socketIOClient(network_domain, {
            transports: ['websocket', 'polling'],
            query: {
                anonymousNetworkKey: user.network_key,
                link: user.user_link,
                type: 'user',
                id: `${user.id}`,
            },
        });

        AppState.addEventListener('change', state => {
            if (state === 'background') {
                socket.disconnect();
            }
            if (state === 'active') {
                const {activeConversation} = getState().conversationsReducer;
                if (!activeConversation) {
                    conversationsActions.checkUpdates()(dispatch, getState);
                }
                socket.connect();
            }
        });

        socket.on('connect', data => {
            setTimeout(() => {
                socket.emit('join', {
                    anonymousNetworkKey: user.network_key,
                    link: user.user_link,
                    type: 'user',
                    id: `${user.id}`,
                    receiveInitiatedConversationEvents: true,
                });
            }, 0);

            setTimeout(() => {
                socket.emit('join', {
                    anonymousNetworkKey: user.network_key,
                    link: user.user_link,
                    type: 'user',
                    id: `${user.id}`,
                    receiveInitiatedConversationEvents: true,
                });
            }, 3000);

            setTimeout(() => {
                socket.emit('join', {
                    anonymousNetworkKey: user.network_key,
                    link: user.user_link,
                    type: 'user',
                    id: `${user.id}`,
                    receiveInitiatedConversationEvents: true,
                });
            }, 20000);

            socket.emit('set_user_link', {
                user_link: user.user_link,
                user_id: user.id,
            });
        });

        socket.on(user.network_key, function (broadcast) {
            if (broadcast.type === 'newConversation') {
                onNewConversation(broadcast.data)(dispatch, getState);
            }
            if (broadcast.type === 'newMessage') {
                onMessageReceived(broadcast.data)(dispatch, getState);
            }
        });

        socket.on(`initiated_${user.network_key}`, function (broadcast) {
            if (broadcast.type === 'newMessage') {
                onMessageReceived(broadcast.data)(dispatch, getState);
            }
        });

        socket.on(`user_id_${user.id}`, function (broadcast) {
            if (
                broadcast.type === 'anonymousIsTyping' &&
                broadcast.conversation_id !== undefined
            ) {
                onAnonymousIsTyping(broadcast.conversation_id)(
                    dispatch,
                    getState,
                );
            }
        });

        global.socket = socket;
    };
}

export function stopSocketListener() {
    if (socket) {
        socket.close();
    }
}

function onNewConversation(data) {
    return async (dispatch, getState) => {
        conversationsActions.newConversationReceived(data)(dispatch, getState);
        playSound({type: 'newConversation'});
    };
}

function onMessageReceived(data) {
    return async (dispatch, getState) => {
        const {activeConversation} = getState().conversationsReducer;
        if (data.conversation_id !== activeConversation) {
            conversationsActions.setNewConversation(data.conversation_id)(
                dispatch,
                getState,
            );
            playSound({type: 'heavyNewMessage'});
        } else {
            conversationsActions.markConversationAsSeen({
                conversationID: data.conversation_id,
            })(dispatch, getState);
            playSound({type: 'lightNewMessage'});
        }
    };
}

function onAnonymousIsTyping(conversationID) {
    return async (dispatch, getState) => {
        conversationsActions.setConversationIsTyping({conversationID})(
            dispatch,
            getState,
        );
    };
}
