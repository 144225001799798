import {Platform} from 'react-native';
import {getDeviceID} from '../helpers';
import Backend from '../axios/axios';

export async function login(email: string, password: string) {
    return Backend.post('/login', {
        email,
        password,
    });
}

export async function signup(
    name: string,
    email: string | undefined,
    password: string | undefined,
    password_confirmation: string | undefined,
    lang: string,
    should_skip: boolean,
) {
    return Backend.post('/signup', {
        name,
        email,
        password,
        password_confirmation,
        lang,
        platform: Platform.OS,
        should_skip,
    });
}

export async function facebookAuth(facebook_token: string) {
    return Backend.post('/facebookAuth', {
        facebook_token,
        platform: Platform.OS,
    });
}

export async function appleAuth({
    apple_token,
    user_id,
    name,
}: {
    apple_token: string | null;
    user_id: string;
    name: string;
}) {
    return Backend.post('/appleAuth', {
        apple_token,
        user_id,
        name,
    });
}

export async function attachNotificationKey(notificationKey: string) {
    return Backend.post('/attachNotificationKey', {
        notification_key: notificationKey,
    });
}

export async function detachNotificationKey(notificationKey: string) {
    return Backend.post('/detachNotificationKey', {
        notification_key: notificationKey,
    });
}

export async function changeProfilePicture({image}: {image: string}) {
    return Backend.post('/changeProfilePicture', {
        image,
    });
}

export async function checkUserAndConfig({
    isSubscribed,
}: {
    isSubscribed: boolean;
}) {
    const deviceID = await getDeviceID();

    return Backend.post('/checkUserAndConfig', {
        device_id: deviceID,
        is_subscribed: isSubscribed,
    });
}

export async function toggleRandomConversation() {
    return Backend.post('/toggleRandomConversations');
}

export async function checkUserAndConfigTwitter({token}: {token: string}) {
    return Backend.post(
        '/checkUserAndConfig',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
}

export async function completeSignup({
    email,
    password,
}: {
    email: string;
    password: string;
}) {
    return Backend.post('/completeSignup', {
        email,
        password,
    });
}

export async function deleteMyAccount() {
    return Backend.post('/deleteMyAccount');
}

export async function changeUsername({newUsername}: {newUsername: string}) {
    return Backend.post('/changeUsername', {
        new_name: newUsername,
    });
}

export async function changeCustomizationOptions({
    frame_color,
    frame_shape,
}: {
    frame_color: string;
    frame_shape: string | undefined;
}) {
    return Backend.post('/changeCustomizationOptions', {
        frame_color,
        frame_shape,
    });
}

export async function sendEmailAuthenticationCodeToUser() {
    return Backend.post('/sendEmailAuthenticationCodeToUser');
}

export async function checkEmailAuthenticationCode({code}: {code: string}) {
    return Backend.post('/checkEmailAuthenticationCode', {
        code,
    });
}
