import {AnyAction} from 'redux';
import {Config} from '../../types/data/config';
import {User} from '../../types/data/user';
import * as t from './actionTypes';

export interface AuthReducer {
    isLoggedIn: boolean;
    user?: User;
    token?: string;
    config?: Config;
    loginLoading: boolean;
    signupLoading: boolean;
    loginError?: string;
    signupError?: string;
}

const initialState: AuthReducer = {
    isLoggedIn: false,
    user: undefined,
    token: undefined,
    config: undefined,
    loginLoading: false,
    signupLoading: false,
    loginError: undefined,
    signupError: undefined,
};

export type AuthReducerAction = AnyAction & {
    data: AuthReducer & {image?: string};
};

const authReducer = (state = initialState, action: AuthReducerAction) => {
    switch (action.type) {
        case t.SET_LOGIN_LOADING:
            return {
                ...state,
                loginLoading: true,
            };

        case t.UNSET_LOGIN_LOADING:
            return {
                ...state,
                loginLoading: false,
            };

        case t.SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.data.loginError,
                loginLoading: false,
            };

        case t.SET_SIGNUP_LOADING:
            return {
                ...state,
                signupLoading: true,
            };

        case t.UNSET_SIGNUP_LOADING:
            return {
                ...state,
                signupLoading: false,
            };

        case t.SET_SIGNUP_ERROR:
            return {
                ...state,
                signupError: action.data.signupError,
                signupLoading: false,
            };

        case t.FREE_ERRORS:
            return {
                ...state,
                loginError: undefined,
                signupError: undefined,
            };

        case t.LOAD_USER:
            return {
                ...state,
                user: action.data.user,
                token: action.data.token,
                config: action.data.config,
            };

        case t.UNLOAD_USER:
            return {
                ...state,
                user: null,
                token: null,
                config: null,
            };

        case t.FREE_REDUCER:
            return {
                ...initialState,
            };

        case t.CHANGE_PROFILE_PICTURE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile_picture_path: action.data.image,
                },
            };

        case t.ALLOW_RANDOM_CONVERSATIONS:
            return {
                ...state,
                user: {
                    ...state.user,
                    can_receive: 1,
                },
            };

        case t.DISALLOW_RANDOM_CONVERSATIONS:
            return {
                ...state,
                user: {
                    ...state.user,
                    can_receive: 0,
                },
            };

        default:
            return state;
    }
};

export default authReducer;
