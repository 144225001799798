import React, {useEffect, useRef} from 'react';
import {Platform, Animated, Easing, useWindowDimensions} from 'react-native';
import {Theme, ThemeColors} from '../../assets/Colors';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {normalize} from '../../modules/helpers';
import {Interest} from '../../types/data/interest';
import {T} from '../Shared';

const offsetY = Platform.select({ios: 15, android: 25, web: 25}) || 1;
const offsetX = Platform.select({ios: -5, android: -15, web: -15}) || 1;

const RandomUserInterests = ({
    dimensions,
    interests = [],
    theme,
}: {
    dimensions: number;
    interests?: Interest[];
    theme: Theme;
}) => {
    const IMAGE_DIMENSIONS = dimensions;
    const {width} = useWindowDimensions();

    const sortedInterests = interests.sort((interestA, interestB) => {
        return (
            (interestB.type !== 'language' ? 0 : 1) -
            (interestA.type !== 'language' ? 0 : 1)
        );
    });

    return (
        <>
            {sortedInterests[0] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[0]}
                    top={-(IMAGE_DIMENSIONS * 0.2 - (offsetY as number))}
                    end={width - IMAGE_DIMENSIONS - offsetX}
                    xPositionDuration={380}
                />
            )}

            {sortedInterests[1] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[1]}
                    top={-(IMAGE_DIMENSIONS * 0.2 - offsetY)}
                    start={width - IMAGE_DIMENSIONS - offsetX}
                    xPositionDuration={380}
                />
            )}

            {sortedInterests[2] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[2]}
                    top={IMAGE_DIMENSIONS * 0.15 + offsetY}
                    end={width - IMAGE_DIMENSIONS + 30 - offsetX}
                    xPositionDuration={420}
                />
            )}

            {sortedInterests[3] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[3]}
                    top={IMAGE_DIMENSIONS * 0.15 + offsetY}
                    start={width - IMAGE_DIMENSIONS + 30 - offsetX}
                    xPositionDuration={420}
                />
            )}

            {sortedInterests[4] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[4]}
                    top={IMAGE_DIMENSIONS * 0.5 + offsetY}
                    end={width - IMAGE_DIMENSIONS + 30 - offsetX}
                    xPositionDuration={460}
                />
            )}

            {sortedInterests[5] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[5]}
                    top={IMAGE_DIMENSIONS * 0.5 + offsetY}
                    start={width - IMAGE_DIMENSIONS + 30 - offsetX}
                    xPositionDuration={460}
                />
            )}

            {sortedInterests[6] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[6]}
                    top={IMAGE_DIMENSIONS * 0.85 + offsetY}
                    end={width - IMAGE_DIMENSIONS + 10 - offsetX}
                    xPositionDuration={500}
                />
            )}

            {sortedInterests[7] && (
                <InterestTag
                    theme={theme}
                    interest={sortedInterests[7]}
                    top={IMAGE_DIMENSIONS * 0.85 + offsetY}
                    start={width - IMAGE_DIMENSIONS + 10 - offsetX}
                    xPositionDuration={500}
                />
            )}
        </>
    );
};

export default RandomUserInterests;

const InterestTag = ({
    interest,
    top,
    end = undefined,
    start = undefined,
    xPositionDuration = 500,
    theme = ThemeColors.light,
}: {
    interest: Interest;
    top: number;
    end?: number;
    start?: number;
    xPositionDuration?: number;
    theme: Theme;
}) => {
    const {width} = useWindowDimensions();
    const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
    const topPosition = useRef(new Animated.Value(30)).current; // Initial value for opacity: 0
    const xPosition = useRef(new Animated.Value(width / 2)).current; // Initial value for opacity: 0

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 0.9,
            duration: 700,
            useNativeDriver: false,
            easing: Easing.cubic,
        }).start();

        Animated.timing(topPosition, {
            toValue: top,
            duration: 500,
            useNativeDriver: false,
            easing: Easing.linear,
        }).start();

        const getXToValue = () => {
            if (end) {
                return end;
            }
            if (start) {
                return start;
            }
            return 0;
        };
        Animated.timing(xPosition, {
            toValue: getXToValue(),
            duration: xPositionDuration,
            useNativeDriver: false,
            easing: Easing.cubic,
        }).start();
    }, [fadeAnim]);

    if (!interest) {
        return <></>;
    }

    // @ts-ignore
    const emoji = String.fromCodePoint(`0x${interest.emoji}`);

    return (
        <Animated.View
            // @ts-ignore
            dir="ltr"
            style={{
                opacity: fadeAnim,
                top: topPosition,
                end: end ? xPosition : undefined,
                start: start ? xPosition : undefined,
                position: 'absolute',
                backgroundColor: theme.TERTIARY,
                paddingHorizontal: 10,
                paddingVertical: 2,
                borderRadius: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 25,
                ...Constants.elevation.one,
                zIndex: -10,
            }}
        >
            <T
                style={{
                    color: Constants.colors.main.dark,
                    fontFamily: Constants.fonts.SemiBold,
                    marginEnd: 4,
                    fontSize: normalize(
                        Platform.select({
                            ios: 10,
                            android: 10,
                            web: 10,
                        }) as number,
                    ),
                }}
            >
                {interest.type !== 'language' ? emoji : '🗣'}
            </T>
            <T
                style={{
                    color: theme.TEXT,
                    fontFamily: Constants.fonts.SemiBold,
                    fontSize: normalize(
                        Platform.select({
                            ios: 11,
                            android: 10,
                            web: 10,
                        }) as number,
                    ),
                }}
            >
                {/* @ts-ignore */}
                {interest[lang().lang]}
            </T>
        </Animated.View>
    );
};
