import React, {useState, useEffect} from 'react';
import {
    SafeAreaView,
    View,
    StyleSheet,
    TouchableOpacity,
    KeyboardAvoidingView,
    Platform,
} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import deviceInfoModule from 'react-native-device-info';
import lang from '../localization';
import Constants from '../assets/Constants';
import {T} from '../components/Shared';
import {Input, SocialLoginButtons} from '../components/Login';
import ErrorSection from '../components/Login/ErrorSections';
import {actions as authActions} from '../modules/auth';
import store from '../modules/redux/store';
import {normalize} from '../modules/helpers';
import PrivacyTermsSection from '../components/Login/PrivacyTermsSection';
import {tinyClick} from '../modules/app/vibrations';
import LoadingModal from '../components/Shared/LoadingModal';
import {hasBadWords} from '../modules/helpers/checkBadWords';
import {NavigationProp} from '../types/navigation/navigationProp';
import {ReduxDispatch, ReduxGetState} from '../types/redux/redux';
import {ActionParameter} from '../types/helpers/helpers';
import {ReducerState} from '../types/reducer/reducer';
import Purchases from '../modules/subscription/Purchases';
import {SCREENS} from './Screens';
import {useTheme} from '../modules/app/theme';
import StatusBarAware from '../components/Shared/StatusBarAware';
import CustomAlert, {CustomAlertProps} from '../components/Shared/CustomAlert';
import {isWeb} from '../../env';
import {acceptedEmailDomains} from '../localization/localization';
import AppType from '../../AppType';
import ApproveTermsCheckbox from '../components/Login/ApproveTermsCheckbox';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Constants.colors.mainBackground,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    keyboardAvoidingView: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    headerSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        height: 50,
        marginBottom: -20,
        backgroundColor: 'white',
        zIndex: 2,
        opacity: 0.9,
        // backgroundColor: 'red',
    },
    signupTitleSection: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 15,
    },
    signupTitle: {
        fontSize: 25,
    },
    mainSection: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: deviceInfoModule.isTablet() ? '20%' : 50,
        width: '100%',
        maxWidth: 600,
    },
    footerSection: {
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'green',
    },
    footerText: {
        fontSize: 10,
    },
    signinButtonsSection: {
        width: '100%',
        minWidth: 260,
        height: normalize(40),
        maxHeight: 50,
        flexDirection: 'row',
        marginTop: 8,
    },
    signinButton: {
        backgroundColor: Constants.colors.secondaryBackground,
        flex: 4,
        margin: 1,
        borderRadius: 4,
        justifyContent: 'center',
    },
    signupButton: {
        backgroundColor: Constants.colors.success,
        flex: 3,
        ...Constants.elevation.one,
        margin: 1,
        marginEnd: 5,
        borderRadius: Constants.borderRadius.small,
        justifyContent: 'center',
    },
    signinButtonText: {
        fontSize: normalize(12),
        textAlign: 'center',
    },
    signupButtonText: {
        fontFamily: Constants.fonts.Bold,
        fontSize: normalize(12),
        color: 'white',
        textAlign: 'center',
    },
    orSection: {
        marginVertical: normalize(10),
        marginTop: normalize(7 + 7),
    },
    orText: {
        fontSize: normalize(12),
    },
    input: {
        width: '100%',
        minWidth: 260,
        height: normalize(35),
        maxHeight: 50,
        borderRadius: 4,
        backgroundColor: 'white',
        marginBottom: 3,
        fontSize: normalize(11),
    },
});

const mapDispatchToProps = (
    dispatch: ReduxDispatch,
    getState: ReduxGetState = store.getState,
) => ({
    signup: (data: ActionParameter<typeof authActions.signup>) =>
        authActions.signup(data)(dispatch, getState),
    freeErrors: () => authActions.freeErrors()(dispatch),
    facebookAuth: (data: ActionParameter<typeof authActions.facebookAuth>) =>
        authActions.facebookAuth(data)(dispatch, getState),
    twitterAuth: (data: ActionParameter<typeof authActions.twitterAuth>) =>
        authActions.twitterAuth(data)(dispatch, getState),
    appleAuthentication: (
        data: ActionParameter<typeof authActions.appleAuthentication>,
    ) => authActions.appleAuthentication(data)(dispatch, getState),
});

const mapStateToProps = (state: ReducerState) => ({
    authReducer: state.authReducer,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    backgroundColor: string;
};

const SignupScreen = (props: Props & NavigationProp<any>) => {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [hardLogin, setHardLogin] = useState(false);
    const [hasApprovedTerms, setHasApprovedTerms] = useState(false);

    const [alert, setAlert] = useState<CustomAlertProps | null>(null);

    useEffect(() => {
        if (Platform.OS !== 'web') {
            Purchases?.canMakePayments().then((canMakePayments: boolean) => {
                if (canMakePayments === false && Platform.OS === 'android') {
                    setHardLogin(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        // @ts-ignore
        props.navigation?.setOptions({
            title: `${lang().screenTitles.signup}`,
        });
    }, []);

    const isSignupDisabled = () => {
        if (isWeb) {
            return (
                email === '' || password === '' || emailWebError() !== undefined
            );
        }
        return false;
    };

    const emailWebError = () => {
        if (isWeb && email !== '') {
            if (!email.includes('@') || !email.includes('.')) {
                return undefined;
            }

            const hasAValidEmail = acceptedEmailDomains.some(
                acceptedEmailDomain => {
                    if (email.includes(`@${acceptedEmailDomain}`)) {
                        return true;
                    }
                    return false;
                },
            );

            if (!hasAValidEmail) {
                return lang().email_authentication.web_email_not_valid;
            }
        }

        return undefined;
    };

    const didUserApproveTerms = () => {
        if (AppType.CURRENT_APP === 'NEZMO') {
            if (hasApprovedTerms) {
                return true;
            }
            return false;
        }
        return true;
    };

    return (
        <SafeAreaView
            style={{...styles.container, backgroundColor: theme.BACKGROUND}}
        >
            <StatusBarAware theme={theme} />
            <View
                style={{
                    ...styles.headerSection,
                    backgroundColor: theme.BACKGROUND,
                }}
            >
                <ErrorSection
                    darkBackground={theme.MODE === 'dark'}
                    error={props.authReducer.signupError}
                />
            </View>
            <View style={styles.mainSection}>
                <KeyboardAvoidingView
                    enabled={Platform.OS === 'ios'}
                    behavior="padding"
                    keyboardVerticalOffset={100}
                    style={styles.keyboardAvoidingView}
                >
                    <View style={styles.signupTitleSection}>
                        <T style={{...styles.signupTitle, color: theme.TEXT}}>
                            {lang().create_account_title}
                        </T>
                    </View>
                    <Input
                        style={{
                            ...styles.input,
                            backgroundColor: theme.INPUT_BACKGROUND,
                            borderColor: theme.PRIMARY_DEVIDER,
                            color: theme.TEXT,
                        }}
                        placeholder={lang().username}
                        autoComplete="username"
                        clearButtonMode="while-editing"
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={name}
                        maxLength={20}
                        onChangeText={(text: string) => {
                            if (props.authReducer.signupError !== undefined) {
                                props.freeErrors();
                            }
                            setName(text);
                        }}
                        testID="signupUsernameInput"
                    />
                    <Input
                        style={{
                            ...styles.input,
                            backgroundColor: theme.INPUT_BACKGROUND,
                            borderColor: theme.PRIMARY_DEVIDER,
                            color: theme.TEXT,
                        }}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                        autoComplete="email"
                        autoCapitalize="none"
                        clearButtonMode="while-editing"
                        autoCorrect={false}
                        placeholder={lang().email}
                        error={emailWebError()}
                        value={email}
                        onChangeText={(text: string) => {
                            if (props.authReducer.signupError !== undefined) {
                                props.freeErrors();
                            }
                            setEmail(text);
                        }}
                        testID="signupEmailInput"
                    />
                    <Input
                        style={{
                            ...styles.input,
                            backgroundColor: theme.INPUT_BACKGROUND,
                            borderColor: theme.PRIMARY_DEVIDER,
                            color: theme.TEXT,
                        }}
                        textContentType="newPassword"
                        autoComplete="off"
                        clearButtonMode="while-editing"
                        autoCorrect={false}
                        secureTextEntry
                        placeholder={lang().password}
                        value={password}
                        onChangeText={(text: string) => {
                            if (props.authReducer.signupError !== undefined) {
                                props.freeErrors();
                            }
                            if (Platform.OS === 'ios') {
                                setPasswordConfirmation(text);
                            }
                            setPassword(text);
                        }}
                        testID="signupPasswordInput"
                    />
                    {Platform.OS !== 'ios' && (
                        <Input
                            style={{
                                ...styles.input,
                                backgroundColor: theme.INPUT_BACKGROUND,
                                borderColor: theme.PRIMARY_DEVIDER,
                                color: theme.TEXT,
                            }}
                            textContentType="password"
                            autoComplete="off"
                            clearButtonMode="while-editing"
                            autoCorrect={false}
                            secureTextEntry
                            placeholder={lang().password_confirm}
                            value={password_confirmation}
                            onChangeText={(text: string) => {
                                if (
                                    props.authReducer.signupError !== undefined
                                ) {
                                    props.freeErrors();
                                }
                                setPasswordConfirmation(text);
                            }}
                            testID="signupPasswordConfirmationInput"
                        />
                    )}
                    {AppType.CURRENT_APP === 'NEZMO' && (
                        <ApproveTermsCheckbox
                            theme={theme}
                            hasApproved={hasApprovedTerms}
                            setHasApproved={setHasApprovedTerms}
                        />
                    )}
                    <View style={styles.signinButtonsSection}>
                        <TouchableOpacity
                            style={{
                                ...styles.signupButton,
                                backgroundColor: didUserApproveTerms()
                                    ? theme.SUCCESS
                                    : theme.DISABLED,
                            }}
                            disabled={
                                isSignupDisabled() || !didUserApproveTerms()
                            }
                            onPress={() => {
                                const signup = () => {
                                    const redirectToMainScreen = () => {
                                        props.navigation.navigate(
                                            SCREENS.WELCOME,
                                        );
                                    };

                                    props.signup({
                                        email,
                                        password,
                                        password_confirmation,
                                        name,
                                        redirectToMainScreen,
                                    });
                                };

                                if (hasBadWords(name)) {
                                    setAlert({
                                        show: true,
                                        message:
                                            lang().warn_name_contains_bad_word,
                                        confirmText: lang().confirm_bad_name,
                                        confirmColor: theme.DANGER,
                                        cancelText: lang().cancel_bad_name,
                                        theme,
                                        onConfirmPressed: () => {
                                            signup();
                                            setAlert(null);
                                        },
                                        onCancelPressed: () => {
                                            setAlert(null);
                                        },
                                        onDismiss: () => {
                                            setAlert(null);
                                        },
                                    });
                                } else {
                                    signup();
                                }
                            }}
                            testID="signupButton"
                        >
                            <T
                                style={{
                                    ...styles.signupButtonText,
                                    color: theme.TEXT_WHITE,
                                }}
                            >
                                {lang().create_account}
                            </T>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{
                                ...styles.signinButton,
                                backgroundColor:
                                    theme.MODE === 'light'
                                        ? theme.INPUT_BACKGROUND
                                        : theme.INPUT_BACKGROUND,
                            }}
                            onPress={() => {
                                tinyClick();
                                props.navigation.navigate(SCREENS.WELCOME);
                            }}
                        >
                            <T
                                style={{
                                    ...styles.signinButtonText,
                                    color: theme.TEXT,
                                }}
                            >
                                {lang().already_have_account}
                            </T>
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
                {!hardLogin && AppType.CURRENT_APP !== 'NEZMO' && (
                    <SocialLoginButtons
                        theme={theme}
                        facebookAuth={props.facebookAuth}
                        appleAuthentication={props.appleAuthentication}
                        navigation={props.navigation}
                        twitterAuth={props.twitterAuth}
                    />
                )}
                {AppType.CURRENT_APP === 'FADFADAH' && (
                    <PrivacyTermsSection
                        isLogin
                        theme={theme}
                    />
                )}
                {alert && (
                    <CustomAlert
                        show={alert.show}
                        message={alert.message}
                        confirmText={alert.confirmText}
                        confirmColor={alert.confirmColor}
                        cancelText={alert.cancelText}
                        theme={alert.theme}
                        onConfirmPressed={alert.onConfirmPressed}
                        onCancelPressed={alert.onCancelPressed}
                        onDismiss={alert.onDismiss}
                    />
                )}
                <LoadingModal show={props.authReducer.signupLoading} />
            </View>
        </SafeAreaView>
    );
};

export default connector(SignupScreen);
