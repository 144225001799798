import {NavigationProp, useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
    Dimensions,
    I18nManager,
    Image,
    Platform,
    TouchableOpacity,
    View,
} from 'react-native';
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';
import Constants from '../../assets/Constants';
import lang from '../../localization';
import {SCREENS} from '../../screens/Screens';
import {T} from '../Shared';
import {Theme} from '../../assets/Colors';
import {
    getDarkModeHomeButtonSectionBackground,
    getDarkModeHomeButtonTextColor,
} from '../../modules/app/theme';
import AppType from '../../../AppType';

const plusIcon = require('../../assets/images/plusIcon.png');

const CreateConversationFloatingButton = ({theme}: {theme: Theme}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigation = useNavigation();

    return (
        <View
            style={{
                position: 'absolute',
                end: 0,
                bottom: Platform.select({
                    ios: Platform.OS === 'ios' && Platform.isPad ? 80 : 55,
                    android: 80,
                    web: 30,
                }),
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                paddingEnd: 20,
            }}
        >
            {isExpanded && (
                <OptionButtons
                    navigation={navigation}
                    setIsExpanded={setIsExpanded}
                    theme={theme}
                />
            )}
            <TouchableOpacity
                accessibilityLabel="add"
                accessibilityHint="Create a new conversation"
                onPress={() => {
                    setIsExpanded(!isExpanded);
                }}
                style={{
                    ...Constants.elevation.two,
                    borderRadius: 53,
                    opacity: 0.9,
                    height: 53,
                    width: 53,
                }}
            >
                <View
                    style={{
                        backgroundColor:
                            theme.MODE === 'dark'
                                ? (getDarkModeHomeButtonSectionBackground(
                                      theme.SUCCESS,
                                  ) as string)
                                : theme.SUCCESS,
                        height: '100%',
                        width: '100%',
                        borderRadius: 100,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image
                        source={plusIcon}
                        style={{
                            height: 25,
                            width: 25,
                            resizeMode: 'contain',
                            tintColor:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.SUCCESS,
                                      ) as string)
                                    : theme.TEXT_WHITE,
                        }}
                    />
                </View>
            </TouchableOpacity>
        </View>
    );
};

const OptionButtons = ({
    theme,
    navigation,
    setIsExpanded,
}: {
    theme: Theme;
    navigation: NavigationProp<any>;
    setIsExpanded: (value: boolean) => void;
}) => {
    const opacity = useSharedValue(0);
    const translateX = useSharedValue(0);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: opacity.value,
            transform: [{translateX: translateX.value}],
        };
    });

    useEffect(() => {
        opacity.value = 0;
        translateX.value = I18nManager.isRTL ? -100 : 100;
        opacity.value = withTiming(1, {duration: 500});
        translateX.value = withTiming(0, {
            duration: 300,
            easing: Easing.bezier(0.0, 0.0, 0.2, 1),
        });
    });

    return (
        <Animated.View
            style={[
                {flexDirection: 'column', alignItems: 'flex-end'},
                animatedStyle,
            ]}
        >
            <View
                style={{
                    zIndex: 2,
                    height: 48,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {AppType.select({
                    fadfadah: (
                        <TouchableOpacity
                            onPress={() => {
                                setIsExpanded(false);
                                navigation.navigate(
                                    SCREENS.CREATE_CONVERSATION_FROM_LINK as never,
                                );
                            }}
                            style={{
                                backgroundColor:
                                    theme.MODE === 'dark'
                                        ? (getDarkModeHomeButtonSectionBackground(
                                              theme.PEACH,
                                          ) as string)
                                        : theme.PEACH,
                                paddingHorizontal: 15,
                                paddingVertical: 8,
                                marginBottom: 10,
                                borderRadius: 30,
                            }}
                        >
                            <T
                                style={{
                                    color:
                                        theme.MODE === 'dark'
                                            ? (getDarkModeHomeButtonTextColor(
                                                  theme.PEACH,
                                              ) as string)
                                            : theme.TEXT_WHITE,
                                    fontFamily: Constants.fonts.SemiBold,
                                }}
                            >
                                {
                                    lang().create_conversation_floating_button
                                        .create_conversation_using_link
                                }
                            </T>
                        </TouchableOpacity>
                    ),
                    nezmo: <></>,
                })}
            </View>
            <View
                style={{
                    zIndex: 2,
                    flexDirection: 'row',
                }}
            >
                <TouchableOpacity
                    onPress={() => {
                        setIsExpanded(false);
                        navigation.navigate(
                            SCREENS.RANDOM_CONVERSATION as never,
                        );
                    }}
                    style={{
                        backgroundColor:
                            theme.MODE === 'dark'
                                ? (getDarkModeHomeButtonSectionBackground(
                                      theme.BABY_BLUE,
                                  ) as string)
                                : theme.BABY_BLUE,
                        paddingHorizontal: 15,
                        paddingVertical: 8,
                        marginBottom: 10,
                        borderRadius: 30,
                    }}
                >
                    <T
                        style={{
                            color:
                                theme.MODE === 'dark'
                                    ? (getDarkModeHomeButtonTextColor(
                                          theme.BABY_BLUE,
                                      ) as string)
                                    : theme.TEXT_WHITE,
                            fontFamily: Constants.fonts.SemiBold,
                        }}
                    >
                        {
                            lang().create_conversation_floating_button
                                .create_random_conversation
                        }
                    </T>
                </TouchableOpacity>
            </View>
        </Animated.View>
    );
};

export default CreateConversationFloatingButton;
