import {Platform} from 'react-native';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import {useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {actions as authActions} from '../auth';
import * as t from './actionTypes';
import store from '../redux/store';
import emitter from '../helpers/Emitter';
import {isWeb} from '../../../env';
import messaging from './react-native-firebase-notifications';

let fcmToken;

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: true,
    }),
});

export function useRegesterNotifications() {
    useEffect(() => {
        if (!isWeb) {
            Notifications.dismissAllNotificationsAsync();
            Notifications.setBadgeCountAsync(0);
            registerForPushNotificationsAsync();
        }
    }, []);
}

export async function registerForPushNotificationsAsync() {
    let token;

    if (Platform.OS === 'android') {
        await Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    if (Device.isDevice) {
        const {status: existingStatus} =
            await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const {status} = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            store.dispatch({
                type: t.DISABLE_NOTIFICATIONS,
            });
            console.log('Failed to get push token for push notification!');
            return;
        }
        await generateTokenAndRegisterForRemoteNotifications();
        store.dispatch({
            type: t.ENABLE_NOTIFICATIONS,
        });
    } else {
        console.log('Must use physical device for Push Notifications');
    }

    return token;
}

async function generateTokenAndRegisterForRemoteNotifications() {
    try {
        messaging().onTokenRefresh(async fcmRefreshedToken => {
            await authActions.attachNotificationKey(fcmRefreshedToken);
        });

        fcmToken = await messaging().getToken();
        console.log({fcmToken});
        await authActions.attachNotificationKey(fcmToken);
    } catch (e) {
        // no one cares
    }
}

// this is used for android because andriod is not responding to expo notifications
export function notificationsListener() {
    messaging()
        .getInitialNotification()
        .then(async remoteMessage => {
            if (remoteMessage) {
                const lastIdleNotificationID = await AsyncStorage.getItem(
                    'lastIdleNotificationID',
                );
                const notificationID = remoteMessage.messageId;
                if (notificationID !== lastIdleNotificationID) {
                    emitter.emit('notificationOpened', {
                        data: remoteMessage.data,
                        type: remoteMessage.data.type,
                    });
                }
                AsyncStorage.setItem('lastIdleNotificationID', notificationID);
            }
        });

    messaging().onNotificationOpenedApp(remoteMessage => {
        if (remoteMessage) {
            emitter.emit('notificationOpened', {
                data: remoteMessage.data,
                type: remoteMessage?.data?.type,
            });
        }
    });
}
